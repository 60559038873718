import { mapEnumToOptions } from '@abp/ng.core';

export enum ChargeOnEventEnum {
  UnKnown = 0,
  SubscriptionCreation = 1,
  SubscriptionTrialStart = 2,
  PlanActivation = 3,
  SubscriptionActivation = 4,
  ContractTermination = 5,
  OnDemand = 6,
}

export const chargeOnEventEnumOptions = mapEnumToOptions(ChargeOnEventEnum);
