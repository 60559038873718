import { mapEnumToOptions } from '@abp/ng.core';

export enum Customer_BillingDayOfWeekEnum {
  UnKnown = 0,
  Sunday = 1,
  Monday = 2,
  Tuesday = 3,
  Wednesday = 4,
  Thursday = 5,
  Friday = 6,
  Saturday = 7,
}

export const customer_BillingDayOfWeekEnumOptions = mapEnumToOptions(Customer_BillingDayOfWeekEnum);
