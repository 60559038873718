import { mapEnumToOptions } from '@abp/ng.core';

export enum PricingModelEnum {
  UnKnown = 0,
  FlatFee = 1,
  PerUnit = 2,
  Tiered = 3,
  Volume = 4,
  Stairstep = 5,
}

export const pricingModelEnumOptions = mapEnumToOptions(PricingModelEnum);
