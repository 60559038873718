import type {
  CategoryPostListInputDto,
  CreateUpdatePostDto,
  PostCategoryReadDto,
  PostDeleteResponseDto,
  PostDto,
  PostGetListInput,
  PostSocialAccountDto,
  SocialAccountPostListInputDto,
} from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  apiName = 'Default';

  copyPost = (postId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PostDto>(
      {
        method: 'POST',
        url: `/api/app/post/copy-post/${postId}`,
      },
      { apiName: this.apiName, ...config },
    );

  create = (input: CreateUpdatePostDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PostDto>(
      {
        method: 'POST',
        url: '/api/app/post',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/app/post/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  deletePublishPost = (postId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PostDeleteResponseDto[]>(
      {
        method: 'DELETE',
        url: `/api/app/post/publish-post/${postId}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PostDto>(
      {
        method: 'GET',
        url: `/api/app/post/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: PostGetListInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PostDto>>(
      {
        method: 'GET',
        url: '/api/app/post',
        params: {
          message: input.message,
          mediaUrls: input.mediaUrls,
          categoryId: input.categoryId,
          isDraft: input.isDraft,
          isReQueue: input.isReQueue,
          isExpireAfter: input.isExpireAfter,
          expireTimes: input.expireTimes,
          isExpireSpecificDate: input.isExpireSpecificDate,
          expireSpecificDate: input.expireSpecificDate,
          isCustomizedPost: input.isCustomizedPost,
          hashtagIds: input.hashtagIds,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getPostByCategoryId = (inputDto: CategoryPostListInputDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PostCategoryReadDto[]>(
      {
        method: 'GET',
        url: '/api/app/post/post-by-category-id',
        params: { categoryId: inputDto.categoryId, postStatus: inputDto.postStatus },
      },
      { apiName: this.apiName, ...config },
    );

  getPostBySocialAccountId = (
    inputDto: SocialAccountPostListInputDto,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, PostSocialAccountDto[]>(
      {
        method: 'GET',
        url: '/api/app/post/post-by-social-account-id',
        params: { socialAccountId: inputDto.socialAccountId, postStatus: inputDto.postStatus },
      },
      { apiName: this.apiName, ...config },
    );

  getTenantPostCount = (startDate: string, endDate: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, number>(
      {
        method: 'GET',
        url: '/api/app/post/tenant-post-count',
        params: { startDate, endDate },
      },
      { apiName: this.apiName, ...config },
    );

  publishAccount = (postId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `/api/app/post/publish-account/${postId}`,
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: CreateUpdatePostDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PostDto>(
      {
        method: 'PUT',
        url: `/api/app/post/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  updateIsDraftOnSchedular = (postId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>(
      {
        method: 'PUT',
        url: `/api/app/post/is-draft-on-schedular/${postId}`,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
