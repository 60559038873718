<kendo-dialog class="share-post-wrapper" [animation]="true">
  <kendo-dialog-titlebar (click)="closePopup()"> </kendo-dialog-titlebar>
  <kendo-dialog-body>
    <div class="text-align-center share-post-message">
      <img class="display-block" src="assets/images/success-check.svg" />
      <p class="font-size-20 mt-1 message">{{ message }}</p>
      <div class="publish-date">
        <p>
          Your available credits: <span class="fw-bold">{{ availableCredits }}</span>
        </p>
      </div>
    </div>
  </kendo-dialog-body>
</kendo-dialog>
