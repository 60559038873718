<div class="q-content">
  <div class="q-content-header">
    <div class="d-flex gap-15 align-items-center justify-content-between wp-100 mb-2 flex-wrap">
      <kendo-textbox
        placeholder="Search Here"
        class="search"
        [(ngModel)]="searchText"
        (valueChange)="onFilter($event)"
      >
        <ng-template kendoTextBoxPrefixTemplate>
          <i class="fas fa-search k-icon font-size-14"></i>
        </ng-template>
      </kendo-textbox>
      <div class="d-flex gap-15 align-items-center">
        <button kendoButton (click)="addHashtag()">
          <i class="far fa-plus pr-5"></i> Add Hashtag
        </button>
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center q-toggle-button">
            <button
              [class.k-selected]="isListView"
              kendoButton
              fillMode="flat"
              (click)="showListView(true)"
            >
              <i class="far fa-list-ul font-size-16"></i>
            </button>
            <button
              [class.k-selected]="!isListView"
              kendoButton
              fillMode="flat"
              (click)="showListView(false)"
            >
              <i class="far fa-th-large font-size-16"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="q-content-bg" *ngIf="isListView">
    <div class="grid-wrapper">
      <kendo-grid
        #grid
        [data]="gridView"
        [pageSize]="pageSize"
        [skip]="skip"
        [pageable]="
          hashtagList === undefinded
            ? false
            : {
                buttonCount: buttonCount,
                info: info,
                type: type,
                pageSizes: pageSizes,
                previousNext: previousNext,
                position: pagerposition
              }
        "
        [height]="gridHeight"
        (pageChange)="pageChange($event)"
        [sortable]="{
          allowUnsort: true,
          mode: multiple ? 'multiple' : 'single'
        }"
        [sort]="sort"
        (sortChange)="sortChange($event)"
      >
        <kendo-grid-column field="hashtagCollectionName" title="Collection Name" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="hashtags" title="Hashtags" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex align-items-center justify-content-between">
              {{ dataItem.hashtags }}
              <ul class="grid-action-list">
                <div class="d-flex gap-10">
                  <li>
                    <a title="Edit" (click)="onEditCollection(dataItem.id)">
                      <i class="far fa-pen"></i>
                    </a>
                  </li>
                  <li *ngIf="!dataItem.isDefault">
                    <a title="Delete" (click)="delete(dataItem)">
                      <i class="far fa-trash-alt"></i>
                    </a>
                  </li>
                </div>
              </ul>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
  <div *ngIf="!isListView">
    <div class="d-flex-row">
      <div
        class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col mb-20"
        *ngFor="let hashtag of hashtagList"
      >
        <kendo-card class="wp-100 h-100">
          <kendo-card-header>
            <div class="d-flex align-items-center justify-content-between wp-100">
              <h1 kendoCardTitle title="hashtag.hashtagCollectionName" class="clamp">
                {{ hashtag.hashtagCollectionName }}
              </h1>
              <kendo-dropdownbutton [data]="actionMenu" class="dropdown-button-icon">
                <i class="fas fa-ellipsis"></i>
                <ng-template kendoDropDownButtonItemTemplate let-dataItem>
                  <div *ngIf="dataItem.text === 'Edit'" (click)="onEditCollection(hashtag.id)">
                    <i [ngClass]="dataItem.iconClass"></i>
                    <span class="ml-10">{{ dataItem.text }}</span>
                  </div>
                  <div *ngIf="dataItem.text === 'Delete'" (click)="delete(hashtag)">
                    <i [ngClass]="dataItem.iconClass"></i>
                    <span class="ml-10">{{ dataItem.text }}</span>
                  </div>
                </ng-template>
              </kendo-dropdownbutton>
            </div>
          </kendo-card-header>
          <kendo-card-body>
            <p kendoCardSubtitle class="fw-normal mb-5">Description:</p>
            <div class="height-clamp" title="{{ hashtag.hashtags }}">
              {{ hashtag.hashtags }}
            </div>
          </kendo-card-body>
        </kendo-card>
      </div>
      <div *ngIf="hashtagList.length === 0">No records available.</div>
    </div>
  </div>
</div>

<div kendoDialogContainer></div>
