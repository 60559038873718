<div class="preview-list-wrapper">
  <div class="preview-list-items" *ngFor="let ad of profilesDetails; let i = index">
    <div class="q-preview mb-24" *ngIf="ad.accountType === rolesRights.Instagram && ad.isSelected">
      <div class="q-preview-header">
        <div class="d-flex justify-content-between">
          <div class="d-flex gap-10">
            <kendo-badge-container>
              <ng-container *ngIf="ad.img; else noImageInstagramTemplate">
                <kendo-avatar rounded="full" size="large" [border]="true" [imageSrc]="ad.img"></kendo-avatar>
              </ng-container>

              <ng-template #noImageInstagramTemplate>
                <kendo-avatar rounded="full" size="large" [border]="true"
                  [initials]="getInitials(ad.accountName)"></kendo-avatar>
              </ng-template>
              <kendo-badge size="large" [align]="badgeAlign" [position]="position" [cutoutBorder]="true"><i [ngClass]="{
                    'instagram-icon-color':
                      commonService.getIconClass(ad.accountType) === 'fa-instagram-square',
                    'text-linked-in-icon-color':
                      commonService.getIconClass(ad.accountType) === 'fa-linkedin-in',
                    'text-twitter-icon-color':
                      commonService.getIconClass(ad.accountType) === 'fa-x-twitter',
                    'text-facebook-icon-color':
                      commonService.getIconClass(ad.accountType) === 'fa-facebook-f',
                    'text-youtube-icon-color':
                      commonService.getIconClass(ad.accountType) === 'fa-youtube'
                  }" class="fab {{ commonService.getIconClass(ad.accountType) }}"></i>
              </kendo-badge>
            </kendo-badge-container>
            <div>
              <h1 kendoCardTitle class="mb-5 clamp">{{ ad.accountName }}</h1>
              <p kendoCardSubtitle class="mb-5 text-secondary-text">n Followers</p>
              <p kendoCardSubtitle class="m-0 text-secondary-text">Just Now</p>
            </div>
          </div>
          <div class="d-flex gap-20 preview-list-buttons">
            <i class="fas fa-ellipsis-h"></i>
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <div class="q-preview-body">
        <div class="text-caption">
          <kendo-badge-container *ngIf="ad.caption?.length > 120; else truncatedTemplateInsta">
            <div [innerHTML]="showFullCaption[i] ? ad.caption : (ad.caption | slice: 0 : 120)"></div>
            <a href="#" (click)="toggleCaptionDisplay($event, i)">{{
              showFullCaption[i] ? '...less' : '...more'
              }}</a>
          </kendo-badge-container>
          <ng-template #truncatedTemplateInsta>
            <div>
              <p [innerHTML]="ad.caption"></p>
            </div>
          </ng-template>
        </div>
        <div *ngIf="ad.uploadedImages.length === 1" class="img-wrapper">
          <img *ngIf="
              commonService.isImage(ad.uploadedImages[0].value) ||
              commonService.isGIF(ad.uploadedImages[0].value)
            " [src]="ad.uploadedImages[0].base64Value" alt="" />
          <video class="sizing" *ngIf="commonService.isVideo(ad.uploadedImages[0].value)" controls loop autoplay>
            <source [src]="ad.uploadedImages[0].base64Value" type="video/mp4" />
          </video>
        </div>
        <div *ngIf="ad.uploadedImages.length > 1">
          <kendo-scrollview kendoCardMedia [data]="ad.uploadedImages" width="100%" height="250px" [arrows]="true"
            [pageable]="true">
            <ng-template let-item="item">
              <img *ngIf="commonService.isImage(item.value) || commonService.isGIF(item.value)"
                src="{{ item.base64Value }}" alt="NoImage" [ngStyle]="{ minWidth: '100%' }" draggable="false" />
              <video *ngIf="commonService.isVideo(item.value)" class="sizing" controls loop autoplay height="100%"
                width="100%">
                <source [src]="item.base64Value" type="video/mp4" />
              </video>
            </ng-template>
          </kendo-scrollview>
        </div>
      </div>
      <div class="q-preview-footer">
        <div class="d-flex align-items-center gap-30 q-preview-footer-container">
          <div class="d-flex gap-10 align-items-center">
            <i class="far fa-thumbs-up"></i>
            <span class="font-size-10 text-secondary-text">Like</span>
          </div>
          <div class="d-flex gap-10 align-items-center">
            <i class="far fa-comments"></i>
            <span class="font-size-10 text-secondary-text">Comment</span>
          </div>
          <div class="d-flex gap-10 align-items-center">
            <i class="fas fa-retweet"></i>
            <span class="font-size-10 text-secondary-text">Repost</span>
          </div>
          <div class="d-flex gap-10 align-items-center">
            <i class="fas fa-share-alt"></i>
            <span class="font-size-10 text-secondary-text">Share</span>
          </div>
        </div>
      </div>
    </div>
    <div class="q-preview mb-24" *ngIf="ad.accountType === rolesRights.Facebook && ad.isSelected">
      <div class="q-preview-header">
        <div class="d-flex justify-content-between">
          <div class="d-flex gap-10">
            <kendo-badge-container>
              <ng-container *ngIf="ad.img; else noImageFacebookTemplate">
                <kendo-avatar rounded="full" size="large" [border]="true" [imageSrc]="ad.img"></kendo-avatar>
              </ng-container>

              <ng-template #noImageFacebookTemplate>
                <kendo-avatar rounded="full" size="large" [border]="true"
                  [initials]="getInitials(ad.accountName)"></kendo-avatar>
              </ng-template>
              <kendo-badge size="large" [align]="badgeAlign" [position]="position" [cutoutBorder]="true">
                <i class="fab fa-facebook-f text-facebook-icon-color"></i>
              </kendo-badge>
            </kendo-badge-container>
            <div>
              <h1 kendoCardTitle class="mb-5">{{ ad.accountName }}</h1>
              <p kendoCardSubtitle class="mb-5 text-secondary-text">n Followers</p>
              <p kendoCardSubtitle class="m-0 text-secondary-text">Just Now</p>
            </div>
          </div>
          <div class="d-flex gap-20 preview-list-buttons">
            <i class="fas fa-ellipsis-h"></i>
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <div class="q-preview-body">
        <div class="text-caption">
          <kendo-badge-container *ngIf="ad.caption?.length > 120; else truncatedTemplateFB">
            <div [innerHTML]="showFullCaption[i] ? ad.caption : (ad.caption | slice: 0 : 120)"></div>
            <a href="#" (click)="toggleCaptionDisplay($event, i)">{{
              showFullCaption[i] ? '...less' : '...more'
              }}</a>
          </kendo-badge-container>
          <ng-template #truncatedTemplateFB>
            <p [innerHTML]="ad.caption"></p>
          </ng-template>
        </div>
        <div class="card-body grid-container" [ngClass]="{
            'two-images': ad.uploadedImages.length === 2,
            'three-images': ad.uploadedImages.length === 3,
            'four-images': ad.uploadedImages.length === 4,
            'five-or-more-images': ad.uploadedImages.length >= 5
          }">
          <div *ngIf="ad.uploadedImages.length >= 4" class="img-wrapper">
            <img *ngIf="
                commonService.isImage(ad.uploadedImages[0].value) ||
                commonService.isGIF(ad.uploadedImages[0].value)
              " [src]="ad.uploadedImages[0].base64Value" alt="" class="large-image" />
            <video class="sizing" *ngIf="commonService.isVideo(ad.uploadedImages[0].value)" loop autoplay controls>
              <source [src]="ad.uploadedImages[0].base64Value" type="video/mp4" />
            </video>
          </div>
          <div class="image-gallary">
            <div class="img-wrapper" *ngFor="let url of ad.uploadedImages | slice: 0 : 5; let i = index"
              [ngClass]="{ 'position-relative': ad.uploadedImages.length >= 5 && i === 4 }">
              <img *ngIf="commonService.isImage(url.value) || commonService.isGIF(url.value)" [src]="url.base64Value"
                alt="" />
              <video class="sizing" *ngIf="commonService.isVideo(url.value)" controls loop autoplay>
                <source [src]="url.base64Value" type="video/mp4" />
              </video>

              <div *ngIf="ad.uploadedImages.length >= 5 && i === 4" class="overlay">
                <div class="d-flex align-items-center justify-content-center h-100 overlay-text">
                  +{{ ad.uploadedImages.length - 3 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="q-preview-footer">
        <div class="d-flex align-items-center gap-30 q-preview-footer-container">
          <div class="d-flex gap-10 align-items-center">
            <i class="far fa-thumbs-up"></i>
            <span class="font-size-10 text-secondary-text">Like</span>
          </div>
          <div class="d-flex gap-10 align-items-center">
            <i class="far fa-comments"></i>
            <span class="font-size-10 text-secondary-text">Comment</span>
          </div>
          <div class="d-flex gap-10 align-items-center">
            <i class="fas fa-share-alt"></i>
            <span class="font-size-10 text-secondary-text">Share</span>
          </div>
        </div>
      </div>
    </div>
    <div class="q-preview mb-24" *ngIf="
        (ad.accountType === rolesRights.LinkedIn ||
          ad.accountType === rolesRights.LinkedInOrganization) &&
        ad.isSelected
      ">
      <div class="q-preview-header">
        <div class="d-flex justify-content-between">
          <div class="d-flex gap-10">
            <kendo-badge-container>
              <ng-container *ngIf="ad.img; else noImageLinkedInTemplate">
                <kendo-avatar rounded="full" size="large" [border]="true" [imageSrc]="ad.img"></kendo-avatar>
              </ng-container>

              <ng-template #noImageLinkedInTemplate>
                <kendo-avatar rounded="full" size="large" [border]="true"
                  [initials]="getInitials(ad.accountName)"></kendo-avatar>
              </ng-template>
              <kendo-badge size="large" [align]="badgeAlign" [position]="position" [cutoutBorder]="true">
                <i class="fab fa-linkedin-in text-linked-in-icon-color"></i>
              </kendo-badge>
            </kendo-badge-container>
            <div>
              <h1 kendoCardTitle class="mb-5">{{ ad.accountName }}</h1>
              <p kendoCardSubtitle class="mb-5 text-secondary-text">n Followers</p>
              <p kendoCardSubtitle class="m-0 text-secondary-text">Just Now</p>
            </div>
          </div>
          <div class="d-flex gap-20 preview-list-buttons">
            <i class="fas fa-ellipsis-h"></i>
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <div class="q-preview-body">
        <div class="text-caption">
          <kendo-badge-container *ngIf="ad.caption?.length > 120; else truncatedTemplateLinkedIn">
            <div [innerHTML]="showFullCaption[i] ? ad.caption : (ad.caption | slice: 0 : 120)"></div>
            <a href="#" (click)="toggleCaptionDisplay($event, i)">{{
              showFullCaption[i] ? '...less' : '...more'
              }}</a>
          </kendo-badge-container>
          <ng-template #truncatedTemplateLinkedIn>
            <div>
              <p [innerHTML]="ad.caption"></p>
            </div>
          </ng-template>
        </div>
        <div *ngIf="ad.uploadedImages.length === 1">
          <div class="img-wrapper">
            <img *ngIf="
                commonService.isImage(ad.uploadedImages[0].value) ||
                commonService.isGIF(ad.uploadedImages[0].value)
              " [src]="ad.uploadedImages[0].base64Value" alt="" />
            <video class="sizing" *ngIf="commonService.isVideo(ad.uploadedImages[0].value)" controls loop autoplay>
              <source [src]="ad.uploadedImages[0].base64Value" type="video/mp4" />
            </video>
          </div>
        </div>

        <div *ngIf="ad.uploadedImages.length > 1">
          <kendo-scrollview kendoCardMedia [data]="ad.uploadedImages" width="100%" height="250px" [arrows]="true"
            [pageable]="true">
            <ng-template let-item="item">
              <img *ngIf="commonService.isImage(item.value) || commonService.isGIF(item.value)"
                src="{{ item.base64Value }}" alt="NoImage" [ngStyle]="{ minWidth: '100%' }" draggable="false" />
              <video *ngIf="commonService.isVideo(item.value)" class="sizing" controls loop autoplay height="100%"
                width="100%">
                <source [src]="item.base64Value" type="video/mp4" />
              </video>
            </ng-template>
          </kendo-scrollview>
        </div>
      </div>
      <div class="q-preview-footer">
        <div class="d-flex align-items-center gap-30 q-preview-footer-container">
          <div class="d-flex gap-10 align-items-center">
            <i class="far fa-thumbs-up"></i>
            <span class="font-size-10 text-secondary-text">Like</span>
          </div>
          <div class="d-flex gap-10 align-items-center">
            <i class="far fa-comments"></i>
            <span class="font-size-10 text-secondary-text">Comment</span>
          </div>
          <div class="d-flex gap-10 align-items-center">
            <i class="fas fa-retweet"></i>
            <span class="font-size-10 text-secondary-text">Repost</span>
          </div>
          <div class="d-flex gap-10 align-items-center">
            <i class="fas fa-share-alt"></i>
            <span class="font-size-10 text-secondary-text">Share</span>
          </div>
        </div>
      </div>
    </div>
    <div class="q-preview mb-24" *ngIf="ad.accountType === rolesRights.Twitter && ad.isSelected">
      <div class="q-preview-header">
        <div class="d-flex justify-content-between">
          <div class="d-flex gap-10">
            <kendo-badge-container>
              <ng-container *ngIf="ad.img; else noImageTwitterTemplate">
                <kendo-avatar rounded="full" size="large" [border]="true" [imageSrc]="ad.img"></kendo-avatar>
              </ng-container>

              <ng-template #noImageTwitterTemplate>
                <kendo-avatar rounded="full" size="large" [border]="true"
                  [initials]="getInitials(ad.accountName)"></kendo-avatar>
              </ng-template>
              <kendo-badge size="large" [align]="badgeAlign" [position]="position" [cutoutBorder]="true">
                <i class="fab fa-x-twitter text-twitter-icon-color"></i>
              </kendo-badge>
            </kendo-badge-container>
            <div>
              <h1 kendoCardTitle class="mb-5">{{ ad.accountName }}</h1>
              <p kendoCardSubtitle class="mb-5 text-secondary-text">n Followers</p>
              <p kendoCardSubtitle class="m-0 text-secondary-text">Just Now</p>
            </div>
          </div>
          <div class="d-flex gap-20 preview-list-buttons">
            <i class="fas fa-ellipsis-h"></i>
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <div class="q-preview-body">
        <div class="text-caption">
          <kendo-badge-container *ngIf="ad.caption?.length > 120; else truncatedTemplateTwitter">
            <div [innerHTML]="showFullCaption[i] ? ad.caption : (ad.caption | slice: 0 : 120)"></div>
            <a href="#" (click)="toggleCaptionDisplay($event, i)">{{
              showFullCaption[i] ? '...less' : '...more'
              }}</a>
          </kendo-badge-container>
          <ng-template #truncatedTemplateTwitter>
            <p [innerHTML]="ad.caption"></p>
          </ng-template>
        </div>
        <div *ngIf="ad.uploadedImages.length === 1">
          <div class="img-wrapper">
            <img *ngIf="
                commonService.isImage(ad.uploadedImages[0].value) ||
                commonService.isGIF(ad.uploadedImages[0].value)
              " [src]="ad.uploadedImages[0].base64Value" alt="" />
            <video class="sizing" *ngIf="commonService.isVideo(ad.uploadedImages[0].value)" controls loop autoplay>
              <source [src]="ad.uploadedImages[0].base64Value" type="video/mp4" />
            </video>
          </div>
        </div>

        <div *ngIf="ad.uploadedImages.length > 1">
          <kendo-scrollview kendoCardMedia [data]="ad.uploadedImages" width="100%" height="250px" [arrows]="true"
            [pageable]="true">
            <ng-template let-item="item">
              <img *ngIf="commonService.isImage(item.value) || commonService.isGIF(item.value)"
                src="{{ item.base64Value }}" alt="NoImage" [ngStyle]="{ minWidth: '100%' }" draggable="false" />
              <video *ngIf="commonService.isVideo(item.value)" class="sizing" controls loop autoplay height="100%"
                width="100%">
                <source [src]="item.base64Value" type="video/mp4" />
              </video>
            </ng-template>
          </kendo-scrollview>
        </div>
      </div>
      <div class="q-preview-footer">
        <div class="d-flex align-items-center gap-30 q-preview-footer-container">
          <div class="d-flex gap-10 align-items-center">
            <i class="far fa-thumbs-up"></i>
            <span class="font-size-10 text-secondary-text">Like</span>
          </div>
          <div class="d-flex gap-10 align-items-center">
            <i class="far fa-comments"></i>
            <span class="font-size-10 text-secondary-text">Comment</span>
          </div>
          <div class="d-flex gap-10 align-items-center">
            <i class="fas fa-retweet"></i>
            <span class="font-size-10 text-secondary-text">Repost</span>
          </div>
          <div class="d-flex gap-10 align-items-center">
            <i class="fas fa-share-alt"></i>
            <span class="font-size-10 text-secondary-text">Share</span>
          </div>
        </div>
      </div>
    </div>
    <div class="q-preview mb-24" *ngIf="ad.accountType === rolesRights.YouTube && ad.isSelected">
      <div class="q-preview-header">
        <div class="d-flex justify-content-between">
          <div class="d-flex gap-10">
            <kendo-badge-container>
              <ng-container *ngIf="ad.img; else noImageYouTubeTemplate">
                <kendo-avatar rounded="full" size="large" [border]="true" [imageSrc]="ad.img"></kendo-avatar>
              </ng-container>

              <ng-template #noImageYouTubeTemplate>
                <kendo-avatar rounded="full" size="large" [border]="true"
                  [initials]="getInitials(ad.accountName)"></kendo-avatar>
              </ng-template>
              <kendo-badge size="large" [align]="badgeAlign" [position]="position" [cutoutBorder]="true">
                <i class="fab fa-youtube text-youtube-icon-color"></i>
              </kendo-badge>
            </kendo-badge-container>
            <div>
              <h1 kendoCardTitle class="mb-5">{{ ad.accountName }}</h1>
              <p kendoCardSubtitle class="mb-5 text-secondary-text">n Followers</p>
              <p kendoCardSubtitle class="m-0 text-secondary-text">Just Now</p>
            </div>
          </div>
          <div class="d-flex gap-20 preview-list-buttons">
            <i class="fas fa-ellipsis-h"></i>
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <div class="q-youtube-preview-body" [ngClass]="{ 'q-preview-body': ad.uploadedImages.length >= 1 }">
        <div class="card-body">
          <div>
            <div *ngFor="let url of ad.uploadedImages | slice: 0 : 5; let i = index"
              [ngClass]="{ 'position-relative': ad.uploadedImages.length >= 5 && i === 4 }">
              <video class="sizing video-contianer" *ngIf="commonService.isVideo(url.value)" controls loop autoplay
                [style.height]="videoHeights[i]" (loadedmetadata)="checkVideoDuration(url.base64Value,i)">
                <source [src]="url.base64Value" type="video/mp4" />
              </video>
              <div *ngIf="videoHeights[i] === '600px'" class="video-wrapper mb-20">
                <div class="q-user-profile-details d-flex align-items-center gap-10"
                  *ngIf="commonService.isVideo(url.value)">
                  <img src="assets/images/user.png" alt="user">
                  <p class="user-name mb-0">@{{ ad.accountName }}</p>
                  <a class="btn">Subscribe</a>
                </div>
                <div class="q-youtube-preview-footer" *ngIf="commonService.isVideo(url.value)">
                  <div class="d-flex align-items-center flex-column gap-15 q-youtube-preview-footer-container">
                    <div class="d-flex gap-10 align-items-center flex-column">
                      <i class="fas fa-thumbs-up"></i>
                      <span class="font-size-10">Like</span>
                    </div>
                    <div class="d-flex gap-10 align-items-center flex-column">
                      <i class="fas fa-thumbs-down"></i>
                      <span class="font-size-10">Dislike</span>
                    </div>
                    <div class="d-flex gap-10 align-items-center flex-column">
                      <i class="fas fa-comments"></i>
                      <span class="font-size-10">Comment</span>
                    </div>
                    <div class="d-flex gap-10 align-items-center flex-column">
                      <i class="fas fa-share-alt"></i>
                      <span class="font-size-10">Share</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isShowPreviewDefaultImage" class="d-flex align-items-center justify-content-center h-100">
  <img src="assets/images/preview.png" alt="preview" />
</div>