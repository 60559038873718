<div class="q-content">
  <div class="q-content-header mb-20">
    <div class="wallet-header d-flex-row">
      <div class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col d-flex gap-20 wallet-balance">
        <div class="wallet-balance-icon">
          <i class="far fa-wallet"></i>
        </div>
        <div class="wallet-balance-desc d-flex justify-content-center flex-column gap-15">
          <div>
            <span class="credit-amount">{{ availableCredits }}/</span
            ><span class="credit-unit">Credits</span>
          </div>
          <p class="mb-0">Current Available Wallet Balance</p>
        </div>
      </div>
      <div
        class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col d-flex justify-content-center align-items-center wallet-validity"
      >
        <span class="date">Valid: {{ validDate | date: 'd MMM, y' }}</span
        ><span><i class="fa-regular fa-clock mr-5"></i>{{ daysLeft }} Day’s Left</span>
      </div>
      <div
        class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col d-flex align-items-center wallet-header-button"
      >
        <button kendoButton (click)="walletPopupToggle()" #walletPopup>
          <i class="far fa-plus mr-10"></i>add credits to wallet
        </button>
        <kendo-popup
          [anchor]="walletPopup.element"
          (anchorViewportLeave)="toggleWalletPopup = false"
          *ngIf="!toggleWalletPopup"
        >
          <div class="q-card">
            <div class="q-card-header d-flex flex-column align-items-center justify-content-center">
              <div class="popup-icon-container">
                <div class="icon-gem"><i class="fal fa-gem"></i></div>
              </div>
              <p class="popup-title">Add Credits to your Wallet</p>
            </div>
            <div class="q-card-body pb-0">
              <div class="d-flex gap-10 mb-20">
                <kendo-textbox
                  [(ngModel)]="noOfCredits"
                  [style.width.px]="183"
                  placeholder="| No. of Credits to buy"
                ></kendo-textbox>

                <kendo-dropdownlist
                  [data]="itemNumber"
                  textField="name"
                  (valueChange)="onItemNumberChange()"
                  valueField="id"
                  [(ngModel)]="selectedItemNumber"
                  [value]="selectedItemNumber"
                  class="bg-white status-dropdown"
                  [style.width.px]="85"
                >
                </kendo-dropdownlist>
              </div>
              <div class="wallet-popup-dropdown mb-30">
                <kendo-textbox [(ngModel)]="walletAmount" [readonly]="true" class="wallent-amount">
                  <ng-template kendoTextBoxPrefixTemplate [style.width.px]="80">
                    <i class="fa-solid fa-indian-rupee-sign"></i>
                  </ng-template>
                </kendo-textbox>
              </div>
              <button kendoButton class="wp-100 mb-20 text-uppercase" (click)="onAddonsPurchase()">
                Progress to Wallet
              </button>
              <p class="wallet-desc">
                The final amount could change depending on the market conditions
              </p>
            </div>
          </div>
        </kendo-popup>
      </div>
    </div>
  </div>
  <div class="q-content-bg mb-20">
    <div class="heading text-align-center">
      <p>
        <img src="assets/images/fun.png" /> Let’s Explore Some of the Top-up Plan’s of your Wallet
      </p>
    </div>
    <div class="d-flex-row credit-container">
      <div
        class="credit-boxes d-flex-col-lg-3 d-flex-col-sm-6 d-flex-col-xs-12 d-flex-col"
        *ngFor="let data of addOnList"
      >
        <div class="d-flex align-items-center justify-content-center flex-column gap-10">
          <div class="c-text"># Most Popular</div>
          <div class="c-box" [class.active]="data.selected" (click)="selectAddon(data)">
            <div class="c-credit mb-20">
              <i class="far fa-gem mr-10"></i>
              <span>{{ data.plan.externalName }}</span>
            </div>
            <div class="c-amount">₹{{ data.plan.price / 100 }} /<span>only</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="q-content-bg">
    <div class="q-content-header d-flex justify-content-between align-items-center flex-wrap">
      <div class="title">Latest Transactions</div>
      <div class="d-flex flex-wrap gap-15">
        <kendo-dropdownlist
          [data]="paymentMethodList"
          textField="name"
          valueField="id"
          (valueChange)="getPaymentHistory()"
          [(ngModel)]="selectedPaymentMethod"
          [ngModelOptions]="{ standalone: true }"
          [value]="selectedPaymentMethod"
          class="bg-white status-dropdown"
          [style.width.px]="200"
        >
        </kendo-dropdownlist>

        <kendo-dropdownlist
          [data]="statusList"
          textField="name"
          valueField="id"
          (valueChange)="getPaymentHistory()"
          [(ngModel)]="selectedStatusList"
          [ngModelOptions]="{ standalone: true }"
          [value]="selectedStatusList"
          class="bg-white status-dropdown"
          [style.width.px]="150"
        >
        </kendo-dropdownlist>
      </div>
    </div>
    <div class="grid-wrapper">
      <kendo-grid [data]="paymentHistory">
        <kendo-grid-column field="invoiceId" title="Invoice Id" [width]="150"> </kendo-grid-column>
        <kendo-grid-column field="invoiceDate" title="Invoice Date" [width]="150">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.invoiceDate | date: 'd MMM, y' }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="paymentDate" title="Payment Date" [width]="150">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.paymentDate | date: 'd MMM, y' }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="paidAmount" title="Amount Paid" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem> ₹{{ dataItem.paidAmount }} </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="paymentMethod" title="Payment Method" [width]="250">
        </kendo-grid-column>
        <kendo-grid-column field="status" title="Status" class="status-field" [width]="150">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="statusList" [ngClass]="getStatusClass(dataItem.status)"></span
            >{{ dataItem.status }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ul class="grid-action-list">
              <li>
                <a (click)="pdfInvoiceDownload(dataItem.invoiceId)">
                  <i class="far fa-download"></i>
                </a>
              </li>
            </ul>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
  <div kendoDialogContainer></div>
</div>
