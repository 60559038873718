import { mapEnumToOptions } from '@abp/ng.core';

export enum Customer_FraudFlagEnum {
  UnKnown = 0,
  Safe = 1,
  Suspicious = 2,
  Fraudulent = 3,
}

export const customer_FraudFlagEnumOptions = mapEnumToOptions(Customer_FraudFlagEnum);
