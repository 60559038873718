import type { AccountData, FBCountryWiseResponse, FacebookMediaDetail } from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  AnalyticsInput,
  BarChartDetailModel,
  PieChartDetailModel,
} from '../common/models/models';
import type { ColumnChartDetailModel, DashboardCardModel } from '../dashboard/dtos/models';
import type { AccountDetailDto } from '../social-account/dtos/models';

@Injectable({
  providedIn: 'root',
})
export class FacebookService {
  apiName = 'Default';

  downloadProfile = (url: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: '/api/app/facebook/download-profile',
        params: { url },
      },
      { apiName: this.apiName, ...config },
    );

  getAllPageAccounts = (stateId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AccountDetailDto>(
      {
        method: 'GET',
        url: `/api/app/facebook/page-accounts/${stateId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDashboardFollowerUnFollowerData = (socialAccountId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ColumnChartDetailModel>(
      {
        method: 'GET',
        url: `/api/app/facebook/dashboard-follower-un-follower-data/${socialAccountId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDashboardKeyMetricsInsights = (socialId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PieChartDetailModel>(
      {
        method: 'GET',
        url: `/api/app/facebook/dashboard-key-metrics-insights/${socialId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDashboardPostCount = (socialId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PieChartDetailModel>(
      {
        method: 'GET',
        url: `/api/app/facebook/dashboard-post-count/${socialId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getFacebookMediaInsights = (mediaId: string, token: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FacebookMediaDetail>(
      {
        method: 'GET',
        url: `/api/app/facebook/facebook-media-insights/${mediaId}`,
        params: { token },
      },
      { apiName: this.apiName, ...config },
    );

  getFacebookPageAccountByPageId = (
    socialId: string,
    token: string,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, AccountData>(
      {
        method: 'GET',
        url: `/api/app/facebook/facebook-page-account-by-page-id/${socialId}`,
        params: { token },
      },
      { apiName: this.apiName, ...config },
    );

  postAnalyticsAudienceGrowthData = (filter: AnalyticsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ColumnChartDetailModel>(
      {
        method: 'POST',
        url: '/api/app/facebook/analytics-audience-growth-data',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  postAnalyticsCount = (filter: AnalyticsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DashboardCardModel>(
      {
        method: 'POST',
        url: '/api/app/facebook/analytics-count',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  postAnalyticsCountryBreakdown = (filter: AnalyticsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FBCountryWiseResponse[]>(
      {
        method: 'POST',
        url: '/api/app/facebook/analytics-country-breakdown',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  postAnalyticsPageView = (filter: AnalyticsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PieChartDetailModel>(
      {
        method: 'POST',
        url: '/api/app/facebook/analytics-page-view',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  postAnalyticsSocialAction = (filter: AnalyticsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BarChartDetailModel>(
      {
        method: 'POST',
        url: '/api/app/facebook/analytics-social-action',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  preparePageUrlByReturnUrl = (returnUrl: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/app/facebook/prepare-page-url',
        params: { returnUrl },
      },
      { apiName: this.apiName, ...config },
    );

  prepareReAuthenticatePageUrlBySocialAccountIdAndReturnUrl = (
    socialAccountId: number,
    returnUrl: string,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: `/api/app/facebook/prepare-re-authenticate-page-url/${socialAccountId}`,
        params: { returnUrl },
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
