import { AuthService, ConfigStateService, PermissionService } from '@abp/ng.core';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NbDialogService, NbMenuService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { GetAllProfiles, GetTenantSubscriptionPlan } from 'src/core/state/common.action';
import { Observable, Subject, filter } from 'rxjs';
import { DrawerMode } from '@progress/kendo-angular-layout';
import { HeaderDataEnum } from 'src/core/enums/header-data-enum';
import { PlanName } from 'src/core/enums/chargbee-enum';
import { CommonService } from '..';
import { CommonState } from 'src/core/state/common.state';
import { QMenuComponent } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('qMenu') QMenu: QMenuComponent;
  public mode: DrawerMode = 'push';

  @Select(CommonState.subscriptionPlanData)
  subscriptionPlanData$: Observable<any>;

  private onDestroy$: Subject<void> = new Subject<void>();

  isMenuOverlay = false;
  isAdmin = false;
  isWalletVisible: boolean = true;

  logoImage = 'assets/images/logo/logo.png';
  iconlogoImage = 'assets/images/logo/logo-mob.png';
  profileImage = 'assets/images/user.png';
  downarrowImage = 'assets/images/arrow.svg';
  availableCredits: number;
  validDate: Date;
  currentUser: string;

  menuList: Array<any> = [];

  headerDataList: Array<{ text: string; value: number }> = [
    { text: HeaderDataEnum[HeaderDataEnum.Subscription], value: HeaderDataEnum.Subscription },
    { text: HeaderDataEnum[HeaderDataEnum.Setting], value: HeaderDataEnum.Setting },
    { text: HeaderDataEnum[HeaderDataEnum['Log out']], value: HeaderDataEnum['Log out'] },
  ];

  get hasLoggedIn(): boolean {
    return this.authService.isAuthenticated;
  }

  @Input() triggerAdminClick: Observable<any>;
  @Input() triggerWalletClick: Observable<any>;

  constructor(
    private permissionService: PermissionService,
    public authService: AuthService,
    private dialogService: NbDialogService,
    private router: Router,
    private store: Store,
    private menuService: NbMenuService,
    private commonService: CommonService,
    public config: ConfigStateService,
    private datePipe: DatePipe,
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (!this.authService.isAuthenticated && event.url !== '/new-signup') {
          this.authService.navigateToLogin();
          return;
        }

        this.store.dispatch(new GetTenantSubscriptionPlan()).subscribe(
          res => {
            if (res === null || res === undefined) {
              this.setSelectedMenu();
            } else {
              const currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ss');
              const subscriptionPlanExpiryDate = res.common.subscriptionPlanData.planExpiryDate;

              if (currentDate > subscriptionPlanExpiryDate) {
                this.setSelectedMenu();
              }

              this.setSelectedMenu(res);
            }
          },
          error => {},
        );
      });

    this.menuService.onItemClick().subscribe(data => {
      if (data.item.link === undefined) {
        const item = data.item as any;
        item.click();
      }
      this.onClick(data.item.link);
    });

    if (this.authService.isAuthenticated) {
      this.commonService.connectWebSocketServer();
    }
    this.isAdmin = this.permissionService.getGrantedPolicy('AbpIdentity.Roles');

    const isPrivateFormURL = localStorage.getItem('isPrivateFormURL');
    const isPrivateRender = localStorage.getItem('isPrivateRender');

    if (this.authService.isAuthenticated && isPrivateFormURL) {
      const redirectURL = isPrivateFormURL;
      localStorage.removeItem('isPrivateFormURL');

      if (!isPrivateRender) {
        localStorage.setItem('isPrivateRender', JSON.stringify(true));
        this.router.navigateByUrl(redirectURL);
      }
    }

    this.currentUser =
      this.config.getOne('currentUser').name === null
        ? this.config.getOne('currentUser').userName
        : this.config.getOne('currentUser').name;
  }

  setSelectedMenu(res?: any): void {
    this.menuList = this.commonService.getMenuList();

    if (res !== undefined && res !== null) {
      this.QMenu.menuItems =
        res.common.subscriptionPlanData === null ||
        res.common.subscriptionPlanData.currentPlanName === PlanName.FreePlan
          ? this.menuList.filter(item => item.id !== 4)
          : this.menuList;
    } else {
      //This is done due to plan is expire
      this.router.navigate(['subscription-plan']);
      this.QMenu.menuItems = this.menuList.filter(item => item.id !== 4);
    }

    const selectedItem = this.findSelectedItemByPath(this.QMenu.menuItems, this.router.url);

    if (selectedItem) {
      selectedItem.selected = true;
      this.QMenu.headerText = selectedItem.headerText;
      this.QMenu.tempHeaderText = selectedItem.headerText;
    }

    if (this.router.url === '/wallet') {
      this.QMenu.headerText = HeaderDataEnum[HeaderDataEnum.Wallet];
      this.QMenu.tempHeaderText = HeaderDataEnum[HeaderDataEnum.Wallet];
    }
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated) {
      this.store.dispatch(new GetAllProfiles()).subscribe();
    }

    this.setMenuMode();
    window.addEventListener('resize', () => {
      this.setMenuMode();
    });

    this.getWalletDetails();
  }

  setMenuMode(): void {
    this.isMenuOverlay = window.outerWidth < 1024;
    if (this.isMenuOverlay) {
      this.mode = 'overlay';
    } else {
      this.mode = 'push';
    }
  }

  findSelectedItemByPath(items: any[], path: string): any {
    items.forEach(item => (item.selected = false));
    for (const item of items) {
      if (item.path === path) {
        return item;
      }
      if (item.children) {
        const childItem = this.findSelectedItemByPath(item.children, path);
        if (childItem) {
          return childItem;
        }
      }
    }
    return null;
  }

  login(): void {
    this.authService.navigateToLogin();
  }

  openDialog(dialog: TemplateRef<any>): void {
    this.dialogService.open(dialog);
  }

  onClick(url) {
    let splitUrl = this.router.url.split(';');
    let currentPageUrl = splitUrl[0];
    if (url === currentPageUrl) {
      this.router.navigate([currentPageUrl]);
      setTimeout(() => {
        location.reload();
      }, 0);
    }
  }

  onHeaderClick(event) {
    let headerName = '';
    switch (event.value) {
      case HeaderDataEnum.Setting:
        headerName = HeaderDataEnum[HeaderDataEnum.Setting];
        this.router.navigateByUrl('account/manage');
        break;
      case HeaderDataEnum['Log out']:
        this.authService.logout();
        break;
      case HeaderDataEnum.Subscription:
        headerName = HeaderDataEnum[HeaderDataEnum.Subscription];
        this.router.navigateByUrl('subscription-plan');
        break;
      default:
        break;
    }

    this.QMenu.headerText = headerName;
    this.QMenu.tempHeaderText = headerName;
  }

  onWalletClick(event) {
    this.router.navigateByUrl('wallet');
  }

  getWalletDetails(): void {
    this.subscriptionPlanData$.subscribe(res => {
      if (res !== undefined && res !== null) {
        this.availableCredits = res.currentWalletCredit;
        this.validDate = new Date(res.planExpiryDate);
      } else {
        this.availableCredits = 0;
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
