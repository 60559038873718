import type { OrderByPhotosEnums } from '../../enums/un-splash/order-by-photos-enums.enum';
import type { OrientationEnums } from '../../enums/un-splash/orientation-enums.enum';
import type { ColorEnums } from '../../enums/un-splash/color-enums.enum';
import type { OrderBySearchEnums } from '../../enums/un-splash/order-by-search-enums.enum';

export interface GetPhotoInputParameters {
  orderBy: OrderByPhotosEnums;
  pageNo: number;
  pageSize: number;
}

export interface GetPhotoLinks {
  self?: string;
  html?: string;
  photos?: string;
  likes?: string;
  portfolio?: string;
  following?: string;
  followers?: string;
}

export interface GetPhotoResponse {
  id?: string;
  slug?: string;
  created_at?: string;
  updated_at?: string;
  width: number;
  height: number;
  color?: string;
  blur_hash?: string;
  description?: string;
  alt_description?: string;
  likes: number;
  liked_by_user: boolean;
  urls: GetPhotoUrls;
  links: GetPhotoLinks;
}

export interface GetPhotoUrls {
  raw?: string;
  full?: string;
  regular?: string;
  small?: string;
  thumb?: string;
  small_s3?: string;
}

export interface GetSearchPhotoLinks {
  self?: string;
  html?: string;
  download?: string;
  download_location?: string;
}

export interface GetSearchPhotoParameters {
  query?: string;
  pageNo: number;
  pageSize: number;
  orientation: OrientationEnums;
  color: ColorEnums;
  orderBy: OrderBySearchEnums;
}

export interface GetSearchPhotoResponse {
  total: number;
  total_pages: number;
  results: GetSearchPhotoResult[];
}

export interface GetSearchPhotoResult {
  id?: string;
  slug?: string;
  created_at?: string;
  updated_at?: string;
  width: number;
  height: number;
  color?: string;
  blur_hash?: string;
  description?: string;
  alt_description?: string;
  likes: number;
  liked_by_user: boolean;
  urls: GetSearchPhotoUrls;
  links: GetSearchPhotoLinks;
}

export interface GetSearchPhotoUrls {
  raw?: string;
  full?: string;
  regular?: string;
  small?: string;
  thumb?: string;
  small_s3?: string;
}

export interface PostRandomPhotosParameters {
  orientation: OrientationEnums;
  search?: string;
  pageSize: number;
}
