import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { DialogService } from '@progress/kendo-angular-dialog';
import { ChargeBeeService } from '@proxy/charge-bee';
import { Observable } from 'rxjs';
import { Frequency, PaymentMethod, PaymentStatus } from 'src/core/enums/chargbee-enum';
import { CommonService } from 'src/core/services';
import { CommonState } from 'src/core/state';
import { SubscriptionPopupComponent } from '../subscription-popup/subscription-popup.component';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
})
export class WalletComponent implements OnInit {
  addOnList: any;
  paymentHistory: any;
  availableCredits = 0;
  walletAmount = 0;
  validDate: Date;
  daysLeft: number;
  noOfCredits: string;
  selectedItemNumber = { id: 1, name: 'x1' };

  toggleWalletPopup = true;

  itemNumber = [
    { id: 1, name: 'x1' },
    { id: 2, name: 'x2' },
    { id: 3, name: 'x3' },
    { id: 4, name: 'x4' },
    { id: 5, name: 'x5' },
    { id: 6, name: 'x6' },
    { id: 7, name: 'x7' },
    { id: 8, name: 'x8' },
    { id: 9, name: 'x9' },
    { id: 10, name: 'x10' },
  ];

  paymentMethodList = [
    { id: PaymentMethod.All, name: PaymentMethod[PaymentMethod.All] },
    { id: PaymentMethod['Card Payment'], name: PaymentMethod[PaymentMethod['Card Payment']] },
    { id: PaymentMethod.Cash, name: PaymentMethod[PaymentMethod.Cash] },
  ];

  statusList = [
    { id: PaymentStatus.All, name: PaymentStatus[PaymentStatus.All] },
    { id: PaymentStatus.Success, name: PaymentStatus[PaymentStatus.Success] },
    { id: PaymentStatus['In Progress'], name: PaymentStatus[PaymentStatus['In Progress']] },
    { id: PaymentStatus.Failed, name: PaymentStatus[PaymentStatus.Failed] },
    { id: 4, name: PaymentStatus[PaymentStatus.Timeout] },
  ];

  selectedPaymentMethod = { id: PaymentMethod.All, name: PaymentMethod[PaymentMethod.All] };
  selectedStatusList = { id: PaymentStatus.All, name: PaymentStatus[PaymentStatus.All] };

  @Select(CommonState.subscriptionPlanData)
  subscriptionPlanData$: Observable<any>;

  constructor(
    private chargeBeeService: ChargeBeeService,
    private commonService: CommonService,
    private dialogService: DialogService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.getAddOnList();
    this.getPaymentHistory();
    this.getWalletDetails();
  }

  getAddOnList(): void {
    this.chargeBeeService.retrieveAddonsList().subscribe(res => {
      this.addOnList = res;
      this.addOnList.forEach(addon => {
        addon.selected = false;
      });

      this.selectAddon(this.addOnList[1]);
    });
  }

  getWalletDetails(): void {
    this.subscriptionPlanData$.subscribe(res => {
      if (res !== undefined && res !== null) {
        this.availableCredits = res.currentWalletCredit;
        this.validDate = new Date(res.planExpiryDate);
        this.daysLeft = this.commonService.getDaysLeftToExpire(new Date(res.planExpiryDate));
      }
    });
  }

  getPaymentHistory(): void {
    const paymentPayload = {
      limit: 10,
      status: +this.selectedStatusList.id,
      paymentMethod: +this.selectedPaymentMethod.id,
    };

    this.chargeBeeService.retrievingPaymentHistory(paymentPayload).subscribe(res => {
      this.paymentHistory = res;
    });
  }

  pdfInvoiceDownload(id): void {
    this.chargeBeeService.pdfInvoice(id).subscribe(res => {
      window.open(res.downloadUrl, '_self');
    });
  }

  getStatusClass(status): string {
    switch (status) {
      case PaymentStatus[PaymentStatus['In Progress']]:
        return 'progress';
      case PaymentStatus[PaymentStatus.Success]:
        return 'success';
      case PaymentStatus[PaymentStatus.Failed]:
        return 'failed';
      default:
        return '';
    }
  }

  public walletPopupToggle(): void {
    this.toggleWalletPopup = !this.toggleWalletPopup;
  }

  selectAddon(selectedAddon: any): void {
    selectedAddon.selected = true;

    this.addOnList.forEach(addOn => {
      if (addOn !== selectedAddon) {
        addOn.selected = false;
      }
    });
    const selectedAddons = this.addOnList.filter(item => item.selected)[0];
    this.noOfCredits = selectedAddons.plan.externalName;
    this.walletAmount = (+selectedAddons.plan.price / 100) * +this.selectedItemNumber.id;
  }

  onItemNumberChange(): void {
    const selectedAddons = this.addOnList.filter(item => item.selected)[0];
    this.walletAmount = (+selectedAddons.plan.price / 100) * +this.selectedItemNumber.id;
  }

  onAddonsPurchase(): void {
    this.spinner.show();
    const selectedAddons = this.addOnList.filter(item => item.selected)[0];

    const data = {
      redirectionUrl: window.location.origin + '/subscription-plan',
      cancelUrl: window.location.origin + '/subscription-plan',
      frequency: Frequency.Month,
      addonPriceId: [
        {
          addonId: selectedAddons.plan.id,
          addonQuantity: +this.selectedItemNumber.id,
        },
      ],
    };

    this.chargeBeeService.createCheckoutForAddonsSubscriptionByCustomerSubscription(data).subscribe(
      res => {
        this.spinner.hide();
        const dialogRef = this.dialogService.open({
          content: SubscriptionPopupComponent,
        });
        const url = this.sanitizer.bypassSecurityTrustResourceUrl(res.url);
        dialogRef.content.instance.url = url;
      },
      error => {
        this.spinner.hide();
      },
    );
  }
}
