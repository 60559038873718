<kendo-dialog-titlebar (click)="close()">
  <div>
    {{ 'Unsplash Search' }}
  </div>
</kendo-dialog-titlebar>

<div class="d-flex gap-15 align-items-center justify-content-between wp-100 mb-2 d-flex-sm-column">
  <form class="k-form k-form-md d-flex justify-content-between align-item-center gap-20 wp-100">
    <fieldset class="k-form-fieldset wp-100">
      <kendo-formfield>
        <kendo-textbox
          [clearButton]="true"
          [(ngModel)]="searchText"
          [ngModelOptions]="{ standalone: true }"
          #searchUnsplash
          placeholder="Search for Images"
          class="wp-100"
        ></kendo-textbox>
      </kendo-formfield>
    </fieldset>
    <button kendoButton (click)="onSearch()">Search</button>
  </form>
</div>

<div class="d-flex gap-15 align-items-center justify-content-between wp-100 mb-2 d-flex-sm-column">
  <kendo-dropdownlist
    [data]="orientationTypes | useEnumValues"
    textField="value"
    valueField="key"
    placeholder="value"
    [(ngModel)]="selectedOrientationValue"
    [ngModelOptions]="{ standalone: true }"
    [value]="selectedOrientationValue"
    [valuePrimitive]="true"
    (valueChange)="onCustomChange()"
    class="bg-white wp-100-sm w-260"
  ></kendo-dropdownlist>

  <kendo-dropdownlist
    [data]="colorTypes | useEnumValues"
    textField="value"
    valueField="key"
    placeholder="value"
    [(ngModel)]="selectedColorValue"
    [ngModelOptions]="{ standalone: true }"
    [value]="selectedColorValue"
    [valuePrimitive]="true"
    (valueChange)="onCustomChange()"
    class="bg-white wp-100-sm w-260"
  ></kendo-dropdownlist>
</div>

<div class="photo-list">
  <ngx-masonry [options]="masonryOptions" [useImagesLoaded]="true">
    <div ngxMasonryItem *ngFor="let data of photoList" class="masonry-item">
      <img [src]="data.urls.small_s3" alt="{{ data.description }}" (click)="selectImage(data)" />
    </div>
  </ngx-masonry>
</div>

<kendo-dialog-actions></kendo-dialog-actions>
