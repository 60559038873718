import { Component, OnInit } from '@angular/core';
import { NgxMasonryOptions } from 'ngx-masonry';
import { GIFDialogService } from '..';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-gif-dynamic',
  templateUrl: './gif-dynamic.component.html',
  styleUrls: ['./gif-dynamic.component.scss'],
})
export class GifDynamicComponent implements OnInit {
  searchText: string = '';
  trendingGifs: any[] = [];
  selectedImageDetails: any = {};

  public masonryOptions: NgxMasonryOptions = {
    gutter: 10,
    resize: true,
    initLayout: true,
    fitWidth: true,
  };

  constructor(
    private gifService: GIFDialogService,
    public dialogRef: DialogRef,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.fetchTrendingGifs();
  }

  searchGifs(): void {
    this.spinner.show();
    this.gifService
      .searchGifs(this.searchText)
      .then((response: any) => {
        this.spinner.hide();
        this.trendingGifs = response.data;
      })
      .catch(error => {
        this.spinner.hide();
        console.error('Error searching gifs:', error);
        // Handle error as needed
      });
  }

  selectGif(data): void {
    if (this.dialogRef) {
      this.selectedImageDetails = {
        url: data.images.fixed_height.url,
        id: data.id + '.gif',
      };
    }
    this.dialogRef.close();
  }

  fetchTrendingGifs(): void {
    this.spinner.show();
    this.gifService
      .getTrendingGifs()
      .then((response: any) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch trending GIFs');
        }
      })
      .then((data: any) => {
        this.spinner.hide();
        this.trendingGifs = data.data;
      })
      .catch((error: any) => {
        this.spinner.hide();
        console.error(error);
      });
  }

  close(): void {
    if (this.dialogRef) this.dialogRef.close();
  }
}
