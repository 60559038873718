import type { AutoCollectionEnum } from '../../models/enums/auto-collection-enum.enum';
import type { OfflinePaymentMethodEnum } from '../../models/enums/offline-payment-method-enum.enum';
import type { Customer_VatNumberStatusEnum } from '../../models/customer-vat-number-status-enum.enum';
import type { TaxabilityEnum } from '../../models/enums/taxability-enum.enum';
import type { EntityCodeEnum } from '../../models/enums/entity-code-enum.enum';
import type { BillingDateModeEnum } from '../../models/enums/billing-date-mode-enum.enum';
import type { Customer_BillingDayOfWeekEnum } from '../../models/customer-billing-day-of-week-enum.enum';
import type { BillingDayOfWeekModeEnum } from '../../models/enums/billing-day-of-week-mode-enum.enum';
import type { Customer_PiiClearedEnum } from '../../models/customer-pii-cleared-enum.enum';
import type { ChannelEnum } from '../../models/enums/channel-enum.enum';
import type { Customer_FraudFlagEnum } from '../../models/customer-fraud-flag-enum.enum';
import type {
  Customer_CustomerBalance,
  Customer_CustomerBillingAddress,
  Customer_CustomerChildAccountAccess,
  Customer_CustomerContact,
  Customer_CustomerEntityIdentifier,
  Customer_CustomerParentAccountAccess,
  Customer_CustomerPaymentMethod,
  Customer_CustomerReferralUrl,
  Customer_CustomerRelationship,
} from '../../models/models';
import type { EinvoicingMethodEnum } from '../../models/enums/einvoicing-method-enum.enum';
import type { CustomerTypeEnum } from '../../models/enums/customer-type-enum.enum';

export interface Customer {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  company?: string;
  vatNumber?: string;
  autoCollection: AutoCollectionEnum;
  offlinePaymentMethod?: OfflinePaymentMethodEnum;
  netTermDays: number;
  vatNumberValidatedTime?: string;
  vatNumberStatus?: Customer_VatNumberStatusEnum;
  allowDirectDebit: boolean;
  isLocationValid?: boolean;
  createdAt?: string;
  createdFromIp?: string;
  taxability?: TaxabilityEnum;
  entityCode?: EntityCodeEnum;
  exemptNumber?: string;
  resourceVersion?: number;
  updatedAt?: string;
  locale?: string;
  billingDate?: number;
  billingMonth?: number;
  billingDateMode?: BillingDateModeEnum;
  billingDayOfWeek?: Customer_BillingDayOfWeekEnum;
  billingDayOfWeekMode?: BillingDayOfWeekModeEnum;
  piiCleared?: Customer_PiiClearedEnum;
  autoCloseInvoices?: boolean;
  channel?: ChannelEnum;
  activeId?: string;
  fraudFlag?: Customer_FraudFlagEnum;
  primaryPaymentSourceId?: string;
  backupPaymentSourceId?: string;
  billingAddress: Customer_CustomerBillingAddress;
  referralUrls: Customer_CustomerReferralUrl[];
  contacts: Customer_CustomerContact[];
  paymentMethod: Customer_CustomerPaymentMethod;
  invoiceNotes?: string;
  businessEntityId?: string;
  preferredCurrencyCode?: string;
  promotionalCredits: number;
  unbilledCharges: number;
  refundableCredits: number;
  excessPayments: number;
  balances: Customer_CustomerBalance[];
  entityIdentifiers: Customer_CustomerEntityIdentifier[];
  isEinvoiceEnabled?: boolean;
  einvoicingMethod?: EinvoicingMethodEnum;
  deleted: boolean;
  registeredForGst?: boolean;
  consolidatedInvoicing?: boolean;
  customerType?: CustomerTypeEnum;
  businessCustomerWithoutVatNumber?: boolean;
  clientProfileId?: string;
  relationship: Customer_CustomerRelationship;
  useDefaultHierarchySettings?: boolean;
  parentAccountAccess: Customer_CustomerParentAccountAccess;
  childAccountAccess: Customer_CustomerChildAccountAccess;
  vatNumberPrefix?: string;
  entityIdentifierScheme?: string;
  entityIdentifierStandard?: string;
}

export interface CustomerInfo {
  customerId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  locale?: string;
  billingAddressFirstName?: string;
  billingAddressLastName?: string;
  billingAddressLine1?: string;
  billingAddressCity?: string;
  billingAddressState?: string;
  billingAddressZip?: string;
  billingAddressCountry?: string;
  identityUserId?: string;
}
