export interface IGGenderWiseResponse {
  gender?: string;
  currentCount: number;
  previousCount: number;
  percentage: number;
}

export interface IGMediaData {
  id?: string;
  description?: string;
  title?: string;
  name?: string;
  period?: string;
  values: MediaValueData[];
}

export interface IGMediaInsightResponse {
  data: IGMediaData[];
}

export interface MediaValueData {
  value: number;
}
