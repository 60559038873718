import { mapEnumToOptions } from '@abp/ng.core';

export enum Customer_CustomerPaymentMethod_TypeEnum {
  UnKnown = 0,
  Card = 1,
  PaypalExpressCheckout = 2,
  AmazonPayments = 3,
  DirectDebit = 4,
  Generic = 5,
  Alipay = 6,
  Unionpay = 7,
  ApplePay = 8,
  WechatPay = 9,
  Ideal = 10,
  GooglePay = 11,
  Sofort = 12,
  Bancontact = 13,
  Giropay = 14,
  Dotpay = 15,
  Upi = 16,
  NetbankingEmandates = 17,
  Venmo = 18,
  PayTo = 19,
  FasterPayments = 20,
  SepaInstantTransfer = 21,
}

export const customer_CustomerPaymentMethod_TypeEnumOptions = mapEnumToOptions(
  Customer_CustomerPaymentMethod_TypeEnum,
);
