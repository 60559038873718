export class GetAllProfiles {
  static readonly type = '[Profiles] Get All Profiles';
}
export class GetTenantSubscriptionPlan {
  static readonly type = '[Chargbee] Get Tenant Subscription Plan';
}

export class SetTenantSubscriptionPlan {
  static readonly type = '[Chargbee] Set Tenant Subscription Plan';

  constructor(public data: any) {}
}
