import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacebookService } from '@proxy/facebook';
import { InstagramService } from '@proxy/instagram';
import { LinkedInService } from '@proxy/linked-in';
import { LinkedInOrganizationService } from '@proxy/linked-in-organization';
import { SocialAccountsService } from '@proxy/social-account';
import { TwitterService } from '@proxy/twitter';
import { YoutubeService } from '@proxy/youtube';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CommonService, NotificationMessage, SocialMediaPlatForms } from 'src/app';

@Component({
  selector: 'app-add-profile',
  templateUrl: './add-profile.component.html',
  styleUrls: ['./add-profile.component.scss'],
})
export class AddProfileComponent implements OnInit {
  subscriptionRouting: Subscription;
  state: any;
  accountType: any;
  errorMessage: string;
  accountTypeValue: string = '';
  accountList: any[] = [];
  profileDetails: any = {};

  isYouTubeAccount = SocialMediaPlatForms.YouTube;
  constructor(
    private _Activatedroute: ActivatedRoute,
    private instagramService: InstagramService,
    private facebookService: FacebookService,
    private linkedInService: LinkedInService,
    private socialAccountsService: SocialAccountsService,
    private route: Router,
    private toasterService: ToasterService,
    public commonService: CommonService,
    private twitterService: TwitterService,
    private linkedInOrganizationService: LinkedInOrganizationService,
    private youtubeService: YoutubeService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(params => {
      if (params.keys.length > 0) {
        this.state = +params.get('state');

        if (this.state > 0) {
          this.spinner.show();
          this.accountType = this.commonService.getDataInLocalStorage('selectedAccount');
          if (this.accountType === SocialMediaPlatForms.Facebook) {
            this.facbookBusinessAccount();
          } else if (this.accountType === SocialMediaPlatForms.Instagram) {
            this.instagramBusinessAccount();
          } else if (this.accountType === SocialMediaPlatForms.Twitter) {
            this.twitterBusinessAccount();
          } else if (this.accountType === SocialMediaPlatForms.LinkedInOrganization) {
            this.linkedinOrganizationAccount();
          } else if (this.accountType === SocialMediaPlatForms.YouTube) {
            this.youTubeBusinessAccount();
          } else {
            this.linkinBusinessAccount();
          }
        }
      }
    });
  }

  instagramBusinessAccount(): void {
    this.instagramService.getInstagramBusinessAccounts(this.state).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res !== undefined) {
          res.accountsDetail.forEach(element => {
            element.isSelected = false;
          });
          this.accountTypeValue = SocialMediaPlatForms[res.accountType];
          this.profileDetails = res.profile;
          this.accountList = res.accountsDetail;
        }
      },
      error => {
        this.spinner.hide();
        this.errorMessage = error.error.error.validationErrors[0].message;
        this.toasterService.error(this.errorMessage, '', {});
        console.error(this.errorMessage);
      },
    );
  }

  facbookBusinessAccount(): void {
    this.facebookService.getAllPageAccounts(this.state).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res !== undefined) {
          res.accountsDetail.forEach(element => {
            element.isSelected = false;
          });
          this.accountTypeValue = SocialMediaPlatForms[res.accountType];
          this.profileDetails = res.profile;
          this.accountList = res.accountsDetail;
        }
      },
      error => {
        this.errorMessage = error.error.error.validationErrors[0].message;
        this.toasterService.error(this.errorMessage, '', {});
        console.error(this.errorMessage);
      },
    );
  }

  twitterBusinessAccount(): void {
    this.twitterService.getTwitterAccount(this.state).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res !== undefined) {
          res.accountsDetail.forEach(element => {
            element.isSelected = false;
          });
          this.accountTypeValue = SocialMediaPlatForms[res.accountType];
          this.profileDetails = res.profile;
          this.accountList = res.accountsDetail;
        }
      },
      error => {
        this.spinner.hide();
        this.errorMessage = error.error.error.validationErrors[0].message;
        this.toasterService.error(this.errorMessage, '', {});
        console.error(this.errorMessage);
      },
    );
  }

  linkinBusinessAccount(): void {
    this.linkedInService.getLinkedInAccount(this.state).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res !== undefined) {
          res.accountsDetail.forEach(element => {
            element.isSelected = false;
          });
          this.accountTypeValue = SocialMediaPlatForms[res.accountType];
          this.profileDetails = res.profile;
          this.accountList = res.accountsDetail;
        }
      },
      error => {
        this.spinner.hide();
        this.errorMessage = error.error.error.validationErrors[0].message;
        this.toasterService.error(this.errorMessage, '', {});
        console.error(this.errorMessage);
      },
    );
  }

  linkedinOrganizationAccount(): void {
    this.linkedInOrganizationService.getLinkedInOrganization(this.state).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res !== undefined) {
          res.accountsDetail.forEach(element => {
            element.isSelected = false;
          });
          this.accountTypeValue = SocialMediaPlatForms[res.accountType];
          this.profileDetails = res.profile;
          this.accountList = res.accountsDetail;
        }
      },
      error => {
        this.spinner.hide();
        this.errorMessage = error.error.error.validationErrors[0].message;
        this.toasterService.error(this.errorMessage, '', {});
        console.error(this.errorMessage);
      },
    );
  }

  youTubeBusinessAccount(): void {
    this.youtubeService.getYoutubeUserDetails(this.state).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res !== undefined) {
          res.accountsDetail.forEach(element => {
            element.isSelected = false;
          });
          this.accountTypeValue = SocialMediaPlatForms[res.accountType];
          this.profileDetails = res.profile;
          this.accountList = res.accountsDetail;
        }
      },

      error => {
        this.spinner.hide();
        this.errorMessage = error.error.error.validationErrors[0].message;
        this.toasterService.error(this.errorMessage, '', {});
        console.error(this.errorMessage);
      },
    );
  }

  toggleSelection(account: any) {
    account.isSelected = !account.isSelected;
  }

  onAddAccount(): void {
    const selectList = this.accountList.filter(x => x.isSelected === true);
    if (selectList.length === 0) {
      this.toasterService.success(
        NotificationMessage.selectAccountToCustomizeMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
    } else {
      const param = {
        stateId: this.state,
        accounts: selectList,
      };
      this.socialAccountsService.create(param).subscribe(
        res => {
          if (res !== null && res !== undefined) {
            this.spinner.show();
            this.route.navigate(['dashboard'], { queryParams: { isNew: true } });
          }
        },
        error => {
          this.errorMessage = error.error.error.validationErrors[0].message;
          this.toasterService.error(this.errorMessage, '', {});
          console.error(
            'Error occurred during social account creation:',
            error.error.error.validationErrors[0].message,
          );
        },
      );
    }
  }

  redirectToDashbopard(): void {
    this.route.navigate(['dashboard']);
  }
}
