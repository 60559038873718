import { mapEnumToOptions } from '@abp/ng.core';

export enum CustomerTypeEnum {
  UnKnown = 0,
  Residential = 1,
  Business = 2,
  SeniorCitizen = 3,
  Industrial = 4,
}

export const customerTypeEnumOptions = mapEnumToOptions(CustomerTypeEnum);
