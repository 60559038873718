import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SocialAccountsService } from '@proxy/social-account';
import { Observable, tap } from 'rxjs';
import {
  GetAllProfiles,
  GetTenantSubscriptionPlan,
  SetTenantSubscriptionPlan,
} from './common.action';
import { CommonService } from 'src/app';
import { ChargeBeeService } from '@proxy/charge-bee';

export class CommonStateInfo {
  profileDetails?: any;
  isScheduleAllowed?: any;
  subscriptionPlanData?: any;
}

@State<CommonStateInfo>({
  name: 'common',
  defaults: {},
})
@Injectable()
export class CommonState {
  constructor(
    private socialAccountsService: SocialAccountsService,
    public commonService: CommonService,
    public chargeBeeService: ChargeBeeService,
  ) {}

  @Selector()
  static profileDetails(state: CommonStateInfo): Array<any> {
    return state.profileDetails!;
  }

  @Selector()
  static isScheduleAllowed(state: CommonStateInfo): any {
    return state.isScheduleAllowed!;
  }

  @Selector()
  static subscriptionPlanData(state: CommonStateInfo): any {
    return state.subscriptionPlanData!;
  }

  @Action(GetAllProfiles)
  getAllProfiles({ patchState }: StateContext<CommonStateInfo>): Observable<any> {
    const data: any = {};

    return this.socialAccountsService.getIsActive(true).pipe(
      tap(res => {
        let profilesDetails;

        if (res.length > 0) {
          profilesDetails = this.commonService.getAllProfiles(res);
        }

        patchState({
          profileDetails: profilesDetails,
        });
      }),
    );
  }

  @Action(GetTenantSubscriptionPlan)
  getTenantSubscriptionPlan({ patchState }: StateContext<CommonStateInfo>): Observable<any> {
    return this.chargeBeeService.getTenantSubscriptionPlan().pipe(
      tap(res => {
        let isScheduleAllowed = false;
        let subscriptionPlanData = null;

        if (res !== null && res !== undefined) {
          subscriptionPlanData = res.planData;
          isScheduleAllowed = res.planData.isScheduleAllowed;
        }
        patchState({
          subscriptionPlanData: subscriptionPlanData,
          isScheduleAllowed: isScheduleAllowed,
        });
      }),
    );
  }

  @Action(SetTenantSubscriptionPlan)
  setTenantSubscriptionPlan(
    { patchState }: StateContext<CommonStateInfo>,
    action: SetTenantSubscriptionPlan,
  ): any {
    patchState({
      subscriptionPlanData: action.data.planData,
      isScheduleAllowed: action.data.planData.isScheduleAllowed,
    });
  }
}
