import { Environment } from '@abp/ng.core';
export { QMenuComponent, QThemeLibModule } from '@dev/qtheme-lib';

const baseUrl = 'https://{0}.qmarketme.mysandboxsite.com/';

export const environment = {
  maxImageSize: 5,
  maxVideoSize: 75,
  production: false,
  perAccoutCharge: 75,
  maxFreeAccountsAllowedForFreePlan: 3,
  maxFreeAccountsAllowedForPaidPlan: 8,
  perPostCharges: 2,
  GIFYKey: 'bS4WnNTjTgWPXGunxMjjHQMasKl6lUns',
  recaptchaKey: '6Ld2InAnAAAAAO8YMgt5DLthgtyOqYS82XkCRzLu',
  application: {
    baseUrl,
    name: 'QMarketMe',
    logoUrl: '',
  },
  oAuthConfig: {
    issuer: 'https://{0}.qmarketmeapi.mysandboxsite.com/',
    redirectUri: baseUrl,
    clientId: 'QMarketMe_App',
    responseType: 'code',
    scope: 'offline_access QMarketMe',
    requireHttps: true,
  },
  apis: {
    default: {
      url: 'https://{0}.qmarketmeapi.mysandboxsite.com',
      rootNamespace: 'QMarketMe',
    },
  },
} as Environment;
