import { EXTENSIONS_IDENTIFIER } from '@abp/ng.theme.shared/extensions';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TenantsComponent, eTenantManagementComponents } from '@abp/ng.tenant-management';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { QMarketMeTenantService } from '@proxy/qmarketme-tenant.service';
import { ToasterService } from '@abp/ng.theme.shared';
@Component({
  selector: 'app-custom-add-tenant',
  templateUrl: './custom-add-tenant.component.html',
  styleUrls: ['./custom-add-tenant.component.scss'],
  providers: [
    {
      provide: EXTENSIONS_IDENTIFIER,
      useValue: eTenantManagementComponents.Tenants,
    },
    {
      provide: TenantsComponent,
      useExisting: CustomAddTenantComponent,
    },
  ],
})
export class CustomAddTenantComponent implements OnInit {
  tenantForm: FormGroup;
  passwordVisible = false;
  isSaved = false;

  constructor(
    private service: QMarketMeTenantService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private toasterService: ToasterService,
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.tenantForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      adminEmail: new FormControl('', [Validators.email, Validators.required]),
      adminPwd: new FormControl('', [Validators.required]),
      adminName: new FormControl('', [Validators.required]),
    });
  }

  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }

  save(): void {
    this.spinner.show();

    let date = new Date();
    date.setDate(date.getDate() + 15);

    const trialExpiredOn = this.datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ss');

    const param = {
      extraProperties: {
        Enable: true,
        TrialExpiredOn: trialExpiredOn,
        UserName: this.tenantForm.controls.adminName.value,
      },
      name: this.tenantForm.controls.name.value,
      adminEmailAddress: this.tenantForm.controls.adminEmail.value,
      adminPassword: this.tenantForm.controls.adminPwd.value,
    };

    this.service.create(param).subscribe(
      res => {
        this.spinner.hide();
        this.isSaved = true;
        this.tenantForm.reset();
      },
      error => {
        this.spinner.hide();
        this.toasterService.error(error.error.error.message, '', {});
      },
    );
  }
}
