import type { FullAuditedEntityDto, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import type { PostDto } from '../../posts/dtos/models';

export interface CreateUpdateScheduleDto {
  postId: number;
  scheduleTime?: string;
  isScheduleJob: boolean;
}

export interface ScheduleDto extends FullAuditedEntityDto<number> {
  postId: number;
  scheduleTime?: string;
  isScheduleJob: boolean;
  post: PostDto;
}

export interface ScheduleGetListInput extends PagedAndSortedResultRequestDto {
  postId?: number;
  scheduleTime?: string;
  isScheduleJob?: boolean;
}

export interface ScheduleReadListDto {
  postId: number;
  scheduleTime?: string;
  isScheduleJob: boolean;
}

export interface ScheduleTimeDto {
  scheduleId?: number;
  postId?: number;
  title?: string;
  start?: string;
  end?: string;
}
