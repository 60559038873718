<kendo-dialog-titlebar (click)="closePopup()">
  <div>
    {{ isEdit ? 'Edit Category' : 'Create Category' }}
  </div>
</kendo-dialog-titlebar>
<div>
  <form class="k-form k-form-md" [formGroup]="categoriesForm">
    <fieldset class="k-form-fieldset">
      <kendo-formfield>
        <kendo-label [for]="categoryName" text="Category Name" class="text-primary-text"
          ><span class="required">*</span></kendo-label
        >
        <kendo-textbox
          formControlName="categoryName"
          [clearButton]="true"
          class="bg-white"
          [maxLength]="100"
          #categoryName
        ></kendo-textbox>
      </kendo-formfield>
      <kendo-formfield showHints="always">
        <kendo-label text="Category Description" class="text-primary-text"></kendo-label>
        <kendo-textarea
          [rows]="3"
          formControlName="description"
          [maxLength]="500"
          resizable="vertical"
          class="bg-white"
        ></kendo-textarea>
      </kendo-formfield>
      <kendo-formfield showHints="always" class="d-flex gap-10">
        <kendo-label text="Active"></kendo-label>
        <kendo-switch [(ngModel)]="isActive" formControlName="isActive"></kendo-switch>
      </kendo-formfield>
    </fieldset>
  </form>
</div>

<kendo-dialog-actions>
  <button kendoButton (click)="saveCategoty(categoriesForm.value)" class="k-button-solid-base">
    Save
  </button>
</kendo-dialog-actions>
