import { mapEnumToOptions } from '@abp/ng.core';

export enum GatewayEnum {
  UnKnown = 0,
  Chargebee = 1,
  ChargebeePayments = 2,
  Stripe = 3,
  Wepay = 4,
  Braintree = 5,
  AuthorizeNet = 6,
  PaypalPro = 7,
  Pin = 8,
  Eway = 9,
  EwayRapid = 10,
  Worldpay = 11,
  BalancedPayments = 12,
  Beanstream = 13,
  Bluepay = 14,
  Elavon = 15,
  FirstDataGlobal = 16,
  Hdfc = 17,
  Migs = 18,
  Nmi = 19,
  Ogone = 20,
  Paymill = 21,
  PaypalPayflowPro = 22,
  SagePay = 23,
  Tco = 24,
  Wirecard = 25,
  AmazonPayments = 26,
  PaypalExpressCheckout = 27,
  Gocardless = 28,
  Adyen = 29,
  Orbital = 30,
  MonerisUs = 31,
  Moneris = 32,
  Bluesnap = 33,
  Cybersource = 34,
  Vantiv = 35,
  CheckoutCom = 36,
  Paypal = 37,
  IngenicoDirect = 38,
  Exact = 39,
  Mollie = 40,
  Quickbooks = 41,
  Razorpay = 42,
  GlobalPayments = 43,
  BankOfAmerica = 44,
  Ecentric = 45,
  MetricsGlobal = 46,
  Windcave = 47,
  PayCom = 48,
  Ebanx = 49,
  NotApplicable = 50,
}

export const gatewayEnumOptions = mapEnumToOptions(GatewayEnum);
