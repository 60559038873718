import type { PublishedPostDto, PublishedPostGetListInput } from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PublishedPostService {
  apiName = 'Default';

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PublishedPostDto>(
      {
        method: 'GET',
        url: `/api/app/published-post/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: PublishedPostGetListInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PublishedPostDto>>(
      {
        method: 'GET',
        url: '/api/app/published-post',
        params: {
          socialAccountType: input.socialAccountType,
          publishedDate: input.publishedDate,
          status: input.status,
          postResponseId: input.postResponseId,
          postId: input.postId,
          socialAccountId: input.socialAccountId,
          scheduleId: input.scheduleId,
          publishedUrl: input.publishedUrl,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
