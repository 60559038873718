import type { CreateUpdateHashtagDto, HashtagDto } from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HashtagService {
  apiName = 'Default';

  create = (input: CreateUpdateHashtagDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, HashtagDto>(
      {
        method: 'POST',
        url: '/api/app/hashtag',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/app/hashtag/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, HashtagDto>(
      {
        method: 'GET',
        url: `/api/app/hashtag/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getAll = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, HashtagDto[]>(
      {
        method: 'GET',
        url: '/api/app/hashtag',
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: CreateUpdateHashtagDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, HashtagDto>(
      {
        method: 'PUT',
        url: `/api/app/hashtag/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
