<div class="q-content" *ngIf="this.authService.isAuthenticated">
  <div class="q-content-header">
    <div class="d-flex gap-15 align-items-center justify-content-between wp-100 mb-2 flex-wrap">
      <kendo-textbox placeholder="Search Here" [(ngModel)]="list.filter" class="bg-white search">
        <ng-template kendoTextBoxPrefixTemplate>
          <i class="fas fa-search k-icon"></i>
        </ng-template>
      </kendo-textbox>
      <abp-page-toolbar [record]="data.items"></abp-page-toolbar>
    </div>
  </div>
  <div class="q-content-bg">
    <app-custom-extensible-table
      [data]="data.items"
      [recordsTotal]="data.totalCount"
      [list]="list"
    ></app-custom-extensible-table>
  </div>
</div>

<div class="authentication-settings">
  <kendo-dialog
    title="{{
      (selected?.id ? 'AbpIdentity::Edit User' : 'AbpIdentity::NewUser') | abpLocalization
    }}"
    *ngIf="isAddEditOpened"
    (close)="close('cancel')"
    [width]="450"
    class="user-identity"
  >
    <div>
      <form *ngIf="form; else loaderRef" [formGroup]="form" (ngSubmit)="save()">
        <kendo-tabstrip>
          <kendo-tabstrip-tab
            title="{{ 'AbpIdentity::UserInformations' | abpLocalization }}"
            [selected]="true"
          >
            <ng-template kendoTabContent>
              <abp-extensible-form [selectedRecord]="selected"></abp-extensible-form>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab title="{{ 'AbpIdentity::Roles' | abpLocalization }}">
            <ng-template kendoTabContent>
              <div
                *ngFor="let roleGroup of roleGroups; let i = index; trackBy: trackByFn"
                class="form-check mb-2"
              >
                <abp-checkbox
                  *abpReplaceableTemplate="{
                    inputs: {
                      checkboxId: 'roles-' + i,
                      label: roles[i].name,
                      formControl: roleGroup.controls[roles[i].name]
                    },
                    componentKey: inputKey
                  }"
                  [checkboxId]="'roles-' + i"
                  [formControl]="roleGroup.controls[roles[i].name]"
                  [label]="roles[i].name"
                >
                </abp-checkbox>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </form>
    </div>
    <kendo-dialog-actions>
      <button kendoButton (click)="close('no')" fillMode="outline">Cancel</button>
      <div [ngClass]="{ 'curser-not-allowd': this.form.invalid }" class="display-inline flex-0">
        <button kendoButton (click)="save()" [disabled]="this.form.invalid">
          {{ 'AbpIdentity::Save' | abpLocalization }}
        </button>
      </div>
    </kendo-dialog-actions>
  </kendo-dialog>

  <kendo-dialog
    *ngIf="isAddEditPermissionOpened"
    (close)="closePermission('cancel')"
    [width]="850"
    class="permission-modal"
  >
    <app-custom-permission
      #abpPermissionManagement="abpPermissionManagement"
      *abpReplaceableTemplate="
        {
          inputs: {
            providerName: { value: 'U' },
            providerKey: { value: providerKey },
            visible: { value: visiblePermissions, twoWay: true }
          },
          outputs: { visibleChange: onVisiblePermissionChange },
          componentKey: permissionManagementKey
        };
        let init = initTemplate
      "
      [entityDisplayName]="entityDisplayName"
      (abpInit)="init(abpPermissionManagement)"
    >
    </app-custom-permission>
  </kendo-dialog>
</div>
