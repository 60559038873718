import { mapEnumToOptions } from '@abp/ng.core';

export enum FilterTypes {
  Last30Days = 0,
  ThisMonth = 1,
  ThisQuarter = 2,
  ThisYear = 3,
  LastMonth = 4,
  LastQuarter = 5,
  LastYear = 6,
  Custom = 7,
  All = -1,
}

export const filterTypesOptions = mapEnumToOptions(FilterTypes);
