import { mapEnumToOptions } from '@abp/ng.core';

export enum Customer_CustomerChildAccountAccess_PortalEditSubscriptionsEnum {
  UnKnown = 0,
  Yes = 1,
  ViewOnly = 2,
}

export const customer_CustomerChildAccountAccess_PortalEditSubscriptionsEnumOptions =
  mapEnumToOptions(Customer_CustomerChildAccountAccess_PortalEditSubscriptionsEnum);
