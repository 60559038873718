import type { FullAuditedEntityDto, PagedAndSortedResultRequestDto } from '@abp/ng.core';

export interface ContactUsDto extends FullAuditedEntityDto<number> {
  name?: string;
  email?: string;
  message?: string;
}

export interface ContactUsGetListInput extends PagedAndSortedResultRequestDto {
  name?: string;
  email?: string;
  message?: string;
}

export interface CreateUpdateContactUsDto {
  name?: string;
  email?: string;
  message?: string;
}
