import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { AccountDetailDto } from '../social-account/dtos/models';

@Injectable({
  providedIn: 'root',
})
export class TwitterService {
  apiName = 'Default';

  downloadProfile = (url: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: '/api/app/twitter/download-profile',
        params: { url },
      },
      { apiName: this.apiName, ...config },
    );

  getTwitterAccount = (state: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AccountDetailDto>(
      {
        method: 'GET',
        url: '/api/app/twitter/twitter-account',
        params: { state },
      },
      { apiName: this.apiName, ...config },
    );

  preparePageUrl = (returnUrl: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/app/twitter/prepare-page-url',
        params: { returnUrl },
      },
      { apiName: this.apiName, ...config },
    );

  prepareReauthenticatedPageUrl = (
    socialAccountId: number,
    returnUrl: string,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: `/api/app/twitter/prepare-reauthenticated-page-url/${socialAccountId}`,
        params: { returnUrl },
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
