import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GIFDialogService {
  private modalRef: NgbModalRef;
  private apiKey = environment.GIFYKey;
  private apiUrl = 'https://api.giphy.com/v1/gifs';
  trendingGifs: any[] = [];

  div_selectedGif = '';
  title = '';
  constructor() {}

  close(): void {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  searchGifs(query: string, limit: number = 10): Promise<any> {
    const params = {
      api_key: this.apiKey,
      q: query,
      limit: limit.toString(),
    };
    return fetch(`${this.apiUrl}/search?${new URLSearchParams(params)}`).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    });
  }

  getTrendingGifs(limit: number = 10): any {
    const params = {
      api_key: this.apiKey,
      limit: limit.toString(),
    };
    return fetch(`${this.apiUrl}/trending?${new URLSearchParams(params)}`);
  }
}
