import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { UnSplashService } from '@proxy/un-splash';
import { GetPhotoInputParameters, GetSearchPhotoParameters } from '@proxy/un-splash/dtos';
import { NgxMasonryOptions } from 'ngx-masonry';
import { NgxSpinnerService } from 'ngx-spinner';
import { UnsplashColorFilterTypes } from 'src/core/enums/unsplash-color-filter';
import { UnsplashOrientationFilterTypes } from 'src/core/enums/unsplash-orientation-filter';
import { UnsplashPhotoFilterTypes } from 'src/core/enums/unsplash-photo-filter';

@Component({
  selector: 'app-unsplash-dynamic',
  templateUrl: './unsplash-dynamic.component.html',
  styleUrls: ['./unsplash-dynamic.component.scss'],
})
export class UnsplashDynamicComponent implements OnInit {
  filterTypes = UnsplashPhotoFilterTypes;
  orientationTypes = UnsplashOrientationFilterTypes;
  colorTypes = UnsplashColorFilterTypes;

  selectedCustomValue: any;
  selectedOrientationValue: any;
  selectedColorValue: any;
  searchText: string = '';
  photoList: any[] = [];
  searchedImageList: any[] = [];
  selectedImageDetails: any = {};

  public masonryOptions: NgxMasonryOptions = {
    gutter: 10,
    resize: true,
    initLayout: true,
    fitWidth: true,
  };

  constructor(
    public dialogRef: DialogRef,
    private unSplashService: UnSplashService,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.selectedCustomValue = UnsplashPhotoFilterTypes['Latest'];
    this.selectedOrientationValue = UnsplashOrientationFilterTypes['Landscape'];
    this.selectedColorValue = UnsplashColorFilterTypes['White'];
    this.getListPhotos();
  }

  onCustomChange() {
    this.onSearch();
  }

  onSearch() {
    this.spinner.show();
    const params: GetSearchPhotoParameters = {
      query: this.searchText,
      pageNo: 1,
      pageSize: 20,
      orientation: this.selectedOrientationValue,
      color: this.selectedColorValue,
      orderBy: this.selectedCustomValue,
    };
    if (this.searchText === '') {
      this.getListPhotos();
    } else {
      this.unSplashService.postSearchPhotos(params).subscribe(
        res => {
          this.spinner.hide();
          this.photoList = res.results;
        },
        error => {
          this.spinner.hide();
        },
      );
    }
  }
  close() {
    this.dialogRef.close();
  }

  getListPhotos() {
    this.spinner.show();
    const params: GetPhotoInputParameters = {
      orderBy: this.selectedCustomValue,
      pageNo: 1,
      pageSize: 20,
    };

    this.unSplashService.postListPhotos(params).subscribe(
      res => {
        this.spinner.hide();
        this.photoList = res;
      },
      error => {
        this.spinner.hide();
      },
    );
  }

  selectImage(data) {
    if (this.dialogRef) {
      this.http.get(data.urls.regular, { responseType: 'blob' }).subscribe((blob: Blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result as string;
          this.selectedImageDetails = {
            url: base64data,
            id: data.id + '.png',
          };
          this.dialogRef.close();
        };
        reader.readAsDataURL(blob);
      });
    }
  }
}
