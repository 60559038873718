import { ChangeDetectorRef, ElementRef, Injectable, Renderer2 } from '@angular/core';
import { Observable, Subject, catchError, map, throwError } from 'rxjs';

import { FileService } from '@proxy/image';
import { HttpClient } from '@angular/common/http';
import { NotificationMessage, RolesRights, SocialMediaPlatForms } from '../enums';
import { SVGIcon, facebookIcon, linkedinIcon, twitterIcon } from '@progress/kendo-svg-icons';
import { PermissionService, SessionStateService } from '@abp/ng.core';
import { environment } from 'src/environments/environment';
import * as signalR from '@microsoft/signalr';
import { SetTenantSubscriptionPlan } from '../state';
import { Store } from '@ngxs/store';
import { Toaster, ToasterService } from '@abp/ng.theme.shared';

@Injectable()
export class CommonService {
  public themeColorChange = new Subject<any>();
  public editChange = new Subject<any>();
  public permissionChange = new Subject<any>();
  public deleteChange = new Subject<any>();
  public facebookBoxIcon: SVGIcon = facebookIcon;
  public linkedinBoxIcon: SVGIcon = linkedinIcon;
  public twitterBoxIcon: SVGIcon = twitterIcon;

  constructor(
    private fileService: FileService,
    private http: HttpClient,
    private permissionService: PermissionService,
    private sessionState: SessionStateService,
    private store: Store,
    private toasterService: ToasterService,
  ) {}

  toasterMessageConfiguration: Partial<Toaster.ToastOptions> = {
    life: 4000,
    tapToDismiss: true,
  };

  getProfileName(role: number): string | undefined {
    switch (role) {
      case SocialMediaPlatForms.Instagram:
        return 'Instagram';
      case SocialMediaPlatForms.Facebook:
        return 'Facebook';
      case SocialMediaPlatForms.LinkedIn:
        return 'LinkedIn';
      case SocialMediaPlatForms.Twitter:
        return 'Twitter';
      case SocialMediaPlatForms.LinkedInOrganization:
        return 'LinkedIn';
      case SocialMediaPlatForms.YouTube:
        return 'YouTube';
      default:
        return undefined;
    }
  }

  getIconClass(role: number): any | undefined {
    switch (role) {
      case SocialMediaPlatForms.Instagram:
        return 'fa-instagram-square';
      case SocialMediaPlatForms.Facebook:
        return 'fa-facebook-f';
      case SocialMediaPlatForms.LinkedIn:
        return 'fa-linkedin-in';
      case SocialMediaPlatForms.LinkedInOrganization:
        return 'fa-linkedin-in';
      case SocialMediaPlatForms.Twitter:
        return 'fa-x-twitter';
      case SocialMediaPlatForms.YouTube:
        return 'fa-youtube';
      default:
        return undefined;
    }
  }

  setDataInLocalStorage(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getDataInLocalStorage(key: string): any {
    return JSON.parse(localStorage.getItem(key) || '{}');
  }

  removeDataFromLocalStorage(key: string): any {
    return localStorage.removeItem(key);
  }

  addValidation(form: any, renderer: any): void {
    (Object as any).values(form.controls).forEach(c => c.markAsTouched());
  }

  getAllProfiles(data): any {
    const profilesDetails = [];

    data.forEach(element => {
      const param = {
        id: element.id,
        img: element.profilePicBase64,
        accountType: element.accountType,
        isSelected: false,
        accountName: element.accountName,
        uploadedImages: [],
        caption: '',
        socialId: element.socialId,
        postCount: element.postCount,
        isActive: element.isActive,
      };

      profilesDetails.push(param);
    });

    return profilesDetails;
  }

  getMimeTypeFromBase64(base64Value: string): string | null {
    const matches = base64Value.match(/^data:(.*?);base64,/);
    if (matches && matches.length >= 2) {
      return matches[1];
    }
    return null;
  }

  removeBase64Prefix(base64Url: string): string {
    const mimeType = this.getMimeTypeFromBase64(base64Url);
    const prefix = `data:${mimeType};base64,`;
    if (base64Url.startsWith(prefix)) {
      return base64Url.slice(prefix.length);
    }
    return base64Url;
  }

  getImageBlob(imageUrl: string): Observable<Blob> {
    return this.http.get(imageUrl, { responseType: 'arraybuffer' }).pipe(
      map((data: ArrayBuffer) => new Blob([data], { type: 'image/jpeg' })),
      catchError(error => {
        console.error('Error fetching image:', error);
        return throwError('Failed to fetch image');
      }),
    );
  }

  convertGifToBlob(gifUrl: string): Observable<Blob> {
    return this.http
      .get(gifUrl, { responseType: 'arraybuffer' })
      .pipe(map(data => new Blob([data], { type: 'image/gif' })));
  }

  updateProfilePictures(accountDetails): any {
    accountDetails.forEach(item => {
      this.fileService.getProfilePicture(item.img).subscribe(response => {
        item.img = response;
        const reader = new FileReader();
        reader.onloadend = () => {
          item.img = reader.result;
        };
        reader.readAsDataURL(response);
      });
    });
  }

  addTimeToDate(dateString): string {
    const containsTime = /\d{2}:\d{2}:\d{2}/.test(dateString);
    const currentTime = new Date();
    const newTime = new Date(currentTime);
    newTime.setMinutes(currentTime.getMinutes() + 10);

    if (!containsTime) {
      const formattedCurrentTime = `${newTime.getHours()}:${newTime.getMinutes()}:${newTime.getSeconds()}`;
      dateString += ` ${formattedCurrentTime}`;
    } else if (containsTime && new Date(dateString) < new Date()) {
      const year = newTime.getFullYear().toString().padStart(2, '0');
      const month = newTime.getMonth() + 1;
      const day = newTime.getDate().toString().padStart(2, '0');
      const hour = newTime.getHours().toString().padStart(2, '0');
      const minute = newTime.getMinutes().toString().padStart(2, '0');
      const second = newTime.getSeconds().toString().padStart(2, '0');
      const timeZone = newTime.getTimezoneOffset();

      const tzo = -timeZone;
      const dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
          const norm = Math.floor(Math.abs(num));
          return (norm < 10 ? '0' : '') + norm;
        };

      dateString = `${pad(year)}-${pad(month)}-${pad(day)}T${pad(hour)}:${pad(minute)}:${pad(
        second,
      )}${dif}${pad(tzo / 60)}:${pad(tzo % 60)}`;
    }
    return dateString;
  }

  getFileExtension(fileName: string): string {
    return fileName.split('.').pop().toLowerCase();
  }

  isGIF(url: string): boolean {
    if (url !== undefined) {
      return url.endsWith('gif');
    }
  }

  isImage(url: string): boolean {
    if (url !== undefined) {
      return url.endsWith('jpg') || url.endsWith('png') || url.endsWith('jpeg');
    }
  }

  isVideo(url: string): boolean {
    if (url !== undefined) {
      return url.endsWith('mp4') || url.endsWith('webm') || url.endsWith('ogg');
    }
  }

  convertVideoToBlob(base64Data, contentType): Blob {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  toConverString(tempDateTime): string {
    if (
      this.isInteger(tempDateTime.year) &&
      this.isInteger(tempDateTime.month) &&
      this.isInteger(tempDateTime.day)
    ) {
      const year = tempDateTime.year.toString().padStart(2, '0');
      const month = tempDateTime.month.toString().padStart(2, '0');
      const day = tempDateTime.day.toString().padStart(2, '0');

      if (!tempDateTime.hour) {
        tempDateTime.hour = 0;
      }
      if (!tempDateTime.minute) {
        tempDateTime.minute = 0;
      }
      if (!tempDateTime.second) {
        tempDateTime.second = 0;
      }
      if (!tempDateTime.timeZoneOffset) {
        tempDateTime.timeZoneOffset = new Date().getTimezoneOffset();
      }

      const hour = tempDateTime.hour.toString().padStart(2, '0');
      const minute = tempDateTime.minute.toString().padStart(2, '0');
      const second = tempDateTime.second.toString().padStart(2, '0');

      const tzo = -tempDateTime.timeZoneOffset;
      const dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
          const norm = Math.floor(Math.abs(num));
          return (norm < 10 ? '0' : '') + norm;
        };

      const isoString = `${pad(year)}-${pad(month)}-${pad(day)}T${pad(hour)}:${pad(minute)}:${pad(
        second,
      )}${dif}${pad(tzo / 60)}:${pad(tzo % 60)}`;
      return isoString;
    }
  }

  convertMbToKb(mb: number) {
    return 1024 * 1024 * mb;
  }

  convertGbToKb(gb: number) {
    return 1024 * 1024 * 1024 * gb;
  }

  private isInteger(value: any): value is number {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  }

  getMenuList(): any {
    const menuList = [
      {
        text: 'Dashboard',
        icon: 'fas fa-home',
        path: '/dashboard',
        visible: true,
        headerText: 'Welcome to the Dashboard',
        id: 0,
        selected: true,
      },
      {
        text: 'Post',
        icon: 'far fa-plus-square',
        path: '/create-post',
        visible: true,
        headerText: 'Post',
        id: 1,
      },
      {
        text: 'Category',
        icon: 'far fa-clone',
        path: '/list-category',
        visible: true,
        headerText: 'Category',
        id: 2,
      },
      {
        text: 'HashTag',
        icon: 'far fa-hashtag',
        path: '/list-hashtag',
        visible: true,
        headerText: 'HashTag',
        id: 3,
      },
      {
        text: 'Schedule Content',
        icon: 'far fa-calendar',
        path: '/schedule-setup',
        visible: true,
        headerText: 'Schedule Content',
        id: 4,
      },
      {
        text: 'Analytics',
        icon: 'far fa-chart-bar',
        path: '/analytics',
        visible: true,
        headerText: 'Analytics',
        id: 5,
      },
      {
        text: 'View Post',
        icon: 'far fa-chart-line',
        path: '/post-list',
        visible: true,
        headerText: 'View Post',
        id: 6,
      },
      {
        text: 'Administration',
        icon: 'far fa-user-lock',
        visible:
          this.permissionService.getGrantedPolicy(RolesRights.AbpIdentity_Roles) ||
          this.permissionService.getGrantedPolicy(RolesRights.AbpIdentity_Users) ||
          this.permissionService.getGrantedPolicy(RolesRights.AbpTenantManagement_Tenants) ||
          this.permissionService.getGrantedPolicy(RolesRights.SettingManagement_Emailing),
        id: 7,
        hasChildren: true,
      },
      {
        text: 'Identity Management',
        icon: 'far fa-id-card',
        visible:
          this.permissionService.getGrantedPolicy(RolesRights.AbpIdentity_Roles) ||
          this.permissionService.getGrantedPolicy(RolesRights.AbpIdentity_Users),
        id: 8,
        parentId: 7,
        hasChildren: true,
      },
      {
        text: 'Tenant Management',
        icon: 'far fa-users-cog',
        visible: this.permissionService.getGrantedPolicy(RolesRights.AbpTenantManagement_Tenants),
        id: 9,
        parentId: 7,
        hasChildren: true,
      },
      {
        text: 'Settings',
        icon: 'far fa-cog',
        path: '/setting-management',
        visible: this.permissionService.getGrantedPolicy(RolesRights.SettingManagement_Emailing),
        headerText: 'Settings',
        id: 10,
        parentId: 7,
      },
      {
        text: 'Tenants',
        icon: 'far fa-user-friends',
        path: '/tenant-management/tenants',
        visible: this.permissionService.getGrantedPolicy(RolesRights.AbpTenantManagement_Tenants),
        headerText: 'Tenants',
        id: 11,
        parentId: 9,
      },
      {
        text: 'Roles',
        icon: 'far fa-clipboard-list',
        path: '/identity/roles',
        visible: this.permissionService.getGrantedPolicy(RolesRights.AbpIdentity_Roles),
        headerText: 'Roles',
        id: 12,
        parentId: 8,
      },
      {
        text: 'Users',
        icon: 'far fa-user',
        path: '/identity/users',
        visible: this.permissionService.getGrantedPolicy(RolesRights.AbpIdentity_Users),
        headerText: 'Users',
        id: 13,
        parentId: 8,
      },
    ];

    return menuList;
  }

  getDaysDiff(startDate: Date, endDate: Date): number {
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const diffMilliseconds = Math.abs(endDate.getTime() - startDate.getTime());
    return Math.ceil(diffMilliseconds / oneDay);
  }

  // Function to calculate the number of days left from today to the expiration date
  getDaysLeftToExpire(expirationDate: Date): number {
    const today = new Date();
    return this.getDaysDiff(today, expirationDate);
  }

  connectWebSocketServer() {
    let apiURL = environment.apis.default.url;
    const tenant = this.sessionState.getTenant();

    if (tenant?.id) {
      apiURL = apiURL.replace('{0}', tenant?.name);
    } else {
      apiURL = apiURL.replace('{0}.', '');
    }
    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(apiURL + `/my-messaging-hub`)
      .build();

    connection
      .start()
      .then(function () {
        if (tenant?.id) {
          connection.invoke('JoinGroup', tenant?.id);
        }
      })
      .catch(function (err) {
        return console.error(err.toString());
      });

    connection.on('SendMessage', res => {
      if (res.planData != null) this.store.dispatch(new SetTenantSubscriptionPlan(res)).subscribe();
    });
  }

  getInitials(name: string): string {
    return name ? name[0].toUpperCase() : '';
  }

  astricValidationColor(cdr?, elementRef?, renderer?) {
    cdr.detectChanges();
    const labelElements = elementRef.nativeElement.querySelectorAll(
      'abp-extensible-form .form-label',
    );
    labelElements.forEach((label: HTMLElement) => {
      const labelText = label.textContent.trim();
      if (label.textContent.includes('*')) {
        const labelTextWithoutAsterisk = labelText.endsWith('*') ? labelText.slice(0, -1) : '';
        label.textContent = labelTextWithoutAsterisk;
        const asterisk = renderer.createElement('span');
        asterisk.textContent = '*';
        renderer.setStyle(asterisk, 'color', '#f44336');
        renderer.appendChild(label, asterisk);
      }
    });
  }

  imageVideoSizeValidation(data: any) {
    const maxImageSize = this.convertMbToKb(environment.maxImageSize);
    const maxVideoSize = this.convertMbToKb(environment.maxVideoSize);

    for (const image of data.uploadedImages) {
      if (this.isImage(image.value) || this.isGIF(image.value)) {
        if (image.size > maxImageSize) {
          this.toasterService.error(
            NotificationMessage.imageMaxSizeLimitMSG,
            '',
            this.toasterMessageConfiguration,
          );
          return false;
        }
      } else {
        if (image.size > maxVideoSize) {
          this.toasterService.error(
            NotificationMessage.videoMaxSizeLimitMSG,
            '',
            this.toasterMessageConfiguration,
          );
          return false;
        }
      }
    }

    return true;
  }
}
