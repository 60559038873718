import { mapEnumToOptions } from '@abp/ng.core';

export enum Subscription_CancelReasonEnum {
  UnKnown = 0,
  NotPaid = 1,
  NoCard = 2,
  FraudReviewFailed = 3,
  NonCompliantEuCustomer = 4,
  TaxCalculationFailed = 5,
  CurrencyIncompatibleWithGateway = 6,
  NonCompliantCustomer = 7,
}

export const subscription_CancelReasonEnumOptions = mapEnumToOptions(Subscription_CancelReasonEnum);
