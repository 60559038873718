import { ConfigStateService, ReplaceableComponentsService } from '@abp/ng.core';
import { Component } from '@angular/core';
import { eThemeLeptonXComponents } from '@abp/ng.theme.lepton-x';
import { HomeComponent } from './home/home.component';
import { eIdentityComponents } from '@abp/ng.identity';
import { CustomRolesComponent, CustomUserComponent, CustomeTenantComponent } from './shared';
import { eTenantManagementComponents } from '@abp/ng.tenant-management';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout [ngClass]="isLoggged ? '' : 'hide-sidebar'"></abp-dynamic-layout>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
      <p style="font-size: 20px; color: white">Loading...</p>
    </ngx-spinner>
  `,
})
export class AppComponent {
  isLoggged = false;

  constructor(
    private config: ConfigStateService,
    private replaceableComponents: ReplaceableComponentsService,
  ) {
    this.replaceableComponents.add({
      component: HomeComponent,
      key: eThemeLeptonXComponents.ApplicationLayout,
    });

    this.replaceableComponents.add({
      component: CustomRolesComponent,
      key: eIdentityComponents.Roles,
    });

    this.replaceableComponents.add({
      component: CustomUserComponent,
      key: eIdentityComponents.Users,
    });

    this.replaceableComponents.add({
      component: CustomeTenantComponent,
      key: eTenantManagementComponents.Tenants,
    });

    const configs = this.config.getAll();
    this.isLoggged = false;
    this.isLoggged = configs.currentUser.isAuthenticated;
  }
}
