import type {
  CreateUpdateSocialAccountAnalyticDto,
  SocialAccountAnalyticsDto,
  SocialAccountAnalyticsInput,
} from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { BarChartDetailModel } from '../common/models/models';

@Injectable({
  providedIn: 'root',
})
export class SocialAccountAnalyticService {
  apiName = 'Default';

  getAnalyticsBarChart = (
    socialAccountAnalyticsInput: SocialAccountAnalyticsInput,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, BarChartDetailModel>(
      {
        method: 'GET',
        url: '/api/app/social-account-analytic/analytics-bar-chart',
        params: {
          socialAccountId: socialAccountAnalyticsInput.socialAccountId,
          analyticsType: socialAccountAnalyticsInput.analyticsType,
          ['DateFilter.Filter']: socialAccountAnalyticsInput.dateFilter.filter,
          ['DateFilter.StartDate']: socialAccountAnalyticsInput.dateFilter.startDate,
          ['DateFilter.EndDate']: socialAccountAnalyticsInput.dateFilter.endDate,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getAnalyticsBySocialAccountId = (
    inputDto: SocialAccountAnalyticsInput,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, SocialAccountAnalyticsDto[]>(
      {
        method: 'GET',
        url: '/api/app/social-account-analytic/analytics-by-social-account-id',
        params: {
          socialAccountId: inputDto.socialAccountId,
          analyticsType: inputDto.analyticsType,
          ['DateFilter.Filter']: inputDto.dateFilter.filter,
          ['DateFilter.StartDate']: inputDto.dateFilter.startDate,
          ['DateFilter.EndDate']: inputDto.dateFilter.endDate,
        },
      },
      { apiName: this.apiName, ...config },
    );

  multipleUpdate = (
    id: number,
    inputDtos: CreateUpdateSocialAccountAnalyticDto[],
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, boolean>(
      {
        method: 'POST',
        url: `/api/app/social-account-analytic/${id}/multiple-update`,
        body: inputDtos,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
