import type { ContactUsDto, ContactUsGetListInput, CreateUpdateContactUsDto } from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService {
  apiName = 'Default';

  create = (input: CreateUpdateContactUsDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ContactUsDto>(
      {
        method: 'POST',
        url: '/api/app/contact-us',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/app/contact-us/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ContactUsDto>(
      {
        method: 'GET',
        url: `/api/app/contact-us/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: ContactUsGetListInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ContactUsDto>>(
      {
        method: 'GET',
        url: '/api/app/contact-us',
        params: {
          name: input.name,
          email: input.email,
          message: input.message,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: CreateUpdateContactUsDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ContactUsDto>(
      {
        method: 'PUT',
        url: `/api/app/contact-us/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
