export enum RolesRights {
  AbpIdentity_Roles = 'AbpIdentity.Roles',
  AbpIdentity_Roles_Create = 'AbpIdentity.Roles.Create',
  AbpIdentity_Roles_Delete = 'AbpIdentity.Roles.Delete',
  AbpIdentity_Roles_ManagePermissions = 'AbpIdentity.Roles.ManagePermissions',
  AbpIdentity_Roles_Update = 'AbpIdentity.Roles.Update',
  AbpIdentity_Users = 'AbpIdentity.Users',
  AbpIdentity_Users_Create = 'AbpIdentity.Users.Create',
  AbpIdentity_Users_Delete = 'AbpIdentity.Users.Delete',
  AbpIdentity_Users_ManagePermissions = 'AbpIdentity.Users.ManagePermissions',
  AbpIdentity_Users_Update = 'AbpIdentity.Users.Update',
  AbpTenantManagement_Tenants = 'AbpTenantManagement.Tenants',
  AbpTenantManagement_Tenants_Create = 'AbpTenantManagement.Tenants.Create',
  AbpTenantManagement_Tenants_Delete = 'AbpTenantManagement.Tenants.Delete',
  AbpTenantManagement_Tenants_ManageConnectionStrings = 'AbpTenantManagement.Tenants.ManageConnectionStrings',
  AbpTenantManagement_Tenants_ManageFeatures = 'AbpTenantManagement.Tenants.ManageFeatures',
  AbpTenantManagement_Tenants_Update = 'AbpTenantManagement.Tenants.Update',
  FeatureManagement_ManageHostFeatures = 'FeatureManagement.ManageHostFeatures',
  QForms_Assignee = 'QForms.Assignee',
  QForms_Assignee_Create = 'QForms.Assignee.Create',
  QForms_Assignee_Delete = 'QForms.Assignee.Delete',
  QForms_Assignee_Update = 'QForms.Assignee.Update',
  QForms_Form = 'QForms.Form',
  QForms_Form_Create = 'QForms.Form.Create',
  QForms_Form_Delete = 'QForms.Form.Delete',
  QForms_Form_Update = 'QForms.Form.Update',
  QForms_FormRevision = 'QForms.FormRevision',
  QForms_FormRevision_Create = 'QForms.FormRevision.Create',
  QForms_FormRevision_Delete = 'QForms.FormRevision.Delete',
  QForms_FormRevision_Update = 'QForms.FormRevision.Update',
  QForms_Group = 'QForms.Group',
  QForms_Group_Create = 'QForms.Group.Create',
  QForms_Group_Delete = 'QForms.Group.Delete',
  QForms_Group_Update = 'QForms.Group.Update',
  QForms_Response = 'QForms.Response',
  QForms_Response_Create = 'QForms.Response.Create',
  QForms_Response_Delete = 'QForms.Response.Delete',
  QForms_Response_Update = 'QForms.Response.Update',
  SettingManagement_Emailing = 'SettingManagement.Emailing',
  SettingManagement_Emailing_Test = 'SettingManagement.Emailing.Test',
}
