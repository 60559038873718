import type {
  CreateUpdateScheduleDto,
  ScheduleDto,
  ScheduleGetListInput,
  ScheduleTimeDto,
} from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  apiName = 'Default';

  create = (input: CreateUpdateScheduleDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ScheduleDto>(
      {
        method: 'POST',
        url: '/api/app/schedule',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/app/schedule/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ScheduleDto>(
      {
        method: 'GET',
        url: `/api/app/schedule/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: ScheduleGetListInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ScheduleDto>>(
      {
        method: 'GET',
        url: '/api/app/schedule',
        params: {
          postId: input.postId,
          scheduleTime: input.scheduleTime,
          isScheduleJob: input.isScheduleJob,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getScheduleTime = (fromTime: string, toTime: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ScheduleTimeDto[]>(
      {
        method: 'GET',
        url: '/api/app/schedule/schedule-time',
        params: { fromTime, toTime },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: CreateUpdateScheduleDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ScheduleDto>(
      {
        method: 'PUT',
        url: `/api/app/schedule/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
