import type { SocialAccountsDto } from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateSocialAccountDto } from '../page-accounts/dtos/models';

@Injectable({
  providedIn: 'root',
})
export class SocialAccountsService {
  apiName = 'Default';

  create = (input: CreateSocialAccountDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SocialAccountsDto>(
      {
        method: 'POST',
        url: '/api/app/social-accounts',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/app/social-accounts/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SocialAccountsDto>(
      {
        method: 'GET',
        url: `/api/app/social-accounts/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getAll = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, SocialAccountsDto[]>(
      {
        method: 'GET',
        url: '/api/app/social-accounts',
      },
      { apiName: this.apiName, ...config },
    );

  getCount = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, number>(
      {
        method: 'GET',
        url: '/api/app/social-accounts/count',
      },
      { apiName: this.apiName, ...config },
    );

  getIsActive = (isActive: boolean, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SocialAccountsDto[]>(
      {
        method: 'GET',
        url: '/api/app/social-accounts/is-active',
        params: { isActive },
      },
      { apiName: this.apiName, ...config },
    );

  updateStatus = (id: number, IsActive: boolean, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>(
      {
        method: 'PUT',
        url: `/api/app/social-accounts/${id}/status`,
        params: { isActive: IsActive },
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
