import { mapEnumToOptions } from '@abp/ng.core';

export enum ItemTypeEnum {
  UnKnown = 0,
  Plan = 1,
  Addon = 2,
  Charge = 3,
}

export const itemTypeEnumOptions = mapEnumToOptions(ItemTypeEnum);
