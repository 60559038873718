import type { YoutubePrivacyStatus } from '../../enums/youtube-privacy-status.enum';
import type { YoutubeLicenceEnums } from '../../enums/youtube-licence-enums.enum';

export interface SavePostAccountDto {
  postId: number;
  socialAccountId: number;
  message?: string;
  mediaUrls: string[];
  isPostLike: boolean;
  hashtagIds: number[];
  youtubeCategoryId: number;
  youtubePrivacyStatus: YoutubePrivacyStatus;
  youtubeTitle?: string;
  youtubeIsLicensedContent: YoutubeLicenceEnums;
  youtubeIsNotifySubscribers: boolean;
  youtubeIsEmbeddable: boolean;
  youtubeIsMadeForKids: boolean;
}
