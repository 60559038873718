import { mapEnumToOptions } from '@abp/ng.core';

export enum Customer_CustomerChildAccountAccess_PortalDownloadInvoicesEnum {
  UnKnown = 0,
  Yes = 1,
  ViewOnly = 2,
  No = 3,
}

export const customer_CustomerChildAccountAccess_PortalDownloadInvoicesEnumOptions =
  mapEnumToOptions(Customer_CustomerChildAccountAccess_PortalDownloadInvoicesEnum);
