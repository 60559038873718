<div class="q-content" *ngIf="isDataLoaded">
  <div class="q-content-header">
    <div class="d-flex gap-15 align-items-center justify-content-end wp-100 mb-2">
      <kendo-dropdownlist
        [data]="postTypeList"
        textField="name"
        valueField="id"
        (valueChange)="onDropdownChange()"
        [(ngModel)]="postType"
        class="status bg-white"
      >
      </kendo-dropdownlist>
    </div>
  </div>
  <div *ngFor="let items of viewPostData; let x = index" class="mb-2">
    <kendo-card class="wp-100">
      <kendo-card-header>
        <div
          class="d-flex align-items-start justify-content-between gap-30 view-post-list flex-wrap"
        >
          <div class="d-flex gap-15 flex-wrap view-post-profile-list">
            <div
              class="d-flex flex-column align-items-center"
              *ngFor="let ad of profileDetails; let i = index"
            >
              <kendo-badge-container
                [ngStyle]="{ opacity: isItemSelected(items, ad.id) ? '1' : '0.35' }"
              >
                <ng-container *ngIf="ad.img; else noImageTemplate">
                  <kendo-avatar
                    rounded="full"
                    size="large"
                    [border]="true"
                    [imageSrc]="ad.img"
                  ></kendo-avatar>
                </ng-container>

                <ng-template #noImageTemplate>
                  <kendo-avatar
                    rounded="full"
                    size="large"
                    [border]="true"
                    [initials]="getInitials(ad.accountName)"
                  ></kendo-avatar>
                </ng-template>
                <kendo-badge
                  size="large"
                  [align]="badgeAlign"
                  [position]="position"
                  [cutoutBorder]="true"
                  class="social-badge"
                >
                  <i
                    [ngClass]="{
                      'instagram-icon-color':
                        commonService.getIconClass(ad.accountType) === 'fa-instagram-square',
                      'text-linked-in-icon-color':
                        commonService.getIconClass(ad.accountType) === 'fa-linkedin-in',
                      'text-twitter-icon-color':
                        commonService.getIconClass(ad.accountType) === 'fa-x-twitter',
                      'text-facebook-icon-color':
                        commonService.getIconClass(ad.accountType) === 'fa-facebook-f',
                      'text-youtube-icon-color':
                        commonService.getIconClass(ad.accountType) === 'fa-youtube'
                    }"
                    class="fab {{ commonService.getIconClass(ad.accountType) }}"
                  ></i>
                </kendo-badge>
              </kendo-badge-container>
              <p class="font-size-10 mt-10 text-align-center text-secondary-text">
                {{ ad.accountName }}
              </p>
            </div>
          </div>
          <div class="d-flex gap-15 view-post-status-list">
            <div class="d-flex gap-15 disabled">
              <div class="d-flex align-items-center gap-10 post-status">
                <input
                  type="radio"
                  [id]="'draft' + x"
                  [name]="'approveSelect' + x"
                  [value]="true"
                  kendoRadioButton
                  [(ngModel)]="items.isDraft"
                  [disabled]="true"
                />
                <kendo-label
                  class="text-primary-text"
                  [for]="'draft' + x"
                  text="Draft"
                ></kendo-label>
              </div>
              <div class="d-flex align-items-center gap-10 post-status">
                <input
                  type="radio"
                  [id]="'approved' + x"
                  [name]="'approveSelect' + x"
                  [value]="false"
                  kendoRadioButton
                  [(ngModel)]="items.isDraft"
                  [disabled]="true"
                />
                <kendo-label
                  class="text-primary-text"
                  [for]="'approved' + x"
                  text="Approved"
                ></kendo-label>
              </div>
            </div>
            <div class="d-flex gap-15 align-items-center">
              <div title="Copy" class="view-post-action-buttons">
                <a href="javascript:void(0)" (click)="onCopyClick(items.id)">
                  <i class="far fa-copy"></i>
                </a>
              </div>
              <div
                *ngIf="items.isDraft || items?.showEditButtonForApproved"
                class="view-post-action-buttons"
                title="Edit"
              >
                <a href="javascript:void(0)" (click)="onEditClick(items.id)">
                  <i class="far fa-pen"></i>
                </a>
              </div>
              <div class="view-post-action-buttons" title="Delete">
                <a href="javascript:void(0)" (click)="onDeleteClick(items)">
                  <i class="far fa-trash-alt"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </kendo-card-header>
      <kendo-card-body>
        <div *ngIf="!items.isCustomizedPost">
          <div>
            <p [innerHTML]="items.message"></p>
          </div>
          <div *ngIf="items.mediaUrls.length > 0">
            <div class="d-flex gap-15 flex-wrap">
              <div
                *ngFor="let mediaUrl of items.mediaUrls; let j = index"
                class="view-post-input-images"
              >
                <div class="img-wrapper">
                  <img
                    *ngIf="commonService.isImage(mediaUrl.value)"
                    [src]="mediaUrl.base64Value"
                    class="sizing"
                  />
                  <video
                    *ngIf="commonService.isVideo(mediaUrl.value)"
                    class="sizing"
                    controls
                    autoplay
                  >
                    <source [src]="mediaUrl.base64Value" type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="items.isCustomizedPost" class="social-account-list-wrapper">
          <div
            *ngFor="let socialAccounts of items.socialAccounts; let i = index"
            class="social-account-list mb-2"
          >
            <div>
              <p [innerHTML]="socialAccounts.message"></p>
            </div>
            <div class="d-flex align-items-center gap-20">
              <div>
                <kendo-badge-container>
                  <ng-container *ngIf="socialAccounts.img; else noImageSocialAccountsTemplate">
                    <kendo-avatar
                      rounded="full"
                      size="large"
                      [border]="true"
                      [imageSrc]="socialAccounts.img"
                    ></kendo-avatar>
                  </ng-container>

                  <ng-template #noImageSocialAccountsTemplate>
                    <kendo-avatar
                      rounded="full"
                      size="large"
                      [border]="true"
                      [initials]="getInitials(socialAccounts.accountName)"
                    ></kendo-avatar>
                  </ng-template>
                  <kendo-badge
                    size="large"
                    [align]="badgeAlign"
                    [position]="position"
                    [cutoutBorder]="true"
                    class="social-badge"
                  >
                    <i
                      [ngClass]="{
                        'instagram-icon-color':
                          commonService.getIconClass(socialAccounts.accountType) ===
                          'fa-instagram-square',
                        'text-linked-in-icon-color':
                          commonService.getIconClass(socialAccounts.accountType) ===
                          'fa-linkedin-in',
                        'text-twitter-icon-color':
                          commonService.getIconClass(socialAccounts.accountType) === 'fa-x-twitter',
                        'text-facebook-icon-color':
                          commonService.getIconClass(socialAccounts.accountType) ===
                          'fa-facebook-f',
                        'text-youtube-icon-color':
                          commonService.getIconClass(socialAccounts.accountType) === 'fa-youtube'
                      }"
                      class="fab {{ commonService.getIconClass(socialAccounts.accountType) }}"
                    ></i>
                  </kendo-badge>
                </kendo-badge-container>
              </div>
              <div *ngIf="socialAccounts.mediaUrls.length > 0">
                <div class="d-flex gap-15 flex-wrap">
                  <div
                    class="view-post-input-images"
                    *ngFor="let mediaUrl of socialAccounts.mediaUrls; let j = index"
                  >
                    <div class="img-wrapper">
                      <img
                        *ngIf="commonService.isImage(mediaUrl.value)"
                        [src]="mediaUrl.base64Value"
                        class="sizing"
                      />
                      <video
                        *ngIf="commonService.isVideo(mediaUrl.value)"
                        class="sizing"
                        controls
                        autoplay
                      >
                        <source [src]="mediaUrl.base64Value" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex gap-40 mt-10">
          <div *ngIf="items.isReQueue" class="view-post-options-menu">
            <p class="m-0"><b>Re-queue after posting :</b> {{ items.isReQueue }}</p>
          </div>
          <div *ngIf="items.isExpireAfter" class="view-post-options-menu">
            <p class="m-0"><b>Expire after it was published :</b> {{ items.isExpireAfter }}</p>
          </div>
          <div *ngIf="items.expireSpecificDate !== null" class="view-post-options-menu">
            <p class="m-0">
              <b>Expire at :</b> {{ items.expireSpecificDate | date: 'd/M/yyyy h:mm a' }}
            </p>
          </div>
          <div *ngIf="items.expireTimes !== null" class="view-post-options-menu">
            <p class="m-0"><b>Expire after :</b> {{ items.expireTimes }} number of post</p>
          </div>
        </div>
      </kendo-card-body>
    </kendo-card>
  </div>

  <ng-container *ngIf="noRecordFound">
    <div class="d-flex justify-content-center align-items-center flex-column gap-15 h-100">
      <div class="icon posts-data d-flex justify-content-center align-items-center">
        <img src="assets/images/viewPost.png" alt="no-post" width="350" />
      </div>
      <p class="subscribers mb-0 fw-500">0 Posts</p>
      <span class="description">There are no posts yet!</span>
    </div>
  </ng-container>
</div>

<div class="align-items-center pagination" *ngIf="collectionSize !== null && showPagination">
  <kendo-datapager
    [total]="collectionSize"
    [style.width.%]="100"
    [skip]="skipPage"
    [pageSize]="pageNumber"
    [pageSizeValues]="pageSizeValues"
    (pageChange)="getPremiumData($event)"
    (pageSizeChange)="onTableSizeChange($event)"
  >
  </kendo-datapager>
</div>
