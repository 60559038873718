import { mapEnumToOptions } from '@abp/ng.core';

export enum SocialAnalyticsType {
  Reach = 1,
  Engagement = 2,
  Impression = 3,
  Reaction = 4,
  Followers = 5,
  Share = 6,
  Saved = 7,
}
export const SocialAnalyticsTypeOptions = mapEnumToOptions(SocialAnalyticsType);
