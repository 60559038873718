import { mapEnumToOptions } from '@abp/ng.core';

export enum ChannelEnum {
  UnKnown = 0,
  Web = 1,
  AppStore = 2,
  PlayStore = 3,
}

export const channelEnumOptions = mapEnumToOptions(ChannelEnum);
