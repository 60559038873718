export interface YoutubeCallbackRequestDto {
  code?: string;
  state?: string;
  error?: string;
  error_code?: string;
  error_description?: string;
  error_reason?: string;
}

export interface YouTubeUserInfoDto {
  id: string;
  name?: string;
  description?: string;
  profile_picture_url?: string;
}
