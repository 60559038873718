import { mapEnumToOptions } from '@abp/ng.core';

export enum ReferralSystemEnum {
  UnKnown = 0,
  ReferralCandy = 1,
  ReferralSaasquatch = 2,
  Friendbuy = 3,
}

export const referralSystemEnumOptions = mapEnumToOptions(ReferralSystemEnum);
