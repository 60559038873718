import type { IGGenderWiseResponse, IGMediaInsightResponse } from './dtos/models';
import type { InstagramBusinessAccount } from './models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  AnalyticsInput,
  BarChartDetailModel,
  PieChartDetailModel,
} from '../common/models/models';
import type { ColumnChartDetailModel, DashboardCardModel } from '../dashboard/dtos/models';
import type { AccountListDto } from '../social-account/dtos/models';

@Injectable({
  providedIn: 'root',
})
export class InstagramService {
  apiName = 'Default';

  downloadProfile = (url: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: '/api/app/instagram/download-profile',
        params: { url },
      },
      { apiName: this.apiName, ...config },
    );

  getAccount = (userId: string, token: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, InstagramBusinessAccount>(
      {
        method: 'GET',
        url: `/api/app/instagram/account/${userId}`,
        params: { token },
      },
      { apiName: this.apiName, ...config },
    );

  getDashboardFollowerUnFollowerData = (socialAccountId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ColumnChartDetailModel>(
      {
        method: 'GET',
        url: `/api/app/instagram/dashboard-follower-un-follower-data/${socialAccountId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDashboardKeyMetricsInsights = (socialId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PieChartDetailModel>(
      {
        method: 'GET',
        url: `/api/app/instagram/dashboard-key-metrics-insights/${socialId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDashboardPostCount = (socialId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PieChartDetailModel>(
      {
        method: 'GET',
        url: `/api/app/instagram/dashboard-post-count/${socialId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getInstagramBusinessAccounts = (state: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AccountListDto[]>(
      {
        method: 'GET',
        url: '/api/app/instagram/instagram-business-accounts',
        params: { state },
      },
      { apiName: this.apiName, ...config },
    );

  getInstagramMediaInsights = (mediaId: string, token: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IGMediaInsightResponse>(
      {
        method: 'GET',
        url: `/api/app/instagram/instagram-media-insights/${mediaId}`,
        params: { token },
      },
      { apiName: this.apiName, ...config },
    );

  postAnalyticsCount = (filter: AnalyticsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DashboardCardModel>(
      {
        method: 'POST',
        url: '/api/app/instagram/analytics-count',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  postAnalyticsFollowerGenderWiseData = (filter: AnalyticsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IGGenderWiseResponse[]>(
      {
        method: 'POST',
        url: '/api/app/instagram/analytics-follower-gender-wise-data',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  postAnalyticsFollowerGrowth = (filter: AnalyticsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ColumnChartDetailModel>(
      {
        method: 'POST',
        url: '/api/app/instagram/analytics-follower-growth',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  postAnalyticsPerformanceSnapshot = (filter: AnalyticsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PieChartDetailModel>(
      {
        method: 'POST',
        url: '/api/app/instagram/analytics-performance-snapshot',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  postAnalyticsSocialAction = (filter: AnalyticsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BarChartDetailModel>(
      {
        method: 'POST',
        url: '/api/app/instagram/analytics-social-action',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  prepareBusinessUrlByReturnUrl = (returnUrl: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/app/instagram/prepare-business-url',
        params: { returnUrl },
      },
      { apiName: this.apiName, ...config },
    );

  prepareReauthenticatedBusinessUrlBySocialAccountIdAndReturnUrl = (
    socialAccountId: number,
    returnUrl: string,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: `/api/app/instagram/prepare-reauthenticated-business-url/${socialAccountId}`,
        params: { returnUrl },
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
