export enum Frequency {
  Month = 3,
  Year = 4,
}

export enum PlanName {
  FreePlan = 'Free Plan',
  PaidPlan = 'Paid Plan',
}

export enum PaymentMethod {
  All = -1,
  'Card Payment' = 1,
  Cash = 2,
}

export enum PaymentStatus {
  All = -1,
  'In Progress' = 1,
  Success = 2,
  Failed = 4,
  Timeout = 5,
}
