<div class="schedule-post">
  <form [formGroup]="scheduleForm">
    <div>
      <div class="d-flex gap-10 align-items-center">
        <h6 class="title m-0">Preview your schedule</h6>
        <a href="javascript:void(0)">
          <i
            class="fas fa-info-circle text-secondary-text"
            [title]="tooltipText.previewPostAtSpecificTime"
          ></i>
        </a>
      </div>
      <div class="mt-2 text-align-center">
        <kendo-calendar
          [value]="previewDatePickerDate"
          type="classic"
          selection="multiple"
          [disabledDates]="disablePreviewDatePicker"
        ></kendo-calendar>
      </div>
    </div>
    <div class="mt-2 schedule-post-setting">
      <div class="d-flex gap-10 align-items-center">
        <h6 class="title m-0">Post at a specific Date and time</h6>
        <a href="javascript:void(0)">
          <i
            class="fas fa-info-circle text-secondary-text"
            [title]="tooltipText.postAtSpecificTime"
          ></i>
        </a>
      </div>
      <div
        class="d-flex align-items-center gap-10 pr-10 mt-2"
        *ngFor="let data of tempDateTimeArray; let i = index"
      >
        <kendo-datetimepicker
          (valueChange)="onDateTimeChange($event, i)"
          [format]="format"
          [(value)]="data.date"
          #dateTimePickers
          [disabledDates]="disabledDates"
          (focus)="openDatePicker(dateTimePickers)"
          [popupSettings]="popupSettings"
        >
        </kendo-datetimepicker>
        <a href="javascript:void(0)" (click)="onDeletePost(i)" class="text-secondary-text">
          <i class="far fa-trash-alt"></i>
        </a>
        <a href="javascript:void(0)" (click)="addDatePicker()" class="text-secondary-text">
          <i class="far fa-plus-square"></i>
        </a>
      </div>
    </div>
    <div class="mt-2">
      <div class="d-flex gap-20 align-items-center">
        <div class="d-flex gap-10 align-items-center">
          <h6 class="title m-0">Re-Queue after posting</h6>
          <a href="javascript:void(0)">
            <i class="fas fa-info-circle text-secondary-text" [title]="tooltipText.requeue"></i>
          </a>
        </div>
        <kendo-label>
          <kendo-switch
            [(ngModel)]="isReQueue"
            (ngModelChange)="onSwitchChange($event)"
            [ngModelOptions]="{ standalone: true }"
          ></kendo-switch>
        </kendo-label>
      </div>
      <div class="mt-2 expire-post-setting">
        <div class="d-flex gap-10 mb-2 d-flex-md-column">
          <div class="d-flex gap-10 align-items-center">
            <input
              type="checkbox"
              value=""
              formControlName="isExpireAfter"
              #isExpireAfter
              kendoCheckBox
            />
            <span class="expire-post-text">Expire after it was published</span>
            <a href="javascript:void(0)">
              <i
                class="fas fa-info-circle text-secondary-text"
                [title]="tooltipText.expireAfterPublish"
              ></i>
            </a>
          </div>
          <div class="d-flex gap-10 align-items-center">
            <kendo-textbox
              formControlName="expireTimes"
              (keypress)="onlyNumericValueValidator($event)"
              [maxlength]="3"
            >
            </kendo-textbox>
            <span class="expire-post-text">Times</span>
          </div>
        </div>
        <div class="d-flex gap-10 d-flex-md-column">
          <div class="d-flex gap-10 align-items-center">
            <input
              type="checkbox"
              value=""
              formControlName="isExpireSpecificDate"
              #isExpireSpecificDate
              kendoCheckBox
            />
            <span class="expire-post-text">Expire at a specific date</span>
            <a href="javascript:void(0)">
              <i
                class="fas fa-info-circle text-secondary-text"
                [title]="tooltipText.expireAtSpecificDate"
              ></i>
            </a>
          </div>
          <kendo-datetimepicker
            [format]="format"
            formControlName="expireSpecificDate"
            [disabledDates]="disabledDates"
            #dateTimePickers
            (focus)="openDatePicker(dateTimePickers)"
          ></kendo-datetimepicker>
        </div>
      </div>
    </div>
  </form>
</div>
