import { mapEnumToOptions } from '@abp/ng.core';

export enum Customer_CustomerPaymentMethod_StatusEnum {
  UnKnown = 0,
  Valid = 1,
  Expiring = 2,
  Expired = 3,
  Invalid = 4,
  PendingVerification = 5,
}

export const customer_CustomerPaymentMethod_StatusEnumOptions = mapEnumToOptions(
  Customer_CustomerPaymentMethod_StatusEnum,
);
