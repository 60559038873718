import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class OpenAiService {
  private apiKey = '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'; // Replace with your OpenAI API key
  private apiUrl = 'https://api.openai.com/v1/engines/davinci/completions';

  constructor(private http: HttpClient) {}

  generateImageCaption(imageUrl: string): Observable<any> {
    const requestBody = {
      prompt: `a happy girl`,
      max_tokens: 50,
    };

    return this.http.post(this.apiUrl, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.apiKey}`,
      },
    });
  }

  getCompletion(prompt: string, engine: string = 'davinci'): Observable<any> {
    const requestBody = {
      prompt: prompt,
      max_tokens: 30,
      n: 3,
    };

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.apiKey}`,
    };

    return this.http.post<any>(this.apiUrl, requestBody, { headers });
  }
}
