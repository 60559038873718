import { mapEnumToOptions } from '@abp/ng.core';

export enum ItemPrice_ShippingPeriodUnitEnum {
  UnKnown = 0,
  Day = 1,
  Week = 2,
  Month = 3,
  Year = 4,
}

export const itemPrice_ShippingPeriodUnitEnumOptions = mapEnumToOptions(
  ItemPrice_ShippingPeriodUnitEnum,
);
