import { mapEnumToOptions } from '@abp/ng.core';

export enum FreePeriodUnitEnum {
  UnKnown = 0,
  Day = 1,
  Week = 2,
  Month = 3,
  Year = 4,
}

export const freePeriodUnitEnumOptions = mapEnumToOptions(FreePeriodUnitEnum);
