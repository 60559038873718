import { mapEnumToOptions } from '@abp/ng.core';

export enum Subscription_SubscriptionSubscriptionItem_ItemTypeEnum {
  UnKnown = 0,
  Plan = 1,
  Addon = 2,
  Charge = 3,
}

export const subscription_SubscriptionSubscriptionItem_ItemTypeEnumOptions = mapEnumToOptions(
  Subscription_SubscriptionSubscriptionItem_ItemTypeEnum,
);
