<div class="left-side-conent">
  <div class="d-flex align-items-center justify-content-between mb-2">
    <div class="d-flex align-items-center gap-10 justify-content-between mb-2">
      <h6 class="title m-0">Publish Post</h6>
      <a href="javascript:void(0)">
        <i class="fas fa-info-circle text-secondary-text" [title]="tooltipText.imageVideoSize"></i>
      </a>
    </div>
    <div class="d-flex align-items-center justify-content-end gap-10">
      <input type="checkbox" kendoCheckBox (change)="selectAllUser()" [checked]="isAllSelected" />
      <label for="selectAll" *ngIf="!isAllSelected" class="m-0">Select All</label>
      <label for="selectAll" *ngIf="isAllSelected" class="m-0">Unselect All</label>
    </div>
  </div>
  <div *ngIf="accountDetails.length > 0">
    <div *ngIf="accountDetails[0].accountType !== null">
      <div class="d-flex gap-15 flex-wrap account-list">
        <div
          class="d-flex flex-column align-items-center"
          *ngFor="let ad of accountDetails; let i = index"
        >
          <kendo-badge-container
            [title]="tooltip(ad.accountType, ad.accountName)"
            [ngStyle]="{ opacity: ad.isSelected ? '1' : '0.35' }"
          >
            <ng-container *ngIf="ad.img; else noImageTemplate">
              <kendo-avatar
                rounded="full"
                size="large"
                [border]="true"
                [imageSrc]="ad.img"
              ></kendo-avatar>
            </ng-container>

            <ng-template #noImageTemplate>
              <kendo-avatar
                rounded="full"
                size="large"
                [border]="true"
                [initials]="getInitials(ad.accountName)"
              ></kendo-avatar>
            </ng-template>
            <input
              type="checkbox"
              #notification
              kendoCheckBox
              [value]="ad.id"
              (change)="selectedUsers($event, ad.isSelected)"
              class="select-user"
              [checked]="ad.isSelected ? true : false"
            />
            <kendo-badge
              size="large"
              [align]="badgeAlign"
              [position]="position"
              [cutoutBorder]="true"
              class="social-badge"
            >
              <i
                [ngClass]="{
                  'instagram-icon-color':
                    commonService.getIconClass(ad.accountType) === 'fa-instagram-square',
                  'text-linked-in-icon-color':
                    commonService.getIconClass(ad.accountType) === 'fa-linkedin-in',
                  'text-twitter-icon-color':
                    commonService.getIconClass(ad.accountType) === 'fa-x-twitter',
                  'text-facebook-icon-color':
                    commonService.getIconClass(ad.accountType) === 'fa-facebook-f',
                  'text-youtube-icon-color':
                    commonService.getIconClass(ad.accountType) === 'fa-youtube'
                }"
                class="fab {{ commonService.getIconClass(ad.accountType) }}"
              ></i>
            </kendo-badge>
          </kendo-badge-container>
          <p class="font-size-10 mt-10 text-align-center text-secondary-text">
            {{ ad.accountName }}
          </p>
        </div>
      </div>
      <div class="post-detalis d-flex align-items-center gap-40">
        <div class="numnber-of-channel">
          <p class="mb-0 d-flex align-items-center gap-10">
            <i class="far fa-circle-info info-icon"></i
            ><span>Channel Selected : {{ selectedAccountCount }}</span>
          </p>
        </div>
        <div class="credits">
          <p class="mb-0">
            Credit will Utilize : <span>{{ creditInUser }} credits</span>
          </p>
        </div>
      </div>
      <div class="mt-2">
        <div class="d-flex gap-20 justify-content-end">
          <a class="d-flex gap-10 edit-post" (click)="onCustomizeclick()">
            <i class="far fa-pen"></i>
            <ng-container *ngIf="!isProfileCustomize; else commonPostTemplate">
              <span>Customize Post</span>
            </ng-container>

            <ng-template #commonPostTemplate>
              <span>Common Post</span>
            </ng-template>
          </a>
          <div class="d-flex align-items-center gap-10 post-status">
            <input
              type="radio"
              #draft
              name="approveSelect"
              [value]="true"
              kendoRadioButton
              [(ngModel)]="isDarfMode"
              (change)="onIsDarfModeChange()"
            />
            <kendo-label class="text-primary-text" [for]="draft" text="Draft"></kendo-label>
          </div>
          <div class="d-flex align-items-center gap-10 post-status">
            <input
              type="radio"
              #approved
              name="approveSelect"
              [value]="false"
              kendoRadioButton
              [(ngModel)]="isDarfMode"
              (change)="onIsDarfModeChange()"
            />
            <kendo-label class="text-primary-text" [for]="approved" text="Approved"></kendo-label>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2" *ngIf="!isProfileCustomize">
      <kendo-card [width]="auto">
        <kendo-card-header class="k-hstack gap-15">
          <kendo-avatar rounded="medium" class="k-avatar-square">
            <i class="far fa-comment-alt font-size-24"></i>
          </kendo-avatar>
          <div>
            <h1 kendoCardTitle class="fw-normal">Create a post</h1>
            <p kendoCardSubtitle class="fw-normal">
              Create a high-performing post to get your message across.
            </p>
          </div>
        </kendo-card-header>
        <kendo-card-body>
          <div *ngFor="let ad of accountDetails; let i = index">
            <div *ngIf="i === 0">
              <quill-editor
                customToolbarPosition="bottom"
                [(ngModel)]="ad.caption"
                [placeholder]="ad.caption ? '' : 'What do you want to share?'"
                (onContentChanged)="onQuillEditorChange($event)"
              >
                <div quill-editor-toolbar>
                  <div>
                    <div class="d-flex gap-20 flex-wrap">
                      <div
                        class="upload-image-wrapper"
                        *ngFor="let url of ad.uploadedImages; let i = index"
                      >
                        <img
                          [src]="url.base64Value"
                          class="sizing"
                          *ngIf="commonService.isImage(url.value) || commonService.isGIF(url.value)"
                        />
                        <video
                          class="sizing"
                          *ngIf="commonService.isVideo(url.value)"
                          loop
                          autoplay
                        >
                          <source [src]="url.base64Value" type="video/mp4" />
                        </video>
                        <a
                          href="javascript:void(0)"
                          (click)="removeImage(url.base64Value, i)"
                          class="btn-round upload-image-action-buttons"
                        >
                          <i class="fas fa-times-circle"></i>
                        </a>
                        <a
                          href="javascript:void(0)"
                          (click)="addAltText(i)"
                          class="btn-list d-none upload-image-action-buttons"
                        >
                          <i class="fa-sharp fa-light fa-rectangle-list"></i>
                        </a>
                        <a
                          href="javascript:void(0)"
                          (click)="editImage(url)"
                          class="btn-edit upload-image-action-buttons"
                          *ngIf="commonService.isImage(url.value)"
                        >
                          <i class="far fa-pen"></i>
                        </a>
                      </div>
                    </div>
                    <div class="d-flex justify-content-space-between">
                      <div class="d-flex align-items-center">
                        <kendo-dropdownbutton
                          class="editor-actions-dropdown"
                          iconClass="fas fa-ellipsis-v"
                          [data]="actionForEditor"
                          (itemClick)="onActionForEditorClick($event, ad)"
                        >
                        </kendo-dropdownbutton>
                        <button class="ql-emoji" (click)="toggleEmojiPicker()">
                          <i class="far fa-smile"></i>
                        </button>
                        <div class="upload-images">
                          <input
                            type="file"
                            class="upload-images-icon"
                            multiple
                            (change)="onSelectFile($event, ad.uploadedImages)"
                            id="btn-check-2-outlined"
                          />
                          <input
                            type="file"
                            class="btn-check"
                            multiple
                            (change)="onSelectFile($event, ad.uploadedImages)"
                            id="btn-check-2-outlined"
                          />

                          <label class="photo-video mb-0" for="btn-check-2-outlined">
                            <i class="fas fa-camera"></i>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </quill-editor>
              <div class="float-right">
                <emoji-mart
                  class="emoji-mart"
                  *ngIf="showEmojiPicker"
                  (emojiSelect)="addEmoji($event, ad)"
                  title="Pick your emoji…"
                ></emoji-mart>
              </div>
            </div>
          </div>
        </kendo-card-body>
      </kendo-card>
    </div>
    <div class="mt-2" *ngIf="!isProfileCustomize">
      <div
        class="form-group d-flex gap-20 align-items-center d-flex-md-column flex-align-items-md-start"
      >
        <div class="d-flex gap-10 align-items-center">
          <kendo-label text="Category"></kendo-label>
          <a href="javascript:void(0)">
            <i
              class="fas fa-info-circle font-size-24 text-secondary-text"
              [tooltipTemplate]="template"
              filter=".k-grid td"
              [title]="tooltipText.category"
            ></i>
          </a>
        </div>
        <div class="wp-100">
          <kendo-dropdownlist
            [data]="categoriesList"
            [textField]="'name'"
            [valueField]="'id'"
            [valuePrimitive]="true"
            [(ngModel)]="category"
          ></kendo-dropdownlist>
        </div>
      </div>
    </div>
    <div class="mt-2" *ngIf="!isProfileCustomize">
      <kendo-card [width]="auto">
        <kendo-card-header class="k-hstack gap-15">
          <kendo-avatar rounded="medium" class="k-avatar-square">
            <i class="fas fa-hashtag font-size-24"></i>
          </kendo-avatar>
          <div>
            <h1 kendoCardTitle class="fw-normal">Hashtag Collection</h1>
            <p kendoCardSubtitle class="fw-normal">Pick from your saved or generate new hashtag</p>
          </div>
        </kendo-card-header>
        <kendo-card-body>
          <kendo-multiselect
            [data]="hashtagList"
            [(ngModel)]="hashtagValue"
            [textField]="'hashtagCollectionName'"
            [valueField]="'hashtags'"
            class="hashtag-name"
            (removeTag)="removeHashtag($event)"
          >
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              <span class="clamp" title="{{ dataItem.hashtagCollectionName }}">
                {{ dataItem.hashtagCollectionName }}
              </span>
            </ng-template>
          </kendo-multiselect>
          <div class="d-flex gap-20 justify-content-center mt-2 hashtag-button">
            <button kendoButton fillMode="outline" (click)="onAddHashtagToEditor(hashtagValue)">
              Use Hashtag
            </button>
            <button kendoButton fillMode="outline" (click)="onAddHashTag()">Create Hashtag</button>
          </div>
        </kendo-card-body>
      </kendo-card>
    </div>
  </div>

  <div *ngIf="isProfileCustomize" class="mt-2 profile-customize">
    <kendo-card width="auto">
      <kendo-card-body>
        <div class="mb-2" *ngFor="let ad of accountDetails; let x = index">
          <div *ngIf="ad.isSelected" class="d-flex gap-10 align-items-center">
            <div class="d-flex flex-column align-items-center profile-details">
              <kendo-badge-container [title]="tooltip(ad.accountType, ad.accountName)">
                <ng-container *ngIf="ad.img; else noImageCustomizeTemplate">
                  <kendo-avatar
                    rounded="full"
                    size="large"
                    [border]="true"
                    [imageSrc]="ad.img"
                  ></kendo-avatar>
                </ng-container>

                <ng-template #noImageCustomizeTemplate>
                  <kendo-avatar
                    rounded="full"
                    size="large"
                    [border]="true"
                    [initials]="getInitials(ad.accountName)"
                  ></kendo-avatar>
                </ng-template>
                <kendo-badge
                  size="large"
                  [align]="badgeAlign"
                  [position]="position"
                  [cutoutBorder]="true"
                >
                  <i
                    [ngClass]="{
                      'instagram-icon-color':
                        commonService.getIconClass(ad.accountType) === 'fa-instagram-square',
                      'text-linked-in-icon-color':
                        commonService.getIconClass(ad.accountType) === 'fa-linkedin-in',
                      'text-twitter-icon-color':
                        commonService.getIconClass(ad.accountType) === 'fa-x-twitter',
                      'text-facebook-icon-color':
                        commonService.getIconClass(ad.accountType) === 'fa-facebook-f',
                      'text-youtube-icon-color':
                        commonService.getIconClass(ad.accountType) === 'fa-youtube'
                    }"
                    class="fab {{ commonService.getIconClass(ad.accountType) }}"
                  ></i>
                </kendo-badge>
              </kendo-badge-container>
              <p class="font-size-10 mt-10 text-align-center text-secondary-text">
                {{ ad.accountName }}
              </p>
            </div>
            <div class="wp-100">
              <quill-editor
                #quillEditor
                customToolbarPosition="bottom"
                [placeholder]="'What do you want to share?'"
                (onContentChanged)="onQuillEditorCustomizeChange()"
                [id]="'editor-' + x"
                (focus)="onFocus(x)"
                (blur)="onBlur(x)"
                [(ngModel)]="ad.caption"
              >
                <div quill-editor-toolbar>
                  <div>
                    <div class="d-flex gap-20 flex-wrap">
                      <div
                        class="upload-image-wrapper"
                        *ngFor="let url of ad.uploadedImages; let imageIndex = index"
                      >
                        <img
                          [src]="url.base64Value"
                          class="sizing"
                          *ngIf="commonService.isImage(url.value) || commonService.isGIF(url.value)"
                        />
                        <video
                          class="sizing"
                          *ngIf="commonService.isVideo(url.value)"
                          loop
                          autoplay
                        >
                          <source [src]="url.base64Value" type="video/mp4" />
                        </video>

                        <a
                          href="javascript:void(0)"
                          (click)="removeImage(url.base64Value, imageIndex)"
                          class="btn-round upload-image-action-buttons"
                          ><i class="fas fa-times-circle"></i
                        ></a>
                        <a
                          href="javascript:void(0)"
                          (click)="addAltText(imageIndex)"
                          class="btn-list upload-image-action-buttons d-none"
                          ><i class="fa-sharp fa-light fa-rectangle-list"></i>
                        </a>
                        <a
                          href="javascript:void(0)"
                          (click)="editImage(url)"
                          class="upload-image-action-buttons btn-edit"
                          *ngIf="commonService.isImage(url.value)"
                        >
                          <i class="far fa-pen"></i
                        ></a>
                      </div>
                    </div>
                    <div class="d-flex justify-content-space-between">
                      <div class="d-flex align-items-center">
                        <kendo-dropdownbutton
                          iconClass="fas fa-ellipsis-v text-primary-text"
                          [data]="actionForEditor"
                          (itemClick)="onActionForCustomizeEditorClick($event, ad)"
                        >
                        </kendo-dropdownbutton>
                        <button class="ql-emoji" (click)="toggleEmojiPickerForIndex(x)">
                          <i class="far fa-smile"></i>
                        </button>
                        <div class="upload-images">
                          <input
                            type="file"
                            class="btn-check"
                            multiple
                            (change)="onSelectFile($event, ad.uploadedImages)"
                            id="btn-check-{{ x }}-outlined"
                          />

                          <label class="photo-video mb-0" for="btn-check-{{ x }}-outlined">
                            <i class="fa fa-camera"></i
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </quill-editor>
              <div class="float-right">
                <emoji-mart
                  class="emoji-mart"
                  *ngIf="showEmojiPickerArray[x]"
                  (emojiSelect)="addEmojiCustomize($event, ad)"
                  title="Pick your emoji…"
                ></emoji-mart>
              </div>
              <div class="wp-100 mt-1">
                <kendo-multiselect
                  [data]="hashtagList"
                  [(ngModel)]="ad.hashtagValue"
                  [textField]="'hashtagCollectionName'"
                  [valueField]="'hashtags'"
                  class="hashtag-name"
                  (removeTag)="removeHashtag($event, ad)"
                >
                  <ng-template kendoMultiSelectItemTemplate let-dataItem>
                    <span class="clamp" title="{{ dataItem.hashtagCollectionName }}">
                      {{ dataItem.hashtagCollectionName }}
                    </span>
                  </ng-template>
                </kendo-multiselect>
                <div class="d-flex gap-20 justify-content-center mt-2">
                  <button
                    kendoButton
                    fillMode="outline"
                    (click)="onAddHashtagToEditor(ad.hashtagValue, ad)"
                  >
                    Use Hashtag
                  </button>
                  <button kendoButton fillMode="outline" (click)="onAddHashTag()">
                    Create Hashtag
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </kendo-card-body>
    </kendo-card>
  </div>
  <div class="mt-2" *ngIf="isProfileCustomize">
    <div class="form-group d-flex gap-30 align-items-center">
      <div class="d-flex gap-10 align-items-center">
        <kendo-label text="Category"></kendo-label>
        <a href="javascript:void(0)">
          <i
            class="fas fa-info-circle font-size-24 text-secondary-text"
            [tooltipTemplate]="template"
            filter=".k-grid td"
            [title]="tooltipText.category"
          ></i>
        </a>
      </div>
      <div class="wp-100">
        <kendo-dropdownlist
          [data]="categoriesList"
          [textField]="'name'"
          [valueField]="'id'"
          [valuePrimitive]="true"
          [(ngModel)]="category"
        ></kendo-dropdownlist>
      </div>
    </div>
  </div>

  <div class="mt-2" *ngIf="isYouTubeAccountSelected">
    <kendo-card [width]="auto">
      <kendo-card-header class="k-hstack gap-15">
        <kendo-avatar rounded="medium" class="k-avatar-square">
          <i class="fa-brands fa-youtube font-size-24"></i>
        </kendo-avatar>
        <div>
          <h1 kendoCardTitle class="fw-normal">Create a YouTube Short</h1>
          <p kendoCardSubtitle class="fw-normal">
            Create a small YouTube Short to attract users to your channel
          </p>
        </div>
      </kendo-card-header>
      <kendo-card-body>
        <div class="upload-short-details">
          <form class="k-form" [formGroup]="youTubeShortsForm">
            <fieldset class="k-form-fieldset">
              <kendo-formfield class="mb-24">
                <kendo-label [for]="title" text="Title *"></kendo-label>
                <kendo-textbox #title formControlName="title"></kendo-textbox>
              </kendo-formfield>
              <div class="d-flex-row mb-24">
                <kendo-formfield class="d-flex-col-md-6 d-flex-col-xs-12 d-flex-col">
                  <kendo-label [for]="category" text="Category *"></kendo-label>
                  <kendo-dropdownlist
                    formControlName="category"
                    #visibility
                    [data]="youtubeCategoriesList"
                    textField="snippet.title"
                    valueField="id"
                    placeholder="value"
                    [(ngModel)]="selectedYoutubeCategoryValue"
                    [ngModelOptions]="{ standalone: true }"
                    [value]="selectedYoutubeCategoryValue"
                    [valuePrimitive]="false"
                  ></kendo-dropdownlist>
                </kendo-formfield>
                <kendo-formfield class="d-flex-col-md-6 d-flex-col-xs-12 d-flex-col">
                  <kendo-label [for]="visibility" text="Visibility *"></kendo-label>
                  <kendo-dropdownlist
                    formControlName="visiblity"
                    #visibility
                    [data]="youtubeVisiblityFilterTypes | useEnumValues"
                    textField="value"
                    valueField="key"
                    placeholder="value"
                    [(ngModel)]="selectedYoutubeVisblityValue"
                    [ngModelOptions]="{ standalone: true }"
                    [value]="selectedYoutubeVisblityValue"
                    [valuePrimitive]="true"
                  ></kendo-dropdownlist>
                </kendo-formfield>
              </div>
              <kendo-formfield class="mb-24">
                <kendo-dropdownlist
                  formControlName="license"
                  [data]="youTubeLicenceFilterTypes | useEnumValues"
                  textField="value"
                  valueField="key"
                  placeholder="value"
                  [(ngModel)]="selectedYoutubeLicenceValue"
                  [ngModelOptions]="{ standalone: true }"
                  [value]="selectedYoutubeLicenceValue"
                  [valuePrimitive]="true"
                ></kendo-dropdownlist>
              </kendo-formfield>
              <div class="d-flex gap-40">
                <div class="d-flex align-items-center gap-10">
                  <input
                    type="checkbox"
                    kendoCheckBox
                    #notifySubscribers
                    formControlName="notifySubscribers"
                  />
                  <kendo-label [for]="notifySubscribers" text="Notify Subscribers"></kendo-label>
                </div>
                <div class="d-flex align-items-center gap-10">
                  <input
                    type="checkbox"
                    kendoCheckBox
                    #allowEmbedding
                    formControlName="allowEmbedding"
                  />
                  <kendo-label [for]="allowEmbedding" text="Allow Embedding"></kendo-label>
                </div>
                <div class="d-flex align-items-center gap-10">
                  <input type="checkbox" kendoCheckBox #madeforkids formControlName="madeForKids" />
                  <kendo-label [for]="madeforkids" text="Made for kids"></kendo-label>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </kendo-card-body>
    </kendo-card>
  </div>
  <app-right-sidebar>
    <div>
      <kendo-tabstrip (tabSelect)="onTabSelect($event)" class="right-sidebar-container">
        <kendo-tabstrip-tab title="Preview" [selected]="selectedTabIndex === 0">
          <ng-template kendoTabContent>
            <app-preview-post [triggerDataToPreview]="triggerDataToPreview"></app-preview-post>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab
          *ngIf="isScheduleAllowed$ | async"
          title="Schedule"
          [selected]="selectedTabIndex === 1"
        >
          <ng-template kendoTabContent>
            <app-schedule-post
              #schedulePostDetails
              (isNewSchedule)="isNewSchedule($event)"
              [scheduleData]="scheduleData"
              [triggerEditPostData]="triggerEditPostData"
              [triggerScheduleDateFromCalendar]="triggerScheduleDateFromCalendar"
              [scheduleEditScheduleId]="scheduleEditScheduleId"
              [catergoryDetails]="catergoryDetails"
            ></app-schedule-post>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="AI Assistant" [selected]="selectedTabIndex === 2">
          <ng-template kendoTabContent>
            <app-ai-assistant></app-ai-assistant>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>
  </app-right-sidebar>
  <div class="d-flex gap-20 justify-content-end pt-2 footer-button">
    <button kendoButton fillMode="outline" (click)="resetCreatePost()">Cancel</button>
    <button kendoButton (click)="savePost()">{{ btnText }}</button>
  </div>
</div>
<div kendoDialogContainer></div>
