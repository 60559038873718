export enum DragDropComponent {
  TextFieldComponent = 'Text Field Component',
  TextAreaComponent = 'Text Area Component',
  NumberComponent = 'Number Component',
  PasswordComponent = 'Password Component',
  CheckboxComponent = 'Checkbox Component',
  SelectBoxesComponent = 'Select Boxes Component',
  SelectComponent = 'Select Component',
  RadioComponent = 'Radio Component',
  ButtonComponent = 'Button Component',
  EmailComponent = 'Email Component',
  UrlComponent = 'Url Component',
  PhoneNumberComponent = 'Phone Number Component',
  TagsComponent = 'Tags Component',
  AddressComponent = 'Address Component',
  Date_TimeComponent = 'Date / Time Component',
  DayComponent = 'Day Component',
  TimeComponent = 'Time Component',
  CurrencyComponent = 'Currency Component',
  SurveyComponent = 'Survey Component',
  SignatureComponent = 'Signature Component',
  HTMLElementComponent = 'HTML Element Component',
  ContentComponent = 'Content Component',
  ColumnsComponent = 'Columns Component',
  FieldSetComponent = 'Field Set Component',
  PanelComponent = 'Panel Component',
  TableComponent = 'Table Component',
  TabsComponent = 'Tabs Component',
  WellComponent = 'Well Component',
  HiddenComponent = 'Hidden Component',
  ContainerComponent = 'Container Component',
  DataMapComponent = 'Data Map Component',
  DataGridComponent = 'Data Grid Component',
  EditGridComponent = 'Edit Grid Component',
  TreeComponent = 'Tree Component',
}
