import { mapEnumToOptions } from '@abp/ng.core';

export enum Customer_PiiClearedEnum {
  UnKnown = 0,
  Active = 1,
  ScheduledForClear = 2,
  Cleared = 3,
}

export const customer_PiiClearedEnumOptions = mapEnumToOptions(Customer_PiiClearedEnum);
