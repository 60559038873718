<div class="left-side-conent" *ngIf="this.authService.isAuthenticated">
  <div class="slick-wrapper">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
      <div
        ngxSlickItem
        *ngFor="let item of socialPlatFormDetails"
        class="slide d-flex-col-lg-3 d-flex-col-sm-6 d-flex-col-xs-12 d-flex-col"
      >
        <div
          (click)="getUserListBySocialMedia(item.id); onSocialMediaClicked(item.id)"
          [class.active]="isActiveItem(item.id)"
          class=" {{ item.icon }} social-content-box"
        >
          <div class="d-flex justify-content-center align-items-center flex-column">
            <i class="fab fa-{{ item.icon }} items-icon"></i>
            <p class="font-size-16 m-0 pt-10 pb-10">{{ item.name }}</p>
          </div>
          <div class="text-align-center" (click)="onDropdownClick($event)">
            <kendo-dropdownbutton
              [data]="item.addtionalDetails"
              (itemClick)="onAddAccountClick($event)"
              class="social-button"
            >
              <i class="far fa-plus"></i> <span class="ml-5">Account</span>
              <ng-template kendoDropDownButtonItemTemplate let-dataItem>
                <span
                  [title]="
                    dataItem.socialAccountId === accountTypeEnum.LinkedInOrganization
                      ? 'Coming Soon'
                      : ''
                  "
                >
                  {{ dataItem.text }}</span
                >
              </ng-template>
            </kendo-dropdownbutton>
          </div>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
  <div class="w-260 mb-20">
    <kendo-dropdownlist
      [data]="userListBySocialId"
      [textField]="'accountName'"
      [valueField]="'id'"
      [(ngModel)]="selectedUser"
      [valuePrimitive]="true"
      (valueChange)="getChartData()"
      class="bg-white"
    >
    </kendo-dropdownlist>
  </div>
  <div class="mb-20 chart-container">
    <kendo-card class="wp-100">
      <kendo-card-header class="k-hstack">
        <div>
          <h1 *ngIf="accountTypeEnum.Facebook === accountTypeId" class="title">
            Facebook Subscribers
          </h1>
          <h1 *ngIf="accountTypeEnum.Instagram === accountTypeId" class="title">
            Instagram Subscribers
          </h1>
          <h1 *ngIf="accountTypeEnum.LinkedIn === accountTypeId" class="title">
            LinkedIn Subscribers
          </h1>
          <h1 *ngIf="accountTypeEnum.Twitter === accountTypeId" class="title">
            Twitter Subscribers
          </h1>
          <h1 *ngIf="accountTypeEnum.YouTube === accountTypeId" class="title">
            YouTube Subscribers
          </h1>
        </div>
      </kendo-card-header>
      <kendo-card-body>
        <ng-container
          *ngIf="
            subscribersGainLosslist?.series[0]?.data &&
            subscribersGainLosslist?.series[1]?.data &&
            (!seriesGainEqual || !seriesLossEqual)
          "
        >
          <kendo-chart class="h-300">
            <kendo-chart-legend
              position="top"
              orientation="horizontal"
              align="end"
              [markers]="{ type: 'circle', width: 8, height: 8 }"
            ></kendo-chart-legend>
            <kendo-chart-category-axis></kendo-chart-category-axis>
            <kendo-chart-series>
              <kendo-chart-series-item
                type="column"
                [data]="subscribersGainLosslist?.series[0]?.data"
                name="gained"
                [stack]="true"
                [color]="'#003BBE'"
              >
                <kendo-chart-series-item-labels color="white">
                  <kendo-chart-series-item-tooltip
                    format="{0} units"
                    background="red"
                  ></kendo-chart-series-item-tooltip>
                </kendo-chart-series-item-labels>
              </kendo-chart-series-item>
              <kendo-chart-series-item
                type="column"
                [data]="subscribersGainLosslist?.series[1]?.data"
                name="lost"
                [stack]="true"
                [color]="'#FF7129'"
              >
                <kendo-chart-series-item-labels color="white">
                  <kendo-chart-series-item-tooltip
                    format="{0} units"
                    background="blue"
                  ></kendo-chart-series-item-tooltip>
                </kendo-chart-series-item-labels>
              </kendo-chart-series-item>
            </kendo-chart-series>
          </kendo-chart>
        </ng-container>
        <ng-container
          *ngIf="
            !(
              subscribersGainLosslist?.series[0]?.data && subscribersGainLosslist?.series[1]?.data
            ) &&
            (!seriesGainEqual || !seriesLossEqual)
          "
        >
          <div
            class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
          >
            <div class="icon users d-flex justify-content-center align-items-center">
              <i class="far fa-user-group"></i>
            </div>
            <p class="subscribers mb-0">0 Subscribers</p>
            <span class="description">There are no subscribers yet!</span>
          </div>
        </ng-container>
        <ng-container *ngIf="seriesGainEqual && seriesLossEqual">
          <div
            class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
          >
            <div class="icon users d-flex justify-content-center align-items-center">
              <i class="far fa-user-group"></i>
            </div>
            <span class="description">There are no data yet!</span>
          </div>
        </ng-container>
      </kendo-card-body>
    </kendo-card>
  </div>
  <div class="d-flex gap-20 d-flex-md-column">
    <kendo-card class="wp-100">
      <kendo-card-header class="k-hstack">
        <h1 class="title">Key Metrics</h1>
      </kendo-card-header>
      <kendo-card-body>
        <div *ngIf="keyMetricsList && keyMetricsList.length > 0">
          <div
            *ngFor="let item of keyMetricsList"
            class="progress-metrics d-flex align-items-center gap-40"
          >
            <kendo-lable title="{{ item.name }}">{{ item.name }}</kendo-lable>
            <kendo-progressbar
              [orientation]="orientation"
              [value]="item.data === null || undefined ? 0 : item.data"
              [showValue]="item.data !== 0"
            ></kendo-progressbar>
          </div>
        </div>
        <div
          class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
          *ngIf="!keyMetricsList || keyMetricsList.length === 0"
        >
          <div class="icon metrics d-flex justify-content-center align-items-center">
            <i class="far fa-thumbs-up"></i>
          </div>
          <p class="subscribers mb-0">0 Interactions</p>
          <span class="description">There are no interactions yet!</span>
        </div>
      </kendo-card-body>
    </kendo-card>
    <kendo-card class="wp-100">
      <kendo-card-header class="k-hstack">
        <h1 class="title">Total Posts</h1>
      </kendo-card-header>
      <kendo-card-body>
        <ng-container *ngIf="totalPostData && totalPostData.length > 0">
          <kendo-chart class="h-300">
            <kendo-chart-series>
              <kendo-chart-series-item
                type="donut"
                [data]="totalPostData"
                categoryField="name"
                field="data"
              >
              </kendo-chart-series-item>
            </kendo-chart-series>
            <kendo-chart-legend
              position="right"
              [markers]="{ type: 'circle', width: 8, height: 8 }"
            ></kendo-chart-legend>
            <kendo-chart-tooltip>
              <ng-template
                kendoChartSeriesTooltipTemplate
                let-value="value"
                let-category="category"
              >
                {{ category }}:{{ value }}
              </ng-template>
            </kendo-chart-tooltip>
          </kendo-chart>
        </ng-container>
        <ng-container *ngIf="!totalPostData || totalPostData.length === 0">
          <div
            class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
          >
            <div class="icon posts d-flex justify-content-center align-items-center">
              <i class="far fa-thumbs-up"></i>
            </div>
            <p class="subscribers mb-0">0 Posts</p>
            <span class="description">There are no post yet!</span>
          </div>
        </ng-container>
      </kendo-card-body>
    </kendo-card>
  </div>
  <app-right-sidebar>
    <div class="right-sidebar-content">
      <div class="right-sidebar-first recent-post">
        <div class="right-sidebar-column">
          <h6 class="title">Recent Posts</h6>
          <div
            class="user-details"
            [ngClass]="{ 'display-none': !viewPostData || viewPostData.length === 0 }"
          >
            <ng-container *ngIf="viewPostData && viewPostData.length > 0">
              <div *ngFor="let item of viewPostData">
                <kendo-stacklayout
                  [gap]="15"
                  [orientation]="orientation"
                  *ngFor="let items of item.socialAccounts"
                >
                  <kendo-card width="auto">
                    <kendo-card-header class="k-hstack align-items-center recent-post-header">
                      <span class="post-date">{{ item.creationTime | date: 'MMM d, y' }}</span>
                      <div class="social-image pl-2 pr-2 d-flex gap-20">
                        <div class="social-icons">
                          <i
                            *ngIf="items?.accountType === accountTypeEnum.Instagram"
                            class="fa-instagram-square fab instagram-icon-color"
                          ></i>
                          <i
                            *ngIf="
                              items?.accountType === accountTypeEnum.LinkedIn ||
                              items?.accountType === accountTypeEnum.LinkedInOrganization
                            "
                            class="fa-linkedin-in fab text-linked-in-icon-color"
                          ></i>
                          <i
                            *ngIf="items?.accountType === accountTypeEnum.Facebook"
                            class="fa-facebook-f fab text-facebook-icon-color"
                          ></i>
                          <i
                            *ngIf="items?.accountType === accountTypeEnum.YouTube"
                            class="fa-youtube fab text-youtube-icon-color"
                          ></i>
                          <i
                            *ngIf="items?.accountType === accountTypeEnum.Twitter"
                            class="fa-x-twitter fab text-twitter-icon-color"
                          ></i>
                        </div>
                        <div>
                          <div class="social-details">
                            <h1 kendoCardTitle>
                              {{ commonService.getProfileName(items.accountType) }}
                            </h1>
                            <div class="post-details" *ngIf="items.mediaUrls.length !== 0">
                              <div>
                                {{ getDescriptionForActivity(items.mediaUrls) }}
                                {{ commonService.getProfileName(items.accountType) }}
                              </div>
                            </div>
                            <div class="post-details" *ngIf="items.mediaUrls.length === 0">
                              <div>No video/image post</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </kendo-card-header>
                  </kendo-card>
                </kendo-stacklayout>
              </div>
            </ng-container>
          </div>
          <div *ngIf="viewPostData.length !== 0" class="d-flex justify-content-center mt-5 pb-2">
            <button
              kendoButton
              *ngIf="seeAllPost"
              (click)="getAccountActivity(true)"
              fillMode="outline"
            >
              See All Posts
            </button>
            <button
              kendoButton
              *ngIf="!seeAllPost"
              (click)="getAccountActivity(false)"
              fillMode="outline"
            >
              See Less Posts
            </button>
          </div>
          <ng-container *ngIf="!viewPostData || viewPostData.length === 0">
            <div
              class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-100"
            >
              <div class="icon posts-data d-flex justify-content-center align-items-center">
                <i class="far fa-images"></i>
              </div>
              <p class="subscribers mb-0">0 Posts</p>
              <span class="description">There are no posts yet!</span>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="right-sidebar-second">
        <div class="right-sidebar-column">
          <h6 class="title">Connected Users</h6>
          <div
            class="user-details"
            [ngClass]="{ 'display-none': !accountDetails || accountDetails.length === 0 }"
          >
            <ng-container *ngIf="accountDetails && accountDetails.length > 0">
              <kendo-stacklayout
                [gap]="15"
                [orientation]="orientation"
                *ngFor="let item of accountDetails"
              >
                <kendo-card width="auto">
                  <kendo-card-header class="k-hstack justify-content-between align-items-start">
                    <div class="d-flex gap-30 align-items-center">
                      <kendo-badge-container>
                        <ng-container *ngIf="item.img; else noImageTemplate">
                          <kendo-avatar
                            rounded="full"
                            size="large"
                            [border]="true"
                            [imageSrc]="item.img"
                            class="social-profile-image"
                          ></kendo-avatar>
                        </ng-container>

                        <ng-template #noImageTemplate>
                          <kendo-avatar
                            rounded="full"
                            size="large"
                            [border]="true"
                            [initials]="getInitials(item.accountName)"
                            class="social-profile-text"
                          ></kendo-avatar>
                        </ng-template>

                        <kendo-badge
                          size="large"
                          [align]="badgeAlign"
                          [position]="position"
                          [cutoutBorder]="true"
                          class="social-icons"
                        >
                          <i
                            *ngIf="item?.accountType === accountTypeEnum.Instagram"
                            class="fab fa-instagram-square instagram-icon-color"
                          ></i>
                          <i
                            *ngIf="
                              item?.accountType === accountTypeEnum.LinkedIn ||
                              item?.accountType === accountTypeEnum.LinkedInOrganization
                            "
                            class="fa-linkedin-in fab text-linked-in-icon-color"
                          ></i>
                          <i
                            *ngIf="item?.accountType === accountTypeEnum.Facebook"
                            class="text-facebook-icon-color fa-facebook-f fab"
                          ></i>
                          <i
                            *ngIf="item?.accountType === accountTypeEnum.Twitter"
                            class="text-twitter-icon-color fa-x-twitter fab"
                          ></i>
                          <i
                            *ngIf="item?.accountType === accountTypeEnum.YouTube"
                            class="text-youtube-icon-color fa-youtube fab"
                          ></i>
                        </kendo-badge>
                      </kendo-badge-container>
                      <div class="account-details">
                        <h1 kendoCardTitle>{{ item.accountName }}</h1>
                        <div class="account-header-content flex-column">
                          <div class="account-name">
                            <p class="m-0">
                              <a href="javascript:void(0);">{{ item.accountName }}</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <kendo-label>
                        <kendo-switch
                          (valueChange)="onProfileToggle(item.id, $event)"
                          [(ngModel)]="item.isActive"
                        ></kendo-switch>
                      </kendo-label>
                    </div>
                  </kendo-card-header>
                </kendo-card>
              </kendo-stacklayout>
            </ng-container>
          </div>
          <ng-container *ngIf="!accountDetails || accountDetails.length === 0">
            <div
              class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-100"
            >
              <div class="icon connect-user d-flex justify-content-center align-items-center">
                <i class="far fa-images"></i>
              </div>
              <p class="subscribers mb-0">0 Posts</p>
              <span class="description">There are no posts yet!</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </app-right-sidebar>
</div>
