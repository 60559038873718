import { mapEnumToOptions } from '@abp/ng.core';

export enum ValidationStatusEnum {
  UnKnown = 0,
  NotValidated = 1,
  Valid = 2,
  PartiallyValid = 3,
  Invalid = 4,
}

export const validationStatusEnumOptions = mapEnumToOptions(ValidationStatusEnum);
