import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  AnalyticsInput,
  BarChartDetailModel,
  PieChartDetailModel,
} from '../common/models/models';
import type { ColumnChartDetailModel, DashboardCardModel } from '../dashboard/dtos/models';
import type { AccountDetailDto } from '../social-account/dtos/models';

@Injectable({
  providedIn: 'root',
})
export class LinkedInOrganizationService {
  apiName = 'Default';

  downloadProfile = (url: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: '/api/app/linked-in-organization/download-profile',
        params: { url },
      },
      { apiName: this.apiName, ...config },
    );

  getDashboardFollowerUnFollowerData = (socialAccountId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ColumnChartDetailModel>(
      {
        method: 'GET',
        url: `/api/app/linked-in-organization/dashboard-follower-un-follower-data/${socialAccountId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDashboardKeyMetricsInsights = (socialId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PieChartDetailModel>(
      {
        method: 'GET',
        url: `/api/app/linked-in-organization/dashboard-key-metrics-insights/${socialId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDashboardPostCount = (socialId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PieChartDetailModel>(
      {
        method: 'GET',
        url: `/api/app/linked-in-organization/dashboard-post-count/${socialId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getLinkedInOrganization = (state: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AccountDetailDto>(
      {
        method: 'GET',
        url: '/api/app/linked-in-organization/linked-in-organization',
        params: { state },
      },
      { apiName: this.apiName, ...config },
    );

  postAnalyticsAudienceGrowth = (filter: AnalyticsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ColumnChartDetailModel>(
      {
        method: 'POST',
        url: '/api/app/linked-in-organization/analytics-audience-growth',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  postAnalyticsCount = (filter: AnalyticsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DashboardCardModel>(
      {
        method: 'POST',
        url: '/api/app/linked-in-organization/analytics-count',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  postAnalyticsPageView = (filter: AnalyticsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PieChartDetailModel>(
      {
        method: 'POST',
        url: '/api/app/linked-in-organization/analytics-page-view',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  postAnalyticsSocialActions = (filter: AnalyticsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BarChartDetailModel>(
      {
        method: 'POST',
        url: '/api/app/linked-in-organization/analytics-social-actions',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  prepareOrganizationPageUrl = (returnUrl: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/app/linked-in-organization/prepare-organization-page-url',
        params: { returnUrl },
      },
      { apiName: this.apiName, ...config },
    );

  prepareOrganizationReauthenticatePageUrlBySocialAccountIdAndReturnUrl = (
    socialAccountId: number,
    returnUrl: string,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: `/api/app/linked-in-organization/prepare-organization-reauthenticate-page-url/${socialAccountId}`,
        params: { returnUrl },
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
