import { Component, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { CommonState } from 'src/core/state';

@Component({
  selector: 'app-create-post-successfully-popup',
  templateUrl: './create-post-successfully-popup.component.html',
  styleUrls: ['./create-post-successfully-popup.component.scss'],
})
export class CreatePostSuccessfullyPopupComponent {
  @Input() message: string;

  @Select(CommonState.subscriptionPlanData)
  subscriptionPlanData$: Observable<any>;

  availableCredits = 0;

  constructor(public dialogRef: DialogRef) {}

  ngOnInit(): void {
    this.getWalletDetails();
  }

  getWalletDetails(): void {
    this.subscriptionPlanData$.subscribe(res => {
      if (res !== undefined && res !== null) {
        this.availableCredits = res.currentWalletCredit;
      }
    });
  }

  closePopup(): void {
    this.dialogRef.close();
  }
}
