import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Select } from '@ngxs/store';
import { Observable, forkJoin } from 'rxjs';
import { CommonState } from 'src/core/state/common.state';
import { SocialAccountsService } from '@proxy/social-account';
import { CommonService, FilterTypes, SocialMediaPlatForms } from 'src/app';
import { CategoryAxis } from '@progress/kendo-angular-charts';
import { InstagramService } from '@proxy/instagram';
import { FilterModel, AnalyticsInput } from '@proxy/common/models';
import { FacebookService } from '@proxy/facebook';
import { LinkedInOrganizationService } from '@proxy/linked-in-organization';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent implements OnInit {
  public barGraphDataCatergory: CategoryAxis = {
    categories: [],
  };

  public lineGraphDataCatergory: CategoryAxis = {
    categories: [],
  };

  @Select(CommonState.profileDetails)
  profileDetails$: Observable<Array<any>>;

  public lineGraphData;
  public barGraphData;
  public tableData;
  public donutGraphData;
  public totalCountData;

  accountDetails: any[] = [];
  filteredAccountList: any[] = [];

  isAnalyticsDate = false;

  toDate = null;
  fromDate = null;
  socialAccountAnalyticsDataForReachEngagementImpression: any;
  socialAccountAnalyticsDataForReactionFollowers: any;
  socialAccountAnalyticsDataForShareSaved: any;
  socialAccountsBySocialAccountId: any;
  accountType: number;
  formGroup: FormGroup;
  selectedAcc: any;
  selectedCustomValue: any;
  socialMediaPlatForms = SocialMediaPlatForms;
  filterTypes = FilterTypes;
  filterTypeForCountCard;

  constructor(
    private fb: FormBuilder,
    private socialAccountsService: SocialAccountsService,
    private instagramService: InstagramService,
    private facebookService: FacebookService,
    private linkedInOrganizationService: LinkedInOrganizationService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      selectedAcc: [null],
      selectedCustomValue: [null],
    });
    this.selectedCustomValue = FilterTypes['This Month'];
    this.filterTypeForCountCard = FilterTypes[FilterTypes['This Month']];
    this.getAccountList();
  }

  assignDefaultValueToCard(): void {
    if (!this.socialAccountsBySocialAccountId) {
      this.socialAccountsBySocialAccountId = {
        card: null,
        card1: {
          total: 0,
        },
        card2: {
          total: 0,
        },
        card3: {
          total: 0,
        },
        card4: {
          total: 0,
        },
      };
    }
  }

  getAccountList(): void {
    this.profileDetails$.subscribe(x => {
      if (x !== undefined) {
        this.accountDetails = JSON.parse(JSON.stringify(x));
        this.filteredAccountList = this.accountDetails.filter(
          x =>
            x.accountType !== SocialMediaPlatForms.LinkedIn &&
            x.accountType !== SocialMediaPlatForms.Twitter &&
            x.accountType !== SocialMediaPlatForms.YouTube,
        );
        if (this.filteredAccountList.length > 0) {
          this.selectedAcc = this.filteredAccountList[0].id;
          this.getSocialAccountsBySocialAccountId();
        } else {
          this.assignDefaultValueToCard();
        }
      } else {
        this.assignDefaultValueToCard();
      }
    });
  }

  getSocialAccountsBySocialAccountId(): void {
    this.lineGraphData = [];
    this.barGraphData = [];
    this.tableData = [];
    this.donutGraphData = [];
    this.totalCountData = [];

    this.socialAccountsService.get(this.selectedAcc).subscribe(res => {
      this.accountType = res.accountType;
      this.socialAccountsBySocialAccountId = JSON.parse(res.additionalInformation);

      this.assignDefaultValueToCard();

      let dateFilter: FilterModel = {
        filter: this.selectedCustomValue,
      };

      let filter: AnalyticsInput = {
        socialAccountId: this.selectedAcc,
        dateFilter: dateFilter,
      };

      switch (this.accountType) {
        case SocialMediaPlatForms.Instagram:
          const postAnalyticsPerformanceSnapshotInstagram =
            this.instagramService.postAnalyticsPerformanceSnapshot(filter);

          const postAnalyticsFollowerGenderWiseDataInstagram =
            this.instagramService.postAnalyticsFollowerGenderWiseData(filter);

          const postAnalyticsFollowerGrowthInstagram =
            this.instagramService.postAnalyticsFollowerGrowth(filter);

          const postAnalyticsSocialActionInstagram =
            this.instagramService.postAnalyticsSocialAction(filter);

          const postAnalyticsCountInstagram = this.instagramService.postAnalyticsCount(filter);

          this.spinner.show();

          forkJoin({
            performanceSnapshot: postAnalyticsPerformanceSnapshotInstagram,
            followerGenderWiseData: postAnalyticsFollowerGenderWiseDataInstagram,
            followersGrowthData: postAnalyticsFollowerGrowthInstagram,
            socialActionData: postAnalyticsSocialActionInstagram,
            countData: postAnalyticsCountInstagram,
          }).subscribe({
            next: results => {
              this.spinner.hide();
              if (results) {
                this.donutGraphData = results.performanceSnapshot.series.every(x => x.data === 0)
                  ? []
                  : results.performanceSnapshot.series;

                this.tableData = results.followerGenderWiseData;

                this.lineGraphData = results.followersGrowthData.series[0].data.every(x => x === 0)
                  ? []
                  : results.followersGrowthData.series[0].data;
                this.lineGraphData.categories = results.followersGrowthData.categories;

                this.barGraphData = results.socialActionData.series.every(x =>
                  x.data.every(x => x === 0),
                )
                  ? []
                  : results.socialActionData.series;
                this.barGraphDataCatergory.categories = results.socialActionData.categories;

                this.socialAccountsBySocialAccountId = results.countData;
              }
            },
            error: error => {
              this.spinner.hide();
            },
          });
          break;
        case SocialMediaPlatForms.Facebook:
          const postAnalyticsAudienceGrowthDataFacebook =
            this.facebookService.postAnalyticsAudienceGrowthData(filter);

          const postAnalyticsCountryBreakdownFacebook =
            this.facebookService.postAnalyticsCountryBreakdown(filter);

          const postAnalyticsPageViewFacebook = this.facebookService.postAnalyticsPageView(filter);

          const postAnalyticsSocialActionFacebook =
            this.facebookService.postAnalyticsSocialAction(filter);

          const postAnalyticsCountFacebook = this.facebookService.postAnalyticsCount(filter);

          this.spinner.show();

          forkJoin({
            audienceGrowthData: postAnalyticsAudienceGrowthDataFacebook,
            countryBreakdown: postAnalyticsCountryBreakdownFacebook,
            pageView: postAnalyticsPageViewFacebook,
            socialActionData: postAnalyticsSocialActionFacebook,
            totalCountData: postAnalyticsCountFacebook,
          }).subscribe({
            next: results => {
              this.spinner.hide();
              if (results) {
                this.lineGraphData = results.audienceGrowthData.series[0].data.every(x => x === 0)
                  ? []
                  : results.audienceGrowthData.series[0].data;
                this.lineGraphData.categories = results.audienceGrowthData.categories;

                this.tableData = results.countryBreakdown;

                this.donutGraphData = results.pageView.series.every(x => x.data === 0)
                  ? []
                  : results.pageView.series;

                this.barGraphData = results.socialActionData.series.every(x =>
                  x.data.every(x => x === 0),
                )
                  ? []
                  : results.socialActionData.series;
                this.barGraphDataCatergory.categories = results.socialActionData.categories;
                this.socialAccountsBySocialAccountId = results.totalCountData;
              }
            },
            error: error => {
              this.spinner.hide();
            },
          });
          break;
        case SocialMediaPlatForms.LinkedInOrganization:
          const postAnalyticsAudienceGrowthDataLinkedIn =
            this.linkedInOrganizationService.postAnalyticsAudienceGrowth(filter);

          const postAnalyticsPageViewLinkedIn =
            this.linkedInOrganizationService.postAnalyticsPageView(filter);

          const postAnalyticsSocialActionLinkedIn =
            this.linkedInOrganizationService.postAnalyticsSocialActions(filter);

          const postAnalyticsCountLinkedIn =
            this.linkedInOrganizationService.postAnalyticsCount(filter);

          this.spinner.show();
          forkJoin({
            audienceGrowthData: postAnalyticsAudienceGrowthDataLinkedIn,
            pageView: postAnalyticsPageViewLinkedIn,
            socialActionData: postAnalyticsSocialActionLinkedIn,
            totalCountData: postAnalyticsCountLinkedIn,
          }).subscribe({
            next: results => {
              this.spinner.hide();
              if (results) {
                this.lineGraphData = results.audienceGrowthData.series[0].data.every(x => x === 0)
                  ? []
                  : results.audienceGrowthData.series[0].data;
                this.lineGraphData.categories = results.audienceGrowthData.categories;

                this.donutGraphData = results.pageView.series.every(x => x.data === 0)
                  ? []
                  : results.pageView.series;

                this.barGraphData = results.socialActionData.series.every(x =>
                  x.data.every(x => x === 0),
                )
                  ? []
                  : results.socialActionData.series;
                this.barGraphDataCatergory.categories = results.socialActionData.categories;

                this.socialAccountsBySocialAccountId = results.totalCountData;
              }
            },
            error: error => {
              this.spinner.hide();
            },
          });
          break;
      }
    });
  }

  onCustomChange() {
    this.filterTypeForCountCard = FilterTypes[this.selectedCustomValue];
    this.getSocialAccountsBySocialAccountId();
  }
}
