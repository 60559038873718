import { mapEnumToOptions } from '@abp/ng.core';

export enum HostedPage_TypeEnum {
  UnKnown = 0,
  CheckoutNew = 1,
  CheckoutExisting = 2,
  UpdateCard = 3,
  UpdatePaymentMethod = 4,
  ManagePaymentSources = 5,
  CollectNow = 6,
  ExtendSubscription = 7,
  CheckoutGift = 8,
  ClaimGift = 9,
  CheckoutOneTime = 10,
  PreCancel = 11,
  ViewVoucher = 12,
}

export const hostedPage_TypeEnumOptions = mapEnumToOptions(HostedPage_TypeEnum);
