import type { FullAuditedEntityDto } from '@abp/ng.core';
import type { PostStatus } from '../../enums/post-status.enum';

export interface CategoryDto extends FullAuditedEntityDto<number> {
  name?: string;
  description?: string;
  isActive: boolean;
  postCount: number;
  isDefault: boolean;
}

export interface CategoryListDto {
  postStatus: PostStatus;
}

export interface CreateUpdateCategoryDto {
  name: string;
  description?: string;
  isActive: boolean;
}
