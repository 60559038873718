import type { FullAuditedEntityDto } from '@abp/ng.core';

export interface CreateUpdateNewsletterSubscriberDto {
  email?: string;
  firstName?: string;
  lastName?: string;
  signupDate?: string;
  ip_Address?: string;
  status?: string;
  unsubscribeDate?: string;
  unsubscribeReason?: string;
}

export interface NewsletterSubscriberDto extends FullAuditedEntityDto<number> {
  email?: string;
  firstName?: string;
  lastName?: string;
  signupDate?: string;
  ip_Address?: string;
  status?: string;
  unsubscribeDate?: string;
  unsubscribeReason?: string;
}
