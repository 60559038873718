import type { Resource } from '../internal/models';
import type { ValidationStatusEnum } from './enums/validation-status-enum.enum';
import type { Customer_CustomerChildAccountAccess_PortalEditSubscriptionsEnum } from './customer-customer-child-account-access-portal-edit-subscriptions-enum.enum';
import type { Customer_CustomerChildAccountAccess_PortalDownloadInvoicesEnum } from './customer-customer-child-account-access-portal-download-invoices-enum.enum';
import type { Customer_CustomerParentAccountAccess_PortalEditChildSubscriptionsEnum } from './customer-customer-parent-account-access-portal-edit-child-subscriptions-enum.enum';
import type { Customer_CustomerParentAccountAccess_PortalDownloadChildInvoicesEnum } from './customer-customer-parent-account-access-portal-download-child-invoices-enum.enum';
import type { Customer_CustomerPaymentMethod_TypeEnum } from './customer-customer-payment-method-type-enum.enum';
import type { GatewayEnum } from './enums/gateway-enum.enum';
import type { Customer_CustomerPaymentMethod_StatusEnum } from './customer-customer-payment-method-status-enum.enum';
import type { ReferralSystemEnum } from './enums/referral-system-enum.enum';
import type { AvalaraSaleTypeEnum } from './enums/avalara-sale-type-enum.enum';
import type { Subscription_SubscriptionSubscriptionItem_ItemTypeEnum } from './subscription-subscription-subscription-item-item-type-enum.enum';
import type { ChargeOnEventEnum } from './enums/charge-on-event-enum.enum';
import type { Subscription_SubscriptionSubscriptionItem_ChargeOnOptionEnum } from './subscription-subscription-subscription-item-charge-on-option-enum.enum';

export interface Customer_CustomerBalance extends Resource {
  promotionalCredits: number;
  excessPayments: number;
  refundableCredits: number;
  unbilledCharges: number;
  currencyCode?: string;
  balanceCurrencyCode?: string;
}

export interface Customer_CustomerBillingAddress extends Resource {
  firstName?: string;
  lastName?: string;
  email?: string;
  company?: string;
  phone?: string;
  line1?: string;
  line2?: string;
  line3?: string;
  city?: string;
  stateCode?: string;
  state?: string;
  country?: string;
  zip?: string;
  validationStatus?: ValidationStatusEnum;
}

export interface Customer_CustomerChildAccountAccess extends Resource {
  portalEditSubscriptions?: Customer_CustomerChildAccountAccess_PortalEditSubscriptionsEnum;
  portalDownloadInvoices?: Customer_CustomerChildAccountAccess_PortalDownloadInvoicesEnum;
  sendSubscriptionEmails: boolean;
  sendInvoiceEmails: boolean;
  sendPaymentEmails: boolean;
}

export interface Customer_CustomerContact extends Resource {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  label?: string;
  enabled: boolean;
  sendAccountEmail: boolean;
  sendBillingEmail: boolean;
}

export interface Customer_CustomerEntityIdentifier extends Resource {
  id?: string;
  value?: string;
  scheme?: string;
  standard?: string;
}

export interface Customer_CustomerParentAccountAccess extends Resource {
  portalEditChildSubscriptions?: Customer_CustomerParentAccountAccess_PortalEditChildSubscriptionsEnum;
  portalDownloadChildInvoices?: Customer_CustomerParentAccountAccess_PortalDownloadChildInvoicesEnum;
  sendSubscriptionEmails: boolean;
  sendInvoiceEmails: boolean;
  sendPaymentEmails: boolean;
}

export interface Customer_CustomerPaymentMethod extends Resource {
  paymentMethodType: Customer_CustomerPaymentMethod_TypeEnum;
  gateway: GatewayEnum;
  gatewayAccountId?: string;
  status: Customer_CustomerPaymentMethod_StatusEnum;
  referenceId?: string;
}

export interface Customer_CustomerReferralUrl extends Resource {
  externalCustomerId?: string;
  referralSharingUrl?: string;
  createdAt?: string;
  updatedAt?: string;
  referralCampaignId?: string;
  referralAccountId?: string;
  referralExternalCampaignId?: string;
  referralSystem: ReferralSystemEnum;
}

export interface Customer_CustomerRelationship extends Resource {
  parentId?: string;
  paymentOwnerId?: string;
  invoiceOwnerId?: string;
}

export interface ItemPrice_ItemPriceAccountingDetail extends Resource {
  sku?: string;
  accountingCode?: string;
  accountingCategory1?: string;
  accountingCategory2?: string;
  accountingCategory3?: string;
  accountingCategory4?: string;
}

export interface ItemPrice_ItemPriceTaxDetail extends Resource {
  taxProfileId?: string;
  avalaraSaleType?: AvalaraSaleTypeEnum;
  avalaraTransactionType?: number;
  avalaraServiceType?: number;
  avalaraTaxCode?: string;
  hsnCode?: string;
  taxjarProductCode?: string;
}

export interface ItemPrice_ItemPriceTier extends Resource {
  startingUnit: number;
  endingUnit?: number;
  price: number;
  startingUnitInDecimal?: string;
  endingUnitInDecimal?: string;
  priceInDecimal?: string;
}

export interface Subscription_SubscriptionSubscriptionItem extends Resource {
  itemPriceId?: string;
  itemType: Subscription_SubscriptionSubscriptionItem_ItemTypeEnum;
  quantity?: number;
  quantityInDecimal?: string;
  meteredQuantity?: string;
  lastCalculatedAt?: string;
  unitPrice?: number;
  unitPriceInDecimal?: string;
  amount?: number;
  amountInDecimal?: string;
  freeQuantity?: number;
  freeQuantityInDecimal?: string;
  trialEnd?: string;
  billingCycles?: number;
  servicePeriodDays?: number;
  chargeOnEvent?: ChargeOnEventEnum;
  chargeOnce?: boolean;
  chargeOnOption?: Subscription_SubscriptionSubscriptionItem_ChargeOnOptionEnum;
}
