import type { FilterTypes } from '../../enums/filter-types.enum';

export interface FilterModel {
  filter: FilterTypes;
  startDate?: string;
  endDate?: string;
}

export interface MediaURLModel {
  value?: string;
  base64Value?: string;
  size: number;
}

export interface AnalyticsInput {
  socialAccountId: number;
  dateFilter: FilterModel;
}

export interface BarChartDetailModel {
  categories: string[];
  series: BarChartSeriesModel[];
}

export interface BarChartSeriesModel {
  name?: string;
  data: number[];
}

export interface PieChartDetailModel {
  categories: string[];
  series: PieChartSeriesModel[];
}

export interface PieChartSeriesModel {
  name?: string;
  data: number;
}
