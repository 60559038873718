import { mapEnumToOptions } from '@abp/ng.core';

export enum TrialEndActionEnum {
  UnKnown = 0,
  SiteDefault = 1,
  PlanDefault = 2,
  ActivateSubscription = 3,
  CancelSubscription = 4,
}

export const trialEndActionEnumOptions = mapEnumToOptions(TrialEndActionEnum);
