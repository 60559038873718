import { ApiInterceptor, CoreModule, ListService } from '@abp/ng.core';
import { registerLocale } from '@abp/ng.core/locale';
import { ThemeLeptonXModule } from '@abp/ng.theme.lepton-x';
import { SideMenuLayoutModule } from '@abp/ng.theme.lepton-x/layouts';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { FeatureManagementModule } from '@abp/ng.feature-management';
import {
  NbLayoutModule,
  NbThemeModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
  NbMenuModule,
  NbDialogModule,
  NbIconModule,
  NbIconLibraries,
} from '@nebular/theme';

import { IdentityConfigModule } from '@abp/ng.identity/config';
import {
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModule,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxMasonryModule } from 'ngx-masonry';
import { QuillModule } from 'ngx-quill';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { FullCalendarModule } from '@fullcalendar/angular';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CommonState } from 'src/core/state/common.state';
import { NgxsModule } from '@ngxs/store';
import { NgxSpinnerModule } from 'ngx-spinner';

import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { TenantManagementConfigModule } from '@abp/ng.tenant-management/config';
import {
  CustomAddTenantComponent,
  CustomExtensibleTableComponent,
  CustomManageFeatureComponent,
  CustomPermissionComponent,
  CustomRolesComponent,
  CustomUserComponent,
  CustomeTenantComponent,
} from './shared';
import { UiExtensionsModule } from '@abp/ng.theme.shared/extensions';

import {
  AddProfileComponent,
  AnalyticsComponent,
  CreateCategoryComponent,
  CreateHashtagComponent,
  CreatePostComponent,
  DashboardComponent,
  GifDynamicComponent,
  ListCategoryComponent,
  ListHashtagComponent,
  PreviewPostComponent,
  SchedulePostComponent,
  ScheduleSetupComponent,
  ViewPostComponent,
  CreatePostSuccessfullyPopupComponent,
} from './modules';
import { CommonService, GIFDialogService, OpenAiService } from '.';
import { NbModule } from './nebula/nb.module';
import { SharedModule } from './shared/shared.module';
import { UseEnumValuePipe } from 'src/core/pipes/use-enum-value.pipe';
import { ShortenPipe } from 'src/core/pipes/shorten.pipe';
import { DateTimePickerComponent } from 'src/core/directive/datetime-picker.component';
import { SubscriptionPlanComponent } from './modules/subscription-plan/subscription-plan.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DrawerModule } from '@progress/kendo-angular-layout';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IconsModule } from '@progress/kendo-angular-icons';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule, SwitchModule } from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { FilterModule } from '@progress/kendo-angular-filter';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { MenuModule } from '@progress/kendo-angular-menu';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { PagerModule } from '@progress/kendo-angular-pager';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { EditorModule } from '@progress/kendo-angular-editor';
import { IntlModule } from '@progress/kendo-angular-intl';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { RightSidebarComponent } from './modules/right-sidebar/right-sidebar.component';
import { TopHeaderNavigationComponent } from './modules/top-header-navigation/top-header-navigation.component';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { AiAssistantComponent } from './modules/create-post/ai-assistant/ai-assistant.component';
import { SubscriptionPopupComponent } from './modules/subscription-popup/subscription-popup.component';
import { WalletComponent } from './modules/wallet/wallet.component';
import { QMarketApiInterceptor } from 'src/core/lib/interceptors/api.interceptor';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { UnsplashDynamicComponent } from './modules/create-post/unsplash-dynamic/unsplash-dynamic.component';

import 'hammerjs';

@NgModule({
  imports: [
    BrowserModule,
    TooltipModule.forRoot(),
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NbIconModule,
    NgbModule,
    NgxMasonryModule,
    NgxPhotoEditorModule,
    FullCalendarModule,
    NgSelectModule,
    IndicatorsModule,
    ChartsModule,
    ProgressBarModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    AbpOAuthModule.forRoot(),
    ThemeSharedModule.forRoot(),
    ThemeLeptonXModule.forRoot(),
    SideMenuLayoutModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbThemeModule.forRoot({ name: 'default' }),
    NbModule.forRoot(),
    NbMenuModule.forRoot(),
    NbLayoutModule,
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbDialogModule.forRoot(),
    QuillModule.forRoot(),
    PickerModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    IdentityConfigModule.forRoot(),
    FeatureManagementModule.forRoot(),
    TenantManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    NgxsModule.forRoot([CommonState]),
    UiExtensionsModule,
    ThemeLeptonXModule.forRoot(),
    ThemeSharedModule,
    NgbDropdownModule,
    SharedModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    ButtonsModule,
    LayoutModule,
    DrawerModule,
    InputsModule,
    LabelModule,
    IconsModule,
    DropDownsModule,
    TreeViewModule,
    TreeListModule,
    GridModule,
    FilterModule,
    SwitchModule,
    MenuModule,
    PopupModule,
    DialogsModule,
    TreeViewModule,
    UploadsModule,
    ListViewModule,
    EditorModule,
    DateInputsModule,
    IntlModule,
    ScrollViewModule,
    DropDownsModule,
    BrowserAnimationsModule,
    PagerModule,
    SchedulerModule,
    SlickCarouselModule,
  ],
  declarations: [
    AppComponent,
    CreatePostComponent,
    GifDynamicComponent,
    SchedulePostComponent,
    CreateCategoryComponent,
    ListCategoryComponent,
    ListHashtagComponent,
    ViewPostComponent,
    CreateHashtagComponent,
    PreviewPostComponent,
    ScheduleSetupComponent,
    CustomeTenantComponent,
    DashboardComponent,
    AddProfileComponent,
    AnalyticsComponent,
    CustomRolesComponent,
    CustomUserComponent,
    CustomExtensibleTableComponent,
    CustomPermissionComponent,
    CustomManageFeatureComponent,
    UseEnumValuePipe,
    DateTimePickerComponent,
    SubscriptionPlanComponent,
    ShortenPipe,
    RightSidebarComponent,
    TopHeaderNavigationComponent,
    AiAssistantComponent,
    SubscriptionPopupComponent,
    WalletComponent,
    CreatePostSuccessfullyPopupComponent,
    CustomAddTenantComponent,
    UnsplashDynamicComponent,
  ],
  providers: [
    APP_ROUTE_PROVIDER,
    {
      provide: ApiInterceptor,
      useClass: QMarketApiInterceptor,
    },
    CommonService,
    OpenAiService,
    GIFDialogService,
    ListService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NbIconModule,
    NgbModule,
    NgxMasonryModule,
    NgxPhotoEditorModule,
    FullCalendarModule,
    NgSelectModule,
    PickerModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    UiExtensionsModule,
    ThemeSharedModule,
    NgbDropdownModule,
    SharedModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    LayoutModule,
    DrawerModule,
    InputsModule,
    LabelModule,
    IconsModule,
    DropDownsModule,
    TreeViewModule,
    TreeListModule,
    GridModule,
    FilterModule,
    SwitchModule,
    MenuModule,
    PopupModule,
    DialogsModule,
    TreeViewModule,
    UploadsModule,
    ListViewModule,
    EditorModule,
    DateInputsModule,
    IntlModule,
    PagerModule,
    SchedulerModule,
    ScrollViewModule,
  ],
})
export class AppModule {
  constructor(private iconLibraries: NbIconLibraries) {
    this.iconLibraries.registerFontPack('fa', { iconClassPrefix: 'fa' });
    this.iconLibraries.setDefaultPack('fa');
  }
}
