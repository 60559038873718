<kendo-dialog [width]="450">
  <kendo-dialog-titlebar (click)="close()">
    <div>GIF Search</div>
  </kendo-dialog-titlebar>
  <div>
    <form>
      <fieldset class="k-form-fieldset wp-100 d-flex gap-10 mt-10 mb-15">
        <kendo-formfield class="wp-100">
          <kendo-textbox
            [clearButton]="true"
            [(ngModel)]="searchText"
            [ngModelOptions]="{ standalone: true }"
            placeholder="Search for GIFs"
            class="wp-100"
          ></kendo-textbox>
        </kendo-formfield>
        <button kendoButton (click)="searchGifs()">Search</button>
      </fieldset>
    </form>
    <div class="gif-list">
      <ngx-masonry [options]="masonryOptions" [useImagesLoaded]="true">
        <div ngxMasonryItem *ngFor="let gif of trendingGifs" class="masonry-item">
          <img [src]="gif.images.fixed_height.url" alt="{{ gif.title }}" (click)="selectGif(gif)" />
        </div>
      </ngx-masonry>
    </div>
  </div>
  <kendo-dialog-actions>
    <div class="d-flex align-items-center justify-content-end">
      <img src="assets/images/GIPHY_logo.png" alt="Giphy logo" width="150" />
    </div>
  </kendo-dialog-actions>
</kendo-dialog>
