import { mapEnumToOptions } from '@abp/ng.core';

export enum OrderByPhotosEnums {
  None = 0,
  Latest = 1,
  Oldest = 2,
  Popular = 3,
}

export const orderByPhotosEnumsOptions = mapEnumToOptions(OrderByPhotosEnums);
