import type { StatusEnum } from '../../models/enums/status-enum.enum';
import type { ProrationTypeEnum } from '../../models/enums/proration-type-enum.enum';
import type { PricingModelEnum } from '../../models/enums/pricing-model-enum.enum';
import type { ItemPrice_PeriodUnitEnum } from '../../models/item-price-period-unit-enum.enum';
import type { ItemPrice_TrialPeriodUnitEnum } from '../../models/item-price-trial-period-unit-enum.enum';
import type { ItemPrice_TrialEndActionEnum } from '../../models/item-price-trial-end-action-enum.enum';
import type { ItemPrice_ShippingPeriodUnitEnum } from '../../models/item-price-shipping-period-unit-enum.enum';
import type { ChannelEnum } from '../../models/enums/channel-enum.enum';
import type {
  ItemPrice_ItemPriceAccountingDetail,
  ItemPrice_ItemPriceTaxDetail,
  ItemPrice_ItemPriceTier,
} from '../../models/models';
import type { ItemTypeEnum } from '../../models/enums/item-type-enum.enum';

export interface ApplicableAddons {
  item: ItemPriceModel;
  cardContent?: string;
}

export interface ItemPriceModel {
  id?: string;
  name?: string;
  itemFamilyId?: string;
  productId?: string;
  itemId?: string;
  description?: string;
  status?: StatusEnum;
  externalName?: string;
  prorationType?: ProrationTypeEnum;
  pricingModel: PricingModelEnum;
  price?: number;
  priceInDecimal?: string;
  period?: number;
  currencyCode?: string;
  periodUnit?: ItemPrice_PeriodUnitEnum;
  trialPeriod?: number;
  trialPeriodUnit?: ItemPrice_TrialPeriodUnitEnum;
  trialEndAction?: ItemPrice_TrialEndActionEnum;
  shippingPeriod?: number;
  shippingPeriodUnit?: ItemPrice_ShippingPeriodUnitEnum;
  billingCycles?: number;
  freeQuantity: number;
  freeQuantityInDecimal?: string;
  channel?: ChannelEnum;
  resourceVersion?: number;
  updatedAt?: string;
  createdAt?: string;
  archivedAt?: string;
  invoiceNotes?: string;
  tiers: ItemPrice_ItemPriceTier[];
  isTaxable?: boolean;
  taxDetail: ItemPrice_ItemPriceTaxDetail;
  accountingDetail: ItemPrice_ItemPriceAccountingDetail;
  itemType?: ItemTypeEnum;
  parentItemId?: string;
  showDescriptionInInvoices?: boolean;
  showDescriptionInQuotes?: boolean;
}
