import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { CategoryService } from '@proxy/categories';
import { CreateUpdateCategoryDto } from '@proxy/categories/dtos';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app';
import { NotificationMessage } from 'src/core/enums/notification-message';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.scss'],
})
export class CreateCategoryComponent implements OnInit {
  categoriesForm: FormGroup;
  displayStyle = 'block';
  isEdit = false;
  isActive = true;
  @Input() categoryId: any;

  constructor(
    private toasterService: ToasterService,
    private categoryService: CategoryService,
    public commonService: CommonService,
    public renderer: Renderer2,
    private spinnerService: NgxSpinnerService,
    public dialogRef: DialogRef,
  ) {}

  ngOnInit(): void {
    if (this.categoryId) {
      this.isEdit = true;
      this.editCategoryForm(this.categoryId);
    }
    this.setForm();
  }

  editCategoryForm(categoryId): void {
    this.categoryService.get(categoryId).subscribe(res => {
      this.categoriesForm.patchValue({
        categoryName: res.name,
        description: res.description,
        isActive: res.isActive,
      });
    });
  }

  setForm(): void {
    this.categoriesForm = new FormGroup({
      categoryName: new FormControl('', Validators.required),
      description: new FormControl(''),
      isActive: new FormControl(true),
    });
  }

  saveCategoty(data): void {
    if (this.categoriesForm.invalid) {
      this.commonService.addValidation(this.categoriesForm, this.renderer);
    } else {
      const param: CreateUpdateCategoryDto = {
        name: data.categoryName,
        description: data.description,
        isActive: data.isActive,
      };

      if (!this.isEdit) {
        this.spinnerService.show();
        this.categoryService.create(param).subscribe(res => {
          if (res) {
            this.spinnerService.hide();
            this.dialogRef.close();
            this.toasterService.success(
              NotificationMessage.createCategorySuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          }
        });
      } else {
        this.spinnerService.show();
        this.categoryService.update(this.categoryId, param).subscribe(res => {
          if (res) {
            this.spinnerService.hide();
            this.dialogRef.close();
            this.toasterService.success(
              NotificationMessage.updateCategorySuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          }
        });
      }
    }
  }

  closePopup(): void {
    this.dialogRef.close();
  }
}
