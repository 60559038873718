<kendo-dialog-titlebar (click)="closePopup()">
  <div>
    {{ isEdit ? 'Edit Hashtag' : 'Create Hashtag' }}
  </div>
</kendo-dialog-titlebar>

<div>
  <form class="k-form k-form-md" [formGroup]="hashtagForm">
    <fieldset class="k-form-fieldset">
      <kendo-formfield>
        <kendo-label class="text-primary-text" [for]="hashtagName" text="Hashtag Collection Name"
          ><span class="required">*</span></kendo-label
        >
        <kendo-textbox
          formControlName="hashtagCollectionName"
          [clearButton]="true"
          class="bg-white"
          [maxLength]="100"
          #hashtagName
        ></kendo-textbox>
      </kendo-formfield>
      <kendo-formfield showHints="always">
        <kendo-label class="text-primary-text" text="Hashtags"> </kendo-label>
        <kendo-textarea
          [rows]="3"
          formControlName="hashtags"
          (change)="hashTagCount($event)"
          class="bg-white"
          [maxLength]="500"
          resizable="vertical"
        ></kendo-textarea>
      </kendo-formfield>
    </fieldset>
  </form>
</div>

<kendo-dialog-actions>
  <button
    kendoButton
    (click)="saveHashTag(hashtagForm.value)"
    themeColor="primary"
    class="k-button-solid-base"
  >
    Save
  </button>
</kendo-dialog-actions>
