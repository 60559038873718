import {
  Component,
  OnInit,
  ViewEncapsulation,
  HostListener,
  ElementRef,
  Output,
  EventEmitter,
  QueryList,
  ViewChildren,
  ViewChild,
  Input,
} from '@angular/core';
import { NgxCroppedEvent, NgxPhotoEditorService } from 'ngx-photo-editor';
import { ActivatedRoute, Router } from '@angular/router';
import { QuillEditorComponent } from 'ngx-quill';
import { ToasterService } from '@abp/ng.theme.shared';
import { HashtagService } from '@proxy/hashtags';
import { ListService } from '@abp/ng.core';
import { Select } from '@ngxs/store';
import { Observable, Subject, concatMap, forkJoin, of } from 'rxjs';
import { PostService } from '@proxy/posts';
import { FileService } from '@proxy/image';
import {
  ButtonDescription,
  CommonService,
  CreateHashtagComponent,
  CreatePostSuccessfullyPopupComponent,
  GifDynamicComponent,
  NotificationMessage,
  SchedulePostComponent,
  SocialMediaPlatForms,
  TOOLTIP,
} from 'src/app';
import { CommonState } from 'src/core/state';
import {
  SVGIcon,
  commentIcon,
  facebookIcon,
  infoCircleIcon,
  pencilIcon,
  plusIcon,
} from '@progress/kendo-svg-icons';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { BadgeAlign, BadgePosition } from '@progress/kendo-angular-indicators';
import { CategoryService } from '@proxy/categories';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import { PlanName } from 'src/core/enums/chargbee-enum';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup } from '@angular/forms';
import { UnsplashDynamicComponent } from './unsplash-dynamic/unsplash-dynamic.component';
import { YouTubeVisiblityFilterTypes } from 'src/core/enums/youtube-visiblity-filter';
import { YoutubeService } from '@proxy/youtube';
import { YouTubeLicenceFilterTypes } from 'src/core/enums/youtube-license-filter';

@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreatePostComponent implements OnInit {
  // Avatar Setting
  public pmAvatar = 'assets/images/logo/profile.jpg';
  public badgeAlign: BadgeAlign = { vertical: 'bottom', horizontal: 'end' };
  public position: BadgePosition = 'inside';

  // Svg Icons
  public facebookBoxIcon: SVGIcon = facebookIcon;
  public commentIcon: SVGIcon = commentIcon;
  public icons = { infoclip: infoCircleIcon };
  public pencilSVG: SVGIcon = pencilIcon;
  public plusSVG: SVGIcon = plusIcon;

  hashtagList = [];
  quillEditorData = [];
  categoriesList: any[];
  accountDetails: any[] = [];
  postDetailsData: any = [];
  customizePostMediaData: any = [];
  showEmojiPickerArray: boolean[] = [];
  youtubeCategoriesList: any = [];

  isAllSelected = false;
  showEmojiPicker = false;
  isProfileCustomize = false;
  isDarfMode = false;
  isExpireDateInvalid = false;
  isYouTubeAccountSelected = false;

  quillData = '';
  shortTitle = '';
  postId: any;
  catergoryDetails: any;
  scheduleDateFromCalendar: any;
  triggerScheduleDateFromCalendar: any;
  category: any;
  hashtagValue: any;
  postingData: any;
  selectedTabIndex = 0;
  selectedAccountCount = 0;
  selectedYoutubeVisblityValue: any;
  selectedYoutubeLicenceValue: any;
  selectedYoutubeCategoryValue: any;
  creditInUser = this.selectedAccountCount * environment.perPostCharges;
  isScheduleAllowed: boolean;

  ngCropImageOutput?: NgxCroppedEvent;
  tooltipText = TOOLTIP;
  planNameEnum = PlanName;
  socialAccountEnum = SocialMediaPlatForms;
  btnText: string = ButtonDescription.sharePost;
  shareText: string = ButtonDescription.approved;
  youtubeVisiblityFilterTypes = YouTubeVisiblityFilterTypes;
  youTubeLicenceFilterTypes = YouTubeLicenceFilterTypes;

  youTubeShortsForm: FormGroup;

  public actionForEditor: any = [
    {
      id: 1,
      text: 'Bold',
      iconClass: 'fas fa-bold',
      openTag: '<b>',
      closeTag: '</b>',
    },
    {
      id: 2,
      text: 'Italic',
      iconClass: 'fas fa-italic',
      openTag: '<i>',
      closeTag: '</i>',
    },
    {
      id: 3,
      text: 'Strike',
      iconClass: 'fas fa-strikethrough',
      openTag: '<s>',
      closeTag: '</s>',
    },
    {
      id: 4,
      text: 'GIF',
      iconClass: 'fas fa-image',
    },
    {
      id: 5,
      text: 'Unsplash',
      iconClass: 'fa-brands fa-unsplash',
    },
  ];

  public youtubeLicenseList: Array<string> = ['Item 1', 'Item 2', 'Item 3'];

  @ViewChildren('quillEditor') quillEditors: QueryList<QuillEditorComponent>;
  @ViewChild(QuillEditorComponent, { static: false }) firstEditor: QuillEditorComponent;
  @ViewChild('schedulePostDetails') schedulePostDetails: SchedulePostComponent;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  tempSchedulePostDetails: SchedulePostComponent;

  @Output() newItemEvent = new EventEmitter<any>();
  @Output() triggerDataToPreview = new EventEmitter<any>();
  @Output() triggerDataToSchedule = new EventEmitter<any>();

  @Input() scheduleData: string;
  @Input() scheduleEditPostId: any;
  @Input() scheduleEditScheduleId: any;

  triggerEditPostData: Subject<any> = new Subject<any>();

  @Select(CommonState.profileDetails)
  profileDetails$: Observable<Array<any>>;

  @Select(CommonState.isScheduleAllowed)
  isScheduleAllowed$: Observable<any>;

  @Select(CommonState.subscriptionPlanData)
  subscriptionPlanData$: Observable<any>;
  currentWalletCredit = 0;

  constructor(
    public commonService: CommonService,
    private ngxPhotoEditorService: NgxPhotoEditorService,
    private toasterService: ToasterService,
    private _eref: ElementRef,
    private _Activatedroute: ActivatedRoute,
    private hashtagService: HashtagService,
    public readonly list: ListService,
    private postService: PostService,
    private fileService: FileService,
    private dialogService: DialogService,
    private categoryService: CategoryService,
    private spinnerService: NgxSpinnerService,
    public datepipe: DatePipe,
    private router: Router,
    private date: DatePipe,
    private youtubeService: YoutubeService,
  ) {
    this.getHashTagList();
    this.getCategoryList();
    this.onIsDarfModeChange();
    this.isScheduleAllowed$.subscribe(res => {
      this.isScheduleAllowed = res;
      this.selectedTabIndex = this.isScheduleAllowed ? 1 : 0;
    });
  }

  ngOnInit(): void {
    this.getAccountList();
    this.setYouTubeShortsForm();

    this.selectedYoutubeVisblityValue = YouTubeVisiblityFilterTypes['Public'];
    this.selectedYoutubeLicenceValue = YouTubeLicenceFilterTypes['Standard YouTube Licence'];

    this._Activatedroute.paramMap.subscribe(params => {
      if (params.keys.length > 0) {
        if (params.keys.some(e => e === 'postId')) {
          this.postId = atob(params.get('postId'));
          this.getAccountList();
        }

        if (params.keys.some(e => e === 'scheduleDateFromCalendar')) {
          this.scheduleDateFromCalendar = params.get('scheduleDateFromCalendar');
          this.setScheduleDateFromCalendar();
        }

        if (params.keys.some(e => e === 'catergoryId')) {
          this.catergoryDetails = {
            catergoryId: atob(params.get('catergoryId')),
            catergoryName: atob(params.get('catergoryName')),
            catergoryDesc: atob(params.get('catergoryDesc')),
          };
        }
      }
    });

    this.subscriptionPlanData$.subscribe(res => {
      if (res !== undefined && res !== null) {
        if (
          res.currentWalletCredit !== undefined &&
          res.currentWalletCredit !== null &&
          res.currentWalletCredit > 0
        ) {
          this.currentWalletCredit = res.currentWalletCredit;
        } else {
          this.currentWalletCredit = 0;
        }
      }
    });
  }

  setYouTubeShortsForm() {
    return (this.youTubeShortsForm = new FormGroup({
      title: new FormControl(''),
      category: new FormControl(''),
      visiblity: new FormControl(''),
      license: new FormControl(''),
      notifySubscribers: new FormControl(false),
      allowEmbedding: new FormControl(false),
      madeForKids: new FormControl(false),
    }));
  }
  isNewSchedule(isCreditUpdated: boolean): void {
    if (isCreditUpdated) this.getCreditInUse();
  }

  getInitials(name: string): string {
    return name ? name[0].toUpperCase() : '';
  }

  getCategoryArray(categoryId: number): any {
    return this.categoriesList.find(x => x.id === categoryId).id;
  }

  getCategoryList(): void {
    this.categoryService.getListByStatus(true).subscribe(res => {
      this.categoriesList = res;
      if (this.catergoryDetails !== undefined) {
        this.category = this.getCategoryArray(+this.catergoryDetails.catergoryId);
      } else if (this.postDetailsData.length > 0) {
        this.category = this.getCategoryArray(+this.postDetailsData.categoryId);
      } else {
        const defaultCategory = this.categoriesList.find(element => element.isDefault === true);

        if (defaultCategory) {
          this.category = defaultCategory.id;
        }
      }
    });
  }

  setScheduleDateFromCalendar(): void {
    this.triggerScheduleDateFromCalendar = this.scheduleDateFromCalendar;
  }

  getPostDetails(postId): void {
    this.postService.get(postId).subscribe(res => {
      this.postDetailsData = res;
      this.isDarfMode = this.postDetailsData.isDraft;
      this.isProfileCustomize = this.postDetailsData.isCustomizedPost;
      this.hashtagValue = [];

      this.isAllSelected = this.postDetailsData.socialAccounts.every(x => x.isSelected);

      this.accountDetails.forEach(element => {
        const imageurls = [];
        const correspondingResAccount = res.socialAccounts.find(
          resElement => resElement.id === element.id,
        );

        if (this.isProfileCustomize) {
          if (correspondingResAccount !== undefined) {
            correspondingResAccount.mediaUrls.forEach(element => {
              imageurls.push({
                base64Value: element.base64Value,
                fileExtension: this.commonService.getFileExtension(element.value),
                value: element.value,
                size: element.size,
                tempURL: element.base64Value,
              });
            });
          } else {
            res.mediaUrls.forEach(element => {
              imageurls.push({
                base64Value: element.base64Value,
                fileExtension: this.commonService.getFileExtension(element.value),
                value: element.value,
                size: element.size,
                tempURL: element.base64Value,
              });
            });
          }
        } else {
          res.mediaUrls.forEach(element => {
            imageurls.push({
              base64Value: element.base64Value,
              fileExtension: this.commonService.getFileExtension(element.value),
              value: element.value,
              size: element.size,
              tempURL: element.base64Value,
            });
          });
        }

        if (res.isCustomizedPost && correspondingResAccount) {
          element.isSelected = true;
          element.caption = correspondingResAccount.message;
          element.uploadedImages = imageurls;
          element.hashtagValue = this.hashtagList.filter(item =>
            correspondingResAccount.hashtagIds.includes(item.id),
          );
        } else {
          element.isSelected = correspondingResAccount ? true : false;
          element.uploadedImages = imageurls;
          element.caption = res.message;
          this.quillData = res.message;
          this.hashtagValue = this.hashtagList.filter(item =>
            this.postDetailsData.hashtagIds.includes(item.id),
          );
        }
      });
      this.setYoutubeShortsFormValueOnEdit(this.postDetailsData);
      this.triggerDataToPreview.emit(this.accountDetails);
      this.triggerEditPostData.next(this.postDetailsData);
      this.category = this.postDetailsData.categoryId;
      this.selectedAccountCount = this.accountDetails.filter(x => x.isSelected === true).length;
    });
  }

  onTabSelect(event: any) {
    this.selectedTabIndex = event.index;

    if (this.isScheduleAllowed) {
      this.tempSchedulePostDetails = this.schedulePostDetails
        ? this.schedulePostDetails
        : this.tempSchedulePostDetails;
    }

    if (event.title === 'Preview') {
      if (this.accountDetails.length > 0) {
        setTimeout(() => {
          this.triggerDataToPreview.emit(this.accountDetails);
        }, 100);
      }
    } else if (event.title === 'Schedule') {
      setTimeout(() => {
        this.schedulePostDetails.resetData(this.tempSchedulePostDetails);
      }, 500);
    }
  }

  onIsDarfModeChange(): void {
    this.btnText = !this.isDarfMode ? ButtonDescription.sharePost : ButtonDescription.savePost;
    this.shareText = !this.isDarfMode ? ButtonDescription.approved : ButtonDescription.draft;
  }

  getAccountList(): void {
    this.profileDetails$.subscribe(x => {
      if (x !== undefined) {
        this.accountDetails = JSON.parse(JSON.stringify(x));

        if (this.postId !== undefined && this.postId !== null) {
          this.getPostDetails(+this.postId);
        }

        if (this.scheduleEditPostId !== undefined) {
          this.postId = this.scheduleEditPostId;
          this.getPostDetails(this.postId);
        }
      } else {
        //To add blank array if no social media account added
        this.accountDetails = [
          {
            accountType: null,
            caption: '',
            id: 0,
            img: null,
            isActive: false,
            isSelected: false,
            postCount: 0,
            socialId: '',
            uploadedImages: [],
          },
        ];
      }
    });
  }

  onCustomizeclick(): void {
    if (this.accountDetails.every(x => x.isSelected === false)) {
      this.toasterService.error(
        NotificationMessage.selectAccountToCustomizeMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
    } else {
      this.accountDetails.forEach(x => ((x.uploadedImages = []), (x.caption = '')));
      this.triggerDataToPreview.emit(this.accountDetails);
      this.isProfileCustomize = !this.isProfileCustomize;
    }
  }

  onUnsplashClick(accountData: any) {
    const dialogRef = this.dialogService.open({
      content: UnsplashDynamicComponent,
      width: 450,
    });

    const unsplashComponentInfo = dialogRef.content.instance as UnsplashDynamicComponent;

    const index = this.accountDetails.findIndex(
      x => x.isSelected === true && x.accountType === SocialMediaPlatForms.YouTube,
    );

    dialogRef.result.subscribe(
      result => {
        if (result instanceof DialogCloseResult) {
          if (unsplashComponentInfo.selectedImageDetails.url !== undefined) {
            if (this.isProfileCustomize) {
              accountData.uploadedImages.push({
                base64Value: unsplashComponentInfo.selectedImageDetails.url,
                fileExtension: this.commonService.getFileExtension(
                  `${unsplashComponentInfo.selectedImageDetails.id}`,
                ),
                value: unsplashComponentInfo.selectedImageDetails.id,
                size: 1,
                tempURL: unsplashComponentInfo.selectedImageDetails.url,
              });

              this.youTubeContentValidation(index, accountData.uploadedImages);

              this.triggerDataToPreview.emit(this.accountDetails);
            } else {
              const accountData = this.accountDetails[0].uploadedImages;

              accountData.push({
                base64Value: unsplashComponentInfo.selectedImageDetails.url,
                fileExtension: this.commonService.getFileExtension(
                  `${unsplashComponentInfo.selectedImageDetails.id}`,
                ),
                value: unsplashComponentInfo.selectedImageDetails.id,
                size: 1,
                tempURL: unsplashComponentInfo.selectedImageDetails.url,
              });

              this.youTubeContentValidation(index, accountData);

              this.setDataForGeneralProfile(accountData);
            }
          }
        }
      },
      error => {},
    );
  }

  onGIFClick(accountData: any): void {
    const dialogRef = this.dialogService.open({
      content: GifDynamicComponent,
      width: 450,
    });

    const gifComponentInfo = dialogRef.content.instance as GifDynamicComponent;

    const index = this.accountDetails.findIndex(
      x => x.isSelected === true && x.accountType === SocialMediaPlatForms.YouTube,
    );

    dialogRef.result.subscribe(
      result => {
        if (result instanceof DialogCloseResult) {
          if (gifComponentInfo.selectedImageDetails.url !== undefined) {
            if (this.isProfileCustomize) {
              accountData.uploadedImages.push({
                base64Value: gifComponentInfo.selectedImageDetails.url,
                fileExtension: this.commonService.getFileExtension(
                  `${gifComponentInfo.selectedImageDetails.id}.gif`,
                ),
                value: gifComponentInfo.selectedImageDetails.id,
                size: 1,
              });

              this.youTubeContentValidation(index, accountData.uploadedImages);

              this.triggerDataToPreview.emit(this.accountDetails);
            } else {
              const accountData = this.accountDetails[0].uploadedImages;

              accountData.push({
                base64Value: gifComponentInfo.selectedImageDetails.url,
                fileExtension: this.commonService.getFileExtension(
                  `${gifComponentInfo.selectedImageDetails.id}.gif`,
                ),
                value: gifComponentInfo.selectedImageDetails.id,
                size: 1,
              });

              this.youTubeContentValidation(index, accountData);

              this.setDataForGeneralProfile(accountData);
            }
          }
        }
      },
      error => {},
    );
  }

  bindImageToEditor(file, fileLenght: number, accountData: any): void {
    const isMultiFile = fileLenght > 1;

    const index = this.accountDetails.findIndex(
      x => x.isSelected === true && x.accountType === SocialMediaPlatForms.YouTube,
    );

    if (isMultiFile) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (e: any) => {
        const base64Value: string = e.target.result.split(',')[1];
        accountData.push({
          base64Value: `data:image/jpeg;base64,${base64Value}`,
          fileExtension: this.commonService.getFileExtension(file.name),
          value: file.name,
          size: file.size,
          tempURL: `data:image/jpeg;base64,${base64Value}`,
        });

        this.youTubeContentValidation(index, accountData);
      };

      this.setDataForGeneralProfile(accountData);
    } else {
      this.ngxPhotoEditorService
        .open(event, {
          aspectRatio: 4 / 3,
          autoCropArea: 1,
        })
        .subscribe(data => {
          this.ngCropImageOutput = data;
          const reader = new FileReader();
          reader.readAsDataURL(this.ngCropImageOutput.file);

          accountData.push({
            base64Value: this.ngCropImageOutput.base64,
            file: this.ngCropImageOutput.file,
            fileExtension: this.commonService.getFileExtension(file.name),
            value: file.name,
            size: file.size,
            tempURL: this.ngCropImageOutput.base64,
          });

          this.youTubeContentValidation(index, accountData);

          this.setDataForGeneralProfile(accountData);
        });
    }
  }

  setDataForGeneralProfile(accountData): void {
    this.accountDetails.forEach(element => {
      if (!this.isProfileCustomize) {
        element.uploadedImages = accountData;
      }
    });

    this.quillEditorData['image'] = accountData;
    this.triggerDataToPreview.emit(this.accountDetails);
  }

  bindVideoToEditor(file, accountData): void {
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = event => {
        let createUrl = (<FileReader>event.target).result;

        accountData.push({
          base64Value: createUrl,
          fileExtension: this.commonService.getFileExtension(file.name),
          value: file.name,
          size: file.size,
        });

        this.setDataForGeneralProfile(accountData);
      };
    }
  }

  onSelectFile(event, accountData?: any): void {
    const files = Array.from(event.target.files);

    files.forEach((element: File) => {
      if (element.type === 'image/jpeg' || element.type === 'image/png') {
        this.bindImageToEditor(element, files.length, accountData);
      } else {
        this.bindVideoToEditor(element, accountData);
      }
    });
  }

  removeImage(data: any, index: number): void {
    this.accountDetails.map(group => {
      if (group.uploadedImages) {
        group.uploadedImages = group.uploadedImages.filter(
          (item, i) => i !== index || item.base64Value !== data,
        );
      }
      return group;
    });
  }

  getHashTagList(): void {
    this.hashtagService.getAll().subscribe(res => {
      this.hashtagList = res;
    });
  }

  onHashTagClick(): void {
    this.hashtagService.getAll().subscribe(res => {
      this.hashtagList = res;
    });
  }

  onAddHashTag(): void {
    const dialogRef = this.dialogService.open({
      content: CreateHashtagComponent,
      width: 300,
    });

    const tagInfo = dialogRef.content.instance as CreateHashtagComponent;

    dialogRef.result.subscribe((r: { [key: string]: string }) => {
      this.getHashTagList();
    });
  }

  selectAllUser(): void {
    this.accountDetails.forEach(x => (x.isSelected = !this.isAllSelected));
    this.isAllSelected = !this.isAllSelected;

    if (!this.isAllSelected) this.isProfileCustomize = false;

    this.selectedAccountCount = this.accountDetails.filter(x => x.isSelected === true).length;
    const selectedYouTubeAccountCount = this.accountDetails.filter(
      x => x.isSelected === true && x.accountType === SocialMediaPlatForms.YouTube,
    );

    if (selectedYouTubeAccountCount.length > 1) {
      for (let i = 1; i < selectedYouTubeAccountCount.length; i++) {
        selectedYouTubeAccountCount[i].isSelected = false;
      }
      this.toasterService.error(
        NotificationMessage.youTubeAccountSelectValidationMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return;
    } else {
      this.youTubeSelectionValidation(selectedYouTubeAccountCount);
    }

    const index = this.accountDetails.findIndex(
      x => x.isSelected === true && x.accountType === SocialMediaPlatForms.YouTube,
    );
    this.youTubeContentValidation(index, this.accountDetails[index].uploadedImages);

    this.getCreditInUse();
    this.triggerDataToPreview.emit(this.accountDetails);
  }

  selectedUsers(e, isSelected): void {
    const index = this.accountDetails.findIndex(item => item.id === +e.target.value);

    this.accountDetails[index].isSelected = !isSelected;

    const selectedYouTubeAccountCount = this.accountDetails.filter(
      x => x.isSelected === true && x.accountType === SocialMediaPlatForms.YouTube,
    );
    if (selectedYouTubeAccountCount.length > 1) {
      this.accountDetails[index].isSelected = false;
      this.toasterService.error(
        NotificationMessage.youTubeAccountSelectValidationMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return;
    } else {
      this.youTubeSelectionValidation(selectedYouTubeAccountCount);
    }

    this.youTubeContentValidation(index, this.accountDetails[index].uploadedImages);

    this.isAllSelected = this.accountDetails.every(x => x.isSelected);
    this.selectedAccountCount = this.accountDetails.filter(x => x.isSelected === true).length;

    this.getCreditInUse();

    this.triggerDataToPreview.emit(this.accountDetails);

    const noAccountSelected = this.accountDetails.filter(x => x.isSelected === true).length;

    if (noAccountSelected === 0) this.isProfileCustomize = false;
  }

  getCreditInUse(): void {
    this.creditInUser = this.isScheduleAllowed
      ? this.schedulePostDetails.tempDateTimeArray.length *
        this.selectedAccountCount *
        environment.perPostCharges
      : this.selectedAccountCount * environment.perPostCharges;

    if (this.postId) {
      const editSelectedAccountCount = this.postDetailsData.socialAccounts.length;
      const deductionAmount = Math.max(0, this.creditInUser - 2 * editSelectedAccountCount);
      this.creditInUser = deductionAmount;
    }
  }

  toggleEmojiPicker(): void {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  toggleEmojiPickerForIndex(index: number): void {
    this.showEmojiPickerArray[index] = !this.showEmojiPickerArray[index];
  }

  addEmoji(event: any, ad: any): void {
    const { quillData } = this;
    const text = `${event.emoji.native}`;
    ad.caption = ad.caption.replace(/<\/?[^>]+(>|$)/g, '');
    ad.caption += text;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: any) {
    if (!this._eref.nativeElement.contains(event.target) && this.showEmojiPicker) {
      this.showEmojiPicker = false;
    }
  }

  onQuillEditorChange(event: any): void {
    this.quillEditorData['quillData'] = event.html;
    for (let i = 0; i < this.accountDetails.length; i++) {
      this.accountDetails[i]['caption'] = event.html;
    }
    this.triggerDataToPreview.emit(this.accountDetails);
  }

  onQuillEditorCustomizeChange(): void {
    this.triggerDataToPreview.emit(this.accountDetails);
  }

  addEmojiCustomize(event: any, accountData: any): void {
    let getSelectedIds = this.accountDetails
      .filter(element => element.isSelected)
      .map(element => element.id);

    this.accountDetails.forEach(element => {
      if (element.socialId === accountData.socialId) {
        element.caption += '\n' + event.emoji.native;
        const quillIndex = getSelectedIds.findIndex(
          selectedElement => selectedElement === element.id,
        );

        if (quillIndex !== -1) {
          const quillInstance = this.quillEditors.toArray()[quillIndex].quillEditor;
          quillInstance.setText(element.caption);
          accountData.caption = element.caption;
        }
      }
    });
  }

  showSucessMessage(): void {
    if (this.isDarfMode) {
      this.spinnerService.hide();
      this.openSuccessfulDraftPost();
    } else {
      this.spinnerService.hide();
      this.openSuccessfulApprovePost();
    }
  }

  onAddHashtagToEditor(selectedHashtag: any, accountData?: any): void {
    if (selectedHashtag !== undefined && selectedHashtag.length !== 0) {
      let value = '';
      if (selectedHashtag.length > 0) {
        selectedHashtag.forEach((element: any) => {
          value += element.hashtags + ' ';
        });
      } else {
        value = selectedHashtag;
      }
      this.quillData += '\n' + value;

      if (accountData === undefined) {
        this.accountDetails.forEach(detail => {
          detail.caption = detail.caption !== null ? detail.caption + value : '';
        });
      } else {
        let getSelectedIds = this.accountDetails
          .filter(element => element.isSelected)
          .map(element => element.id);

        this.accountDetails.forEach(element => {
          if (element.socialId === accountData.socialId) {
            element.caption += '\n' + value;
            const quillIndex = getSelectedIds.findIndex(
              selectedElement => selectedElement === element.id,
            );
            if (quillIndex !== -1) {
              const quillInstance = this.quillEditors.toArray()[quillIndex].quillEditor;
              quillInstance.setText(element.caption);
            }
          }
        });
      }

      this.triggerDataToPreview.emit(this.accountDetails);
    } else {
      this.toasterService.error(
        NotificationMessage.emptyHashtagValidaitonMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
    }
  }

  editImage(accountData?: any): void {
    const base64Value = accountData.tempURL.replace(
      'data:application/octet-stream;base64,',
      'data:image/jpeg;base64,',
    );

    this.ngxPhotoEditorService
      .open(base64Value, {
        aspectRatio: 4 / 3,
        autoCropArea: 1,
      })
      .subscribe(data => {
        this.ngCropImageOutput = data;
        var reader = new FileReader();
        reader.readAsDataURL(this.ngCropImageOutput.file);
        accountData.base64Value = this.ngCropImageOutput.base64;

        if (!this.isProfileCustomize) {
          this.accountDetails.forEach(element => {
            const uploadedImage = element.uploadedImages.find(
              element1 => accountData.value === element1.value,
            );

            if (uploadedImage) {
              uploadedImage.base64Value = this.ngCropImageOutput.base64;
            }
          });
        }
      });
  }

  tooltip(accounttype, accountName) {
    if (accounttype === SocialMediaPlatForms.LinkedInOrganization) {
      return accountName + ' (LinkedIn Page)';
    } else if (accounttype === SocialMediaPlatForms.LinkedIn) {
      return accountName + ' (LinkedIn Profile)';
    } else {
      return accountName;
    }
  }

  checkValidations(): boolean {
    if (!this.validateCreditAvaialble()) {
      return false;
    }

    if (this.isScheduleAllowed) {
      const scheduleForm = this.schedulePostDetails.scheduleForm;

      if (!this.checkValidationsForSchedule()) {
        return false;
      }

      if (
        !this.validateReQueue(scheduleForm.value.expireSpecificDate, scheduleForm.value.expireTimes)
      ) {
        return false;
      }
    }

    if (!this.validateSelectedAccounts()) {
      return false;
    }

    if (!this.validateNoDataAdded()) {
      return false;
    }

    if (!this.validateCategory(this.category)) {
      return false;
    }

    if (!this.validateAccountDetails()) {
      return false;
    }

    if (!this.validateSelectedYouTubeAccountContent()) {
      return false;
    }

    return true;
  }

  private validateReQueue(expireSpecificDate: Date, expireTimes: string): boolean {
    const isReQueue = this.schedulePostDetails.isReQueue;

    if (isReQueue && expireSpecificDate === null && (expireTimes === null || expireTimes === '')) {
      this.toasterService.error(
        NotificationMessage.expireTimesValueRequired,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return false;
    }

    return true;
  }

  private validateNoDataAdded(): boolean {
    if (
      this.accountDetails.every(item => item.uploadedImages.length === 0 && item.caption === '')
    ) {
      this.toasterService.error(
        NotificationMessage.emptyEditorValidaitonMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return false;
    }

    return true;
  }

  private validateSelectedAccounts(): boolean {
    if (!this.accountDetails.some(e => e.isSelected === true)) {
      this.toasterService.error(
        NotificationMessage.selectAccountMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return false;
    }

    return true;
  }

  private validateSelectedYouTubeAccountContent(): boolean {
    const isImageUploaded = this.accountDetails.some(item => {
      return item.uploadedImages.some(image =>
        ['jpg', 'png', 'jpeg', 'gif'].includes(image.fileExtension),
      );
    });

    const isYoutubeAccoutSelected = this.accountDetails.some(item => {
      return item.isSelected && item.accountType === SocialMediaPlatForms.YouTube;
    });

    if (isImageUploaded && isYoutubeAccoutSelected) {
      this.toasterService.error(
        NotificationMessage.youTubeContentValidationMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return false;
    }

    if (
      this.accountDetails.every(item => item.uploadedImages.length === 0 && isYoutubeAccoutSelected)
    ) {
      this.toasterService.error(
        NotificationMessage.emptyEditorValidaitonMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return false;
    }

    return true;
  }

  private validateCreditAvaialble(): boolean {
    if (this.currentWalletCredit <= 0) {
      this.toasterService.error(
        NotificationMessage.noCreditAvailableMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return false;
    }

    if (this.currentWalletCredit < this.creditInUser) {
      this.toasterService.error(
        NotificationMessage.noCreditAvailableMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return false;
    }

    return true;
  }

  private checkValidationsForSchedule(): boolean {
    if (this.currentWalletCredit < this.creditInUser) {
      this.toasterService.error(
        NotificationMessage.lessCreditAvailableMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return false;
    }

    return true;
  }

  private validateCategory(category: any): boolean {
    if (category === undefined) {
      this.toasterService.error(
        NotificationMessage.selectCategoryMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return false;
    }

    return true;
  }

  private validateAccountDetails(): boolean {
    const selectedAccounts = this.accountDetails.filter(x => x.isSelected === true);

    for (const account of selectedAccounts) {
      if (!this.validateAccount(account)) {
        return false;
      }
    }

    return true;
  }

  private validateAccount(account: any): boolean {
    const accountType = account.accountType;

    if (account.uploadedImages.length > 10) {
      this.toasterService.error(
        NotificationMessage.imageFileLimitMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return false;
    }

    if (!this.validateImageVideoSize(account)) {
      return false;
    }

    if (
      accountType === SocialMediaPlatForms.LinkedIn ||
      accountType === SocialMediaPlatForms.LinkedInOrganization
    ) {
      if (!this.validateLinkedIn(account.uploadedImages)) {
        return false;
      }
    } else if (accountType === SocialMediaPlatForms.Instagram) {
      if (!this.validateInstagram(account)) {
        return false;
      }
    } else if (accountType === SocialMediaPlatForms.Facebook) {
      if (!this.validateFacebook(account)) {
        return false;
      }
    }

    return true;
  }

  private validateImageVideoSize(data): boolean {
    return this.commonService.imageVideoSizeValidation(data);
  }

  private validateLinkedIn(images: any[]): boolean {
    const hasImagesAndVideos = images.every(item => item.fileExtension === images[0].fileExtension);

    if (!hasImagesAndVideos) {
      this.toasterService.error(
        NotificationMessage.linkedInImageVedioMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return false;
    }
    //#region  Old image/video size validations
    // for (const image of images) {
    //   if (this.commonService.isImage(image.value) || this.commonService.isGIF(image.value)) {
    //     if (image.size > this.commonService.convertMbToKb(5)) {
    //       this.toasterService.error(
    //         NotificationMessage.linkedInImageSizeMsg,
    //         '',
    //         this.commonService.toasterMessageConfiguration,
    //       );
    //       return false;
    //     }
    //   } else {
    //     if (image.size > this.commonService.convertGbToKb(5)) {
    //       this.toasterService.error(
    //         NotificationMessage.linkedInVedioSizeMsg,
    //         '',
    //         this.commonService.toasterMessageConfiguration,
    //       );
    //       return false;
    //     }
    //   }
    // }
    //#endregion
    return true;
  }

  private validateInstagram(account: any): boolean {
    let regex = new RegExp('#', 'g');
    let hashtagcount: number = 0;

    if (account.caption.match(regex) !== null) {
      hashtagcount = account.caption.match(regex).length;
    }

    if (account.caption.length > 2200) {
      this.toasterService.error(
        NotificationMessage.captionLimitMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return false;
    }

    if (hashtagcount > 20) {
      this.toasterService.error(
        NotificationMessage.hastagLimitMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return false;
    }

    if (account.uploadedImages.length === 0) {
      this.spinnerService.hide();
      this.toasterService.error(
        NotificationMessage.addMediaMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return false;
    }

    //#region  Old image/video size validations
    // for (const image of account.uploadedImages) {
    //   if (this.commonService.isImage(image.value) || this.commonService.isGIF(image.value)) {
    //     if (image.size > this.commonService.convertMbToKb(8)) {
    //       this.toasterService.error(
    //         NotificationMessage.instagramImageSizeMsg,
    //         '',
    //         this.commonService.toasterMessageConfiguration,
    //       );
    //       return false;
    //     }
    //   } else {
    //     if (image.size > this.commonService.convertMbToKb(650)) {
    //       this.toasterService.error(
    //         NotificationMessage.InstagramVedioSizeMsg,
    //         '',
    //         this.commonService.toasterMessageConfiguration,
    //       );
    //       return false;
    //     }
    //   }
    // }
    //#endregion

    return true;
  }

  private validateFacebook(account: any): boolean {
    if (account.caption.length > 63206) {
      this.toasterService.error(
        NotificationMessage.captionLimitMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return false;
    }
    //#region  Old image/video size validations
    // for (const image of account.uploadedImages) {
    //   if (this.commonService.isImage(image.value) || this.commonService.isGIF(image.value)) {
    //     if (image.size > this.commonService.convertMbToKb(30)) {
    //       this.toasterService.error(
    //         NotificationMessage.facebookImageSizeMsg,
    //         '',
    //         this.commonService.toasterMessageConfiguration,
    //       );
    //       return false;
    //     }
    //   } else {
    //     if (image.size > this.commonService.convertGbToKb(10)) {
    //       this.toasterService.error(
    //         NotificationMessage.facebookImageSizeMsg,
    //         '',
    //         this.commonService.toasterMessageConfiguration,
    //       );
    //       return false;
    //     }
    //   }
    // }
    //#endregion
    return true;
  }

  customizeMediaUploadImage(selectedAccounts): void {
    const customizePostAccountData = this.accountDetails.filter(x => x.isSelected);

    const customPostDataObservables = customizePostAccountData.map(el => {
      const mediaUrlRequests = el.uploadedImages.map(element => {
        if (this.commonService.isGIF(element.fileExtension)) {
          return this.commonService.convertGifToBlob(element.base64Value);
        } else if (this.commonService.isVideo(element.fileExtension)) {
          const base64Value = this.commonService.removeBase64Prefix(element.base64Value);
          const videoBlob = this.commonService.convertVideoToBlob(
            base64Value,
            element.fileExtension,
          );
          return of(videoBlob);
        } else {
          return this.commonService.getImageBlob(element.base64Value);
        }
      });

      if (mediaUrlRequests.length > 0) {
        return forkJoin(mediaUrlRequests).pipe(
          concatMap((blobs: Blob[]) => {
            const formData = new FormData();
            blobs.forEach((blob, index) => {
              if (this.commonService.isVideo(blob.type)) {
                formData.append('files', blob, `files${index}.mp4`);
              } else {
                formData.append('files', blob, `files${index}.png`);
              }
            });
            return this.fileService.saveMultipleFiles(formData);
          }),
          concatMap(uploadedfileList => {
            let hashtagIds: any[] = [];
            if (el.hashtagValue !== undefined) {
              el.hashtagValue.forEach(element => {
                hashtagIds.push(element.id);
              });
            }

            const param = {
              socialAccountId: el.id,
              message: el.caption.replace(/<[^>]*>/g, ''),
              mediaUrls: uploadedfileList,
              hashtagIds: hashtagIds,
            };
            this.customizePostMediaData.push(param);
            return of(param);
          }),
        );
      } else {
        let hashtagIdss: any[] = [];
        if (el.hashtagValue !== undefined) {
          el.hashtagValue.forEach(element => {
            hashtagIdss.push(element.id);
          });
        }
        const param = {
          socialAccountId: el.id,
          message: el.caption.replace(/<[^>]*>/g, ''),
          mediaUrls: [],
          hashtagIds: hashtagIdss,
        };
        this.customizePostMediaData.push(param);
        return of(param);
      }
    });

    forkJoin(customPostDataObservables).subscribe((x: any[]) => {
      if (x && x.some(obs => obs !== null)) {
        this.createPost(selectedAccounts, this.customizePostMediaData);
      } else {
        this.spinnerService.hide();
      }
    });
  }

  createPost(dataSelected: any, uploadedfileList: any): void {
    if (this.isScheduleAllowed) {
      if (this.schedulePostDetails.expireDateTimeString) {
        this.schedulePostDetails.tempDateTimeArray.forEach(element => {
          if (
            element.date === this.schedulePostDetails.expireDateTimeString ||
            new Date(element.date) > new Date(this.schedulePostDetails.expireDateTimeString)
          ) {
            this.isExpireDateInvalid = true;
          }
        });
      }
    }

    if (this.dataSubmit(dataSelected, uploadedfileList)) {
      if (!this.postId) {
        this.postService.create(this.postingData).subscribe(
          res => {
            if (res) {
              this.spinnerService.hide();
              this.isExpireDateInvalid = false;
              this.resetCreatePost();
              this.showSucessMessage();
            }
          },
          error => {
            const errorMessage = error.error.error.message || 'Post Failed';
            this.toasterService.error(errorMessage);
            this.spinnerService.hide();
          },
        );
      } else {
        this.postService.update(+this.postId, this.postingData).subscribe(
          res => {
            if (res) {
              this.spinnerService.hide();
              this.resetCreatePost();
              this.isExpireDateInvalid = false;
              this.router.navigate(['create-post']);
              this.showSucessMessage();
            }
          },
          error => {
            const errorMessage = error.error.error.message || 'Post Failed';
            this.toasterService.error(errorMessage);
            this.spinnerService.hide();
          },
        );
      }
    }
  }

  resetCreatePost(): void {
    if (this.isScheduleAllowed) {
      this.schedulePostDetails.scheduleForm.reset();
      this.schedulePostDetails.expireSpecificDateTemp = this.date.transform(new Date());
      this.schedulePostDetails.tempDateTimeArray = [];
      this.schedulePostDetails.tempDateTimeArray.push({ id: 0 });
      this.schedulePostDetails.tempDateExpireTimeArray = [];
      this.schedulePostDetails.previewDatePickerDate = [];
      this.schedulePostDetails.isReQueue = false;
      this.schedulePostDetails.setValidation(false);
    }

    this.quillData = '';
    this.isAllSelected = false;
    this.isProfileCustomize = false;
    this.isDarfMode = false;
    this.hashtagValue = '';
    this.accountDetails.forEach(x => {
      x.caption = '';
      x.isSelected = false;
      x.uploadedImages = [];
    });

    this.btnText = ButtonDescription.savePost;
    this.shareText = ButtonDescription.approved;
    const defaultCategory = this.categoriesList.find(element => element.isDefault === true);

    if (defaultCategory) {
      this.category = defaultCategory.id;
    }
    this.onIsDarfModeChange();
    this.selectedAccountCount = this.accountDetails.filter(x => x.isSelected === true).length;
    this.creditInUser = 0;
    this.youTubeShortsForm.reset();
    this.isYouTubeAccountSelected = false;
  }

  dataSubmit(dataSelected: any, uploadedfileList: any): boolean {
    try {
      let scheduleTimeDataArr: any[] = [];
      if (this.isScheduleAllowed) {
        this.schedulePostDetails.tempDateTimeArray.forEach(element => {
          if (element.date !== undefined && element.date !== null) {
            const param: any = {
              scheduleTime: this.datepipe.transform(new Date(element.date), 'yyyy-MM-ddTHH:mm:ss'),
            };

            scheduleTimeDataArr.push(param);
          }
        });
      }
      const selectedAccountsId = this.accountDetails
        .filter(x => x.isSelected)
        .map(account => ({
          socialAccountId: account.id,
        }));
      if (this.isProfileCustomize) {
        this.postingData = {
          youtubeCategoryId:
            this.isYouTubeAccountSelected === false ? null : +this.selectedYoutubeCategoryValue.id,
          youtubePrivacyStatus:
            this.isYouTubeAccountSelected === false ? null : this.selectedYoutubeVisblityValue,
          youtubeTitle:
            this.isYouTubeAccountSelected === false
              ? null
              : this.youTubeShortsForm.get('title').value,
          youtubeIsLicensedContent:
            this.isYouTubeAccountSelected === false ? null : this.selectedYoutubeLicenceValue,
          youtubeIsNotifySubscribers:
            this.isYouTubeAccountSelected === false
              ? null
              : this.youTubeShortsForm.get('notifySubscribers').value,
          youtubeIsEmbeddable:
            this.isYouTubeAccountSelected === false
              ? null
              : this.youTubeShortsForm.get('allowEmbedding').value,
          youtubeIsMadeForKids:
            this.isYouTubeAccountSelected === false
              ? null
              : this.youTubeShortsForm.get('madeForKids').value,
          categoryId: this.category,
          isDraft: this.isDarfMode,
          isReQueue: this.isScheduleAllowed
            ? this.schedulePostDetails.isReQueue === null
              ? false
              : this.schedulePostDetails.isReQueue
            : false,
          isExpireAfter: this.isScheduleAllowed
            ? this.schedulePostDetails.scheduleForm.controls.isExpireAfter.value === null
              ? false
              : this.schedulePostDetails.scheduleForm.controls.isExpireAfter.value
            : false,
          expireTimes: this.isScheduleAllowed
            ? this.schedulePostDetails.isReQueue === false
              ? null
              : +this.schedulePostDetails.scheduleForm.controls.expireTimes.value
            : null,
          isExpireSpecificDate: this.isScheduleAllowed
            ? this.schedulePostDetails.scheduleForm.controls.isExpireSpecificDate.value === null
              ? false
              : this.schedulePostDetails.scheduleForm.controls.isExpireSpecificDate.value
            : false,

          expireSpecificDate: this.isScheduleAllowed
            ? this.schedulePostDetails.isReQueue === false
              ? null
              : this.datepipe.transform(
                  new Date(this.schedulePostDetails.scheduleForm.controls.expireSpecificDate.value),
                  'yyyy-MM-ddTHH:mm:ss',
                )
            : null,
          socialAccounts: uploadedfileList,
          schedules: scheduleTimeDataArr,
          isCustomizedPost: this.isProfileCustomize,
          hashtagIds: [],
        };
      } else {
        let hastagIds: any = [];
        if (
          this.hashtagValue !== undefined &&
          this.hashtagValue !== null &&
          this.hashtagValue !== ''
        ) {
          this.hashtagValue.forEach(element => {
            hastagIds.push(element.id);
          });
        }
        this.postingData = {
          youtubeCategoryId:
            this.isYouTubeAccountSelected === false ? null : +this.selectedYoutubeCategoryValue.id,
          youtubePrivacyStatus:
            this.isYouTubeAccountSelected === false ? null : this.selectedYoutubeVisblityValue,
          youtubeTitle:
            this.isYouTubeAccountSelected === false
              ? null
              : this.youTubeShortsForm.get('title').value,
          youtubeIsLicensedContent:
            this.isYouTubeAccountSelected === false ? null : this.selectedYoutubeLicenceValue,
          youtubeIsNotifySubscribers:
            this.isYouTubeAccountSelected === false
              ? null
              : this.youTubeShortsForm.get('notifySubscribers').value,
          youtubeIsEmbeddable:
            this.isYouTubeAccountSelected === false
              ? null
              : this.youTubeShortsForm.get('allowEmbedding').value,
          youtubeIsMadeForKids:
            this.isYouTubeAccountSelected === false
              ? null
              : this.youTubeShortsForm.get('madeForKids').value,
          message: dataSelected.caption.replace(/<[^>]*>/g, ''),
          mediaUrls: uploadedfileList != undefined ? uploadedfileList : [],
          categoryId: +this.category,
          isDraft: this.isDarfMode,
          isReQueue: this.isScheduleAllowed
            ? this.schedulePostDetails.isReQueue === null
              ? false
              : this.schedulePostDetails.isReQueue
            : false,
          isExpireAfter: this.isScheduleAllowed
            ? this.schedulePostDetails.scheduleForm.controls.isExpireAfter.value === null
              ? false
              : this.schedulePostDetails.scheduleForm.controls.isExpireAfter.value
            : false,
          expireTimes: this.isScheduleAllowed
            ? this.schedulePostDetails.isReQueue === false
              ? null
              : +this.schedulePostDetails.scheduleForm.controls.expireTimes.value
            : null,
          isExpireSpecificDate: this.isScheduleAllowed
            ? this.schedulePostDetails.scheduleForm.controls.isExpireSpecificDate.value === null
              ? false
              : this.schedulePostDetails.scheduleForm.controls.isExpireSpecificDate.value
            : false,

          expireSpecificDate: this.isScheduleAllowed
            ? this.schedulePostDetails.isReQueue === false
              ? null
              : this.datepipe.transform(
                  new Date(this.schedulePostDetails.scheduleForm.controls.expireSpecificDate.value),
                  'yyyy-MM-ddTHH:mm:ss',
                )
            : null,
          socialAccounts: selectedAccountsId,
          schedules: scheduleTimeDataArr,
          isCustomizedPost: this.isProfileCustomize,
          hashtagIds: hastagIds,
        };
      }
    } catch (error) {
      this.spinnerService.hide();

      return false;
    }

    return true;
  }

  savePost(): void {
    try {
      if (this.isScheduleAllowed) {
        this.schedulePostDetails = this.schedulePostDetails
          ? this.schedulePostDetails
          : this.tempSchedulePostDetails;
      }

      if (this.checkValidations()) {
        this.spinnerService.show();
        const selectedAccounts = this.accountDetails.filter(x => x.isSelected === true)[0];
        if (selectedAccounts) {
          if (this.isProfileCustomize) {
            this.customizeMediaUploadImage(selectedAccounts);
          } else {
            const mediaUrlRequests = selectedAccounts.uploadedImages.map(element => {
              if (this.commonService.isGIF(element.fileExtension)) {
                return this.commonService.convertGifToBlob(element.base64Value);
              } else if (this.commonService.isVideo(element.fileExtension)) {
                const base64Value = this.commonService.removeBase64Prefix(element.base64Value);
                const videoBlob = this.commonService.convertVideoToBlob(
                  base64Value,
                  element.fileExtension,
                );
                return of(videoBlob);
              } else {
                return this.commonService.getImageBlob(element.base64Value);
              }
            });
            if (mediaUrlRequests.length > 0) {
              forkJoin(mediaUrlRequests).subscribe(
                (blobs: Blob[]) => {
                  const formData = new FormData();
                  blobs.forEach((blob, index) => {
                    if (this.commonService.isVideo(blob.type)) {
                      formData.append('files', blob, `files${index}.mp4`);
                    } else {
                      formData.append('files', blob, `files${index}.png`);
                    }
                  });
                  this.fileService.saveMultipleFiles(formData).subscribe(uploadedfileList => {
                    this.createPost(selectedAccounts, uploadedfileList);
                  });
                },
                error => {
                  this.spinnerService.hide();
                },
              );
            } else {
              this.createPost(selectedAccounts, []);
            }
          }
        }
      }
    } catch (error) {
      this.spinnerService.hide();
      this.toasterService.error(
        NotificationMessage.errorMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
    }
  }

  openSharePostDialog(message: string): void {
    const dialogRef = this.dialogService.open({
      content: CreatePostSuccessfullyPopupComponent,
      width: 512,
    });
    const sharPostInfo = dialogRef.content.instance as CreatePostSuccessfullyPopupComponent;
    sharPostInfo.message = message;
    setTimeout(() => {
      dialogRef.close();
    }, 4000);
  }

  openSuccessfulDraftPost(): void {
    const successDraftMessage = NotificationMessage.successfulDraftPost;
    this.openSharePostDialog(successDraftMessage);
  }

  openSuccessfulApprovePost(): void {
    const successApproveMessage = NotificationMessage.successfulApprovePost;
    this.openSharePostDialog(successApproveMessage);
  }

  onActionForEditorClick(event, accountData) {
    const applyQuillOperation = (caption: string) => {
      return event.openTag + caption + event.closeTag;
    };

    if (event.id !== 4 && event.id !== 5) {
      this.quillEditorData['quillData'] = applyQuillOperation(accountData.caption);

      this.accountDetails = this.accountDetails.map(ad => ({
        ...ad,
        caption: applyQuillOperation(ad.caption),
      }));

      this.triggerDataToPreview.emit(this.accountDetails);
    } else if (event.id === 5) {
      this.onUnsplashClick(accountData);
    } else {
      this.onGIFClick(accountData);
    }
  }

  onActionForCustomizeEditorClick(event, accountData) {
    const applyQuillOperation = (caption: string) => {
      return event.openTag + caption + event.closeTag;
    };

    let getSelectedIds = this.accountDetails
      .filter(element => element.isSelected)
      .map(element => element.id);

    if (event.id !== 4 && event.id !== 5) {
      this.accountDetails.forEach(element => {
        if (element.socialId === accountData.socialId) {
          this.quillEditorData['quillData'] = applyQuillOperation(accountData.caption);

          this.accountDetails = this.accountDetails.map(ad => ({
            ...ad,
            caption: applyQuillOperation(ad.caption),
          }));
          const quillIndex = getSelectedIds.findIndex(
            selectedElement => selectedElement === element.id,
          );
          if (quillIndex !== -1) {
            const quillInstance = this.quillEditors.toArray()[quillIndex].quillEditor;
            quillInstance.clipboard.dangerouslyPasteHTML(element.caption);
          }
        }
      });

      this.triggerDataToPreview.emit(this.accountDetails);
    } else if (event.id === 5) {
      this.onUnsplashClick(accountData);
    } else {
      this.onGIFClick(accountData);
    }
  }

  removeHashtag(selectedValues: any, accountData?: any) {
    const removeHashtagFromCaption = (caption: string, hashtags: string) => {
      return caption.replace(hashtags, ' ');
    };

    if (accountData === undefined) {
      this.accountDetails.forEach(account => {
        if (account.caption.includes(selectedValues.dataItem.hashtags)) {
          account.caption = removeHashtagFromCaption(
            account.caption,
            selectedValues.dataItem.hashtags,
          );
        }
      });
    } else {
      const selectedIds = this.accountDetails
        .filter(element => element.isSelected)
        .map(element => element.id);

      this.accountDetails.forEach(element => {
        if (element.socialId === accountData.socialId) {
          element.caption = removeHashtagFromCaption(
            element.caption,
            selectedValues.dataItem.hashtags,
          );
        }

        const quillIndex = selectedIds.findIndex(selectedElement => selectedElement === element.id);

        if (quillIndex !== -1) {
          const quillInstance = this.quillEditors.toArray()[quillIndex].quillEditor;
          quillInstance.setText(element.caption);
        }
      });
    }

    this.triggerDataToPreview.emit(this.accountDetails);
  }

  getVideoCategoriesList() {
    this.youtubeService.getVideoCategoriesList().subscribe(res => {
      this.youtubeCategoriesList = res;
      this.selectedYoutubeCategoryValue = res[0];
    });
  }

  setYoutubeShortsFormValueOnEdit(data) {
    this.selectedYoutubeCategoryValue = data.youtubeCategoryId;
    this.selectedYoutubeVisblityValue = data.youtubePrivacyStatus;
    this.selectedYoutubeLicenceValue = data.youtubeIsLicensedContent;
    this.youTubeShortsForm.patchValue({
      title: data.youtubeTitle === null ? '' : data.youtubeTitle,
      notifySubscribers: data.youtubeIsNotifySubscribers,
      allowEmbedding: data.youtubeIsEmbeddable,
      madeForKids: data.youtubeIsMadeForKids,
    });
  }

  youTubeSelectionValidation(selectedAccount) {
    if (selectedAccount.length === 1) {
      this.isYouTubeAccountSelected = true;
      this.getVideoCategoriesList();
    } else {
      this.isYouTubeAccountSelected = false;
    }
  }

  youTubeContentValidation(index: number, accountData: any) {
    const isImageGifSelected = accountData.some(
      x => this.commonService.isImage(x.value) || this.commonService.isGIF(x.value),
    );

    if (this.isYouTubeAccountSelected && isImageGifSelected) {
      if (!this.isProfileCustomize) {
        this.accountDetails[index].isSelected = false;
        this.isYouTubeAccountSelected = false;
        this.selectedAccountCount = this.accountDetails.filter(x => x.isSelected === true).length;
        this.getCreditInUse();
        this.toasterService.error(
          NotificationMessage.youTubeContentValidationMsg,
          '',
          this.commonService.toasterMessageConfiguration,
        );
      } else {
        this.accountDetails[index].uploadedImages = this.accountDetails[
          index
        ].uploadedImages.filter(x => this.commonService.isVideo(x.value));
        return this.toasterService.error(
          NotificationMessage.youTubeContentValidationMsg,
          '',
          this.commonService.toasterMessageConfiguration,
        );
      }
    }
  }
}
