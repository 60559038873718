import { mapEnumToOptions } from '@abp/ng.core';

export enum UnsplashColorFilterTypes {
  'None' = 0,
  'BlackAndWhite' = 1,
  'Black' = 2,
  'White' = 3,
  'Yellow' = 4,
  'Orange' = 5,
  'Red' = 6,
  'Purple' = 7,
  'Magenta' = 8,
  'Green' = 9,
  'Teal' = 10,
  'Blue' = 11,
}
export const FilterTypesOptions = mapEnumToOptions(UnsplashColorFilterTypes);
