import type { CreateIndividualCheckout, HostedPage } from './dtos/checkout/models';
import type { Customer, CustomerInfo } from './dtos/customer/models';
import type { DownloadModel } from './dtos/models';
import type {
  PaymentHistoryResponseModel,
  RetrieveTransactions,
} from './dtos/payment-history/models';
import type { SubscriptionModel, TenantSubscriptionModel } from './dtos/subscription/models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChargeBeeService {
  apiName = 'Default';

  createCheckoutForAddonsSubscriptionByCustomerSubscription = (
    customerSubscription: CreateIndividualCheckout,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, HostedPage>(
      {
        method: 'POST',
        url: '/api/app/charge-bee/checkout-for-addons-subscription',
        body: customerSubscription,
      },
      { apiName: this.apiName, ...config },
    );

  createCheckoutForExistingSubscription = (
    customerSubscription: CreateIndividualCheckout,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, HostedPage>(
      {
        method: 'POST',
        url: '/api/app/charge-bee/checkout-for-existing-subscription',
        body: customerSubscription,
      },
      { apiName: this.apiName, ...config },
    );

  createCheckoutForIndividualSubscription = (
    customerSubscription: CreateIndividualCheckout,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, HostedPage>(
      {
        method: 'POST',
        url: '/api/app/charge-bee/checkout-for-individual-subscription',
        body: customerSubscription,
      },
      { apiName: this.apiName, ...config },
    );

  createCustomer = (customerDetails: CustomerInfo, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Customer>(
      {
        method: 'POST',
        url: '/api/app/charge-bee/customer',
        body: customerDetails,
      },
      { apiName: this.apiName, ...config },
    );

  getTenantSubscription = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, SubscriptionModel>(
      {
        method: 'GET',
        url: '/api/app/charge-bee/tenant-subscription',
      },
      { apiName: this.apiName, ...config },
    );

  getTenantSubscriptionPlan = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, TenantSubscriptionModel>(
      {
        method: 'GET',
        url: '/api/app/charge-bee/tenant-subscription-plan',
      },
      { apiName: this.apiName, ...config },
    );

  pdfInvoice = (invoiceId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadModel>(
      {
        method: 'POST',
        url: `/api/app/charge-bee/pdf-invoice/${invoiceId}`,
      },
      { apiName: this.apiName, ...config },
    );

  retrieveAddonsList = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, object>(
      {
        method: 'POST',
        url: '/api/app/charge-bee/retrieve-addons-list',
      },
      { apiName: this.apiName, ...config },
    );

  retrieveItemsList = (frequency: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, object>(
      {
        method: 'POST',
        url: '/api/app/charge-bee/retrieve-items-list',
        params: { frequency },
      },
      { apiName: this.apiName, ...config },
    );

  retrievingPaymentHistory = (transaction: RetrieveTransactions, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PaymentHistoryResponseModel[]>(
      {
        method: 'POST',
        url: '/api/app/charge-bee/retrieving-payment-history',
        body: transaction,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
