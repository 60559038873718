<div class="q-content account-card-container p-0">
  <div class="q-card account-card">
    <div class="">
      <div class="q-card-header d-flex flex-column">
        <p class="user-name">
          Great going,{{ profileDetails.name }}!
          <img src="{{ profileDetails.url }}" alt="fun" height="34px" width="34px" />
        </p>
        <p>You have successfully added your {{ accountTypeValue }} account.</p>
      </div>
      <div class="q-card-body user-account pb-0">
        <div class="account-details">
          <p>Account Details:</p>
          <div class="acoount-description d-flex-row">
            <div class="user-deatils d-flex-col-md-6 d-flex-col-xs-12 d-flex-col d-flex gap-30">
              <div class="account-icon">
                <i
                  [ngClass]="{
                    'instagram-icon-color':
                      commonService.getIconClass(accountType) === 'fa-instagram-square',
                    'text-linked-in-icon-color':
                      commonService.getIconClass(accountType) === 'fa-linkedin-in',
                    'text-twitter-icon-color':
                      commonService.getIconClass(accountType) === 'fa-x-twitter',
                    'text-facebook-icon-color':
                      commonService.getIconClass(accountType) === 'fa-facebook-f',
                    'text-youtube-icon-color':
                      commonService.getIconClass(accountType) === 'fa-youtube'
                  }"
                  class="fab {{ commonService.getIconClass(accountType) }}"
                ></i>
              </div>
              <div class="d-flex gap-10">
                <img src="{{ profileDetails.url }}" alt="user" height="34px" width="34px" />
                <div class="d-flex flex-column justify-content-center">
                  <span class="name">{{ profileDetails.name }}</span>
                  <span class="user-pages">{{ accountList.length }} profile found</span>
                </div>
              </div>
            </div>
            <!-- <div class="post-details d-flex-col-md-6 d-flex-col-xs-12 d-flex-col d-flex justify-content-end align-items-center">
              <span>Added on : 22-Feb-2024</span>
            </div> -->
          </div>
        </div>
        <div class="account-pages">
          <div class="q-card-header">
            <p class="mb-0">Please select from the following :</p>
          </div>
          <div class="q-card-header d-flex flex-column user-account-pages">
            <div
              *ngFor="let sa of accountList; let i = index"
              class="d-flex align-items-center gap-20"
            >
              <div>
                <input type="checkbox" kendoCheckBox [(ngModel)]="sa.isSelected" />
              </div>
              <div class="d-flex gap-10">
                <img src="{{ sa.profile_picture_url }}" alt="user" height="34px" width="34px" />
                <div class="d-flex flex-column justify-content-center">
                  <span class="name"> {{ sa.name }}</span>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-center align-items-center"
              *ngIf="accountList.length === 0"
            >
              <img src="assets/images/page-not-found.png" alt="not-found" />
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between action-button align-items-center">
          <a (click)="redirectToDashbopard()"
            ><i class="far fa-arrow-left mr-10"></i>Go to Dashboard</a
          >
          <button kendoButton (click)="onAddAccount()">Add Account</button>
        </div>
      </div>
    </div>
  </div>
</div>
