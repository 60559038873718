import type {
  GetPhotoInputParameters,
  GetPhotoResponse,
  GetSearchPhotoParameters,
  GetSearchPhotoResponse,
  PostRandomPhotosParameters,
} from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UnSplashService {
  apiName = 'Default';

  postListPhotos = (parameters: GetPhotoInputParameters, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GetPhotoResponse[]>(
      {
        method: 'POST',
        url: '/api/app/un-splash/list-photos',
        body: parameters,
      },
      { apiName: this.apiName, ...config },
    );

  postPhotosById = (photoId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GetPhotoResponse>(
      {
        method: 'POST',
        url: `/api/app/un-splash/photos-by-id/${photoId}`,
      },
      { apiName: this.apiName, ...config },
    );

  postRandomPhotos = (parameters: PostRandomPhotosParameters, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GetPhotoResponse[]>(
      {
        method: 'POST',
        url: '/api/app/un-splash/random-photos',
        body: parameters,
      },
      { apiName: this.apiName, ...config },
    );

  postSearchPhotos = (parameters: GetSearchPhotoParameters, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GetSearchPhotoResponse>(
      {
        method: 'POST',
        url: '/api/app/un-splash/search-photos',
        body: parameters,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
