import { Component, OnInit } from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { ChargeBeeService } from '@proxy/charge-bee';
import { CreateIndividualCheckout } from '@proxy/charge-bee/dtos/checkout';
import { SubscriptionPopupComponent } from '../subscription-popup/subscription-popup.component';
import { DomSanitizer } from '@angular/platform-browser';
import { Frequency, PlanName } from 'src/core/enums/chargbee-enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { Select } from '@ngxs/store';
import { CommonState } from 'src/core/state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss'],
})
export class SubscriptionPlanComponent implements OnInit {
  planName = PlanName;
  frequency = Frequency.Month;
  frequencyName = `${Frequency[Frequency.Month]}ly`;

  frequencyEnum = Frequency;
  subscriptionDetailData: any;
  checkOutPayload: CreateIndividualCheckout;

  @Select(CommonState.isScheduleAllowed)
  isScheduleAllowed$: Observable<any>;

  @Select(CommonState.subscriptionPlanData)
  subscriptionPlanData$: Observable<any>;

  activePlanFrequency: any;
  activeCurrentPlanName: any;

  constructor(
    private chargeBeeService: ChargeBeeService,
    private dialogService: DialogService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.getSubscriptionList();

    this.subscriptionPlanData$.subscribe(res => {
      if (res !== undefined && res !== null) {
        this.activeCurrentPlanName = res.currentPlanName;
        this.frequency = res.frequency;
        this.activePlanFrequency = res.frequency;
      }
    });
  }

  getSubscriptionList(): void {
    this.spinner.show();
    this.chargeBeeService.retrieveItemsList(this.frequency).subscribe(
      res => {
        this.spinner.hide();
        this.subscriptionDetailData = res;
      },
      error => {
        this.spinner.hide();
      },
    );
  }

  onBuyNowClick(item: any, isUpgrade: boolean): void {
    this.checkOutPayload = {
      planPriceId: item.plan.id,
      redirectionUrl: window.location.origin + '/subscription-plan',
      cancelUrl: window.location.origin + '/subscription-plan',
      frequency: this.frequency,
      addonPriceId: [],
    };
    if (isUpgrade) {
      this.chargeBeeService
        .createCheckoutForExistingSubscription(this.checkOutPayload)
        .subscribe(res => {
          const dialogRef = this.dialogService.open({
            content: SubscriptionPopupComponent,
          });
          const url = this.sanitizer.bypassSecurityTrustResourceUrl(res.url);
          dialogRef.content.instance.url = url;
        });
    } else {
      this.chargeBeeService
        .createCheckoutForIndividualSubscription(this.checkOutPayload)
        .subscribe(res => {
          const dialogRef = this.dialogService.open({
            content: SubscriptionPopupComponent,
          });
          const url = this.sanitizer.bypassSecurityTrustResourceUrl(res.url);
          dialogRef.content.instance.url = url;
        });
    }
  }

  onFrequencyToggle(frequency: any): void {
    this.frequency = frequency;
    this.frequencyName =
      this.frequency === Frequency.Month
        ? `${Frequency[Frequency.Month]}ly`
        : `${Frequency[Frequency.Year]}ly`;
    this.getSubscriptionList();
  }
}
