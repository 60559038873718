import type { PostStatus } from '../../enums/post-status.enum';
import type { YoutubePrivacyStatus } from '../../enums/youtube-privacy-status.enum';
import type { YoutubeLicenceEnums } from '../../enums/youtube-licence-enums.enum';
import type { SavePostAccountDto } from '../../post-accounts/dtos/models';
import type { ScheduleDto, ScheduleReadListDto } from '../../schedules/dtos/models';
import type { FullAuditedEntityDto, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import type { MediaURLModel } from '../../common/models/models';
import type { SocialAccountPostReadDto } from '../../social-account/dtos/models';
import type { SocialAccountType } from '../../enums/social-account-type.enum';

export interface CategoryPostListInputDto {
  categoryId: number;
  postStatus: PostStatus;
}

export interface CreateUpdatePostDto {
  message?: string;
  mediaUrls: string[];
  categoryId?: number;
  isDraft: boolean;
  isReQueue: boolean;
  isExpireAfter: boolean;
  expireTimes?: number;
  isExpireSpecificDate: boolean;
  expireSpecificDate?: string;
  isCustomizedPost: boolean;
  noOfTimesPosted?: number;
  hashtagIds: number[];
  youtubeCategoryId?: number;
  youtubePrivacyStatus?: YoutubePrivacyStatus;
  youtubeTitle?: string;
  youtubeIsLicensedContent?: YoutubeLicenceEnums;
  youtubeIsNotifySubscribers?: boolean;
  youtubeIsEmbeddable?: boolean;
  youtubeIsMadeForKids?: boolean;
  socialAccounts: SavePostAccountDto[];
  schedules: ScheduleDto[];
}

export interface PostCategoryReadDto extends FullAuditedEntityDto<number> {
  message?: string;
  mediaUrls: MediaURLModel[];
  categoryId?: number;
  isDraft: boolean;
  isReQueue: boolean;
  isExpireAfter: boolean;
  expireTimes?: number;
  noOfTimesPosted?: number;
  isExpireSpecificDate: boolean;
  expireSpecificDate?: string;
  categoryName?: string;
  categoryDescription?: string;
  categoryIsActive?: boolean;
  isCustomizedPost: boolean;
  socialAccounts: SocialAccountPostReadDto[];
  schedules: ScheduleReadListDto[];
}

export interface PostDeleteResponseDto {
  socialAccountType: SocialAccountType;
  status: boolean;
}

export interface PostDto extends FullAuditedEntityDto<number> {
  message?: string;
  mediaUrls: MediaURLModel[];
  categoryId?: number;
  isDraft: boolean;
  isReQueue: boolean;
  isExpireAfter: boolean;
  expireTimes?: number;
  noOfTimesPosted?: number;
  isExpireSpecificDate: boolean;
  expireSpecificDate?: string;
  isCustomizedPost: boolean;
  hashtagIds: number[];
  youtubeCategoryId?: number;
  youtubePrivacyStatus?: YoutubePrivacyStatus;
  youtubeTitle?: string;
  youtubeIsLicensedContent: YoutubeLicenceEnums;
  youtubeIsNotifySubscribers: boolean;
  youtubeIsEmbeddable: boolean;
  youtubeIsMadeForKids: boolean;
  socialAccounts: SocialAccountPostReadDto[];
  schedules: ScheduleDto[];
}

export interface PostGetListInput extends PagedAndSortedResultRequestDto {
  message?: string;
  mediaUrls: string[];
  categoryId?: number;
  isDraft?: boolean;
  isReQueue?: boolean;
  isExpireAfter?: boolean;
  expireTimes?: number;
  isExpireSpecificDate?: boolean;
  expireSpecificDate?: string;
  isCustomizedPost?: boolean;
  hashtagIds: number[];
}

export interface PostSocialAccountDto extends FullAuditedEntityDto<number> {
  message?: string;
  mediaUrls: MediaURLModel[];
  categoryId?: number;
  isDraft: boolean;
  isReQueue: boolean;
  isExpireAfter: boolean;
  expireTimes?: number;
  isExpireSpecificDate: boolean;
  expireSpecificDate?: string;
  isCustomizedPost: boolean;
  socialAccounts: SocialAccountPostReadDto[];
  schedules: ScheduleReadListDto[];
}

export interface SocialAccountPostListInputDto {
  socialAccountId: number;
  postStatus: PostStatus;
}
