import type { CategoryDto, CategoryListDto, CreateUpdateCategoryDto } from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  apiName = 'Default';

  create = (input: CreateUpdateCategoryDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CategoryDto>(
      {
        method: 'POST',
        url: '/api/app/category',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/app/category/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CategoryDto>(
      {
        method: 'GET',
        url: `/api/app/category/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getAll = (categoryListDto: CategoryListDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CategoryDto[]>(
      {
        method: 'GET',
        url: '/api/app/category',
        params: { postStatus: categoryListDto.postStatus },
      },
      { apiName: this.apiName, ...config },
    );

  getListByStatus = (isActive: boolean, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CategoryDto[]>(
      {
        method: 'GET',
        url: '/api/app/category/by-status',
        params: { isActive },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: CreateUpdateCategoryDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CategoryDto>(
      {
        method: 'PUT',
        url: `/api/app/category/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
