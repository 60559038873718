import { HttpHandler, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { finalize } from 'rxjs/operators';
import { SessionStateService, HttpWaitService, TENANT_KEY } from '@abp/ng.core';

@Injectable({
  providedIn: 'root',
})
export class QMarketApiInterceptor {
  constructor(
    private oAuthService: OAuthService,
    private sessionState: SessionStateService,
    private httpWaitService: HttpWaitService,
    @Inject(TENANT_KEY) private tenantKey: string,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    this.httpWaitService.addRequest(request);
    const newRequest = request.clone({
      setHeaders: this.getAdditionalHeaders(request),
    });

    return next
      .handle(newRequest)
      .pipe(finalize(() => this.httpWaitService.deleteRequest(request)));
  }

  getAdditionalHeaders(request: HttpRequest<any>): any {
    const existingHeaders = request.headers;
    const headers = {} as any;

    const token = this.oAuthService.getAccessToken();
    if (!existingHeaders?.has('Authorization') && token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const lang = this.sessionState.getLanguage();
    if (!existingHeaders?.has('Accept-Language') && lang) {
      headers['Accept-Language'] = lang;
    }

    const tenant = this.sessionState.getTenant();
    if (
      !existingHeaders?.has(this.tenantKey) &&
      tenant?.id &&
      !request.url.startsWith('https://keycloak-dev.mysandboxsite.com')
    ) {
      headers[this.tenantKey] = tenant.id;
    }

    headers['X-Requested-With'] = 'XMLHttpRequest';

    return headers;
  }
}
