import { mapEnumToOptions } from '@abp/ng.core';

export enum OfflinePaymentMethodEnum {
  UnKnown = 0,
  NoPreference = 1,
  Cash = 2,
  Check = 3,
  BankTransfer = 4,
  AchCredit = 5,
  SepaCredit = 6,
  Boleto = 7,
}

export const offlinePaymentMethodEnumOptions = mapEnumToOptions(OfflinePaymentMethodEnum);
