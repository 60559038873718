import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ListService } from '@abp/ng.core';
import { Observable, Subject, debounceTime, takeUntil } from 'rxjs';
import { CommonService, TOOLTIP } from 'src/app';
import { DateTimeModel } from 'src/core/Models/date-time.model';
import { DatePipe } from '@angular/common';
import { DateTimePickerComponent } from 'src/core/directive/datetime-picker.component';
import { SVGIcon, infoCircleIcon, plusIcon, trashIcon } from '@progress/kendo-svg-icons';
import { drawing } from '@progress/kendo-drawing';
import { PopupSettings } from '@progress/kendo-angular-dateinputs';

@Component({
  selector: 'app-schedule-post',
  templateUrl: './schedule-post.component.html',
  styleUrls: ['./schedule-post.component.scss'],
})
export class SchedulePostComponent implements OnInit, OnDestroy {
  isReQueue = false;
  isUpdateSchedule = false;
  displayStyle = 'none';
  showDatePicker: boolean = false;
  scheduleForm: FormGroup;
  tooltipText = TOOLTIP;
  expireDateTimeString: any; //REMOVE
  editScheduleData: any;
  expireSpecificDateTemp: any;

  categoriesList: any[];
  previewDatePickerDate: any[] = [];
  tempDateTimeArray: Array<any> = [];
  tempDateExpireTimeArray: Array<any> = [];

  public icons = {
    infoclip: infoCircleIcon,
    trashclip: trashIcon,
    plusIocn: plusIcon,
  };

  public popupSettings: PopupSettings = {
    appendTo: 'component',
  };

  public format = 'MM/dd/yyyy HH:mm';
  public plusSVG: SVGIcon = plusIcon;

  public disabledDates = (date: Date): boolean => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return date < currentDate;
  };

  public disablePreviewDatePicker = (date: Date): boolean => {
    return true;
  };

  @Input() scheduleData: string;
  @Input() isSchedulerPopupView: any;

  @ViewChild('scheduledModal') myModalContent;
  @ViewChild('myDateTimePicker') dateTimePicker: DateTimePickerComponent;

  @Input() catergoryDetails: any;
  @Input() triggerEditPostData: Observable<any>;
  @Input() triggerScheduleDateFromCalendar: any;
  @Input() scheduleEditScheduleId: any;

  @Output()
  readonly isNewSchedule = new EventEmitter<any>();

  @ViewChild('dateTimePicker', { static: false }) dateTimePickers;
  checked: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(
    public readonly list: ListService,
    private commonService: CommonService,
    public datepipe: DatePipe,
  ) {}

  ngOnInit(): void {
    if (this.scheduleData !== undefined) {
      this.tempDateTimeArray.push({
        id: 0,
        date: this.commonService.addTimeToDate(this.scheduleData),
      });
    }
    this.setForm();
    if (this.triggerScheduleDateFromCalendar !== undefined) {
      let datetime = new Date(this.triggerScheduleDateFromCalendar);
      datetime.setMinutes(datetime.getMinutes() + 10);
      this.tempDateTimeArray.push({
        id: 0,
        date: new Date(datetime),
      });
      this.addDateInPreviewSchedule(this.tempDateTimeArray);
    } else {
      this.tempDateTimeArray.push({ id: 0 });
    }

    this.triggerEditPostData?.pipe(debounceTime(700), takeUntil(this.destroy$)).subscribe(data => {
      this.editScheduleData = data;
      this.setEditData(data);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openDatePicker(dateTimePicker): void {
    // Open the date picker selection programmatically
    if (dateTimePicker) {
      dateTimePicker.toggle();
    }
  }

  resetData(data: any): void {
    this.tempDateTimeArray = [];
    this.isReQueue = data.isReQueue;
    this.scheduleForm = data.scheduleForm;
    this.tempDateTimeArray = data.tempDateTimeArray;
    this.expireSpecificDateTemp = data.expireSpecificDateTemp;
    this.addDateInPreviewSchedule(this.tempDateTimeArray);
    setTimeout(() => {
      this.setValidation(this.isReQueue);
    }, 0);
  }

  setEditData(data: any): void {
    this.tempDateTimeArray = [];
    this.isReQueue = data.isReQueue;

    if (data.schedules.length > 0) {
      data.schedules.forEach(element => {
        this.tempDateTimeArray.push({
          id: element.id,
          date: new Date(element.scheduleTime),
        });
      });
    }
    if (this.tempDateTimeArray.length === 0) {
      this.tempDateTimeArray.push({ id: 0 });
    } else {
      this.addDateInPreviewSchedule(this.tempDateTimeArray);
    }

    setTimeout(() => {
      this.setValidation(this.isReQueue);
    }, 0);
    this.scheduleForm.patchValue({
      isExpireAfter: data.isExpireAfter,
      isExpireSpecificDate: data.isExpireSpecificDate,
      expireTimes: data.expireTimes,
      expireSpecificDate:
        data.expireSpecificDate !== undefined && data.expireSpecificDate !== null
          ? this.datepipe.transform(
              new Date(data.expireSpecificDate),
              "EEE MMM dd yyyy HH:mm:ss 'GMT'ZZZZZ",
            )
          : new Date(),
    });

    this.expireSpecificDateTemp =
      data.expireSpecificDate !== undefined && data.expireSpecificDate !== null
        ? this.datepipe.transform(
            new Date(data.expireSpecificDate),
            "EEE MMM dd yyyy HH:mm:ss 'GMT'ZZZZZ",
          )
        : new Date();
  }

  onSwitchChange(event: boolean): void {
    this.setValidation(event);
  }

  setValidation(isEnable: boolean): void {
    if (isEnable) {
      this.scheduleForm.controls.isExpireAfter.enable();
      this.scheduleForm.controls.expireTimes.enable();
      this.scheduleForm.controls.isExpireSpecificDate.enable();
      this.scheduleForm.controls.expireSpecificDate.enable();
    } else {
      this.scheduleForm.controls.isExpireAfter.disable();
      this.scheduleForm.controls.expireTimes.disable();
      this.scheduleForm.controls.isExpireSpecificDate.disable();
      this.scheduleForm.controls.expireSpecificDate.disable();
    }
  }

  setForm(): void {
    this.scheduleForm = new FormGroup({
      postNow: new FormControl(''),
      isExpireAfter: new FormControl({ value: false, disabled: true }),
      isExpireSpecificDate: new FormControl({ value: false, disabled: true }),
      expireTimes: new FormControl({ value: null, disabled: true }),
      expireSpecificDate: new FormControl({ value: new Date(), disabled: true }),
    });
  }

  createDateTimeFormPicker(): any {
    return new FormGroup({
      year: new FormControl(null),
      month: new FormControl(null),
      day: new FormControl(null),
      hour: new FormControl(null),
      minute: new FormControl(null),
      second: new FormControl(null),
    });
  }

  addDateInPreviewSchedule(dateTimeArray) {
    const datePipe = new DatePipe('en-US');
    this.previewDatePickerDate = [];
    dateTimeArray.forEach(element => {
      if (element.date !== undefined) {
        const formattedDate = datePipe.transform(
          new Date(element.date),
          'yyyy-MM-ddTHH:mm:ss.SSSZ',
        );

        this.previewDatePickerDate.push(formattedDate);
      }
    });

    this.previewDatePickerDate = this.previewDatePickerDate.map(dateStr => new Date(dateStr));
  }

  onDateTimeChange(selectedDate: Date, index: number) {
    this.tempDateTimeArray[index].date = selectedDate;
    this.addDateInPreviewSchedule(this.tempDateTimeArray);
  }

  onDeletePost(i): void {
    this.tempDateTimeArray.splice(i, 1);
    if (this.tempDateTimeArray.length === 0) {
      setTimeout(() => {
        this.tempDateTimeArray.push({ id: 0 });
      }, 100);
    }

    this.addDateInPreviewSchedule(this.tempDateTimeArray);

    if (this.tempDateTimeArray.length > 0) {
      this.isNewSchedule.emit(true);
    }
  }

  onlyNumericValueValidator(event) {
    const regex = /[0-9]/g;

    if (!event.key.match(regex)) {
      event.preventDefault();
    }
  }

  convertStringToDateFormat(input: string): DateTimeModel {
    let splitDate = input.split(/[-+:T]/);

    let formatedDate: DateTimeModel = {
      year: +splitDate[0],
      month: +splitDate[1],
      day: +splitDate[2],
      hour: +splitDate[3],
      minute: +splitDate[4],
      second: +splitDate[5],
      timeZoneOffset: +splitDate[6],
    };
    return formatedDate;
  }

  addDatePicker() {
    let datetime = new Date();
    datetime.setMinutes(datetime.getMinutes() + 10);
    this.tempDateTimeArray.push({ id: 0, date: datetime });
    this.addDateInPreviewSchedule(this.tempDateTimeArray);
    this.isNewSchedule.emit(true);
  }

  customVisual = (args: any): any => {
    return this.createColumn(args.rect, args.options.color);
  };

  public createColumn(rect: any, color: string): any {
    var origin = rect.origin;
    var bottomRight = rect.bottomRight();
    var radiusX = rect.width() / 2;
    var radiusY = radiusX / 8;
    var gradient = new drawing.LinearGradient({
      stops: [
        new drawing.GradientStop(0, color),
        new drawing.GradientStop(0.5, color, 0.9),
        new drawing.GradientStop(0.5, color, 0.9),
        new drawing.GradientStop(1, color),
      ],
    });

    var path = new drawing.Path({
      fill: gradient,
      stroke: {
        color: 'none',
      },
    })
      .moveTo(origin.x, origin.y)
      .lineTo(origin.x, bottomRight.y)
      .lineTo(origin.x + rect.width(), bottomRight.y)
      .lineTo(bottomRight.x, origin.y)
      .arc(0, 170, radiusX, radiusY * 6, true);

    var group = new drawing.Group();
    group.append(path);
    return group;
  }
}
