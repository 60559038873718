<nb-card *ngIf="this.visible">
  <nb-card-header class="d-flex align-items-center">
    {{ 'AbpFeatureManagement::Features' | abpLocalization }}
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <ng-container *ngIf="groups.length">
        <div class="col-md-4">
          <ul
            ngbNav
            #nav="ngbNav"
            [(activeId)]="selectedGroupDisplayName"
            class="nav-pills"
            orientation="vertical"
          >
            <li
              *ngFor="let group of groups; trackBy: track.by('name')"
              [ngbNavItem]="group.displayName"
            >
              <a ngbNavLink>{{ group.displayName }}</a>
              <ng-template ngbNavContent>
                <h4>{{ selectedGroupDisplayName }}</h4>
                <hr class="mt-2 mb-3" />

                <div
                  class="mt-2"
                  *ngFor="
                    let feature of features[group.name];
                    let i = index;
                    trackBy: track.by('id')
                  "
                  [ngStyle]="feature.style"
                  [ngSwitch]="feature.valueType?.name"
                  (keyup.enter)="save()"
                >
                  <ng-container *ngSwitchCase="valueTypes.ToggleStringValueType">
                    <div class="form-check">
                      <nb-checkbox
                        [id]="feature.name"
                        [checked]="feature.value"
                        (ngModelChange)="onCheckboxClick($event, feature)"
                      >
                        <label class="form-check-label" [htmlFor]="feature.name">{{
                          feature.displayName
                        }}</label>
                        <ng-container
                          *ngTemplateOutlet="descTmp; context: { $implicit: feature.description }"
                        ></ng-container>
                      </nb-checkbox>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="valueTypes.FreeTextStringValueType">
                    <div class="mb-3 form-group">
                      <label [htmlFor]="feature.name" class="form-label">{{
                        feature.displayName
                      }}</label>
                      <input
                        class="form-control"
                        type="text"
                        [id]="feature.name"
                        [(ngModel)]="feature.value"
                        [abpFeatureManagementFreeText]="feature"
                      />

                      <ng-container
                        *ngTemplateOutlet="descTmp; context: { $implicit: feature.description }"
                      ></ng-container>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="valueTypes.SelectionStringValueType">
                    <ng-container *ngIf="feature.valueType.itemSource?.items?.length">
                      <div class="mb-3 form-group">
                        <label [htmlFor]="feature.name" class="form-label">{{
                          feature.displayName
                        }}</label>
                        <select class="form-select" [id]="feature.name" [(ngModel)]="feature.value">
                          <option
                            *ngFor="
                              let item of feature.valueType.itemSource?.items;
                              trackBy: track.by('value')
                            "
                            [ngValue]="item.value"
                          >
                            {{
                              item.displayText?.resourceName + '::' + item.displayText?.name
                                | abpLocalization
                            }}
                          </option>
                        </select>
                        <ng-container
                          *ngTemplateOutlet="descTmp; context: { $implicit: feature.description }"
                        ></ng-container>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchDefault>{{ feature.displayName }}</ng-container>
                </div>
              </ng-template>
            </li>
          </ul>
        </div>

        <ng-template #descTmp let-description>
          <small *ngIf="description" class="d-block form-text text-muted"
            >{{ description }} tyty</small
          >
        </ng-template>

        <div class="col-md-8">
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </ng-container>

      <div class="col" *ngIf="!groups.length">
        {{ 'AbpFeatureManagement::NoFeatureFoundMessage' | abpLocalization }}
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button
      *ngIf="groups.length"
      nbButton
      status="success"
      [disabled]="modalBusy"
      (click)="resetToDefault()"
    >
      {{ 'AbpFeatureManagement::ResetToDefault' | abpLocalization }}
    </button>
    <button class="cancel mr-3" nbButton status="danger" (click)="this.visible = false">
      {{ 'AbpFeatureManagement::Cancel' | abpLocalization }}
    </button>
    <button *ngIf="groups.length" nbButton status="success" (click)="save()">
      {{ 'AbpFeatureManagement::Save' | abpLocalization }}
    </button>
  </nb-card-footer>
</nb-card>
