import { mapEnumToOptions } from '@abp/ng.core';

export enum StatusEnum {
  UnKnown = 0,
  Future = 1,
  InTrial = 2,
  Active = 3,
  NonRenewing = 4,
  Paused = 5,
  Cancelled = 6,
}

export const statusEnumOptions = mapEnumToOptions(StatusEnum);
