export enum NotificationMessage {
  addMediaMsg = 'Please add any media to the post for Instagram profile!',
  createPostSuccessMsg = 'Post Created Successfully!',
  updatePostSuccessMsg = 'Post Updated Successfully!',
  deletePostSuccessMsg = 'Post Deleted Successfully!',
  deletePublishPostSuccessMsg = 'Publish Post Deleted Successfully!',
  deletePublishPostErrorMsg = 'Publish Post Not Deleted!',
  deletePublishInstagramErrorMsg = 'Publish Instagram Post cant be Deleted!',
  selectAccountMsg = 'Please Select Account!',
  selectCategoryMsg = 'Please Select Category!',
  imageFileLimitMsg = 'Sorry! You cannot select file more than 10.',
  linkedInImageSizeMsg = 'Sorry! You cannot select file more than 5MB.',
  instagramImageSizeMsg = 'Sorry! You cannot select file more than 8MB.',
  facebookImageSizeMsg = 'Sorry! You cannot select file more than 30MB.',
  twitterImageSizeMsg = 'Sorry! You cannot select file more than 5MB.',
  linkedInVedioSizeMsg = 'Sorry! You cannot select file more than 5GB.',
  InstagramVedioSizeMsg = 'Sorry! You cannot select file more than 650MB.',
  FaceBookVedioSizeMsg = 'Sorry! You cannot select file more than 10GB.',
  captionLimitMsg = 'Sorry! You cannot add more caption.',
  hastagLimitMsg = 'Sorry! hastag cannot be more than 20',
  selectAccountToCustomizeMsg = 'Please select account to customize!',
  createCategorySuccessMsg = 'Category Created Successfully!',
  updateCategorySuccessMsg = 'Category Updated Successfully!',
  deleteCategorySuccessMsg = 'Category Deleted Successfully!',
  noPostMsg = 'No Post to view in this category.',
  schedulePostSuccessMsg = 'Post Scheduled Successfully!',
  updateSchedulePostSuccessMsg = 'Schedule Post Updated Successfully!',
  accountActivatedMsg = 'Account Activated',
  accountDeactivatedMsg = 'Account Deactivated',
  createHastagSuccessMsg = 'Hastag Created Successfully!',
  updateHastagSuccessMsg = 'Hastag Updated Successfully!',
  deleteHastagSuccessMsg = 'Hastag Deleted Successfully!',
  apbFeatureManagementMsg = 'AbpFeatureManagement::ResetToDefault',
  abpUiDeleteSuccessMsg = 'AbpUi::SuccessFully Deleted!',
  expireTimesValueRequired = 'Expire Times or Expires Specific Date anyone value is required!',
  scheduleAndExpireDateMsg = 'The expiration date you selected will make the post expire sooner than your chosen dates to post.',
  emptyEditorValidaitonMsg = 'Please add media/caption to post!',
  errorMsg = 'Opps! There is an error',
  linkedInImageVedioMsg = 'Sorry! You cannot select Image and Vedio at same time.',
  copyMsg = 'Copy Successfully!,',
  emptyHashtagValidaitonMsg = 'Please select hashtag to use!',
  noCreditAvailableMsg = 'Sorry! No credits available. Please recharge your wallet.',
  successfulDraftPost = 'Success! Your post is saved as a draft. You can review and edit it later before approval.',
  successfulApprovePost = 'Success! Your post has been approved and successfully published on your social account.',
  lessCreditAvailableMsg = 'Sorry! Your current credit is less then the credit requires. Please recharge your wallet.',
  youTubeAccountSelectValidationMsg = 'You can select only 1 YouTube Account at a time.',
  youTubeContentValidationMsg = 'You cannot add Image/GIF in YouTube',
  imageMaxSizeLimitMSG = 'Sorry! You cannot select image more than 5MB.',
  videoMaxSizeLimitMSG = 'Sorry! You cannot select video more than 75MB.',
}

export enum PopupMessage {
  postDeleteDeletePopupMsg = 'Are you sure to delete post ?',
  instagramPostDeleteDeletePopupMsg = 'Sorry, Instagram doesnot provide feature to delete the post  Are you sure to delete post for other platform ?',
}
