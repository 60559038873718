import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from '@proxy/categories';
import { ListService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { NotificationMessage } from 'src/core/enums/notification-message';
import { PostStatus } from '@proxy/enums';
import { DialogService } from '@progress/kendo-angular-dialog';
import { CreateCategoryComponent } from '../create-category/create-category.component';
import { PagerType } from '@progress/kendo-angular-pager';
import {
  GridComponent,
  GridDataResult,
  PageChangeEvent,
  PagerPosition,
} from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, process } from '@progress/kendo-data-query';
import { CommonService } from 'src/core/services';

@Component({
  selector: 'app-list-category',
  templateUrl: './list-category.component.html',
  styleUrls: ['./list-category.component.scss'],
})
export class ListCategoryComponent implements OnInit {
  @ViewChild('grid', { static: true }) grid: GridComponent;
  categoriesList: any[];
  originalCategoriesList: any[];

  isListView = true;
  isMobile: boolean = false;
  gridHeight: number;
  selectedPostType = { id: PostStatus.All, name: 'All' };

  postTypeList = [
    { id: PostStatus.All, name: 'All' },
    { id: PostStatus.Approved, name: 'Approved' },
    { id: PostStatus.Draft, name: 'Draft' },
  ];

  searchText = '';

  //#region  Pagination
  public gridView: GridDataResult;
  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public pagerposition: PagerPosition = 'bottom';
  public pageSize = 20;
  public skip = 0;
  sorting = null;
  public multiple = false;
  public allowUnsort = true;
  //#endregion

  public actionMenu = [
    {
      text: 'Edit',
      iconClass: 'far fa-pen',
    },
    {
      text: 'Delete',
      iconClass: 'far fa-trash-alt',
    },
    {
      text: 'Add',
      iconClass: 'far fa-plus',
    },
  ];

  public sort: SortDescriptor[] = [
    {
      field: '',
      dir: 'asc',
    },
  ];

  constructor(
    private router: Router,
    private categoryService: CategoryService,
    public readonly list: ListService,
    private toasterService: ToasterService,
    private dialogService: DialogService,
    public commonService: CommonService,
  ) {}

  ngOnInit(): void {
    this.getList();
    this.checkScreenSize();
    this.calculateGridHeight();

    window.addEventListener('resize', () => {
      this.checkScreenSize();
      this.calculateGridHeight();
    });
  }

  onItemClicked(event): void {
    this.isListView = event.isListView;
  }

  checkScreenSize() {
    this.isMobile = window.innerWidth < 450;
    if (this.isMobile) {
      this.isListView = !this.isMobile;
    }
  }

  getList(): void {
    const data = {
      postStatus: +this.selectedPostType.id,
    };

    this.categoryService.getAll(data).subscribe(res => {
      this.categoriesList = res;
      this.originalCategoriesList = res;
      this.loadItems();
      this.sortCatergory();
    });
  }

  sortCatergory(): void {
    this.categoriesList.sort((a, b) => {
      if (a.name === 'Post With No Category') return -1;
      if (b.name === 'Post With No Category') return 1;
      return a.name.localeCompare(b.name);
    });
  }

  addCategoty(): void {
    const dialogRef = this.dialogService.open({
      content: CreateCategoryComponent,
      width: 450,
    });

    dialogRef.result.subscribe((r: { [key: string]: string }) => {
      this.getList();
    });
  }

  onSearch(event: any): void {
    const filteredListData = process(
      this.originalCategoriesList.slice(this.skip, this.skip + this.pageSize),
      {
        filter: {
          logic: 'or',
          filters: [
            { field: 'name', operator: 'contains', value: this.searchText },
            { field: 'description', operator: 'contains', value: this.searchText },
          ],
        },
      },
    );

    const filteredGridData = process(this.originalCategoriesList, {
      filter: {
        logic: 'or',
        filters: [
          { field: 'name', operator: 'contains', value: this.searchText },
          { field: 'description', operator: 'contains', value: this.searchText },
        ],
      },
    });

    this.gridView.data = filteredListData.data;
    this.categoriesList = filteredGridData.data;
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.searchText = '';
    this.loadItems();
  }

  public loadItems(): void {
    this.gridView = {
      data: this.originalCategoriesList.slice(this.skip, this.skip + this.pageSize),
      total: this.originalCategoriesList.length,
    };
  }

  onViewPost(catergory, postCount: any): void {
    if (postCount > 0) {
      var params = {
        catergoryId: btoa(catergory.id),
        catergoryName: btoa(catergory.name),
        catergoryDesc: btoa(catergory.description),
        postType: this.selectedPostType.id,
      };
      this.router.navigate(['view-post', params]);
    } else {
      this.toasterService.error(
        NotificationMessage.noPostMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
    }
  }

  onAddPost(catergory): void {
    var params = {
      catergoryId: btoa(catergory.id),
      catergoryName: btoa(catergory.catergoryName),
      catergoryDesc: btoa(catergory.catergoryDesc),
    };
    this.router.navigate(['create-post', params]);
  }

  onEditCatergory(id: any): void {
    const dialogRef = this.dialogService.open({
      content: CreateCategoryComponent,
      width: 450,
    });
    dialogRef.content.instance.categoryId = id;

    dialogRef.result.subscribe((r: { [key: string]: string }) => {
      this.getList();
    });
  }

  delete(category: any): void {
    swal({
      title: 'Are you sure?',
      text: 'The category will be deleted. Do you confirm that : ' + category.name + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.categoryService.delete(category.id).subscribe(
          res => {
            this.toasterService.success(
              NotificationMessage.deleteCategorySuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.searchText = '';
            this.getList();
          },
          error => {
            const errorMessage = error.error.error.message || 'Delete Failed';
            this.toasterService.error(errorMessage);
          },
        );
      }
    });
  }

  showListView(isListView): void {
    this.isListView = isListView;
  }

  onSort(event) {
    this.sorting = event.column.prop + ' ' + event.column.newValue;
    this.getList();
  }

  calculateGridHeight(): void {
    const screenHeight = window.innerHeight;
    const headerHeight = 88;
    const gridHeaderHeight = 69;
    const wrapperpadding = 60;

    this.gridHeight = screenHeight - (headerHeight + gridHeaderHeight + wrapperpadding);
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadProducts();
  }

  loadProducts(): void {
    this.gridView = {
      data: orderBy(this.categoriesList, this.sort),
      total: this.categoriesList.length,
    };
  }
}
