import { AuthService, ConfigStateService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { SVGIcon, twitterBoxIcon } from '@progress/kendo-svg-icons';
import { Observable } from 'rxjs';
import { HeaderDataEnum } from 'src/core/enums/header-data-enum';
import { CommonState } from 'src/core/state';

@Component({
  selector: 'app-top-header-navigation',
  templateUrl: './top-header-navigation.component.html',
  styleUrls: ['./top-header-navigation.component.scss'],
})
export class TopHeaderNavigationComponent implements OnInit {
  public headerDataList: Array<{ text: string; value: number }> = [
    { text: HeaderDataEnum[HeaderDataEnum.Subscription], value: HeaderDataEnum.Subscription },
    { text: HeaderDataEnum[HeaderDataEnum.Setting], value: HeaderDataEnum.Setting },
    { text: HeaderDataEnum[HeaderDataEnum['Log out']], value: HeaderDataEnum['Log out'] },
  ];

  currentUser: string;
  availableCredits = 0;
  validDate: Date;

  twitterBoxIcon: SVGIcon = twitterBoxIcon;

  walletPopupshow = false;

  @Select(CommonState.subscriptionPlanData)
  subscriptionPlanData$: Observable<any>;

  constructor(
    public authService: AuthService,
    private router: Router,
    public config: ConfigStateService,
  ) {}

  ngOnInit(): void {
    this.getWalletDetails();
    this.currentUser =
      this.config.getOne('currentUser').name === null
        ? this.config.getOne('currentUser').userName
        : this.config.getOne('currentUser').name;
  }

  onHeaderClick(event) {
    switch (event.value) {
      case HeaderDataEnum.Setting:
        this.router.navigateByUrl('account/manage');
        break;
      case HeaderDataEnum['Log out']:
        this.authService.logout();
        break;
      case HeaderDataEnum.Subscription:
        this.router.navigateByUrl('subscription-plan');
        break;

      default:
        break;
    }
  }

  onWalletClick(): void {
    this.router.navigateByUrl('wallet');
  }

  getWalletDetails(): void {
    this.subscriptionPlanData$.subscribe(res => {
      if (res !== undefined && res !== null) {
        this.availableCredits = res.currentWalletCredit;
        this.validDate = new Date(res.planExpiryDate);
      }
    });
  }

  onWalletPopupEnter(): void {
    this.walletPopupshow = true;
  }

  onWalletPopupLeave(): void {
    this.walletPopupshow = false;
  }
}
