import type { FullAuditedEntityDto } from '@abp/ng.core';

export interface CreateUpdateHashtagDto {
  hashtagCollectionName: string;
  hashtags?: string;
}

export interface HashtagDto extends FullAuditedEntityDto<number> {
  hashtagCollectionName?: string;
  hashtags?: string;
}
