export interface FacebookCallBackRequestDto {
  code?: string;
  state?: string;
  error?: string;
  error_code?: string;
  error_description?: string;
  error_reason?: string;
}

export interface AccountData {
  id?: string;
  name?: string;
  access_token?: string;
  followers_count: number;
  fan_count: number;
  picture: FacebookPageProfile;
  description?: string;
  about?: string;
  posts: Posts;
  stories: Stories;
}

export interface CommentData {
  data: CommentDataSummery[];
  summary: CommentSummary;
}

export interface CommentDataSummery {
  createdTime?: string;
  message?: string;
  id?: string;
}

export interface CommentSummary {
  totalCount: number;
}

export interface FBCountryWiseResponse {
  gender?: string;
  currentCount: number;
  previousCount: number;
  percentage: number;
}

export interface FacebookMediaDetail {
  caption?: string;
  permalink?: string;
  timestamp?: string;
  id?: string;
  isPublished: boolean;
  shares: ShareCount;
  likes: LikeData;
  comments: CommentData;
}

export interface FacebookPageProfile {
  data: ProfilePicData;
}

export interface LikeData {
  summary: LikeSummary;
}

export interface LikeSummary {
  totalCount: number;
}

export interface Posts {
  data: PostsData[];
}

export interface PostsData {
  id?: string;
}

export interface ProfilePicData {
  url?: string;
}

export interface ShareCount {
  count: number;
}

export interface Stories {
  data: StoriesData[];
}

export interface StoriesData {
  post_id?: string;
}
