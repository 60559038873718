<div class="q-content">
  <div class="q-content-header">
    <div class="d-flex gap-15 align-items-center justify-content-between wp-100 mb-2 flex-wrap">
      <kendo-textbox
        placeholder="Search Here"
        (valueChange)="onSearch($event)"
        class="search"
        [(ngModel)]="searchText"
      >
        <ng-template kendoTextBoxPrefixTemplate>
          <i class="fas fa-search k-icon font-size-14"></i>
        </ng-template>
      </kendo-textbox>
      <div class="d-flex gap-15 align-items-center flex-wrap q-content-header-options">
        <button kendoButton (click)="addCategoty()">
          <i class="far fa-plus pr-5"></i> Add Category
        </button>
        <kendo-dropdownlist
          [data]="postTypeList"
          textField="name"
          valueField="id"
          (valueChange)="getList()"
          [(ngModel)]="selectedPostType"
          [value]="selectedPostType"
          class="bg-white status-dropdown"
        >
        </kendo-dropdownlist>
        <div class="d-flex align-items-center flex-wrap">
          <div class="d-flex align-items-center q-toggle-button">
            <button
              [class.k-selected]="isListView"
              kendoButton
              fillMode="flat"
              (click)="showListView(true)"
            >
              <i class="far fa-list-ul font-size-16"></i>
            </button>
            <button
              [class.k-selected]="!isListView"
              kendoButton
              fillMode="flat"
              (click)="showListView(false)"
            >
              <i class="far fa-th-large font-size-16"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="q-content-bg" *ngIf="isListView">
    <div class="grid-wrapper">
      <kendo-grid
        #grid
        [data]="gridView"
        [pageSize]="pageSize"
        [skip]="skip"
        [pageable]="
          categoriesList === undefinded
            ? false
            : {
                buttonCount: buttonCount,
                info: info,
                type: type,
                pageSizes: pageSizes,
                previousNext: previousNext,
                position: pagerposition
              }
        "
        [height]="gridHeight"
        (pageChange)="pageChange($event)"
        [sortable]="{
          allowUnsort: true,
          mode: multiple ? 'multiple' : 'single'
        }"
        [sort]="sort"
        (sortChange)="sortChange($event)"
      >
        <kendo-grid-column field="name" title="Name" [width]="200"> </kendo-grid-column>
        <kendo-grid-column field="description" title="Description" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column [filterable]="true" field="ViewPost" title="View Post" [width]="250">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex align-items-center justify-content-between">
              <a
                class="view-posts text-primary text-grid-link-color font-size-14 white-space-nowrap"
                title="View post ({{ dataItem.postCount }})"
                (click)="onViewPost(dataItem, dataItem.postCount)"
                placement="bottom"
              >
                View Post ({{ dataItem.postCount }})</a
              >
              <ul class="grid-action-list">
                <div class="d-flex gap-10">
                  <li *ngIf="!dataItem.isDefault">
                    <a title="Edit" (click)="onEditCatergory(dataItem.id)">
                      <i class="far fa-pen"></i>
                    </a>
                  </li>
                  <li *ngIf="!dataItem.isDefault">
                    <a title="Delete" (click)="delete(dataItem)">
                      <i class="far fa-trash-alt"></i>
                    </a>
                  </li>
                  <li>
                    <a title="Add Post" (click)="onAddPost(dataItem)">
                      <i class="far fa-plus"></i>
                    </a>
                  </li>
                </div>
              </ul>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
  <div *ngIf="!isListView">
    <div class="d-flex-row">
      <div
        *ngFor="let catergory of categoriesList"
        class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col mb-20"
      >
        <kendo-card class="wp-100 h-100">
          <kendo-card-header class="k-hstack">
            <div class="d-flex align-items-center justify-content-between wp-100">
              <h1 kendoCardTitle title="{{ catergory.name }}" class="clamp">
                {{ catergory.name }}
              </h1>
              <kendo-dropdownbutton [data]="actionMenu" class="dropdown-button-icon">
                <i class="fas fa-ellipsis"></i>
                <ng-template kendoDropDownButtonItemTemplate let-dataItem>
                  <div *ngIf="dataItem.text === 'Edit'" (click)="onEditCatergory(catergory.id)">
                    <i [ngClass]="dataItem.iconClass"></i>
                    <span class="ml-10">{{ dataItem.text }}</span>
                  </div>
                  <div *ngIf="dataItem.text === 'Delete'" (click)="delete(catergory)">
                    <i [ngClass]="dataItem.iconClass"></i>
                    <span class="ml-10">{{ dataItem.text }}</span>
                  </div>
                  <div *ngIf="dataItem.text === 'Add'" (click)="onAddPost(catergory)">
                    <i [ngClass]="dataItem.iconClass"></i>
                    <span class="ml-10">{{ dataItem.text }}</span>
                  </div>
                </ng-template>
              </kendo-dropdownbutton>
            </div>
          </kendo-card-header>
          <kendo-card-body>
            <p kendoCardSubtitle class="fw-normal mb-5">Description:</p>
            <div class="height-clamp" title="{{ catergory.description }}">
              {{ catergory.description }}
            </div>
          </kendo-card-body>
          <kendo-card-actions>
            <a
              class="view-posts text-primary text-grid-link-color font-size-14"
              title="View post ({{ catergory.postCount }})"
              (click)="onViewPost(catergory, catergory.postCount)"
              placement="bottom"
            >
              <i class="far fa-eye font-size-14 pr-5"></i>
              View Posts ({{ catergory.postCount }})</a
            >
          </kendo-card-actions>
        </kendo-card>
      </div>
      <div *ngIf="categoriesList.length === 0">No records available.</div>
    </div>
  </div>
  <div kendoDialogContainer></div>
</div>
