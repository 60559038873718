import type { SocialAccountType } from '../../enums/social-account-type.enum';
import type { MediaURLModel } from '../../common/models/models';
import type { FullAuditedEntityDto } from '@abp/ng.core';

export interface SocialAccountPostReadDto {
  accountType: SocialAccountType;
  tenantId?: string;
  socialId?: string;
  accountName?: string;
  profilePicUrl?: string;
  expireTime: number;
  message?: string;
  mediaUrls: MediaURLModel[];
  postId: number;
  id: number;
  hashtagIds: number[];
}

export interface SocialAccountsDto extends FullAuditedEntityDto<number> {
  accountType: SocialAccountType;
  socialId?: string;
  accountName?: string;
  profilePicUrl?: string;
  profilePicBase64?: string;
  isActive?: boolean;
  postCount: number;
  additionalInformation?: string;
}

export interface AccountDetailDto {
  accountsDetail: AccountListDto[];
  profile: AccountProfileDetails;
  accountType: SocialAccountType;
  profilePicBase64?: string;
}

export interface AccountListDto {
  id: string;
  name?: string;
  username?: string;
  profile_picture_url?: string;
}

export interface AccountProfileDetails {
  id?: string;
  name?: string;
  url?: string;
}
