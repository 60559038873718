import { mapEnumToOptions } from '@abp/ng.core';

export enum OrientationEnums {
  None = 0,
  Landscape = 1,
  Portrait = 2,
  Squarish = 3,
}

export const orientationEnumsOptions = mapEnumToOptions(OrientationEnums);
