import { mapEnumToOptions } from '@abp/ng.core';

export enum Subscription_BillingPeriodUnitEnum {
  UnKnown = 0,
  Day = 1,
  Week = 2,
  Month = 3,
  Year = 4,
}

export const subscription_BillingPeriodUnitEnumOptions = mapEnumToOptions(
  Subscription_BillingPeriodUnitEnum,
);
