export enum DragDropLblName {
  TextField = 'Text Field',
  TextArea = 'Text Area',
  Number = 'Number',
  Password = 'Password',
  Checkbox = 'Checkbox',
  MultipleCheckbox = 'Multiple Checkbox',
  Select = 'Select',
  Radio = 'Radio',
  Button = 'Button',
  Email = 'Email',
  Url = 'Url',
  PhoneNumber = 'Phone Number',
  Tags = 'Tags',
  Address = 'Address',
  Date_Time = 'Date / Time',
  Day = 'Day',
  Time = 'Time',
  Currency = 'Currency',
  Metrix = 'Metrix',
  Signature = 'Signature',
  HTMLElement = 'HTML Element',
  Content = 'Content',
  Columns = 'Columns',
  FieldSet = 'Field Set',
  Panel = 'Panel',
  Table = 'Table',
  Tabs = 'Tabs',
  Box = 'Box',
  Hidden = 'Hidden',
  Container = 'Container',
  DataMap = 'Data Map',
  DataGrid = 'Data Grid',
  EditGrid = 'Edit Grid',
  Tree = 'Tree',
}
