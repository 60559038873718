import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { IFormFile } from '../microsoft/asp-net-core/http/models';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  apiName = 'Default';

  delete = (fileName: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>(
      {
        method: 'DELETE',
        url: '/api/app/file',
        params: { fileName },
      },
      { apiName: this.apiName, ...config },
    );

  getPostPicture = (name: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/app/file/post-picture',
        params: { name },
      },
      { apiName: this.apiName, ...config },
    );

  getPostPictureStream = (name: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: '/api/app/file/post-picture-stream',
        params: { name },
      },
      { apiName: this.apiName, ...config },
    );

  getProfilePicture = (name: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/app/file/profile-picture',
        params: { name },
      },
      { apiName: this.apiName, ...config },
    );

  getProfilePictureStream = (name: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: '/api/app/file/profile-picture-stream',
        params: { name },
      },
      { apiName: this.apiName, ...config },
    );

  saveFileFromRemoteStream = (file: FormData, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/app/file/save-file-from-remote-stream',
        body: file,
      },
      { apiName: this.apiName, ...config },
    );

  saveMultipleFiles = (files: FormData, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string[]>(
      {
        method: 'POST',
        url: '/api/app/file/save-multiple-files',
        body: files,
      },
      { apiName: this.apiName, ...config },
    );

  savePicture = (file: IFormFile, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/app/file/save-picture',
        body: file,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
