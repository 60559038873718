import { mapEnumToOptions } from '@abp/ng.core';

export enum AvalaraSaleTypeEnum {
  UnKnown = 0,
  Wholesale = 1,
  Retail = 2,
  Consumed = 3,
  VendorUse = 4,
}

export const avalaraSaleTypeEnumOptions = mapEnumToOptions(AvalaraSaleTypeEnum);
