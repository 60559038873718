import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbEvaIconsModule } from '@nebular/eva-icons';

import {
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbThemeModule,
} from '@nebular/theme';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbEvaIconsModule,
];

@NgModule({
  imports: [CommonModule, ...NB_MODULES],
  exports: [CommonModule, ...NB_MODULES],
  declarations: [],
})
export class NbModule {
  static forRoot(): ModuleWithProviders<NbModule> {
    return {
      ngModule: NbModule,
      providers: [
        ...NbThemeModule.forRoot({
          name: 'default',
        }).providers,
      ],
    };
  }
}
