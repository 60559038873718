export interface VideoCategory {
  eTag?: string;
  id?: string;
  kind?: string;
  snippet: VideoCategorySnippet;
}

export interface VideoCategorySnippet {
  assignable?: boolean;
  channelId?: string;
  title?: string;
  eTag?: string;
}
