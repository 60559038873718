<div class="top-header-navigation d-flex gap-20">
  <div
    (mouseenter)="onWalletPopupEnter()"
    (mouseleave)="onWalletPopupLeave()"
    class="wallet-credit-container"
  >
    <kendo-button #walletCredits class="link" class="wallet-link" (click)="onWalletClick()">
      <i class="far fa-wallet mr-5"></i> My Wallet: <span>{{ availableCredits }}</span>
    </kendo-button>
    <kendo-popup [anchor]="walletCredits.element" *ngIf="walletPopupshow">
      <div class="q-card">
        <div class="q-card-header d-flex flex-column align-items-center justify-content-center">
          <div class="popup-icon-container">
            <div class="icon-wallet"><i class="far fa-wallet"></i></div>
          </div>
        </div>
        <div class="q-card-body pb-0">
          <div class="d-flex mb-10 flex-column align-items-center">
            <p class="popup-title mb-5">Available Wallet Balance</p>
            <p class="points mb-10">
              <i class="far fa-gem mr-10"></i>{{ availableCredits }} Credits
            </p>
            <p class="expiry mb-0">Valid till: {{ validDate | date: 'd MMM, y' }}</p>
          </div>
          <button kendoButton class="wp-100 text-uppercase" (click)="onWalletClick()">
            TOP-UP NOW
          </button>
        </div>
      </div>
    </kendo-popup>
  </div>

  <div class="d-flex align-items-center gap-5">
    <div class="profile-image">
      <img src="assets/images/user.png" alt="profile" />
    </div>
    <kendo-dropdownbutton [data]="headerDataList" (itemClick)="onHeaderClick($event)">
      <span class="k-button-text-value clamp" title="{{ currentUser }}">{{ currentUser }}</span>
      <img src="assets/images/arrow.svg" />
    </kendo-dropdownbutton>
  </div>
</div>
