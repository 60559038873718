import { Component, Input, forwardRef, ElementRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
  selector: 'ngbDateTimePicker',
  template: `
    <div ngbDropdown (openChange)="!$event && onTouch()">
      <input
        [class]="parentClass"
        [disabled]="isDisabled"
        class="datepicker form-control"
        ngbDropdownToggle
        value="{{ _value ? (_value | date: placeholder) : label }}"
      />

      <div ngbDropdownMenu>
        <ngb-datepicker
          #dp
          [(ngModel)]="date"
          (dateSelect)="getDatetime()"
          [minDate]="minDateObject"
        ></ngb-datepicker>
        <ngb-timepicker
          [hourStep]="hourStep"
          [minuteStep]="minuteStep"
          [secondStep]="secondStep"
          [meridian]="meridian"
          [ngModel]="time"
          (ngModelChange)="time = $event; getDatetime()"
        ></ngb-timepicker>
      </div>
    </div>
  `,
  styles: [
    `
      .input-container {
        position: relative;
        width: 212px;
      }

      .input-container .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: 15px;
      }

      .input-container .calendar-icon {
        right: 37px;
      }

      .input-container .delete-icon {
        right: 13px;
      }

      .datepicker {
        color: black;
        width: 212px;
        padding-left: 13px !important;
        padding-right: 13px !important;
        font-size: 12px !important;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true,
    },
  ],
})
export class DateTimePickerComponent implements ControlValueAccessor {
  @Input() mask = 'medium';
  @Input() meridian: boolean = false;
  @Input() placeholder: string = 'MMM d, y h:mm:ss a';
  @Input() hourStep = 1;
  @Input() minuteStep = 5;
  @Input()
  secondStep = 30;
  @Input()
  seconds = true;
  @Output() dateTimeChange: EventEmitter<Date> = new EventEmitter<Date>();
  editValue: any;

  @Input() set triggerDateTime(value) {
    if (value !== null && value !== 'MMM d, y h:mm:ss a' && value !== undefined) {
      const newDate = new Date(value);
      if (newDate.getTime() !== this._value?.getTime()) {
        this.editValue = newDate;
        this.writeValue(newDate);
      }
    }
  }

  minDateObject = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  date: any;
  time: any = { hour: 0, minute: 0 };
  isDisabled: boolean;
  onChange = (_: any) => {};
  onTouch = () => {};
  _value: any;
  label: any;
  control: any;
  get parentClass() {
    return this.elementRef.nativeElement.className;
  }
  constructor(private elementRef: ElementRef) {}

  getDatetime() {
    let value = null;
    if (!this.date) {
      value = this.placeholder;
      this._value = null;
    } else {
      value = new Date(
        this.date.year,
        this.date.month - 1,
        this.date.day,
        this.time ? this.time.hour : 0,
        this.time ? this.time.minute : 0,
      );
      this._value = value;
    }

    this.onChange(this._value);
    this.label = value;
    this.emitDateTime();
  }

  emitDateTime() {
    this.dateTimeChange.emit(this._value);
  }

  writeValue(obj: any): void {
    if (obj && obj.getFullYear) {
      const date = new Date(
        obj.getFullYear(),
        obj.getMonth(),
        obj.getDate(),
        obj.getUTCHours(),
        obj.getMinutes(),
      );
      this.date = {
        year: obj.getFullYear(),
        month: obj.getMonth() + 1,
        day: obj.getDate(),
      };
      this.time = {
        hour: this.hourStep * Math.round(obj.getHours() / this.hourStep),
        minute: this.minuteStep * Math.round(obj.getMinutes() / this.minuteStep),
      };
      setTimeout(() => {
        this.getDatetime();
      });
    }
    this.getDatetime();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
