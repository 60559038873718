import { Component, Input } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-subscription-popup',
  templateUrl: './subscription-popup.component.html',
  styleUrls: ['./subscription-popup.component.scss'],
})
export class SubscriptionPopupComponent {
  @Input() url: any;

  constructor(public dialogRef: DialogRef) {}

  closePopup(): void {
    this.dialogRef.close();
  }
}
