export interface ColumnChartDetailModel {
  categories: string[];
  series: ColumnChartSeriesModel[];
}

export interface ColumnChartSeriesModel {
  name?: string;
  data: number[];
}

export interface DashboardCardModel {
  card: DashboardFilterModel;
  card1: DashboardFilterModel;
  card2: DashboardFilterModel;
  card3: DashboardFilterModel;
  card4: DashboardFilterModel;
}

export interface DashboardFilterModel {
  cardName?: string;
  total: number;
  increase: number;
}
