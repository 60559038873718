import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PostService } from '@proxy/posts';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { ToasterService } from '@abp/ng.theme.shared';
import { CommonService, NotificationMessage, PopupMessage } from 'src/app';
import { CommonState } from 'src/core/state';
import { PostStatus, SocialAccountType } from '@proxy/enums';
import { PostDto, PostGetListInput } from '@proxy/posts/dtos';
import { PagedResultDto } from '@abp/ng.core';
import { NbSidebarService, NbSidebarState } from '@nebular/theme';
import { SVGIcon, copyIcon, editToolsIcon, cancelIcon } from '@progress/kendo-svg-icons';
import { DatePipe } from '@angular/common';
import { PageChangeEvent, PageSizeChangeEvent } from '@progress/kendo-angular-pager';
import { BadgeAlign, BadgePosition } from '@progress/kendo-angular-indicators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-post',
  templateUrl: './view-post.component.html',
  styleUrls: ['./view-post.component.scss'],
})
export class ViewPostComponent implements OnInit, OnDestroy {
  @Select(CommonState.profileDetails)
  profileDetails$: Observable<Array<any>>;

  catergoryDetails: any;
  profileDetails: any;
  viewPostData: any = [];
  categoriesList: any[];
  postType: any;

  //#region pagination
  collectionSize: number;
  pageNumber = 5;
  skipPage = 0;
  page = 0;
  pageSize = 5;
  maxSize: number;
  pageSizeValues: number[] = [5, 10, 15, 20];
  //#endregion

  pageTitle: string;
  PageDescription: string;

  showEditButtonForApproved: boolean = false;
  showPagination = false;
  hasParam = false;
  noRecordFound: boolean = false;
  isDataLoaded = false;

  @ViewChild('fixedElement') fixedElement: ElementRef;
  private sidebarSubscription: Subscription;

  public copyIcon: SVGIcon = copyIcon;
  public editToolsIcon: SVGIcon = editToolsIcon;
  public cancelIcon: SVGIcon = cancelIcon;

  postListData: PagedResultDto<PostDto> = { items: [], totalCount: 0 };

  public badgeAlign: BadgeAlign = { vertical: 'bottom', horizontal: 'end' };

  public position: BadgePosition = 'inside';

  selectedPostType = { id: PostStatus.All, name: PostStatus[PostStatus.All] };

  postTypeList = [
    { id: PostStatus.All, name: PostStatus[PostStatus.All] },
    { id: PostStatus.Approved, name: PostStatus[PostStatus.Approved] },
    { id: PostStatus.Draft, name: PostStatus[PostStatus.Draft] },
  ];

  @Select(CommonState.subscriptionPlanData)
  subscriptionPlanData$: Observable<any>;
  currentWalletCredit = 0;

  constructor(
    private router: Router,
    private _Activatedroute: ActivatedRoute,
    private postService: PostService,
    public commonService: CommonService,
    private spinnerService: NgxSpinnerService,
    private toasterService: ToasterService,
    private sidebarService: NbSidebarService,
    private datepipe: DatePipe,
  ) {
    this.calculateMaxSize();
  }

  ngOnInit(): void {
    this._Activatedroute.paramMap.subscribe(params => {
      this.spinnerService.show();
      this.showPagination = false;
      if (params.keys.length > 0) {
        this.hasParam = true;

        this.catergoryDetails = {
          catergoryId: atob(params.get('catergoryId')),
          catergoryName: atob(params.get('catergoryName')),
          catergoryDesc: atob(params.get('catergoryDesc')),
          postType: params.get('postType'),
        };
        switch (+this.catergoryDetails.postType) {
          case PostStatus.Approved:
            this.postType = { id: PostStatus.Approved, name: PostStatus[PostStatus.Approved] };
            break;
          case PostStatus.Draft:
            this.postType = { id: PostStatus.Draft, name: PostStatus[PostStatus.Draft] };
            break;
          default:
            this.postType = { id: PostStatus.All, name: PostStatus[PostStatus.All] };
            break;
        }

        if (params.has('catergoryId')) {
          this.pageTitle = this.catergoryDetails.catergoryName;
          this.PageDescription = this.catergoryDetails.catergoryDesc;
          this.getViewPostByCatergoryId();
        }

        this.profileDetails$.subscribe(x => {
          if (x !== undefined) {
            this.profileDetails = JSON.parse(JSON.stringify(x));
          }
        });
      } else {
        this.hasParam = false;

        this.postType = { id: PostStatus.All, name: 'All' };
        this.profileDetails$.subscribe(x => {
          if (x !== undefined) {
            this.profileDetails = JSON.parse(JSON.stringify(x));
          }
        });
        this.getNewPostData();
      }
    });

    window.addEventListener('resize', () => {
      this.calculateMaxSize();
    });
    this.subscriptionPlanData$.pipe().subscribe(res => {
      if (res !== undefined && res !== null) {
        if (
          res.currentWalletCredit !== undefined &&
          res.currentWalletCredit !== null &&
          res.currentWalletCredit > 0
        ) {
          this.currentWalletCredit = res.currentWalletCredit;
        } else {
          this.currentWalletCredit = 0;
        }
      }
    });
  }

  ngAfterViewInit() {
    this.sidebarService.getSidebarState('menu-sidebar').subscribe((state: NbSidebarState) => {
      this.adjustWidthBasedOnState(state);
    });
    this.sidebarSubscription = this.sidebarService.onToggle().subscribe(() => {
      this.sidebarService.getSidebarState('menu-sidebar').subscribe((state: NbSidebarState) => {
        this.adjustWidthBasedOnState(state);
      });
    });
  }

  adjustWidthBasedOnState(state: NbSidebarState) {
    if (this.fixedElement && this.fixedElement.nativeElement) {
      switch (state) {
        case 'compacted':
          this.fixedElement.nativeElement.style.width = 'calc(100% - 80px)';
          break;
        case 'expanded':
          this.fixedElement.nativeElement.style.width = 'calc(100% - 264px)';
          break;
      }
    }
  }

  isItemSelected(item, adId) {
    return item.socialAccounts.some(socialAccount => socialAccount.id === adId);
  }

  onDropdownChange(): void {
    this.spinnerService.show();

    if (this.catergoryDetails !== undefined && this.catergoryDetails.catergoryId !== undefined) {
      this.getViewPostByCatergoryId();
    } else {
      this.getNewPostData();
    }
  }

  getViewPostByCatergoryId(): void {
    this.spinnerService.show();
    var params = {
      categoryId: this.catergoryDetails.catergoryId,
      postStatus: this.postType.id,
    };
    this.postService.getPostByCategoryId(params).subscribe(res => {
      this.isDataLoaded = true;
      this.viewPostData = res;
      this.spinnerService.hide();

      this.showApprovedEditButton(res);
    });
  }

  onEditClick(id): void {
    const params = {
      postId: btoa(id),
    };
    this.navigateToEditPost(params);
  }

  navigateToEditPost(params) {
    this.router.navigate(['create-post', params]);
  }

  onCopyClick(id) {
    let selectedAccountCount = this.viewPostData.filter(element => element.id === id)[0]
      .socialAccounts.length;

    this.spinnerService.show();

    if (this.currentWalletCredit > 0) {
      if (this.currentWalletCredit > selectedAccountCount * environment.perPostCharges) {
        this.copyPostAndUpdate(id);
      } else {
        this.spinnerService.hide();
        this.toasterService.error(
          NotificationMessage.lessCreditAvailableMsg,
          '',
          this.commonService.toasterMessageConfiguration,
        );
      }
    } else {
      this.handleInsufficientCredit();
    }
  }

  hasSufficientCredit(res) {
    return (
      res !== undefined &&
      res.currentWalletCredit !== undefined &&
      res.currentWalletCredit !== null &&
      res.currentWalletCredit > 0
    );
  }

  copyPostAndUpdate(id) {
    this.postService.copyPost(id).subscribe(res => {
      this.toasterService.success(
        NotificationMessage.copyMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      this.onDropdownChange();
    });
  }

  handleInsufficientCredit() {
    this.spinnerService.hide();
    this.toasterService.error(
      NotificationMessage.noCreditAvailableMsg,
      '',
      this.commonService.toasterMessageConfiguration,
    );
  }

  onDeleteClick(item: any): void {
    const hasInstaAccount = item.socialAccounts.some(
      e => e.accountType === SocialAccountType.InstagramBusiness,
    );

    swal({
      title: 'Are you sure?',
      text:
        hasInstaAccount && !item.isDraft
          ? PopupMessage.instagramPostDeleteDeletePopupMsg
          : PopupMessage.postDeleteDeletePopupMsg,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(result => {
      if (result) {
        if (!item.isDraft) {
          this.postService.deletePublishPost(item.id).subscribe(res => {
            const instaValue = [];
            res.forEach(x => {
              if (
                x.status === true &&
                x.socialAccountType !== SocialAccountType.InstagramBusiness
              ) {
                instaValue.push(x.status);
              }
            });
            if (instaValue.length > 0) {
              this.toasterService.success(
                NotificationMessage.deletePublishPostSuccessMsg,
                '',
                this.commonService.toasterMessageConfiguration,
              );
              this.onDropdownChange();
            } else {
              this.toasterService.error(
                NotificationMessage.deletePublishInstagramErrorMsg,
                '',
                this.commonService.toasterMessageConfiguration,
              );
            }
          });
        } else {
          this.postService.delete(item.id).subscribe(res => {
            this.toasterService.success(
              NotificationMessage.deletePostSuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.onDropdownChange();
          });
        }
      }
    });
  }

  getNewPostData() {
    var isDraftValue;
    if (this.postType.id == 1) {
      isDraftValue = null;
    } else if (this.postType.id == 2) {
      isDraftValue = false;
    } else if (this.postType.id == 3) {
      isDraftValue = true;
    }

    const param: PostGetListInput = {
      mediaUrls: null,
      skipCount: this.page,
      maxResultCount: this.pageSize,
      isDraft: isDraftValue,
      hashtagIds: null,
    };

    this.postService.getList(param).subscribe(res => {
      this.viewPostData = res.items;
      this.noRecordFound = res.items.length > 0 ? false : true;
      this.isDataLoaded = true;

      if (this.viewPostData.length !== 0 && !this.hasParam) {
        this.showPagination = true;
      }

      this.spinnerService.hide();

      this.showApprovedEditButton(res.items);
      this.collectionSize = res.totalCount;
    });
  }

  getInitials(name: string): string {
    // Extract the first letter of the name
    return name ? name[0].toUpperCase() : '';
  }

  getPremiumData(event: PageChangeEvent) {
    this.spinnerService.show();
    this.page = event.skip;
    this.pageNumber = event.take;
    this.skipPage = event.skip;
    this.getNewPostData();
  }

  onTableSizeChange(event: PageSizeChangeEvent): void {
    this.spinnerService.show();
    this.pageSize = +event.newPageSize;
    this.getNewPostData();
  }

  calculateMaxSize() {
    const screenWidth = window.innerWidth;
    if (screenWidth > 1024) {
      this.maxSize = 12;
    } else if (screenWidth > 768) {
      this.maxSize = 8;
    } else if (screenWidth > 575) {
      this.maxSize = 10;
    } else {
      this.maxSize = 6;
    }
  }

  ngOnDestroy(): void {
    if (this.sidebarSubscription) {
      this.sidebarSubscription.unsubscribe();
    }
  }

  showApprovedEditButton(data: any[]): void {
    const currentDate = this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ss');
    data.forEach(element => {
      if (!element.isDraft && element.isReQueue) {
        if (element.isExpireSpecificDate && element.expireSpecificDate > currentDate) {
          if (element.isExpireAfter && element.expireTimes === element.noOfTimesPosted) {
            element.showEditButtonForApproved = false;
          } else {
            element.showEditButtonForApproved = true;
          }
        } else if (element.isExpireAfter && element.expireTimes > element.noOfTimesPosted) {
          element.showEditButtonForApproved = true;
        }
      }
    });
  }
}
