import type { Subscription_BillingPeriodUnitEnum } from '../../models/subscription-billing-period-unit-enum.enum';
import type { AutoCollectionEnum } from '../../models/enums/auto-collection-enum.enum';
import type { TrialEndActionEnum } from '../../models/enums/trial-end-action-enum.enum';
import type { Subscription_CancelReasonEnum } from '../../models/subscription-cancel-reason-enum.enum';
import type { OfflinePaymentMethodEnum } from '../../models/enums/offline-payment-method-enum.enum';
import type { ChannelEnum } from '../../models/enums/channel-enum.enum';
import type { Subscription_SubscriptionSubscriptionItem } from '../../models/models';
import type { FreePeriodUnitEnum } from '../../models/enums/free-period-unit-enum.enum';
import type { ApplicableAddons } from '../product/models';

export interface AddonPriceIds {
  addonId?: string;
  addonQuantity: number;
}

export interface SubscriptionModel {
  id?: string;
  currencyCode?: string;
  planId?: string;
  planQuantity: number;
  planUnitPrice?: number;
  setupFee?: number;
  billingPeriod?: number;
  billingPeriodUnit?: Subscription_BillingPeriodUnitEnum;
  startDate?: string;
  trialEnd?: string;
  remainingBillingCycles?: number;
  poNumber?: string;
  autoCollection?: AutoCollectionEnum;
  planQuantityInDecimal?: string;
  planUnitPriceInDecimal?: string;
  customerId?: string;
  planAmount?: number;
  planFreeQuantity?: number;
  trialStart?: string;
  trialEndAction?: TrialEndActionEnum;
  currentTermStart?: string;
  currentTermEnd?: string;
  nextBillingAt?: string;
  createdAt?: string;
  startedAt?: string;
  activatedAt?: string;
  giftId?: string;
  contractTermBillingCycleOnRenewal?: number;
  overrideRelationship?: boolean;
  pauseDate?: string;
  resumeDate?: string;
  cancelledAt?: string;
  cancelReason?: Subscription_CancelReasonEnum;
  affiliateToken?: string;
  createdFromIp?: string;
  resourceVersion?: number;
  updatedAt?: string;
  hasScheduledAdvanceInvoices: boolean;
  hasScheduledChanges: boolean;
  paymentSourceId?: string;
  planFreeQuantityInDecimal?: string;
  planAmountInDecimal?: string;
  cancelScheduleCreatedAt?: string;
  offlinePaymentMethod?: OfflinePaymentMethodEnum;
  channel?: ChannelEnum;
  netTermDays?: number;
  activeId?: string;
  subscriptionItems: Subscription_SubscriptionSubscriptionItem[];
  dueInvoicesCount?: number;
  dueSince?: string;
  totalDues?: number;
  mrr?: number;
  exchangeRate?: number;
  baseCurrencyCode?: string;
  invoiceNotes?: string;
  deleted: boolean;
  changesScheduledAt?: string;
  cancelReasonCode?: string;
  freePeriod?: number;
  freePeriodUnit?: FreePeriodUnitEnum;
  createPendingInvoices?: boolean;
  autoCloseInvoices?: boolean;
  businessEntityId?: string;
}

export interface TenantSubscriptionModel {
  planData: TenantSubscriptionPlanData;
  addOns: ApplicableAddons[];
}

export interface TenantSubscriptionPlanData {
  postsCount: number;
  totalCredit: number;
  accountsCount: number;
  currentAccountsCount: number;
  currentWalletCredit: number;
  currentPlanName?: string;
  planExpiryDate?: string;
  isScheduleAllowed: boolean;
  isRequeAllowed: boolean;
  isAiAssitantAllowed: boolean;
  frequency: number;
  planId?: string;
}
