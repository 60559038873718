import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AddProfileComponent,
  AnalyticsComponent,
  CreateCategoryComponent,
  CreateHashtagComponent,
  CreatePostComponent,
  ListCategoryComponent,
  ListHashtagComponent,
  ScheduleSetupComponent,
  ViewPostComponent,
} from './modules';
import { SubscriptionPlanComponent } from './modules/subscription-plan/subscription-plan.component';
import { CustomAddTenantComponent } from './shared';
import { WalletComponent } from './modules/wallet/wallet.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'subscription-plan', component: SubscriptionPlanComponent },
  { path: 'wallet', component: WalletComponent },
  {
    path: 'dashboard',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'account',
    loadChildren: () => import('@abp/ng.account').then(m => m.AccountModule.forLazy()),
  },

  {
    path: 'identity',
    loadChildren: () => import('@abp/ng.identity').then(m => m.IdentityModule.forLazy()),
  },
  {
    path: 'tenant-management',
    loadChildren: () =>
      import('@abp/ng.tenant-management').then(m => m.TenantManagementModule.forLazy()),
  },
  {
    path: 'setting-management',
    loadChildren: () =>
      import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
  },
  {
    path: 'create-post',
    component: CreatePostComponent,
  },
  {
    path: 'list-category',
    component: ListCategoryComponent,
  },
  {
    path: 'create-category',
    component: CreateCategoryComponent,
  },
  {
    path: 'list-hashtag',
    component: ListHashtagComponent,
  },
  {
    path: 'create-hashtag',
    component: CreateHashtagComponent,
  },
  {
    path: 'view-post',
    component: ViewPostComponent,
  },
  {
    path: 'schedule-setup',
    component: ScheduleSetupComponent,
  },
  {
    path: 'analytics',
    component: AnalyticsComponent,
  },
  {
    path: 'add-profile',
    component: AddProfileComponent,
  },
  {
    path: 'post-list',
    component: ViewPostComponent,
  },
  {
    path: 'new-signup',
    component: CustomAddTenantComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
