import { Component, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { ListService } from '@abp/ng.core';
import { ScheduleService } from '@proxy/schedules';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { CommonService, NotificationMessage } from 'src/app';
import {
  SchedulerEvent,
  SlotRange,
  EventClickEvent,
  SlotClassArgs,
} from '@progress/kendo-angular-scheduler';
import { Select } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CommonState } from 'src/core/state';
import { ToasterService } from '@abp/ng.theme.shared';

@Component({
  selector: 'app-schedule-setup',
  templateUrl: './schedule-setup.component.html',
  styleUrls: ['./schedule-setup.component.scss'],
})
export class ScheduleSetupComponent implements OnInit, OnDestroy {
  eventGuid = 0;
  currentWalletCredit = 0;
  public selectedDate: Date = new Date();
  scheduleData: any;

  displayStyle = 'none';
  title = 'Schedule your post';

  views = [
    { calendarView: 'Month', value: 'dayGridMonth' },
    { calendarView: 'Week', value: 'timeGridWeek' },
    { calendarView: 'Day', value: 'timeGridDay' },
    { calendarView: 'List', value: 'listWeek' },
  ];

  selectedView = this.views[0].value;

  @Select(CommonState.subscriptionPlanData)
  subscriptionPlanData$: Observable<any>;

  constructor(
    private scheduleService: ScheduleService,
    public readonly list: ListService,
    public commonService: CommonService,
    private datePipe: DatePipe,
    private router: Router,
    private toasterService: ToasterService,
  ) {
    this.handleResize = this.handleResize.bind(this);
  }

  @ViewChild('fullcalendar') calendarComponent;

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(): void {
    let calendarApi = this.calendarComponent.getApi();
    let currentView = calendarApi.view.type;

    calendarApi.changeView('dayGridDay');
    calendarApi.changeView(currentView);
    calendarApi.changeView('dayGridDay');
    calendarApi.changeView(currentView);
  }

  changeView(event): void {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.changeView(event.value);
  }

  createEventId(): string {
    return String(this.eventGuid++);
  }

  ngOnInit(): void {
    const currentDate = new Date();
    const currentYear = new Date().getFullYear();
    const firstDayOfMonth = this.datePipe.transform(
      new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      'yyyy-MM-dd HH:mm:ss',
    );
    const lastDayOfMonth = this.datePipe.transform(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
      'yyyy-MM-dd HH:mm:ss',
    );
    const parseAdjust = (eventDate: string): Date => {
      const date = new Date(eventDate);
      date.setFullYear(currentYear);
      return date;
    };
    this.scheduleService.getScheduleTime(firstDayOfMonth, lastDayOfMonth).subscribe(res => {
      const baseData: any[] = res;
      baseData.map(data => {
        return {
          postId: data.postId,
          scheduleId: data.scheduleId,
          title: data.title,
          start: data.start,
          end: data.end,
          description: data.description,
          StartTimezone: null,
          EndTimezone: null,
          RecurrenceRule: null,
          RecurrenceID: null,
          RecurrenceException: null,
          IsAllDay: false,
        };
      });

      this.scheduleData = baseData.map(
        dataItems =>
          <SchedulerEvent>{
            postId: dataItems.postId,
            scheduleId: dataItems.scheduleId,
            start: parseAdjust(dataItems.start),
            end: parseAdjust(dataItems.end),
            title: dataItems.title,
            description: dataItems.description,
          },
      );
    });

    this.subscriptionPlanData$.subscribe(res => {
      if (res !== undefined && res !== null) {
        if (
          res.currentWalletCredit !== undefined &&
          res.currentWalletCredit !== null &&
          res.currentWalletCredit > 0
        ) {
          this.currentWalletCredit = res.currentWalletCredit;
        } else {
          this.currentWalletCredit = 0;
        }
      }
    });
  }

  isMobileScreen(): boolean {
    return window.innerWidth <= 768;
  }

  public events: SchedulerEvent[] = [
    {
      id: 1,
      title: 'Breakfast',
      start: new Date(),
      end: new Date(),
      recurrenceRule: 'FREQ=DAILY;COUNT=5;',
    },
  ];

  public selection: SlotRange = {
    start: new Date(new Date().setHours(8, 0, 0, 0)),
    end: new Date(new Date().setHours(9, 30, 0, 0)),
    isAllDay: false,
  };

  public onSelectionChange(event: SlotRange): void {
    const clickedDate = this.datePipe.transform(new Date(event.start), 'yyyy-MM-dd');
    const currentDate = new Date();
    if (clickedDate >= this.datePipe.transform(new Date(), 'yyyy-MM-dd')) {
      if (event.isAllDay) {
        if (clickedDate > this.datePipe.transform(new Date(), 'yyyy-MM-dd')) {
          const scheduleDateFromCalendar = new Date(event.start);
          scheduleDateFromCalendar.setHours(currentDate.getHours());
          scheduleDateFromCalendar.setMinutes(currentDate.getMinutes());
          scheduleDateFromCalendar.setSeconds(currentDate.getSeconds());
          const newParseDate = this.datePipe.transform(
            scheduleDateFromCalendar,
            'yyyy-MM-ddTHH:mm:ss',
          );
          var params = {
            scheduleDateFromCalendar: newParseDate,
          };
          this.router.navigate(['create-post', params]);
        } else {
          const scheduleDateFromCalendar = currentDate;
          const newParseDate = this.datePipe.transform(
            scheduleDateFromCalendar,
            'yyyy-MM-ddTHH:mm:ss',
          );
          var params = {
            scheduleDateFromCalendar: newParseDate,
          };
          this.router.navigate(['create-post', params]);
        }
      } else {
        const scheduleDateFromCalendar = new Date(event.start);
        const newParseDate = this.datePipe.transform(
          scheduleDateFromCalendar,
          'yyyy-MM-ddTHH:mm:ss',
        );
        var params = {
          scheduleDateFromCalendar: newParseDate,
        };
        this.router.navigate(['create-post', params]);
      }
    }
  }

  onEditClick({ sender, event }: EventClickEvent): void {
    this.showEditModal();
    const params = {
      postId: btoa(event.dataItem.postId),
    };
    this.navigateToEditPost(params);
  }

  hasSufficientCredit(res) {
    return (
      res !== undefined &&
      res.currentWalletCredit !== undefined &&
      res.currentWalletCredit !== null &&
      res.currentWalletCredit > 0
    );
  }

  showEditModal() {
    this.title = 'Edit the scheduled post';
    this.displayStyle = 'block';
  }

  navigateToEditPost(params) {
    this.router.navigate(['create-post', params]);
  }

  handleInsufficientCredit() {
    this.toasterService.error(
      NotificationMessage.noCreditAvailableMsg,
      '',
      this.commonService.toasterMessageConfiguration,
    );
  }

  onDeleteSchedule(event: any): void {
    this.scheduleService.delete(event.dataItem.scheduleId).subscribe(res => {
      this.ngOnInit();
    });
  }

  public getSlotClass = (args: SlotClassArgs) => {
    const currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    const slotDates = this.datePipe.transform(new Date(args.start), 'yyyy-MM-dd');
    return {
      disableLink: slotDates < currentDate,
    };
  };
}
