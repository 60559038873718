<div class="q-content">
  <div class="q-content-bg">
    <div
      class="q-content-header d-flex justify-content-between align-items-center flex-wrap mb-20 gap-20"
    >
      <div class="heading">
        <div class="title">Choose your plan</div>
        <div class="d-flex-row">
          <p class="description d-flex-col-xl-5 d-flex-col-sm-12 pl-10 pr-10">
            Get easy access to our content with your desired plan. We are excited to offer a special
            licensing deal to our users.
          </p>
        </div>
      </div>
      <div class="d-flex align-items-center q-toggle-button">
        <button
          [class.k-selected]="frequency === frequencyEnum.Month"
          kendoButton
          fillMode="flat"
          (click)="onFrequencyToggle(frequencyEnum.Month)"
        >
          <span>Monthly</span>
        </button>
        <button
          [class.k-selected]="frequency !== frequencyEnum.Month"
          kendoButton
          fillMode="flat"
          (click)="onFrequencyToggle(frequencyEnum.Year)"
        >
          <span>Yearly</span>
        </button>
      </div>
    </div>
    <div class="d-flex-row justify-content-center">
      <div class="d-flex-col-lg-4 d-flex-col-xs-12 d-flex-col mb-15">
        <div class="d-flex align-items-center justify-content-center h-100">
          <img src="assets/images/subscription.svg" alt="subscription" />
        </div>
      </div>
      <div class="d-flex-col-sm-8 d-flex-col mb-20">
        <div class="subscription d-flex-row justify-content-center">
          <div
            class="d-flex-col-lg-6 d-flex-col-xs-12 d-flex-col mb-15"
            *ngFor="let item of subscriptionDetailData"
          >
            <div
              class="active-plan-select"
              [ngClass]="{
                'current-plan':
                  (activeCurrentPlanName === planName.FreePlan &&
                    item.plan.externalName === planName.FreePlan) ||
                  (activeCurrentPlanName !== planName.FreePlan &&
                    item.plan.externalName !== planName.FreePlan &&
                    activePlanFrequency === frequency)
              }"
            >
              <span>Current Plan </span>
            </div>
            <div
              class="subscription-details h-100"
              [ngClass]="{
                'plan-active':
                  (activeCurrentPlanName === planName.FreePlan &&
                    item.plan.externalName === planName.FreePlan) ||
                  (activeCurrentPlanName !== planName.FreePlan &&
                    item.plan.externalName !== planName.FreePlan &&
                    activePlanFrequency === frequency)
              }"
            >
              <div class="subscription-content-details">
                <div class="subscription-header">
                  <p class="title">
                    {{ item.plan.externalName }}
                  </p>
                  <span>{{ item.planData.total_credit }} Credit/month</span>
                  <p *ngIf="item.plan.externalName === planName.FreePlan" class="status">
                    Free Upto 1000 Users Forever
                  </p>
                  <p
                    *ngIf="
                      item.plan.externalName !== planName.FreePlan &&
                      item.plan.periodUnit === frequencyEnum.Month
                    "
                    class="status"
                  >
                    {{ item.plan.price / 100 }} / Month
                  </p>
                  <p
                    *ngIf="
                      item.plan.externalName !== planName.FreePlan &&
                      item.plan.periodUnit === frequencyEnum.Year
                    "
                    class="status"
                  >
                    {{ item.plan.price / (100 * 12) }} / Month
                  </p>
                </div>
                <div
                  class="subscription-content"
                  *ngIf="item.plan.externalName === planName.FreePlan"
                >
                  <div class="d-flex align-items-center gap-15 mb-20">
                    <span class="fa-solid fa-check font-size-18 check-blue"></span>
                    <p class="plan-details">3 Channels</p>
                  </div>
                  <div class="d-flex align-items-center gap-15 mb-20">
                    <span class="fa-solid fa-check font-size-18 check-blue"></span>
                    <p class="plan-details">75 credit extra to add more channel</p>
                  </div>
                  <div class="d-flex align-items-center gap-15 mb-20">
                    <span class="fa-solid fa-check font-size-18 check-blue"></span>
                    <p class="plan-details">Planning and Scheduling</p>
                  </div>
                  <div class="d-flex align-items-center gap-15 mb-20">
                    <span class="fa-solid fa-check font-size-18 check-blue"></span>
                    <p class="plan-details">Posting ( 2 Credits/post)</p>
                  </div>
                  <div class="d-flex align-items-center gap-15 mb-20">
                    <span class="fa-solid fa-check font-size-18 check-blue"></span>
                    <p class="plan-details">Analytics, Insights & Reporting</p>
                  </div>
                  <div class="d-flex align-items-center gap-15 mb-20">
                    <span class="fa-solid fa-check font-size-18 check-blue"></span>
                    <p class="plan-details">Support</p>
                  </div>
                </div>
                <div
                  class="subscription-content"
                  *ngIf="item.plan.externalName !== planName.FreePlan"
                >
                  <div class="d-flex align-items-center gap-15 mb-20">
                    <span class="fa-solid fa-check font-size-18 check-blue"></span>
                    <p class="plan-details">8 Channels</p>
                  </div>
                  <div class="d-flex align-items-center gap-15 mb-20">
                    <span class="fa-solid fa-check font-size-18 check-blue"></span>
                    <p class="plan-details">75 credit extra to add more channel</p>
                  </div>
                  <div class="d-flex align-items-center gap-15 mb-20">
                    <span class="fa-solid fa-check font-size-18 check-blue"></span>
                    <p class="plan-details">Planning and Scheduling</p>
                  </div>
                  <div class="d-flex align-items-center gap-15 mb-20">
                    <span class="fa-solid fa-check font-size-18 check-blue"></span>
                    <p class="plan-details">Posting ( 2 Credits/post)</p>
                  </div>
                  <div class="d-flex align-items-center gap-15 mb-20">
                    <span class="fa-solid fa-check font-size-18 check-blue"></span>
                    <p class="plan-details">Analytics, Insights & Reporting</p>
                  </div>
                  <div class="d-flex align-items-center gap-15 mb-20">
                    <span class="fa-solid fa-check font-size-18 check-blue"></span>
                    <p class="plan-details">Support</p>
                  </div>
                  <div class="d-flex align-items-center gap-15 mb-20">
                    <span class="fa-solid fa-check font-size-18 check-blue"></span>
                    <p class="plan-details">Queue/Scheduling</p>
                  </div>
                  <div class="d-flex align-items-center gap-15 mb-20">
                    <span class="fa-solid fa-check font-size-18 check-blue"></span>
                    <p class="plan-details">AI Assistant</p>
                  </div>
                  <div class="d-flex align-items-center gap-15 mb-20">
                    <span class="fa-solid fa-check font-size-18 check-blue"></span>
                    <p class="plan-details">Post Performance Report</p>
                  </div>
                  <div class="d-flex align-items-center gap-15">
                    <span class="fa-solid fa-check font-size-18 check-blue"></span>
                    <p class="plan-details">Chat Support</p>
                  </div>

                  <div
                    *ngIf="
                      item.plan.externalName !== planName.FreePlan &&
                      (activeCurrentPlanName !== planName.PaidPlan ||
                        (activeCurrentPlanName === planName.PaidPlan &&
                          activePlanFrequency !== frequency))
                    "
                    class="plan-button d-flex justify-content-center"
                  >
                    <button
                      kendoButton
                      (click)="onBuyNowClick(item, item.plan.externalName !== planName.PaidPlan)"
                    >
                      Get this Plan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-center flex-column heading plan-title">
    <div class="title font-size-22">Plans comparison</div>
    <p class="description text-align-center wp-100">
      Pick that plan that fits your needs the best.
    </p>
  </div>
  <div class="product-fullsuite">
    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list plan-detail-header">
            <div class="plan-dec">
              Take your desired plan to get access to our content easily. we like to offer special
              license offer to our users.
            </div>
          </div>
        </div>
      </div>
      <div
        *ngFor="let item of subscriptionDetailData"
        [ngClass]="
          item.plan.externalName === planName.FreePlan ? 'product' : 'product bg-color-col'
        "
      >
        <div class="plan-detail">
          <div class="list plan-detail-header">
            <div class="plan-detail-title mb-20">
              <span *ngIf="item.plan.externalName === planName.FreePlan">Free</span>
              <span *ngIf="item.plan.externalName !== planName.FreePlan">Paid</span>
            </div>
            <div class="plan-amount">
              <span
                class="mb-15 display-inline"
                *ngIf="item.plan.externalName === planName.FreePlan"
                >month/year/channel profile</span
              >
              <span
                class="mb-20 display-inline"
                *ngIf="
                  item.plan.externalName !== planName.FreePlan &&
                  item.plan.periodUnit === frequencyEnum.Month
                "
                class="status"
              >
                {{ item.plan.price / 100 }} / Month
              </span>

              <span
                class="mb-20 display-inline"
                *ngIf="
                  item.plan.externalName !== planName.FreePlan &&
                  item.plan.periodUnit === frequencyEnum.Year
                "
                class="status"
              >
                {{ item.plan.price / (100 * 12) }} / Month
              </span>
            </div>
            <div>
              <p *ngIf="item.plan.externalName === planName.FreePlan" class="status">
                Free Upto 1000 Users Forever
              </p>
              <button
                *ngIf="
                  item.plan.externalName !== planName.FreePlan &&
                  (activeCurrentPlanName !== planName.PaidPlan ||
                    (activeCurrentPlanName === planName.PaidPlan &&
                      activePlanFrequency !== frequency))
                "
                kendoButton
                (click)="onBuyNowClick(item, item.plan.externalName !== planName.PaidPlan)"
                class="mt-10"
              >
                Get this Plan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <div class="title">Channels</div>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center"></div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center"></div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <p>Facebook (Post)(Pages)</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <p>Instagram (Post)(Creator/Business accounts)</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <p>LinkedIn (Profiles and Organization)</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <p>Twitter (Profiles)</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <p>YouTube (Channels)</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <div class="title">Planning and scheduling</div>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <div class="text-align-center"></div>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list">
            <div class="text-align-center"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <p>Posting</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center">
            <span>2 credits / post</span>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center">
            <span>2 credits / post</span>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <p>Queue/Scheduling</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-times font-size-18 cross-red"></i>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <p>Calendar View</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-times font-size-18 cross-red"></i>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <p>Draft Posts</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <p>Customize Posts Per Profile</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <p>Post previews</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <p>Post Labeling / Category</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <p>Saved Hashtags</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <p>Requeue post</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-times font-size-18 cross-red"></i>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <p>AI Assistant</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-times font-size-18 cross-red"></i>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <div class="title">Analytics, Insights & Reporting</div>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center"></div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center"></div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <p>Social Media Report</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <p>Post Performance Report</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-times font-size-18 cross-red"></i>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <div class="title mb-0">Support</div>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center"></div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center"></div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list">
            <p>Email Support</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex product-container">
      <div class="product">
        <div class="plan-detail">
          <div class="list no-border">
            <p>Chat Support</p>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="plan-detail">
          <div class="list text-align-center no-border">
            <i class="fa-solid fa-times font-size-18 cross-red"></i>
          </div>
        </div>
      </div>
      <div class="product bg-color-col">
        <div class="plan-detail">
          <div class="list text-align-center no-border">
            <i class="fa-solid fa-check font-size-18 check-green"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div kendoDialogContainer></div>
</div>
