import type { YouTubeUserInfoDto, YoutubeCallbackRequestDto } from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { VideoCategory } from '../google/apis/you-tube/v3/data/models';
import type { AccountDetailDto } from '../social-account/dtos/models';

@Injectable({
  providedIn: 'root',
})
export class YoutubeService {
  apiName = 'Default';

  getVideoCategoriesList = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, VideoCategory[]>(
      {
        method: 'GET',
        url: '/api/app/youtube/video-categories-list',
      },
      { apiName: this.apiName, ...config },
    );

  getYoutubeCallBack = (input: YoutubeCallbackRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>(
      {
        method: 'GET',
        responseType: 'text',
        url: '/api/app/youtube/youtube-call-back',
        params: {
          code: input.code,
          state: input.state,
          error: input.error,
          error_code: input.error_code,
          error_description: input.error_description,
          error_reason: input.error_reason,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getYoutubeChannelByChannelId = (
    channelId: string,
    token: string,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, YouTubeUserInfoDto>(
      {
        method: 'GET',
        url: `/api/app/youtube/youtube-channel-by-channel-id/${channelId}`,
        params: { token },
      },
      { apiName: this.apiName, ...config },
    );

  getYoutubeUserDetails = (state: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AccountDetailDto>(
      {
        method: 'GET',
        url: '/api/app/youtube/youtube-user-details',
        params: { state },
      },
      { apiName: this.apiName, ...config },
    );

  preparePageUrl = (returnUrl: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/app/youtube/prepare-page-url',
        params: { returnUrl },
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
