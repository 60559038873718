import { mapEnumToOptions } from '@abp/ng.core';

export enum ItemPrice_TrialEndActionEnum {
  UnKnown = 0,
  SiteDefault = 1,
  ActivateSubscription = 2,
  CancelSubscription = 3,
}

export const itemPrice_TrialEndActionEnumOptions = mapEnumToOptions(ItemPrice_TrialEndActionEnum);
