<div class="q-content">
  <div class="q-content-bg">
    <kendo-scheduler
      [editable]="true"
      [kendoSchedulerBinding]="scheduleData"
      kendoSchedulerSlotSelectable
      [(slotSelection)]="selection"
      (slotSelectionChange)="onSelectionChange($event)"
      (eventClick)="onEditClick($event)"
      [slotClass]="getSlotClass"
    >
      <kendo-scheduler-month-view> </kendo-scheduler-month-view>
      <kendo-scheduler-week-view> </kendo-scheduler-week-view>
      <kendo-scheduler-day-view> </kendo-scheduler-day-view>
      <kendo-scheduler-agenda-view> </kendo-scheduler-agenda-view>
    </kendo-scheduler>
  </div>
</div>
