import { mapEnumToOptions } from '@abp/ng.core';

export enum Subscription_SubscriptionSubscriptionItem_ChargeOnOptionEnum {
  UnKnown = 0,
  Immediately = 1,
  OnEvent = 2,
}

export const subscription_SubscriptionSubscriptionItem_ChargeOnOptionEnumOptions = mapEnumToOptions(
  Subscription_SubscriptionSubscriptionItem_ChargeOnOptionEnum,
);
