import { Component, Input, OnInit } from '@angular/core';
import { BadgeAlign, BadgePosition } from '@progress/kendo-angular-indicators';
import {
  arrowsSwapIcon,
  commentIcon,
  facebookBoxIcon,
  moreHorizontalIcon,
  shareIcon,
  thumbUpOutlineIcon,
  xIcon,
} from '@progress/kendo-svg-icons';
import { Observable } from 'rxjs';
import { CommonService, SocialMediaPlatForms } from 'src/app';

@Component({
  selector: 'app-preview-post',
  templateUrl: './preview-post.component.html',
  styleUrls: ['./preview-post.component.scss'],
})
export class PreviewPostComponent implements OnInit {
  private showFullCaption: { [index: number]: boolean } = {};
  profilesDetails: any = [];
  videoHeights: string[] = [];

  rolesRights = SocialMediaPlatForms;
  isShowPreviewDefaultImage: boolean = true;

  public icons = {
    facebookBoxIcon: facebookBoxIcon,
    likeIcon: thumbUpOutlineIcon,
    shareIcon: shareIcon,
    commentIcon: commentIcon,
    repostIcon: arrowsSwapIcon,
    moreIcon: moreHorizontalIcon,
    closeIcon: xIcon,
  };

  public badgeAlign: BadgeAlign = { vertical: 'bottom', horizontal: 'end' };
  public position: BadgePosition = 'inside';

  public pmAvatar = 'https://demos.telerik.com/kendo-ui/content/web/Customers/GOURL.jpg';

  @Input() triggerDataToPreview: Observable<any>;

  quillData: any;

  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.triggerDataToPreview?.subscribe(data => {
      this.isShowPreviewDefaultImage = data.every(x => x.isSelected === false);
      this.profilesDetails = data;
    });
  }

  getInitials(name: string): string {
    // Extract the first letter of the name
    return name ? name[0].toUpperCase() : '';
  }

  toggleCaptionDisplay(event: Event, index): void {
    event.preventDefault();
    this.showFullCaption[index] = !this.showFullCaption[index];
  }

  checkVideoDuration(urlBase64Value: string, index: number) {
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      const videoDuration = video.duration;
      this.videoHeights[index] = videoDuration > 60 ? '100%' : '600px';
    };

    video.src = urlBase64Value;
  }
}
