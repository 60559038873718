export interface PaymentHistoryResponseModel {
  invoiceId?: string;
  paidAmount?: string;
  status?: string;
  invoiceDate?: string;
  paymentDate?: string;
  paymentMethod?: string;
}

export interface RetrieveTransactions {
  customerId?: string;
  limit: number;
  offset?: string;
  status: number;
  paymentMethod: number;
}
