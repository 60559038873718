import { mapEnumToOptions } from '@abp/ng.core';

export enum HostedPage_StateEnum {
  UnKnown = 0,
  Created = 1,
  Requested = 2,
  Succeeded = 3,
  Cancelled = 4,
  Failed = 5,
  Acknowledged = 6,
}

export const hostedPage_StateEnumOptions = mapEnumToOptions(HostedPage_StateEnum);
