import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { FacebookCallBackRequestDto } from '../facebook/dtos/models';
import type { InstagramCallBackRequestDto } from '../instagram/models';
import type { LinkedInOrganizationCallBackRequestDto } from '../linked-in-organization/dto/models';
import type { LinkedInCallBackRequestDto } from '../linked-in/dto/models';
import type { IActionResult } from '../microsoft/asp-net-core/mvc/models';
import type { TwitterCallBackRequestDto } from '../twitter/dto/models';
import type { YoutubeCallbackRequestDto } from '../youtube/dtos/models';

@Injectable({
  providedIn: 'root',
})
export class CallbackService {
  apiName = 'Default';

  facebookPage = (dto: FacebookCallBackRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IActionResult>(
      {
        method: 'GET',
        url: '/api/Callback/facebook-page',
        params: {
          code: dto.code,
          state: dto.state,
          error: dto.error,
          error_code: dto.error_code,
          error_description: dto.error_description,
          error_reason: dto.error_reason,
        },
      },
      { apiName: this.apiName, ...config },
    );

  googleAuthentication = (dto: YoutubeCallbackRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IActionResult>(
      {
        method: 'GET',
        url: '/api/Callback/google-authentication',
        params: {
          code: dto.code,
          state: dto.state,
          error: dto.error,
          error_code: dto.error_code,
          error_description: dto.error_description,
          error_reason: dto.error_reason,
        },
      },
      { apiName: this.apiName, ...config },
    );

  instagramBusiness = (dto: InstagramCallBackRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IActionResult>(
      {
        method: 'GET',
        url: '/api/Callback/instagram-business',
        params: {
          code: dto.code,
          state: dto.state,
          error: dto.error,
          error_code: dto.error_code,
          error_description: dto.error_description,
          error_reason: dto.error_reason,
        },
      },
      { apiName: this.apiName, ...config },
    );

  linkedInOrganization = (
    dto: LinkedInOrganizationCallBackRequestDto,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, IActionResult>(
      {
        method: 'GET',
        url: '/api/Callback/linkedin-organization',
        params: {
          code: dto.code,
          state: dto.state,
          error: dto.error,
          error_code: dto.error_code,
          error_description: dto.error_description,
          error_reason: dto.error_reason,
        },
      },
      { apiName: this.apiName, ...config },
    );

  linkedInProfile = (dto: LinkedInCallBackRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IActionResult>(
      {
        method: 'GET',
        url: '/api/Callback/linkedin-profile',
        params: {
          code: dto.code,
          state: dto.state,
          error: dto.error,
          error_code: dto.error_code,
          error_description: dto.error_description,
          error_reason: dto.error_reason,
        },
      },
      { apiName: this.apiName, ...config },
    );

  twitterProfile = (dto: TwitterCallBackRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IActionResult>(
      {
        method: 'GET',
        url: '/api/Callback/twitter-profile',
        params: {
          oauth_token: dto.oauth_token,
          oauth_verifier: dto.oauth_verifier,
          state: dto.state,
          error: dto.error,
          error_code: dto.error_code,
          error_description: dto.error_description,
          error_reason: dto.error_reason,
        },
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
