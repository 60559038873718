import { mapEnumToOptions } from '@abp/ng.core';

export enum Customer_CustomerParentAccountAccess_PortalEditChildSubscriptionsEnum {
  UnKnown = 0,
  Yes = 1,
  ViewOnly = 2,
  No = 3,
}

export const customer_CustomerParentAccountAccess_PortalEditChildSubscriptionsEnumOptions =
  mapEnumToOptions(Customer_CustomerParentAccountAccess_PortalEditChildSubscriptionsEnum);
