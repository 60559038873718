import { Component, OnInit } from '@angular/core';
import { InstagramService } from '@proxy/instagram';
import { SocialAccountsService } from '@proxy/social-account';
import { Select, Store } from '@ngxs/store';
import { ToasterService } from '@abp/ng.theme.shared';
import { GetAllProfiles } from 'src/core/state/common.action';
import { FacebookService } from '@proxy/facebook';
import { ActivatedRoute } from '@angular/router';
import { NotificationMessage } from 'src/core/enums/notification-message';
import { CommonService, SocialMediaPlatForms } from 'src/app';
import { LinkedInService } from '@proxy/linked-in';
import { TwitterService } from '@proxy/twitter';
import { DrawerSelectEvent } from '@progress/kendo-angular-layout';
import {
  SVGIcon,
  menuIcon,
  facebookIcon,
  linkedinIcon,
  twitterIcon,
  youtubeIcon,
} from '@progress/kendo-svg-icons';
import { BadgeAlign, BadgePosition } from '@progress/kendo-angular-indicators';
import { ProgressBarOrientation } from '@progress/kendo-angular-progressbar';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { PostService } from '@proxy/posts';
import { Observable, forkJoin } from 'rxjs';
import { LinkedInOrganizationService } from '@proxy/linked-in-organization';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonState } from 'src/core/state';
import { PlanName } from 'src/core/enums/chargbee-enum';
import { environment } from 'src/environments/environment';
import { AuthService } from '@abp/ng.core';
import { YoutubeService } from '@proxy/youtube';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  seeAllPost = true;
  seriesGainEqual = false;
  seriesLossEqual = false;

  menuSvg: SVGIcon = menuIcon;
  accountTypeEnum = SocialMediaPlatForms;

  badgeAlign: BadgeAlign = { vertical: 'bottom', horizontal: 'end' };
  position: BadgePosition = 'inside';

  page = 1;
  pageSize = 5;
  maxSize: number;
  activeItemId: any = 1;
  subscribersGainLosslist: any;
  selectedUser: any;
  accountTypeId: any;
  selected: any;
  dropdownOpen = false;

  orientation: ProgressBarOrientation = 'horizontal';
  facebookSvg: SVGIcon = facebookIcon;
  linkedinSvg: SVGIcon = linkedinIcon;
  twitterSvg: SVGIcon = twitterIcon;
  youTubeSvg: SVGIcon = youtubeIcon;

  viewPostData: any = [];
  accountDetails = [];
  keyMetricsList: any[] = [];
  userListBySocialId: any[] = [];
  totalPostData: any[] = [];

  socialPlatFormDetails = [
    {
      id: 1,
      name: 'Facebook',
      icon: 'facebook-f',
      svgIcon: facebookIcon,
      addtionalDetails: [{ text: 'Page', socialAccountId: 1 }],
    },
    {
      id: 2,
      name: 'Instagram',
      icon: 'instagram',
      svgIcon: linkedinIcon,
      addtionalDetails: [{ text: 'Business Account', socialAccountId: 2 }],
    },
    {
      id: 5,
      name: 'LinkedIn',
      icon: 'linkedin-in',
      svgIcon: linkedinIcon,
      addtionalDetails: [
        { text: 'Profile', socialAccountId: 3 },
        { text: 'Organization Profile', socialAccountId: 5 },
      ],
    },
    {
      id: 4,
      name: 'Twitter',
      icon: 'x-twitter',
      svgIcon: twitterIcon,
      addtionalDetails: [{ text: 'Profile', socialAccountId: 4 }],
    },
    {
      id: 6,
      name: 'YouTube',
      icon: 'youtube',
      svgIcon: youtubeIcon,
      addtionalDetails: [{ text: 'Channel', socialAccountId: 6 }],
    },
  ];

  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    speed: 300,
    infinite: false,
    responsive: [
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  @Select(CommonState.subscriptionPlanData)
  subscriptionPlanData$: Observable<any>;

  currentWalletCredit = 0;
  currentPlanName: string;

  constructor(
    private instagramService: InstagramService,
    private facebookService: FacebookService,
    private socialAccountsService: SocialAccountsService,
    public commonService: CommonService,
    private toasterService: ToasterService,
    private store: Store,
    private route: ActivatedRoute,
    private linkedInService: LinkedInService,
    private twitterService: TwitterService,
    private postService: PostService,
    private linkedInOrganizationService: LinkedInOrganizationService,
    private youtubeService: YoutubeService,
    private spinner: NgxSpinnerService,
    public authService: AuthService,
  ) {}

  onSelect(ev: DrawerSelectEvent): void {
    this.selected = ev.item.text;
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated) {
      this.getAccountActivity(!this.seeAllPost);
      this.getAccountDetails();

      this.route.queryParams.subscribe(params => {
        if (params['isNew']) {
          this.store.dispatch(new GetAllProfiles()).subscribe();
        }
      });

      this.subscriptionPlanData$.subscribe(res => {
        if (res !== undefined && res !== null) {
          if (
            res.currentWalletCredit !== undefined &&
            res.currentWalletCredit !== null &&
            res.currentWalletCredit > 0
          ) {
            this.currentWalletCredit = res.currentWalletCredit;
            this.currentPlanName = res.currentPlanName;
          } else {
            this.currentWalletCredit = 0;
          }
        }
      });
    }
  }

  getInitials(name: string): string {
    // Extract the first letter of the name
    return name ? name[0].toUpperCase() : '';
  }

  getAccountDetails(): void {
    const data: any = {};

    this.socialAccountsService.getAll(data).subscribe(x => {
      this.spinner.hide();
      this.accountDetails = x;

      this.getUserListBySocialMedia(this.socialPlatFormDetails[0].id);
      this.updateProfilePictures();
    });
  }

  getUserListBySocialMedia(socialMediaId: any): void {
    if (!this.dropdownOpen) {
      this.accountTypeId = socialMediaId;
      this.subscribersGainLosslist = null;
      this.totalPostData = [];
      this.keyMetricsList = [];
      this.userListBySocialId = [];
      this.selectedUser = null;
      this.accountDetails.forEach(element => {
        if (element.accountType === socialMediaId && element.isActive) {
          this.userListBySocialId.push(element);
        }
      });

      if (this.userListBySocialId.length > 0) {
        this.selectedUser = this.userListBySocialId[0].id;
        this.getChartData();
      }
    }
  }

  getChartData(): void {
    this.subscribersGainLosslist = null;
    this.totalPostData = [];
    this.keyMetricsList = [];
    let param;

    switch (this.accountTypeId) {
      case SocialMediaPlatForms.Instagram:
        param = {
          data1: this.instagramService.getDashboardFollowerUnFollowerData(this.selectedUser),
          data2: this.instagramService.getDashboardPostCount(this.selectedUser),
          data3: this.instagramService.getDashboardKeyMetricsInsights(this.selectedUser),
        };
        break;

      case SocialMediaPlatForms.Facebook:
        param = {
          data1: this.facebookService.getDashboardFollowerUnFollowerData(this.selectedUser),
          data2: this.facebookService.getDashboardPostCount(this.selectedUser),
          data3: this.facebookService.getDashboardKeyMetricsInsights(this.selectedUser),
        };
        break;

      case SocialMediaPlatForms.LinkedInOrganization:
        param = {
          data1: this.linkedInOrganizationService.getDashboardFollowerUnFollowerData(
            this.selectedUser,
          ),
          data2: this.linkedInOrganizationService.getDashboardPostCount(this.selectedUser),
          data3: this.linkedInOrganizationService.getDashboardKeyMetricsInsights(this.selectedUser),
        };
        break;
      default:
    }

    if (param) {
      this.loadData(param);
    }
  }

  loadData(param: any) {
    this.spinner.show();
    forkJoin(param).subscribe(
      (result: { data1: any; data2: any; data3: any }) => {
        this.spinner.hide();
        this.subscribersGainLosslist = result.data1;
        this.checkSubscribersGainLossLength(this.subscribersGainLosslist);
        this.totalPostData = result.data2.series;
        this.keyMetricsList = result.data3.series;
      },
      error => {
        this.spinner.hide();
      },
    );
  }

  updateProfilePictures(): any {
    this.accountDetails.forEach(item => {
      item.img = item.profilePicBase64;
    });
  }

  onAddAccountClick(data: any): void {
    const selectedAccount = data.socialAccountId;

    if (selectedAccount === SocialMediaPlatForms.LinkedInOrganization) {
      return;
    }

    if (this.currentWalletCredit <= 0) {
      this.toasterService.error(
        NotificationMessage.noCreditAvailableMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return;
    }

    let maxAccountsAllowed =
      this.currentPlanName === PlanName.PaidPlan
        ? environment.maxFreeAccountsAllowedForPaidPlan
        : environment.maxFreeAccountsAllowedForFreePlan;
    let maxCreditForAddAccounts = environment.perAccoutCharge;

    if (
      this.accountDetails.length >= maxAccountsAllowed &&
      this.currentWalletCredit < maxCreditForAddAccounts
    ) {
      this.toasterService.error(
        NotificationMessage.lessCreditAvailableMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return;
    }

    const returnUrl = window.location.origin + '/add-profile';
    this.commonService.setDataInLocalStorage('selectedAccount', selectedAccount);

    const openPageUrl = (service: any) => {
      service.preparePageUrl(returnUrl).subscribe(prepareBusinessUrl => {
        window.open(prepareBusinessUrl, '_blank');
      });
    };

    switch (selectedAccount) {
      case SocialMediaPlatForms.Facebook:
        this.facebookService.preparePageUrlByReturnUrl(returnUrl).subscribe(prepareBusinessUrl => {
          window.open(prepareBusinessUrl, '_blank');
        });
        break;

      case SocialMediaPlatForms.Twitter:
        openPageUrl(this.twitterService);
        break;

      case SocialMediaPlatForms.LinkedIn:
        openPageUrl(this.linkedInService);
        break;

      case SocialMediaPlatForms.LinkedInOrganization:
        this.linkedInOrganizationService
          .prepareOrganizationPageUrl(returnUrl)
          .subscribe(prepareBusinessUrl => {
            window.open(prepareBusinessUrl, '_blank');
          });
        break;

      case SocialMediaPlatForms.YouTube:
        openPageUrl(this.youtubeService);
        break;

      default:
        this.instagramService
          .prepareBusinessUrlByReturnUrl(returnUrl)
          .subscribe(prepareBusinessUrl => {
            window.open(prepareBusinessUrl, '_blank');
          });
    }
  }

  onProfileToggle(id: number, isActive: any): void {
    this.socialAccountsService.updateStatus(id, isActive).subscribe(res => {
      this.store.dispatch(new GetAllProfiles()).subscribe();
      if (isActive) {
        this.toasterService.success(
          NotificationMessage.accountActivatedMsg,
          '',
          this.commonService.toasterMessageConfiguration,
        );
      } else {
        this.toasterService.error(
          NotificationMessage.accountDeactivatedMsg,
          '',
          this.commonService.toasterMessageConfiguration,
        );
      }
    });
  }

  parseAdditionalInformation(additionalInformation: string) {
    return JSON.parse(additionalInformation);
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'startsWith',
  };

  getAccountActivity(seeAllPost) {
    var param;
    this.seeAllPost = !seeAllPost;

    param = {
      mediaUrls: null,
      skipCount: seeAllPost ? '' : this.page,
      maxResultCount: seeAllPost ? '' : this.pageSize,
    };

    this.postService.getList(param).subscribe(res => {
      this.viewPostData = res.items;
    });
  }

  onSocialMediaClicked(itemId: string): void {
    if (!this.dropdownOpen) {
      this.activeItemId = itemId;
    }
  }

  isActiveItem(itemId: string): boolean {
    return this.activeItemId === itemId;
  }

  onDropdownClick(event: Event) {
    event.stopPropagation();
    this.dropdownOpen = false;
  }

  getDescriptionForActivity(items) {
    for (const image of items) {
      const isImage = this.commonService.isImage(image.value);
      const isVideo = this.commonService.isVideo(image.value);

      if (isImage && isVideo) {
        return 'Posted video/image on  ';
      } else if (isImage) {
        return 'Posted image on  ';
      } else if (isVideo) {
        return 'Posted video on  ';
      }
    }
  }

  checkSubscribersGainLossLength(data) {
    this.seriesGainEqual = data.series[0].data.every(val => val === 0);
    this.seriesLossEqual = data.series[1].data.every(val => val === 0);
  }
}
