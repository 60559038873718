export interface InstagramCallBackRequestDto {
  code?: string;
  state?: string;
  error?: string;
  error_code?: string;
  error_description?: string;
  error_reason?: string;
}

export interface InstagramBusinessAccount {
  id?: string;
  name?: string;
  username?: string;
  profile_picture_url?: string;
  followers_count: number;
  follows_count: number;
  media_count: number;
  biography?: string;
  stories: Stories;
  tags: Tags;
}

export interface Stories {
  data: StoriesData[];
}

export interface StoriesData {
  id?: string;
}

export interface Tags {
  data: TagsData[];
}

export interface TagsData {
  id?: string;
}
