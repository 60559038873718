import { Component, OnInit } from '@angular/core';
import { HashtagService } from '@proxy/hashtags';
import { ListService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { CommonService, CreateHashtagComponent, NotificationMessage } from 'src/app';
import { DialogService } from '@progress/kendo-angular-dialog';
import { SortDescriptor, orderBy, process } from '@progress/kendo-data-query';
import {
  GridDataResult,
  PageChangeEvent,
  PagerPosition,
  PagerType,
} from '@progress/kendo-angular-grid';
@Component({
  selector: 'app-list-hashtag',
  templateUrl: './list-hashtag.component.html',
  styleUrls: ['./list-hashtag.component.scss'],
})
export class ListHashtagComponent implements OnInit {
  hashtagList: any[];
  originalhashtagList: any[];
  isListView = true;
  isMobile: boolean = false;
  sorting = null;
  gridHeight: number;
  searchText = '';
  public multiple = false;
  public allowUnsort = true;
  //#region  Pagination
  public gridView: GridDataResult;
  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public pagerposition: PagerPosition = 'bottom';
  public pageSize = 20;
  public skip = 0;

  public actionMenu = [
    {
      text: 'Edit',
      iconClass: 'far fa-pen',
    },
    {
      text: 'Delete',
      iconClass: 'far fa-trash-alt',
    },
  ];

  public sort: SortDescriptor[] = [
    {
      field: '',
      dir: 'asc',
    },
  ];

  constructor(
    private hashtagService: HashtagService,
    public readonly list: ListService,
    private toasterService: ToasterService,
    private dialogService: DialogService,
    public commonService: CommonService,
  ) {}

  ngOnInit(): void {
    this.getList();
    this.checkScreenSize();
    this.calculateGridHeight();
    window.addEventListener('resize', () => {
      this.checkScreenSize();
      this.calculateGridHeight();
    });
  }

  checkScreenSize() {
    this.isMobile = window.innerWidth < 450;
    if (this.isMobile) {
      this.isListView = !this.isMobile;
    }
  }

  getList(): void {
    this.hashtagService.getAll().subscribe(res => {
      this.hashtagList = res;
      this.originalhashtagList = res;
      this.loadItems();
    });
  }

  onFilter(event: any): void {
    const filteredData = process(
      this.originalhashtagList.slice(this.skip, this.skip + this.pageSize),
      {
        filter: {
          logic: 'or',
          filters: [
            { field: 'hashtagCollectionName', operator: 'contains', value: this.searchText },
            { field: 'hashtags', operator: 'contains', value: this.searchText },
          ],
        },
      },
    );
    const filteredGridData = process(this.originalhashtagList, {
      filter: {
        logic: 'or',
        filters: [
          { field: 'hashtagCollectionName', operator: 'contains', value: this.searchText },
          { field: 'hashtags', operator: 'contains', value: this.searchText },
        ],
      },
    });

    this.gridView.data = filteredData.data;
    this.hashtagList = filteredGridData.data;
  }

  public loadItems(): void {
    this.gridView = {
      data: this.originalhashtagList.slice(this.skip, this.skip + this.pageSize),
      total: this.originalhashtagList.length,
    };
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.searchText = '';
    this.loadItems();
  }

  onEditCollection(id: any): void {
    const dialogRef = this.dialogService.open({
      content: CreateHashtagComponent,
      width: 450,
    });
    dialogRef.content.instance.hashTagId = id;

    dialogRef.result.subscribe((r: { [key: string]: string }) => {
      this.getList();
    });
  }

  addHashtag(): void {
    const dialogRef = this.dialogService.open({
      content: CreateHashtagComponent,
      width: 450,
    });

    dialogRef.result.subscribe((r: { [key: string]: string }) => {
      this.getList();
    });
  }

  delete(hashtag: any): void {
    swal({
      title: 'Are you sure?',
      text:
        'The hashtag will be deleted. Do you confirm that : ' +
        hashtag.hashtagCollectionName +
        ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
    }).then(confirmed => {
      if (confirmed) {
        this.hashtagService.delete(hashtag.id).subscribe(res => {
          this.toasterService.success(
            NotificationMessage.deleteHastagSuccessMsg,
            '',
            this.commonService.toasterMessageConfiguration,
          );
          this.searchText = '';
          this.getList();
        });
      }
    });
  }

  showListView(isListView): void {
    this.isListView = isListView;
  }

  onSort(event) {
    this.sorting = event.column.prop + ' ' + event.column.newValue;
    this.getList();
  }

  calculateGridHeight(): void {
    const screenHeight = window.innerHeight;
    const headerHeight = 88;
    const gridHeaderHeight = 69;
    const wrapperpadding = 60;

    this.gridHeight = screenHeight - (headerHeight + gridHeaderHeight + wrapperpadding);
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadProducts();
  }

  private loadProducts(): void {
    this.gridView = {
      data: orderBy(this.hashtagList, this.sort),
      total: this.hashtagList.length,
    };
  }
}
