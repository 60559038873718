import { mapEnumToOptions } from '@abp/ng.core';

export enum EinvoicingMethodEnum {
  UnKnown = 0,
  Automatic = 1,
  Manual = 2,
  SiteDefault = 3,
}

export const einvoicingMethodEnumOptions = mapEnumToOptions(EinvoicingMethodEnum);
