<div class="container-wrapper">
  <ng-container *ngIf="this.authService.isAuthenticated; else notAuthenticated">
    <lib-q-menu
      [mode]="mode"
      [logoImage]="logoImage"
      [iconlogoImage]="iconlogoImage"
      [profileImage]="profileImage"
      [headerData]="headerDataList"
      [items]="menuList"
      [downarrowImage]="downarrowImage"
      (triggerAdminClick)="onHeaderClick($event)"
      [availableCredits]="availableCredits"
      [validDate]="validDate"
      [isWalletVisible]="isWalletVisible"
      (triggerWalletClick)="onWalletClick($event)"
      [currentUser]="currentUser"
      #qMenu
    >
      <router-outlet></router-outlet>
    </lib-q-menu>
  </ng-container>
  <ng-template #notAuthenticated>
    <router-outlet></router-outlet>
  </ng-template>
</div>
