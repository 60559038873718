import type { AddonPriceIds } from '../subscription/models';
import type { HostedPage_StateEnum } from './hosted-page-state-enum.enum';
import type { HostedPage_TypeEnum } from './hosted-page-type-enum.enum';

export interface CreateIndividualCheckout {
  customerId?: string;
  planPriceId?: string;
  redirectionUrl?: string;
  cancelUrl?: string;
  frequency: number;
  addonPriceId: AddonPriceIds[];
}

export interface HostedPage {
  createdAt?: string;
  embed: boolean;
  expiresAt?: string;
  id?: string;
  resourceVersion?: number;
  state?: HostedPage_StateEnum;
  hostedPageType?: HostedPage_TypeEnum;
  updatedAt?: string;
  url?: string;
  businessEntityId?: string;
}
