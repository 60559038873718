import { mapEnumToOptions } from '@abp/ng.core';

export enum Customer_VatNumberStatusEnum {
  UnKnown = 0,
  Valid = 1,
  Invalid = 2,
  NotValidated = 3,
  Undetermined = 4,
}

export const customer_VatNumberStatusEnumOptions = mapEnumToOptions(Customer_VatNumberStatusEnum);
