<div class="q-content" *ngIf="this.authService.isAuthenticated">
  <div class="q-content-header">
    <div class="d-flex gap-15 align-items-center justify-content-between wp-100 mb-2 flex-wrap">
      <kendo-textbox placeholder="Search Here" [(ngModel)]="list.filter" class="bg-white search">
        <ng-template kendoTextBoxPrefixTemplate>
          <i class="fas fa-search k-icon"></i>
        </ng-template>
      </kendo-textbox>
      <abp-page-toolbar [record]="data.items"></abp-page-toolbar>
    </div>
  </div>
  <div class="q-content-bg">
    <app-custom-extensible-table
      [data]="data.items || []"
      [recordsTotal]="data.totalCount || 0"
      [list]="list"
    ></app-custom-extensible-table>
  </div>
</div>
<div class="authentication-settings">
  <kendo-dialog
    title=" {{
      (selected?.id ? 'AbpTenantManagement::Edit' : 'AbpTenantManagement::NewTenant')
        | abpLocalization
    }}"
    *ngIf="isAddEditOpened"
    (close)="close('cancel')"
    [width]="450"
  >
    <form [formGroup]="tenantForm" (ngSubmit)="save()" [validateOnSubmit]="true">
      <abp-extensible-form [selectedRecord]="selected"></abp-extensible-form>
    </form>
    <kendo-dialog-actions>
      <button kendoButton (click)="close('no')" fillMode="outline">Cancel</button>
      <div [ngClass]="{ 'curser-not-allowd': tenantForm.invalid }" class="display-inline flex-0">
        <button kendoButton (click)="save()" [disabled]="tenantForm.invalid" themeColor="primary">
          {{ 'AbpIdentity::Save' | abpLocalization }}
        </button>
      </div>
    </kendo-dialog-actions>
  </kendo-dialog>

  <kendo-dialog *ngIf="isAddEditFeatureOpened" (close)="closeFeature('cancel')" [minWidth]="250">
    <app-custom-manage-feature
      #abpFeatureManagement="abpFeatureManagement"
      *abpReplaceableTemplate="
        {
          inputs: {
            providerName: { value: 'T' },
            providerKey: { value: providerKey },
            visible: { value: visibleFeatures, twoWay: true }
          },
          outputs: { visibleChange: onVisibleFeaturesChange },
          componentKey: featureManagementKey
        };
        let init = initTemplate
      "
      [(visible)]="visibleFeatures"
      providerName="T"
      [providerKey]="providerKey"
      (abpInit)="init(abpFeatureManagement)"
    >
    </app-custom-manage-feature>
  </kendo-dialog>
</div>
