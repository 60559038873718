<div class="q-content">
  <div class="q-content-header">
    <div
      class="d-flex gap-15 align-items-center justify-content-between wp-100 mb-2 d-flex-sm-column"
    >
      <kendo-dropdownlist
        [data]="filterTypes | useEnumValues"
        textField="value"
        valueField="key"
        placeholder="value"
        [(ngModel)]="selectedCustomValue"
        [ngModelOptions]="{ standalone: true }"
        [value]="selectedCustomValue"
        [valuePrimitive]="true"
        (valueChange)="onCustomChange()"
        class="bg-white wp-100-sm w-260"
      ></kendo-dropdownlist>
      <kendo-dropdownlist
        [data]="filteredAccountList"
        textField="accountName"
        valueField="id"
        [(ngModel)]="selectedAcc"
        [ngModelOptions]="{ standalone: true }"
        [value]="selectedAcc"
        [valuePrimitive]="true"
        (valueChange)="getSocialAccountsBySocialAccountId()"
        class="bg-white wp-100-sm w-260"
      >
        <ng-template kendoDropDownListItemTemplate let-dataItem>
          <ng-container *ngIf="dataItem.img; else noImageTemplate">
            <kendo-avatar
              rounded="full"
              size="large"
              [border]="true"
              [imageSrc]="dataItem.img"
              class="social-profile-image"
            ></kendo-avatar>
          </ng-container>

          <ng-template #noImageTemplate>
            <kendo-avatar
              rounded="full"
              size="large"
              [border]="true"
              [initials]="commonService.getInitials(dataItem.accountName)"
              class="social-profile-text"
            ></kendo-avatar>
          </ng-template>
          {{ dataItem.accountName }}
          {{ commonService.getProfileName(dataItem.accountType) }}
        </ng-template>
      </kendo-dropdownlist>
    </div>
  </div>
  <div>
    <div class="d-flex-row">
      <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex">
        <div class="q-card mb-20 flex-1">
          <div class="q-card-header d-flex justify-content-between">
            <div>
              <p class="q-card-title">{{ socialAccountsBySocialAccountId?.card1?.cardName }}</p>
              <p class="q-card-value m-0">
                {{
                  socialAccountsBySocialAccountId?.card1?.total >= 1000
                    ? socialAccountsBySocialAccountId?.card1?.total / 1000 + 'K'
                    : socialAccountsBySocialAccountId?.card1?.total
                }}
              </p>
            </div>
            <div class="q-card-icon q-card-icon-first">
              <i class="far fa-user"></i>
            </div>
          </div>
          <div class="q-card-body pb-0">
            <p class="m-0">
              {{ socialAccountsBySocialAccountId?.card1?.increase }} {{ filterTypeForCountCard }}
            </p>
          </div>
        </div>
      </div>

      <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex">
        <div class="q-card mb-20 flex-1">
          <div class="q-card-header d-flex justify-content-between">
            <div>
              <p class="q-card-title">{{ socialAccountsBySocialAccountId?.card2?.cardName }}</p>
              <p class="q-card-value m-0">
                {{
                  socialAccountsBySocialAccountId?.card2?.total >= 1000
                    ? socialAccountsBySocialAccountId?.card2?.total / 1000 + 'K'
                    : socialAccountsBySocialAccountId?.card2?.total
                }}
              </p>
            </div>
            <div class="q-card-icon q-card-icon-second">
              <i class="far fa-images"></i>
            </div>
          </div>
          <div class="q-card-body pb-0">
            <p class="m-0">
              {{ socialAccountsBySocialAccountId?.card2?.increase }} {{ filterTypeForCountCard }}
            </p>
          </div>
        </div>
      </div>

      <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex">
        <div class="q-card mb-20 flex-1">
          <div class="q-card-header d-flex justify-content-between">
            <div>
              <p class="q-card-title">{{ socialAccountsBySocialAccountId?.card3?.cardName }}</p>
              <p class="q-card-value m-0">
                {{
                  socialAccountsBySocialAccountId?.card3?.total >= 1000
                    ? socialAccountsBySocialAccountId?.card3?.total / 1000 + 'K'
                    : socialAccountsBySocialAccountId?.card3?.total
                }}
              </p>
            </div>
            <div class="q-card-icon q-card-icon-third">
              <i class="far fa-thumbs-up"></i>
            </div>
          </div>
          <div class="q-card-body pb-0">
            <p class="m-0">
              {{ socialAccountsBySocialAccountId?.card3?.increase }} {{ filterTypeForCountCard }}
            </p>
          </div>
        </div>
      </div>

      <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex">
        <div class="q-card mb-20 flex-1">
          <div class="q-card-header d-flex justify-content-between">
            <div>
              <p class="q-card-title">{{ socialAccountsBySocialAccountId?.card4?.cardName }}</p>
              <p class="q-card-value m-0">
                {{
                  socialAccountsBySocialAccountId?.card4?.total >= 1000
                    ? socialAccountsBySocialAccountId?.card4?.total / 1000 + 'K'
                    : socialAccountsBySocialAccountId?.card4?.total
                }}
              </p>
            </div>
            <div class="q-card-icon q-card-icon-forth">
              <i class="far fa-star"></i>
            </div>
          </div>
          <div class="q-card-body pb-0">
            <p class="m-0">
              {{ socialAccountsBySocialAccountId?.card4?.increase }} {{ filterTypeForCountCard }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="accountType === socialMediaPlatForms.Instagram">
      <div class="d-flex gap-20 mb-20 d-flex-md-column">
        <kendo-card class="wp-100">
          <kendo-card-header class="k-hstack">
            <h1 class="title">Audience Growth</h1>
          </kendo-card-header>
          <kendo-card-body>
            <ng-container
              *ngIf="lineGraphData && lineGraphData.length > 0; else noDataTemplateGrowth"
            >
              <kendo-chart class="h-300" [categoryAxis]="lineGraphDataCatergory">
                <kendo-chart-series>
                  <kendo-chart-series-item type="line" [data]="lineGraphData" [color]="'#003BBE'">
                  </kendo-chart-series-item>
                </kendo-chart-series>
              </kendo-chart>
            </ng-container>
            <ng-template #noDataTemplateGrowth>
              <div
                class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
              >
                <div class="icon growth d-flex justify-content-center align-items-center">
                  <i class="far fa-arrow-up-right-dots"></i>
                </div>
                <p class="subscribers mb-0">0 Data</p>
                <span class="description">There are no data available yet!</span>
              </div>
            </ng-template>
          </kendo-card-body>
        </kendo-card>

        <kendo-card class="wp-100">
          <kendo-card-header class="k-hstack">
            <h1 class="title">Social Action</h1>
          </kendo-card-header>
          <kendo-card-body>
            <ng-container
              *ngIf="barGraphData && barGraphData.length > 0; else noDataTemplateSocial"
            >
              <kendo-chart [categoryAxis]="barGraphDataCatergory">
                <kendo-chart-legend
                  position="bottom"
                  orientation="horizontal"
                  [markers]="{ type: 'circle', width: 8, height: 8 }"
                >
                </kendo-chart-legend>
                <kendo-chart-series>
                  <kendo-chart-series-item
                    [name]="item.name"
                    *ngFor="let item of barGraphData"
                    type="column"
                    [stack]="true"
                    [data]="item.data"
                  >
                    <kendo-chart-series-item-tooltip>
                      <ng-template>
                        {{ item.name }}
                      </ng-template>
                    </kendo-chart-series-item-tooltip>
                  </kendo-chart-series-item>
                </kendo-chart-series>
              </kendo-chart>
            </ng-container>
            <ng-template #noDataTemplateSocial>
              <div
                class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
              >
                <div class="icon social d-flex justify-content-center align-items-center">
                  <i class="far fa-wand-magic-sparkles"></i>
                </div>
                <p class="subscribers mb-0">0 Data</p>
                <span class="description">There are no data available yet!</span>
              </div>
            </ng-template>
          </kendo-card-body>
        </kendo-card>
      </div>
      <div class="d-flex gap-20 mb-20 d-flex-md-column">
        <kendo-card class="wp-100">
          <kendo-card-header class="k-hstack">
            <h1 class="title">Gender Wise Break Down</h1>
          </kendo-card-header>
          <kendo-card-body>
            <ng-container *ngIf="tableData && tableData.length > 0; else noDataTemplateBreakDown">
              <kendo-grid [kendoGridBinding]="tableData">
                <kendo-grid-column
                  title="GENDER"
                  [width]="50"
                  [class]="{ 'text-center': true }"
                  [headerClass]="'headerCustomClass'"
                >
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <p class="fw-normal text-align-left mb-0">{{ dataItem.gender }}</p>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="CUR.COUNT" [width]="50" [class]="{ 'text-center': true }">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <p class="fw-normal text-align-left mb-0">{{ dataItem.currentCount }}</p>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="PRE.COUNT" [width]="50" [class]="{ 'text-center': true }">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <p class="fw-normal text-align-left mb-0">{{ dataItem.previousCount }}</p>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column
                  title="PERCENTAGE"
                  [width]="50"
                  [class]="{ 'text-center': true }"
                >
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <p class="fw-normal text-align-left mb-0">{{ dataItem.percentage }}%</p>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
            </ng-container>
            <ng-template #noDataTemplateBreakDown>
              <div
                class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
              >
                <div class="icon details d-flex justify-content-center align-items-center">
                  <i class="far fa-table"></i>
                </div>
                <p class="subscribers mb-0">0 Break Down</p>
                <span class="description">There are no Break Down yet!</span>
              </div>
            </ng-template>
          </kendo-card-body>
        </kendo-card>

        <kendo-card class="wp-100">
          <kendo-card-header class="k-hstack">
            <h1 class="title">Performance Snapshot</h1>
          </kendo-card-header>
          <kendo-card-body>
            <ng-container
              *ngIf="donutGraphData && donutGraphData.length > 0; else noDataTemplatePerformance"
            >
              <kendo-chart class="h-300">
                <kendo-chart-series>
                  <kendo-chart-series-item
                    type="donut"
                    [data]="donutGraphData"
                    categoryField="name"
                    field="data"
                  >
                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend
                  position="right"
                  [markers]="{ type: 'circle', width: 8, height: 8 }"
                ></kendo-chart-legend>
                <kendo-chart-tooltip>
                  <ng-template
                    kendoChartSeriesTooltipTemplate
                    let-value="value"
                    let-category="category"
                  >
                    {{ category }}:{{ value }}
                  </ng-template>
                </kendo-chart-tooltip>
              </kendo-chart>
            </ng-container>
            <ng-template #noDataTemplatePerformance>
              <div
                class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
              >
                <div class="icon performance d-flex justify-content-center align-items-center">
                  <i class="far fa-arrow-up-right-dots"></i>
                </div>
                <p class="subscribers mb-0">0 Performance Snapshot</p>
                <span class="description">There are no Performance Snapshot yet!</span>
              </div>
            </ng-template>
          </kendo-card-body>
        </kendo-card>
      </div>
    </div>
    <div *ngIf="accountType === socialMediaPlatForms.Facebook">
      <div class="d-flex gap-20 mb-20 d-flex-md-column">
        <kendo-card class="wp-100">
          <kendo-card-header class="k-hstack">
            <h1 class="title">Audience Growth</h1>
          </kendo-card-header>
          <kendo-card-body>
            <ng-container
              *ngIf="lineGraphData && lineGraphData.length > 0; else noDataTemplateGrowth"
            >
              <kendo-chart class="h-300" [categoryAxis]="lineGraphDataCatergory">
                <kendo-chart-series>
                  <kendo-chart-series-item type="line" [data]="lineGraphData" [color]="'#003BBE'">
                  </kendo-chart-series-item>
                </kendo-chart-series>
              </kendo-chart>
            </ng-container>
            <ng-template #noDataTemplateGrowth>
              <div
                class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
              >
                <div class="icon growth d-flex justify-content-center align-items-center">
                  <i class="far fa-arrow-up-right-dots"></i>
                </div>
                <p class="subscribers mb-0">0 Data</p>
                <span class="description">There are no data available yet!</span>
              </div>
            </ng-template>
          </kendo-card-body>
        </kendo-card>

        <kendo-card class="wp-100">
          <kendo-card-header class="k-hstack">
            <h1 class="title">Social Action</h1>
          </kendo-card-header>
          <kendo-card-body>
            <ng-container
              *ngIf="barGraphData && barGraphData.length > 0; else noDataTemplateSocial"
            >
              <kendo-chart class="h-300" [categoryAxis]="barGraphDataCatergory">
                <kendo-chart-legend
                  position="bottom"
                  orientation="horizontal"
                  [markers]="{ type: 'circle', width: 8, height: 8 }"
                >
                </kendo-chart-legend>
                <kendo-chart-series>
                  <kendo-chart-series-item
                    [name]="item.name"
                    *ngFor="let item of barGraphData"
                    type="column"
                    [stack]="true"
                    [data]="item.data"
                  >
                    <kendo-chart-series-item-tooltip>
                      <ng-template>
                        {{ item.name }}
                      </ng-template>
                    </kendo-chart-series-item-tooltip>
                  </kendo-chart-series-item>
                </kendo-chart-series>
              </kendo-chart>
            </ng-container>
            <ng-template #noDataTemplateSocial>
              <div
                class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
              >
                <div class="icon social d-flex justify-content-center align-items-center">
                  <i class="far fa-wand-magic-sparkles"></i>
                </div>
                <p class="subscribers mb-0">0 Data</p>
                <span class="description">There are no data available yet!</span>
              </div>
            </ng-template>
          </kendo-card-body>
        </kendo-card>
      </div>
      <div class="d-flex gap-20 mb-20 d-flex-md-column">
        <kendo-card class="wp-100">
          <kendo-card-header class="k-hstack">
            <h1 class="title">Country Breakdown</h1>
          </kendo-card-header>
          <kendo-card-body>
            <ng-container *ngIf="tableData && tableData.length > 0; else noDataTemplateDetails">
              <kendo-grid [kendoGridBinding]="tableData" [style.max-height.px]="300">
                <kendo-grid-column title=" COUNTRY" [width]="50" [class]="{ 'text-center': true }">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <p class="fw-normal text-align-left mb-0">{{ dataItem.gender }}</p>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="SESSIONS" [width]="50" [class]="{ 'text-center': true }">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <p class="fw-normal text-align-left mb-0">{{ dataItem.currentCount }}</p>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="USERS" [width]="50" [class]="{ 'text-center': true }">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <p class="fw-normal text-align-left mb-0">{{ dataItem.previousCount }}</p>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="CTR" [width]="50" [class]="{ 'text-center': true }">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <p class="fw-normal text-align-left mb-0">{{ dataItem.percentage }}%</p>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
            </ng-container>
            <ng-template #noDataTemplateDetails>
              <div
                class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
              >
                <div class="icon details d-flex justify-content-center align-items-center">
                  <i class="far fa-table"></i>
                </div>
                <p class="subscribers mb-0">0 Details</p>
                <span class="description">There are no details yet!</span>
              </div>
            </ng-template>
          </kendo-card-body>
        </kendo-card>
        <kendo-card class="wp-100">
          <kendo-card-header class="k-hstack">
            <h1 class="title">Performance Snapshot</h1>
          </kendo-card-header>
          <kendo-card-body>
            <ng-container
              *ngIf="donutGraphData && donutGraphData.length > 0; else noDataTemplatePerformance"
            >
              <kendo-chart class="h-300">
                <kendo-chart-series>
                  <kendo-chart-series-item
                    type="donut"
                    [data]="donutGraphData"
                    categoryField="name"
                    field="data"
                  >
                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend
                  position="right"
                  [markers]="{ type: 'circle', width: 8, height: 8 }"
                ></kendo-chart-legend>
                <kendo-chart-tooltip>
                  <ng-template
                    kendoChartSeriesTooltipTemplate
                    let-value="value"
                    let-category="category"
                  >
                    {{ category }}:{{ value }}
                  </ng-template>
                </kendo-chart-tooltip>
              </kendo-chart>
            </ng-container>
            <ng-template #noDataTemplatePerformance>
              <div
                class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
              >
                <div class="icon view d-flex justify-content-center align-items-center">
                  <i class="far fa-chart-simple"></i>
                </div>
                <p class="subscribers mb-0">0 Data</p>
                <span class="description">There are no data yet!</span>
              </div>
            </ng-template>
          </kendo-card-body>
        </kendo-card>
      </div>
    </div>
    <div *ngIf="accountType === socialMediaPlatForms.LinkedInOrganization">
      <div class="d-flex gap-20 mb-20 d-flex-md-column">
        <kendo-card class="wp-100">
          <kendo-card-header class="k-hstack">
            <h1 class="title">Audience Growth</h1>
          </kendo-card-header>
          <kendo-card-body>
            <ng-container
              *ngIf="!showNoChart && lineGraphData.length > 0; else noDataTemplateGrowth"
            >
              <kendo-chart class="h-300" [categoryAxis]="lineGraphDataCatergory">
                <kendo-chart-series>
                  <kendo-chart-series-item type="line" [data]="lineGraphData" [color]="'#003BBE'">
                  </kendo-chart-series-item>
                </kendo-chart-series>
              </kendo-chart>
            </ng-container>
            <ng-template #noDataTemplateGrowth>
              <div
                class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
              >
                <div class="icon growth d-flex justify-content-center align-items-center">
                  <i class="far fa-arrow-up-right-dots"></i>
                </div>
                <p class="subscribers mb-0">0 Data</p>
                <span class="description">There are no data available yet!</span>
              </div>
            </ng-template>
          </kendo-card-body>
        </kendo-card>

        <kendo-card class="wp-100">
          <kendo-card-header class="k-hstack">
            <h1 class="title">Social Action</h1>
          </kendo-card-header>
          <kendo-card-body>
            <ng-container
              *ngIf="barGraphData && barGraphData.length > 0; else noDataTemplateSocial"
            >
              <kendo-chart class="h-300" [categoryAxis]="barGraphDataCatergory">
                <kendo-chart-legend
                  position="bottom"
                  orientation="horizontal"
                  [markers]="{ type: 'circle', width: 8, height: 8 }"
                >
                </kendo-chart-legend>
                <kendo-chart-series>
                  <kendo-chart-series-item
                    [name]="item.name"
                    *ngFor="let item of barGraphData"
                    type="column"
                    [stack]="true"
                    [data]="item.data"
                  >
                    <kendo-chart-series-item-tooltip>
                      <ng-template>
                        {{ item.name }}
                      </ng-template>
                    </kendo-chart-series-item-tooltip>
                  </kendo-chart-series-item>
                </kendo-chart-series>
              </kendo-chart>
            </ng-container>
            <ng-template #noDataTemplateSocial>
              <div
                class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
              >
                <div class="icon social d-flex justify-content-center align-items-center">
                  <i class="far fa-chart-simple"></i>
                </div>
                <p class="subscribers mb-0">0 Data</p>
                <span class="description">There are no data available yet!</span>
              </div>
            </ng-template>
          </kendo-card-body>
        </kendo-card>
      </div>
      <kendo-card class="wp-100">
        <kendo-card-header class="k-hstack">
          <h1 class="title">Page Views</h1>
        </kendo-card-header>
        <kendo-card-body>
          <ng-container
            *ngIf="donutGraphData && donutGraphData.length > 0; else noDataTemplateView"
          >
            <kendo-chart class="h-300">
              <kendo-chart-series>
                <kendo-chart-series-item
                  type="donut"
                  [data]="donutGraphData"
                  categoryField="name"
                  field="data"
                >
                </kendo-chart-series-item>
              </kendo-chart-series>
              <kendo-chart-legend
                position="right"
                [markers]="{ type: 'circle', width: 8, height: 8 }"
              ></kendo-chart-legend>
              <kendo-chart-tooltip>
                <ng-template
                  kendoChartSeriesTooltipTemplate
                  let-value="value"
                  let-category="category"
                >
                  {{ category }}:{{ value }}
                </ng-template>
              </kendo-chart-tooltip>
            </kendo-chart>
          </ng-container>
          <ng-template #noDataTemplateView>
            <div
              class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
            >
              <div class="icon view d-flex justify-content-center align-items-center">
                <i class="far fa-eye"></i>
              </div>
              <p class="subscribers mb-0">0 Views</p>
              <span class="description">There are no views yet!</span>
            </div>
          </ng-template>
        </kendo-card-body>
      </kendo-card>
    </div>
  </div>
</div>
