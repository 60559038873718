import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { HashtagService } from '@proxy/hashtags';
import { CreateUpdateHashtagDto } from '@proxy/hashtags/dtos';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService, NotificationMessage } from 'src/app';

@Component({
  selector: 'app-create-hashtag',
  templateUrl: './create-hashtag.component.html',
  styleUrls: ['./create-hashtag.component.scss'],
})
export class CreateHashtagComponent implements OnInit {
  hashtagForm: FormGroup;
  hashtagCount: any;

  @Input() hashTagId: any;

  displayStyle = 'none';
  isEdit = false;

  constructor(
    private toasterService: ToasterService,
    private hashtagService: HashtagService,
    private commonService: CommonService,
    private renderer: Renderer2,
    private spinnerService: NgxSpinnerService,
    public dialogRef: DialogRef,
  ) {}

  ngOnInit(): void {
    if (this.hashTagId) {
      this.isEdit = true;
      this.editHashTagForm(this.hashTagId);
    }
    this.displayStyle = 'block';
    this.setForm();
  }

  setForm(): void {
    this.hashtagForm = new FormGroup({
      hashtagCollectionName: new FormControl('', Validators.required),
      hashtags: new FormControl(''),
      hashTagCount: new FormControl(''),
    });
  }

  editHashTagForm(hashTagId): void {
    this.hashtagService.get(hashTagId).subscribe(res => {
      this.hashtagForm.patchValue({
        hashtagCollectionName: res.hashtagCollectionName,
        hashtags: res.hashtags,
      });
    });
  }

  hashTagCount(event): void {
    let pattern = /(#\w+)/gm;
    let matches = [...event.target.value.match(pattern)];
    this.hashtagCount = matches.length;
  }

  saveHashTag(data): void {
    if (this.hashtagForm.invalid) {
      this.commonService.addValidation(this.hashtagForm, this.renderer);
    } else {
      const param: CreateUpdateHashtagDto = {
        hashtagCollectionName: data.hashtagCollectionName,
        hashtags: data.hashtags,
      };

      if (!this.isEdit) {
        this.spinnerService.show();
        this.hashtagService.create(param).subscribe(res => {
          if (res) {
            this.spinnerService.hide();
            this.dialogRef.close();
            this.toasterService.success(
              NotificationMessage.createHastagSuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          }
        });
      } else {
        this.spinnerService.show();
        this.hashtagService.update(this.hashTagId, param).subscribe(res => {
          if (res) {
            this.spinnerService.hide();
            this.dialogRef.close();
            this.toasterService.success(
              NotificationMessage.updateHastagSuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          }
        });
      }
    }
  }

  closePopup(): void {
    this.dialogRef.close();
  }
}
