import type { FullAuditedEntityDto, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import type { SocialAccountType } from '../../enums/social-account-type.enum';
import type { PublishPostStatus } from '../../enums/publish-post-status.enum';

export interface PublishedPostDto extends FullAuditedEntityDto<number> {
  socialAccountType: SocialAccountType;
  publishedDate?: string;
  status?: PublishPostStatus;
  postResponseId?: string;
  postId: number;
  socialAccountId: number;
  scheduleId?: number;
  publishedUrl?: string;
}

export interface PublishedPostGetListInput extends PagedAndSortedResultRequestDto {
  socialAccountType?: SocialAccountType;
  publishedDate?: string;
  status?: PublishPostStatus;
  postResponseId?: string;
  postId?: number;
  socialAccountId?: number;
  scheduleId?: number;
  publishedUrl?: string;
}
