export enum TOOLTIP {
  category = 'Is this a single post, or do you want to categorize it? You can choose a category or leave it uncategorized to be shared later.',
  postAtSpecificTime = 'Schedule the content to be posted at a specified date/time."',
  requeue = 'Queue your post for indefinite resharing until deletion or expiration. Re-queuing is not available for content without a category.',
  expireAfterPublish = 'We will archive re-queued posts after reaching the selected post count.',
  expireAtSpecificDate = 'We will automatically archive this post at the specified time and date.',
  PreviewPost = 'Social networks frequently update their designs; our depiction is a close approximation of the expected appearance upon publication.',
  approved = 'Your post will be saved and shared.',
  draft = 'Your post will be saved but only be shared once it has been approved.',
  previewPostAtSpecificTime = 'Effortlessly plan your upcoming activities by previewing your schedule at a glance.',
  imageVideoSize = 'Upload images up to 5 MB and videos up to 75 MB.',
}

export enum ButtonDescription {
  sharePost = 'Share Post',
  savePost = 'Save Post',
  approved = 'Approved',
  draft = 'Draft',
}
