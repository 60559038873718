import { mapEnumToOptions } from '@abp/ng.core';

export enum ProrationTypeEnum {
  UnKnown = 0,
  FullTerm = 1,
  PartialTerm = 2,
  None = 3,
}

export const prorationTypeEnumOptions = mapEnumToOptions(ProrationTypeEnum);
