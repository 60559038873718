import { mapEnumToOptions } from '@abp/ng.core';

export enum SocialAccountType {
  FacebookPage = 1,
  InstagramBusiness = 2,
  LinkedInProfile = 3,
  TwitterProfile = 4,
  LinkedInOrganization = 5,
  Youtube = 6,
}

export const socialAccountTypeOptions = mapEnumToOptions(SocialAccountType);
