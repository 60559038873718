import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { IActionResult } from '../microsoft/asp-net-core/mvc/models';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  apiName = 'Default';

  downloadPicture = (fileName: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IActionResult>(
      {
        method: 'GET',
        url: `/api/File/downloadPictureByName/${fileName}`,
      },
      { apiName: this.apiName, ...config },
    );

  downloadPostMedia = (fileName: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IActionResult>(
      {
        method: 'GET',
        url: `/api/File/download-post-media/${fileName}`,
      },
      { apiName: this.apiName, ...config },
    );

  downloadProfile = (fileName: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IActionResult>(
      {
        method: 'GET',
        url: `/api/File/download-profile/${fileName}`,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
