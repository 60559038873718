import type { FullAuditedEntityDto } from '@abp/ng.core';
import type { FilterModel } from '../../common/models/models';

export interface CreateUpdateSocialAccountAnalyticDto {
  socialAccountId: number;
  date?: string;
  followers: number;
  reaction: number;
  reach: number;
  engagement: number;
  impression: number;
  share: number;
  saved: number;
}

export interface SocialAccountAnalyticsDto extends FullAuditedEntityDto<number> {
  tenantId?: string;
  socialAccountId: number;
  date?: string;
  followers: number;
  reaction: number;
  reach: number;
  engagement: number;
  impression: number;
  share: number;
  saved: number;
}

export interface SocialAccountAnalyticsInput {
  socialAccountId: number;
  analyticsType: number[];
  dateFilter: FilterModel;
}
