import { mapEnumToOptions } from '@abp/ng.core';

export enum EntityCodeEnum {
  UnKnown = 0,
  A = 1,
  B = 2,
  C = 3,
  D = 4,
  E = 5,
  F = 6,
  G = 7,
  H = 8,
  I = 9,
  J = 10,
  K = 11,
  L = 12,
  M = 13,
  N = 14,
  P = 15,
  Q = 16,
  R = 17,
  Med1 = 18,
  Med2 = 19,
}

export const entityCodeEnumOptions = mapEnumToOptions(EntityCodeEnum);
